import { DEVELOPMENT_MODE } from '../helper/logger';

export const DOMAIN_URL = DEVELOPMENT_MODE
    ? process.env.NEXT_PUBLIC_ENV_WEBAPP_HOST_NAME || '' // Development mode will use empty string as a fallback
    : process.env.NEXT_PUBLIC_ENV_WEBAPP_HOST_NAME; // Production will throw an error if env variable not exists
export const AUTH_CLIENT_URL = process.env.NEXT_PUBLIC_ENV_AUTH_CLIENT_URL;
export const ADMIN_URL = process.env.NEXT_PUBLIC_ENV_ADMIN_HOST_NAME;
export const CINEMA_URL = process.env.NEXT_PUBLIC_ENV_CINEMA_HOST_NAME;
export const DISTRIBUTOR_URL = process.env.NEXT_PUBLIC_ENV_DISTRIBUTOR_HOST_NAME;
export const CDN_URL = process.env.NEXT_PUBLIC_ENV_CDN_HOST_NAME;
export const PAYMENT_URL = process.env.NEXT_PUBLIC_ENV_PAYMENT_HOST_NAME;

export const SIGNOUT_URL = (locale?: string): string =>
    !AUTH_CLIENT_URL?.startsWith('/') // Check if AUTH_URL is equal to e.g. '/login' or another domain like 'staging.cineamo.com'
        ? `${AUTH_CLIENT_URL + (locale ? `/${locale}` : '')}/logout`
        : `${locale ? `/${locale}` : ''}/`;

export const SIGNIN_URL = (locale?: string): string =>
    !AUTH_CLIENT_URL?.startsWith('/') // Check if AUTH_URL is equal to e.g. '/login' or another domain like 'staging.cineamo.com'
        ? AUTH_CLIENT_URL + (locale ? `/${locale}` : '')
        : AUTH_CLIENT_URL;

export const REDIRECT_URL = (
    redirectTargetRoot: string,
    locale?: string,
    redirectTargetParams?: string,
    urlParams?: string
): string => {
    let url = !redirectTargetRoot?.startsWith('/') // Check if redirectTarget is equal to e.g. '/login' or another domain like 'staging.cineamo.com'
        ? // if redirectTarget is equal to '/login'
          redirectTargetRoot + (locale ? `/${locale}` : '') + redirectTargetParams
        : // if redirectTarget is equal to 'staging.cineamo.com'
          (locale ? `/${locale}` : '') + redirectTargetRoot + redirectTargetParams + urlParams;
    if (!!urlParams && urlParams.length > 0) {
        url += urlParams;
    } else {
        // '?target=' needs to be present in url so the option for return can be given
        url += '?target=';
    }
    return url;
};

export const PLAY_STORE_URL =
    'https://play.google.com/store/apps/details?id=org.mpp.cineamo&referrer=utm_source%3Dcineamo.com%26utm_medium%3Dlandingpage%26utm_campaign%3Dwebsite-cta';
export const APP_STORE_URL =
    'https://apps.apple.com/app/apple-store/id1447730281?pt=120760330&ct=Cineamo-Landing-Page&mt=8';

export const KINOCLOUD_CINEAMO_MOVIELIBRARY_URL = 'https://app.kinocloud.de/pages/filmbibliothek/apps/content/app';

export const OUTLOOK_APPOINTMENT_LINK =
    'https://outlook.office365.com/owa/calendar/CineamoGmbH@cineamo.com/bookings/s/P2cPaNlk1k-Vt8BL1MbzLg2';
