import classnames from 'classnames';
import React from 'react';

export type WebsiteContentProps = {
    content?: JSX.Element;
    contentClassName?: string;
};

function WebsiteContent(props: WebsiteContentProps): JSX.Element {
    const { content, contentClassName } = props;

    return (
        <div className={classnames(contentClassName, 'flex justify-center')}>
            <div className="container space-y-48 lg:space-y-148">{content}</div>
        </div>
    );
}

export default WebsiteContent;
