import { useTranslation } from 'next-i18next';
import React from 'react';

import { adminNavigation } from '@/components/navigation/data/adminNavigation';
import NavigationItemGroup, { NavigationItemGroupProps } from '@/components/navigation/NavigationItemGroup';
import SideNavigationItem from '@/components/navigation/SidebarNavigationItem';

import { IconDashboard24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-dashboard';

function NavigationSwitch(props: { modalOpeners: NavigationItemGroupProps['modalOpeners'] }): JSX.Element {
    const { t } = useTranslation('navigation');

    return (
        <>
            <SideNavigationItem
                link="/"
                icon={<IconDashboard24 />}
                text={t('navigation.dashboard')}
            />
            {adminNavigation().map((group, i) => (
                <NavigationItemGroup
                    key={i}
                    navigation={group}
                    modalOpeners={props.modalOpeners}
                />
            ))}
        </>
    );
}

export default NavigationSwitch;
