import React, { useState } from 'react';

/*
 * For how to use guide see Tooltip.md
 */

export type TooltipProps = {
    children?: React.ReactNode;
    className?: string;
    tooltipText?: string;
    fadeInDuration?: TooltipFadeInDuration;
    delay?: number;
    direction?: TooltipDirection;
};

export enum TooltipDirection {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left'
}

export enum TooltipFadeInDuration {
    _75 = 'duration-75',
    _100 = 'duration-100',
    _150 = 'duration-150',
    _200 = 'duration-200',
    _300 = 'duration-300',
    _500 = 'duration-500',
    _700 = 'duration-700',
    _1000 = 'duration-1000'
}

function Tooltip(props: TooltipProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let showTimeout;
    let hiddenTimeout;
    const [active, setActive] = useState(false);
    const [hidden, setHidden] = useState(true);

    const showTip = () => {
        setHidden(false);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clearInterval(hiddenTimeout);
        showTimeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 50);
    };

    const hideTip = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clearInterval(showTimeout);
        setActive(false);
        hiddenTimeout = setTimeout(() => {
            setHidden(true);
        }, props.delay || 50);
    };

    return (
        <div className={`${props.className || ''} Tooltip-Wrapper z-modal-view`}>
            <div
                onMouseEnter={showTip}
                onMouseLeave={hideTip}>
                {props.children}
            </div>
            <div
                className={`Tooltip-Tip ${props.direction || TooltipDirection.Top} transition-opacity ${
                    props.fadeInDuration || TooltipFadeInDuration._150
                } ${active ? 'opacity-100' : ''} opacity-0 ${hidden ? 'hidden' : ''}`}>
                {props.tooltipText}
            </div>
        </div>
    );
}

export default Tooltip;
