// Base Url
export const TMDB_BASE_URL = 'https://www.themoviedb.org/';
export const TMDB_MOVIE_URL: (movieId: number | string) => string = (movieId) => {
    return `${TMDB_BASE_URL}movie/${movieId}`;
};
export const TMDB_SERIES_URL: (seriesId: number | string) => string = (seriesId) => {
    return `${TMDB_BASE_URL}tv/${seriesId}`;
};
export const TMDB_MOVIE_REVIEWS_URL: (movieId: string) => string = (movieId) => {
    return `${TMDB_BASE_URL}movie/${movieId}/reviews`;
};
export const TMDB_SERIES_REVIEWS_URL: (seriesId: string) => string = (seriesId) => {
    return `${TMDB_BASE_URL}tv/${seriesId}/reviews`;
};
export const TMDB_PERSON_URL: (personId: string) => string = (personId) => {
    return `${TMDB_BASE_URL}person/${personId}`;
};

// Videos
export enum VideoSites {
    YouTube = 'YouTube',
    Vimeo = 'Vimeo'
}

export enum TMDB_IMAGE_RESOLUTION {
    W92 = 'https://image.tmdb.org/t/p/w92',
    W152 = 'https://image.tmdb.org/t/p/w152',
    W185 = 'https://image.tmdb.org/t/p/w185',
    W342 = 'https://image.tmdb.org/t/p/w342',
    W500 = 'https://image.tmdb.org/t/p/w500',
    W780 = 'https://image.tmdb.org/t/p/w780',
    W1280 = 'https://image.tmdb.org/t/p/w1280',
    ORIGINAL = 'https://image.tmdb.org/t/p/original'
}
