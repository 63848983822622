import classnames from 'classnames';
import React, { ReactElement } from 'react';

function TwoColumnsOnLg(props: {
    left?: JSX.Element;
    right?: JSX.Element;
    className?: string;
    leftExtraClass?: string;
    rightExtraClass?: string;
    leftLarge?: boolean;
    rightLarge?: boolean;
}): ReactElement {
    return (
        <div className={classnames(props.className, 'flex flex-col lg:flex-row')}>
            <div
                className={classnames(props.leftExtraClass, 'flex flex-col h-auto', {
                    'w-full lg:w-1/2': props.right && !props.leftLarge,
                    'w-full lg:w-2/3': props.right && props.leftLarge,
                    'w-full lg:w-1/3': props.right && props.rightLarge
                })}>
                {props.left}
            </div>

            {props.right && (
                <div
                    className={classnames(props.rightExtraClass, 'flex flex-col', {
                        'w-full lg:w-1/2': props.left && !props.rightLarge,
                        'w-full lg:w-2/3': props.left && props.rightLarge,
                        'w-full lg:w-1/3': props.left && props.leftLarge
                    })}>
                    {props.right}
                </div>
            )}
        </div>
    );
}

export default TwoColumnsOnLg;
