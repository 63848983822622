export const dateToday = new Date();
export const dateTomorrow = () => {
    const date = new Date(dateToday);
    date.setDate(dateToday.getDate() + 1);
    return date;
};

export function dateTodayMinus(days = 0, hours = 0, minutes = 0, seconds = 0) {
    return new Date(
        new Date().getTime() -
            (1000 * seconds + 1000 * 60 * minutes + 1000 * 60 * 60 * hours + 1000 * 60 * 60 * 24 * days)
    );
}

export function dateTodayPlus(days = 0, hours = 0, minutes = 0, seconds = 0) {
    return new Date(
        new Date().getTime() +
            (1000 * seconds + 1000 * 60 * minutes + 1000 * 60 * 60 * hours + 1000 * 60 * 60 * 24 * days)
    );
}

export function datePlus(date: Date, days = 0, hours = 0, minutes = 0, seconds = 0) {
    return new Date(
        date.getTime() + (1000 * seconds + 1000 * 60 * minutes + 1000 * 60 * 60 * hours + 1000 * 60 * 60 * 24 * days)
    );
}

/** @deprecated Use new Date(<date_string>) directly */
export function parseDate(date: string | Date): Date {
    return new Date(date);
}

export type DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export function formatDate(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    const tmpDate: Date = new Date(date);
    if (tmpDate) {
        return new Intl.DateTimeFormat(
            locales || 'de-DE',
            options || {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            }
        ).format(tmpDate);
    }
    return null;
}

export function formatDay(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    if (date) {
        return formatDate(
            date,
            locales || 'de-DE',
            options || {
                weekday: 'short'
            }
        );
    }
    return null;
}

export function formatDayNumeric(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    if (date) {
        return formatDate(
            date,
            locales || 'de-DE',
            options || {
                day: 'numeric'
            }
        );
    }
    return null;
}

export function formatMonthLong(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    if (date) {
        return formatDate(
            date,
            locales || 'de-DE',
            options || {
                month: 'long'
            }
        );
    }
    return null;
}

export function formatYearNumeric(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    if (date) {
        return formatDate(
            date,
            locales || 'de-DE',
            options || {
                year: 'numeric'
            }
        );
    }
    return null;
}

export function formatTime(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    return formatDate(
        date,
        locales,
        options || {
            hour: 'numeric',
            minute: 'numeric'
        }
    );
}

export function formatDateTime(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    return formatDate(
        date,
        locales,
        options || {
            weekday: 'short',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }
    );
}

export function formatDateNumbersOnly(date: string | Date, locales?: string, options?: DateTimeFormatOptions): string {
    return formatDate(
        date,
        locales,
        options || {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }
    );
}

export function formatDateHyphenSeparatedYearMonthDay(date: string | Date): string {
    return formatDate_yyyyMMdd(date);
}

export function formatDate_HHmm24(date: Date, locales?: string): string {
    return formatDate(date, locales, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });
}

export function formatDateForMyRequestsList(date: string | Date, locales?: string): string {
    return formatDate(date, locales, {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).replace('.,', ',');
}

export function formatDayMonth(date: string | Date, locales?: string): string {
    return formatDate(date, locales, {
        day: '2-digit',
        month: '2-digit'
    });
}

export function formatDateWeekdayYearMonthDay(date: string | Date, locales?: string): string {
    return formatDate(date, locales, {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }).replace('.,', ',');
}

export function formatDateWeekdayYearMonthDayHourMinute(date: string | Date, locales?: string): string {
    return formatDate(date, locales, {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }).replace('.,', ',');
}

export function getAge(dateString: string | Date): number {
    const birthDate = new Date(dateString);
    let age = dateToday.getFullYear() - birthDate.getFullYear();
    const m = dateToday.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && dateToday.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

/** @deprecated Use formatDate_yyyyMMddTHHmmssZ or formatDate_yyyyMMdd directly **/
export function createServerDate(value: string | Date) {
    const valueAsDateString = new Date(value);
    valueAsDateString.setUTCHours(0, 0, 0, 0);
    const isoString = valueAsDateString.toISOString();
    return isoString.replace('.000Z', 'Z');
}

export function formatDate_yyyyMMdd(value: Date | string): string {
    const date = new Date(value);
    const localDate = new Date(date.getTime());
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDate_yyyyMMddTHHmmssZ(value: Date | string): string {
    const date = new Date(value);
    const isoString = date.toISOString();
    return isoString.substring(0, 19) + 'Z';
}

export const isDateFuture = (date: string | Date): boolean => {
    if (!date) {
        return false;
    }
    const dateToday = new Date();
    return new Date(date) > dateToday;
};

/* The logical day for a cinema starts at 4am and ends the next day at 3:59am!  */
export function getLogicalStartOfDay(date: Date | string): Date {
    const logicalDatetime: Date = new Date(date);
    logicalDatetime.setHours(4, 0, 0);
    return logicalDatetime;
}
export function getLogicalEndOfDay(date: Date | string): Date {
    const logicalDatetime: Date = new Date(date);
    logicalDatetime.setHours(3, 59, 0);
    logicalDatetime.setDate(logicalDatetime.getDate() + 1);
    return logicalDatetime;
}

export function getWeekNumber(date: Date): number {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getWeeksBetweenDates(
    startDate: Date,
    endDate: Date
): { weekNumber: number; startDate: Date; endDate: Date }[] {
    const weeks: { weekNumber: number; startDate: Date; endDate: Date }[] = [];

    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const weekStart = new Date(currentDate);
        const weekNumber = getWeekNumber(currentDate);
        currentDate.setDate(currentDate.getDate() + 6); // move to the end of the week
        const weekEnd = currentDate > endDate ? new Date(endDate) : new Date(currentDate);

        weeks.push({
            weekNumber,
            startDate: weekStart,
            endDate: weekEnd
        });

        currentDate.setDate(currentDate.getDate() + 1); // move to the next week's start
    }

    return weeks;
}

export function getDateFourWeeksAgo() {
    const today = new Date();
    today.setDate(today.getDate() - 28);
    return today;
}

export function dateAsStringOrNull(date: string | Date): string {
    return date ? formatDate_yyyyMMddTHHmmssZ(new Date(date)) : null;
}

export function getCinemaWeek(dateInput?: string | Date): {
    isTodayMonday: boolean;
    current: { monday: Date; start: Date; end: Date };
    next: { monday: Date; start: Date; end: Date };
} {
    const date = dateInput ? getLogicalStartOfDay(new Date(dateInput)) : getLogicalStartOfDay(new Date());
    const dayOfWeek = date.getDay();
    const isTodayMonday = dayOfWeek === 1;

    // Find the most recent Thursday
    const daysSinceThursday = (dayOfWeek + 3) % 7;
    const currentStart = new Date(date);
    currentStart.setDate(date.getDate() - daysSinceThursday);
    currentStart.setMilliseconds(0);
    const currentEnd = new Date(currentStart);
    currentEnd.setDate(currentStart.getDate() + 6);
    currentEnd.setMilliseconds(0);

    // Calculate the current and next Monday and Thursday
    const currentMonday = new Date(currentStart);
    currentMonday.setDate(currentStart.getDate() - 3);
    currentMonday.setMilliseconds(0);
    const nextStart = new Date(currentStart);
    nextStart.setDate(currentStart.getDate() + 7);
    nextStart.setMilliseconds(0);
    const nextEnd = new Date(nextStart);
    nextEnd.setDate(nextStart.getDate() + 6);
    nextEnd.setMilliseconds(0);
    const nextMonday = new Date(currentMonday);
    nextMonday.setDate(currentMonday.getDate() + 7);
    nextMonday.setMilliseconds(0);

    return {
        isTodayMonday,
        current: {
            monday: currentMonday,
            start: currentStart,
            end: currentEnd
        },
        next: {
            monday: nextMonday,
            start: nextStart,
            end: nextEnd
        }
    };
}
