import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconId48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <line x1="14" y1="7" x2="21" y2="7"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <line x1="14" y1="10" x2="21" y2="10"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <rect x="1" y="4" width="22" height="16"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"
                  strokeLinejoin="round"/>
            <mask id="path-4-inside-1_10469_208309" fill="white">
                <path fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 18C3 15.7909 4.79086 14 7 14H10C12.2091 14 14 15.7909 14 18V21H3V18Z"
                      fill={props.fill || 'currentColor'}/>
            </mask>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 21V23H16V21H14ZM3 21H1V23H3V21ZM7 16H10V12H7V16ZM12 18V21H16V18H12ZM14 19H3V23H14V19ZM5 21V18H1V21H5ZM10 16C11.1046 16 12 16.8954 12 18H16C16 14.6863 13.3137 12 10 12V16ZM7 12C3.68629 12 1 14.6863 1 18H5C5 16.8954 5.89543 16 7 16V12Z"
                  fill={props.fill || 'currentColor'}
                  mask="url(#path-4-inside-1_10469_208309)"/>
            <circle cx="8.5" cy="9.5" r="2.5" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
        </svg>
    );
}

export function IconId24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21 8H14V6H21V8Z" fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21 11H14V9H21V11Z" fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 4C0 3.44772 0.447715 3 1 3H23C23.5523 3 24 3.44772 24 4V20C24 20.5523 23.5523 21 23 21H1C0.447715 21 0 20.5523 0 20V4ZM2 5V19H22V5H2Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10 16H7C5.89543 16 5 16.8954 5 18V19H12V18C12 16.8954 11.1046 16 10 16ZM7 14C4.79086 14 3 15.7909 3 18V21H14V18C14 15.7909 12.2091 14 10 14H7Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11ZM8.5 13C10.433 13 12 11.433 12 9.5C12 7.567 10.433 6 8.5 6C6.567 6 5 7.567 5 9.5C5 11.433 6.567 13 8.5 13Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

    );
}