import {CustomSVGProps} from '../types/CustomSVGProps.types';

type IconRingingBellProps = CustomSVGProps & {
    unreadSquareFill?: string
};

export function IconRingingBell48(props: IconRingingBellProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="36" y="36" width="12" height="12" rx="2" fill={props.unreadSquareFill || '#00FFDC'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.68398 6.54022L5.34433 6.28135C5.80175 6.23684 6.22989 6.03618 6.55675 5.7131L8.60697 3.68661C9.57049 2.73423 11.1236 2.74325 12.076 3.70674C13.0285 4.67028 13.0195 6.2235 12.0559 7.17592L10.0051 9.20302C9.67962 9.5247 9.4743 9.94824 9.42336 10.403L9.1336 12.9898L2.68398 6.54022ZM1.13772 6.87957L3.24754 8.9894C3.22999 9.01884 3.21309 9.04855 3.19684 9.07853C2.67702 10.0377 2.82655 11.262 3.64148 12.0675C4.4564 12.873 5.68237 13.0083 6.63541 12.4774C6.65672 12.4655 6.67789 12.4533 6.69892 12.4408L8.77475 14.5166C9.14027 14.8821 9.73288 14.8821 10.0984 14.5166C10.2485 14.3665 10.3431 14.1699 10.3667 13.959L10.7484 10.5514C10.7654 10.3998 10.8339 10.2586 10.9424 10.1513L12.9932 8.1242C14.3318 6.80107 14.4769 4.72674 13.4207 3.2409C13.7629 3.0821 14.0003 2.73543 14.0003 2.33333C14.0003 1.78105 13.5525 1.33333 13.0003 1.33333C12.5459 1.33333 12.1623 1.63638 12.0406 2.05138C10.6274 1.33656 8.85802 1.56373 7.66966 2.73833L5.61945 4.76482C5.51047 4.87254 5.36772 4.93945 5.2152 4.95429L1.70545 5.2958C1.49085 5.31668 1.29018 5.41145 1.13772 5.56391C0.774407 5.92722 0.774407 6.51626 1.13772 6.87957ZM4.23914 10.1088L5.59311 11.4471C5.23505 11.5026 4.85627 11.3935 4.5788 11.1192C4.30133 10.845 4.18787 10.4675 4.23914 10.1088Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconRingingBell24(props: IconRingingBellProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.02548 9.81031L8.01601 9.42201C8.70214 9.35525 9.34435 9.05425 9.83464 8.56964L12.91 5.5299C14.3553 4.10134 16.685 4.11486 18.1136 5.5601C19.5422 7.0054 19.5287 9.33524 18.0834 10.7639L15.0071 13.8045C14.5189 14.287 14.211 14.9223 14.1346 15.6045L13.6999 19.4847L4.02548 9.81031ZM1.70609 10.3193L4.87083 13.4841C4.8445 13.5282 4.81915 13.5728 4.79478 13.6178C4.01504 15.0565 4.23934 16.893 5.46173 18.1012C6.68411 19.3095 8.52306 19.5125 9.95262 18.7161C9.98459 18.6983 10.0163 18.68 10.0479 18.6611L13.1616 21.7749C13.7099 22.3232 14.5988 22.3232 15.1471 21.7749C15.3722 21.5498 15.5141 21.2548 15.5496 20.9384L16.1221 15.8271C16.1476 15.5997 16.2503 15.3878 16.4131 15.2269L19.4893 12.1863C21.4972 10.2016 21.7149 7.0901 20.1306 4.86134C20.6439 4.62313 20.9999 4.10313 20.9999 3.49999C20.9999 2.67156 20.3283 1.99999 19.4999 1.99999C18.8183 1.99999 18.2429 2.45456 18.0603 3.07705C15.9406 2.00482 13.2865 2.34558 11.504 4.10748L8.42868 7.14722C8.26521 7.3088 8.05108 7.40915 7.82232 7.43141L2.55769 7.94369C2.23579 7.97501 1.93478 8.11716 1.70609 8.34585C1.16112 8.89081 1.16112 9.77438 1.70609 10.3193ZM6.35823 15.1632L8.38917 17.1707C7.85209 17.2538 7.28391 17.0902 6.86771 16.6788C6.45151 16.2674 6.28132 15.7012 6.35823 15.1632Z"
                  fill={props.fill || 'currentColor'}/>
            <rect x="18" y="18" width="6" height="6" rx="1" fill={props.unreadSquareFill || '#00FFDC'}/>
        </svg>
    );
}

export function IconRingingBell16(props: IconRingingBellProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.68398 6.54022L5.34433 6.28135C5.80175 6.23684 6.22989 6.03618 6.55675 5.7131L8.60697 3.68661C9.57049 2.73423 11.1236 2.74325 12.076 3.70674C13.0285 4.67028 13.0195 6.2235 12.0559 7.17592L10.0051 9.20302C9.67962 9.5247 9.4743 9.94824 9.42336 10.403L9.1336 12.9898L2.68398 6.54022ZM1.13772 6.87957L3.24754 8.9894C3.22999 9.01884 3.21309 9.04855 3.19684 9.07853C2.67702 10.0377 2.82655 11.262 3.64148 12.0675C4.4564 12.873 5.68237 13.0083 6.63541 12.4774C6.65672 12.4655 6.67789 12.4533 6.69892 12.4408L8.77475 14.5166C9.14027 14.8821 9.73288 14.8821 10.0984 14.5166C10.2485 14.3665 10.3431 14.1699 10.3667 13.959L10.7484 10.5514C10.7654 10.3998 10.8339 10.2586 10.9424 10.1513L12.9932 8.1242C14.3318 6.80107 14.4769 4.72674 13.4207 3.2409C13.7629 3.0821 14.0003 2.73543 14.0003 2.33333C14.0003 1.78105 13.5525 1.33333 13.0003 1.33333C12.5459 1.33333 12.1623 1.63638 12.0406 2.05138C10.6274 1.33656 8.85802 1.56373 7.66966 2.73833L5.61945 4.76482C5.51047 4.87254 5.36772 4.93945 5.2152 4.95429L1.70545 5.2958C1.49085 5.31668 1.29018 5.41145 1.13772 5.56391C0.774407 5.92722 0.774407 6.51626 1.13772 6.87957ZM4.23914 10.1088L5.59311 11.4471C5.23505 11.5026 4.85627 11.3935 4.5788 11.1192C4.30133 10.845 4.18787 10.4675 4.23914 10.1088Z"
                  fill={props.fill || 'currentColor'}/>
            <rect x="12" y="12" width="4" height="4" rx="1" fill={props.unreadSquareFill || '#00FFDC'}/>
        </svg>
    );
}