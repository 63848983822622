import classnames from 'classnames';
import { ReactNode, useState } from 'react';

import TopNavigation from '@/components/navigation/TopNavigation';

import { ImageCineamo24 } from '@cineamo/legacy-frontend-lib/src/logos/im-cineamo';

import { IconMenu24 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-menu';

type SidebarLayoutProps = {
    className?: string;
    nav?: JSX.Element;
    content: ReactNode | JSX.Element;
    pageProps: Record<string, unknown>;
};

function SideNavLayout(props: SidebarLayoutProps): JSX.Element {
    const { className, nav, content } = props;

    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean | null>(null);

    function toggleMobileMenu() {
        if (mobileMenuOpen === true) {
            setMobileMenuOpen(false);
        } else {
            setMobileMenuOpen(true);
        }
    }

    return (
        <div className={classnames('relative w-full h-full overflow-hidden flex flex-col md:flex-row', className)}>
            {nav && (
                <div
                    className={classnames(
                        'z-menu-button w-300 absolute inset-y-0 left-0 transform flex-none duration-150',
                        'md:relative md:translate-x-0 ease-in-out bg-darkBlue md:bg-transparent',
                        { '-translate-x-full': !mobileMenuOpen }
                    )}>
                    {nav}
                </div>
            )}

            {/* MOBILE NAVIGATION BUTTON */}
            <nav className="bg-darkBlue bg-opacity-50 text-white mb-10 flex justify-between p-12 md:hidden">
                <ImageCineamo24 />
                <button
                    onClick={toggleMobileMenu}
                    className="focus:outline-none">
                    <IconMenu24 />
                </button>
            </nav>

            <div className="relative w-full md:w-0 flex flex-col flex-auto">
                <TopNavigation pageProps={props.pageProps} />
                <div className="flex flex-col h-0 flex-auto font-montserrat-regular text-white overflow-auto">
                    {content}
                </div>
            </div>
        </div>
    );
}

export default SideNavLayout;
