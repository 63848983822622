import classnames from 'classnames';
import React from 'react';

export type InputLabelProps = {
    label: string | JSX.Element;
    labelStyle?: string;
    textSizeClassName?: string;
    requiredLabel?: boolean;
};

function InputLabel(props: InputLabelProps): JSX.Element {
    return (
        <span
            className={classnames(
                'ml-2 mb-6 select-none',
                props.textSizeClassName || 'text-body-12',
                props.labelStyle
            )}>
            {props.label}
            {props.requiredLabel && ' *'}
        </span>
    );
}

export default InputLabel;
