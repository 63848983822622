import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconDistributor48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.2222 36V12H24.7778V36H23.2222ZM28.458 19.2C28.3442 18.25 27.8826 17.5125 27.0732 16.9875C26.2638 16.4625 25.271 16.2 24.0949 16.2C23.2349 16.2 22.4824 16.3375 21.8374 16.6125C21.1987 16.8875 20.6992 17.2656 20.3388 17.7469C19.9846 18.2281 19.8076 18.775 19.8076 19.3875C19.8076 19.9 19.9309 20.3406 20.1775 20.7094C20.4304 21.0719 20.7529 21.375 21.145 21.6187C21.537 21.8562 21.9481 22.0531 22.378 22.2094C22.808 22.3594 23.2033 22.4812 23.5637 22.575L25.5366 23.1C26.0425 23.2312 26.6052 23.4125 27.2249 23.6438C27.8509 23.875 28.4485 24.1906 29.0176 24.5906C29.593 24.9844 30.0673 25.4906 30.4404 26.1094C30.8135 26.7281 31 27.4875 31 28.3875C31 29.425 30.7249 30.3625 30.1748 31.2C29.631 32.0375 28.8342 32.7031 27.7846 33.1969C26.7412 33.6906 25.4734 33.9375 23.981 33.9375C22.5899 33.9375 21.3853 33.7156 20.3672 33.2719C19.3555 32.8281 18.5587 32.2094 17.977 31.4156C17.4015 30.6219 17.0759 29.7 17 28.65H19.4282C19.4914 29.375 19.738 29.975 20.168 30.45C20.6043 30.9187 21.1545 31.2688 21.8184 31.5C22.4887 31.725 23.2096 31.8375 23.981 31.8375C24.879 31.8375 25.6852 31.6938 26.3997 31.4063C27.1143 31.1125 27.6802 30.7062 28.0976 30.1875C28.5149 29.6625 28.7236 29.05 28.7236 28.35C28.7236 27.7125 28.5434 27.1937 28.1829 26.7937C27.8225 26.3937 27.3482 26.0687 26.7602 25.8187C26.1721 25.5687 25.5366 25.35 24.8537 25.1625L22.4634 24.4875C20.9458 24.0563 19.7444 23.4406 18.8591 22.6406C17.9738 21.8406 17.5312 20.7938 17.5312 19.5C17.5312 18.425 17.8252 17.4875 18.4133 16.6875C19.0077 15.8813 19.8044 15.2563 20.8035 14.8125C21.8089 14.3625 22.9313 14.1375 24.1707 14.1375C25.4228 14.1375 26.5357 14.3594 27.5095 14.8031C28.4833 15.2406 29.2547 15.8406 29.8238 16.6031C30.3993 17.3656 30.7028 18.2312 30.7344 19.2H28.458Z"
                fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 1C3.44772 1 3 1.44772 3 2V45C3 45.5523 3.44772 46 4 46H44C44.5523 46 45 45.5523 45 45V2C45 1.44772 44.5523 1 44 1H4ZM13 3C12.4477 3 12 3.44771 12 4V43C12 43.5523 12.4477 44 13 44H35C35.5523 44 36 43.5523 36 43V4C36 3.44772 35.5523 3 35 3H13ZM5 4C5 3.44772 5.44772 3 6 3H9C9.55228 3 10 3.44772 10 4V9C10 9.55229 9.55228 10 9 10H6C5.44772 10 5 9.55228 5 9V4ZM6 37C5.44772 37 5 37.4477 5 38V43C5 43.5523 5.44772 44 6 44H9C9.55228 44 10 43.5523 10 43V38C10 37.4477 9.55228 37 9 37H6ZM5 29.5C5 28.9477 5.44772 28.5 6 28.5H9C9.55228 28.5 10 28.9477 10 29.5V34.5C10 35.0523 9.55228 35.5 9 35.5H6C5.44772 35.5 5 35.0523 5 34.5V29.5ZM6 20C5.44772 20 5 20.4477 5 21V26C5 26.5523 5.44772 27 6 27H9C9.55228 27 10 26.5523 10 26V21C10 20.4477 9.55228 20 9 20H6ZM5 12.5C5 11.9477 5.44772 11.5 6 11.5H9C9.55228 11.5 10 11.9477 10 12.5V17.5C10 18.0523 9.55228 18.5 9 18.5H6C5.44772 18.5 5 18.0523 5 17.5V12.5ZM39 3C38.4477 3 38 3.44772 38 4V9C38 9.55228 38.4477 10 39 10H42C42.5523 10 43 9.55229 43 9V4C43 3.44772 42.5523 3 42 3H39ZM38 38C38 37.4477 38.4477 37 39 37H42C42.5523 37 43 37.4477 43 38V43C43 43.5523 42.5523 44 42 44H39C38.4477 44 38 43.5523 38 43V38ZM39 28.5C38.4477 28.5 38 28.9477 38 29.5V34.5C38 35.0523 38.4477 35.5 39 35.5H42C42.5523 35.5 43 35.0523 43 34.5V29.5C43 28.9477 42.5523 28.5 42 28.5H39ZM38 21C38 20.4477 38.4477 20 39 20H42C42.5523 20 43 20.4477 43 21V26C43 26.5523 42.5523 27 42 27H39C38.4477 27 38 26.5523 38 26V21ZM39 11.5C38.4477 11.5 38 11.9477 38 12.5V17.5C38 18.0523 38.4477 18.5 39 18.5H42C42.5523 18.5 43 18.0523 43 17.5V12.5C43 11.9477 42.5523 11.5 42 11.5H39Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconDistributor24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24 0.5C24 0.223857 23.7761 0 23.5 0H0.5C0.223857 0 0 0.223858 0 0.5V23.5C0 23.7761 0.223858 24 0.5 24H23.5C23.7761 24 24 23.7761 24 23.5V0.5ZM5.5 2C5.22386 2 5 2.22386 5 2.5V21.5C5 21.7761 5.22386 22 5.5 22H18.5C18.7761 22 19 21.7761 19 21.5V2.5C19 2.22386 18.7761 2 18.5 2H5.5ZM20 2.5C20 2.22386 20.2239 2 20.5 2H22.5C22.7761 2 23 2.22386 23 2.5V4.5C23 4.77614 22.7761 5 22.5 5H20.5C20.2239 5 20 4.77614 20 4.5V2.5ZM20.5 7C20.2239 7 20 7.22386 20 7.5V10.5C20 10.7761 20.2239 11 20.5 11H22.5C22.7761 11 23 10.7761 23 10.5V7.5C23 7.22386 22.7761 7 22.5 7H20.5ZM20 13.5C20 13.2239 20.2239 13 20.5 13H22.5C22.7761 13 23 13.2239 23 13.5V16.5C23 16.7761 22.7761 17 22.5 17H20.5C20.2239 17 20 16.7761 20 16.5V13.5ZM20.5 19C20.2239 19 20 19.2239 20 19.5V21.5C20 21.7761 20.2239 22 20.5 22H22.5C22.7761 22 23 21.7761 23 21.5V19.5C23 19.2239 22.7761 19 22.5 19H20.5ZM1 2.5C1 2.22386 1.22386 2 1.5 2H3.5C3.77614 2 4 2.22386 4 2.5V4.5C4 4.77614 3.77614 5 3.5 5H1.5C1.22386 5 1 4.77614 1 4.5V2.5ZM1.5 7C1.22386 7 1 7.22386 1 7.5V10.5C1 10.7761 1.22386 11 1.5 11H3.5C3.77614 11 4 10.7761 4 10.5V7.5C4 7.22386 3.77614 7 3.5 7H1.5ZM1 13.5C1 13.2239 1.22386 13 1.5 13H3.5C3.77614 13 4 13.2239 4 13.5V16.5C4 16.7761 3.77614 17 3.5 17H1.5C1.22386 17 1 16.7761 1 16.5V13.5ZM1.5 19C1.22386 19 1 19.2239 1 19.5V21.5C1 21.7761 1.22386 22 1.5 22H3.5C3.77614 22 4 21.7761 4 21.5V19.5C4 19.2239 3.77614 19 3.5 19H1.5Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M15.1843 8.82386C15.103 8.20013 14.7733 7.71591 14.1951 7.37121C13.617 7.02652 12.9079 6.85417 12.0678 6.85417C11.4535 6.85417 10.916 6.94444 10.4553 7.125C9.9991 7.30556 9.64228 7.55382 9.38482 7.86979C9.13189 8.18576 9.00542 8.54482 9.00542 8.94697C9.00542 9.28346 9.0935 9.57276 9.26965 9.81487C9.45032 10.0529 9.68067 10.2519 9.9607 10.4119C10.2407 10.5679 10.5343 10.6971 10.8415 10.7997C11.1486 10.8982 11.4309 10.9782 11.6883 11.0398L13.0976 11.3845C13.4589 11.4706 13.8609 11.5896 14.3035 11.7415C14.7507 11.8933 15.1775 12.1005 15.584 12.3632C15.995 12.6217 16.3338 12.9541 16.6003 13.3603C16.8668 13.7666 17 14.2652 17 14.8561C17 15.5372 16.8035 16.1528 16.4106 16.7027C16.0221 17.2525 15.453 17.6896 14.7033 18.0137C13.958 18.3379 13.0524 18.5 11.9864 18.5C10.9928 18.5 10.1323 18.3543 9.40515 18.063C8.68248 17.7716 8.11337 17.3654 7.69783 16.8442C7.28681 16.3231 7.0542 15.7178 7 15.0284H8.73442C8.77958 15.5044 8.95574 15.8984 9.26287 16.2102C9.57453 16.518 9.96748 16.7478 10.4417 16.8996C10.9205 17.0473 11.4354 17.1212 11.9864 17.1212C12.6278 17.1212 13.2037 17.0268 13.7141 16.8381C14.2245 16.6452 14.6287 16.3785 14.9268 16.0379C15.2249 15.6932 15.374 15.291 15.374 14.8314C15.374 14.4129 15.2453 14.0723 14.9878 13.8097C14.7304 13.547 14.3916 13.3336 13.9715 13.1695C13.5515 13.0054 13.0976 12.8617 12.6098 12.7386L10.9024 12.2955C9.81843 12.0123 8.96025 11.6081 8.32791 11.0829C7.69557 10.5576 7.3794 9.87027 7.3794 9.02083C7.3794 8.31503 7.58943 7.6995 8.00949 7.17424C8.43406 6.64489 9.00316 6.23453 9.7168 5.94318C10.435 5.64773 11.2367 5.5 12.122 5.5C13.0163 5.5 13.8112 5.64568 14.5068 5.93703C15.2023 6.22427 15.7534 6.61821 16.1599 7.11884C16.5709 7.61948 16.7877 8.18782 16.8103 8.82386H15.1843Z"
                fill={props.fill || 'currentColor'}/>
            <path
                d="M15.1843 8.82386C15.103 8.20013 14.7733 7.71591 14.1951 7.37121C13.617 7.02652 12.9079 6.85417 12.0678 6.85417C11.4535 6.85417 10.916 6.94444 10.4553 7.125C9.9991 7.30556 9.64228 7.55382 9.38482 7.86979C9.13189 8.18576 9.00542 8.54482 9.00542 8.94697C9.00542 9.28346 9.0935 9.57276 9.26965 9.81487C9.45032 10.0529 9.68067 10.2519 9.9607 10.4119C10.2407 10.5679 10.5343 10.6971 10.8415 10.7997C11.1486 10.8982 11.4309 10.9782 11.6883 11.0398L13.0976 11.3845C13.4589 11.4706 13.8609 11.5896 14.3035 11.7415C14.7507 11.8933 15.1775 12.1005 15.584 12.3632C15.995 12.6217 16.3338 12.9541 16.6003 13.3603C16.8668 13.7666 17 14.2652 17 14.8561C17 15.5372 16.8035 16.1528 16.4106 16.7027C16.0221 17.2525 15.453 17.6896 14.7033 18.0137C13.958 18.3379 13.0524 18.5 11.9864 18.5C10.9928 18.5 10.1323 18.3543 9.40515 18.063C8.68248 17.7716 8.11337 17.3654 7.69783 16.8442C7.28681 16.3231 7.0542 15.7178 7 15.0284H8.73442C8.77958 15.5044 8.95574 15.8984 9.26287 16.2102C9.57453 16.518 9.96748 16.7478 10.4417 16.8996C10.9205 17.0473 11.4354 17.1212 11.9864 17.1212C12.6278 17.1212 13.2037 17.0268 13.7141 16.8381C14.2245 16.6452 14.6287 16.3785 14.9268 16.0379C15.2249 15.6932 15.374 15.291 15.374 14.8314C15.374 14.4129 15.2453 14.0723 14.9878 13.8097C14.7304 13.547 14.3916 13.3336 13.9715 13.1695C13.5515 13.0054 13.0976 12.8617 12.6098 12.7386L10.9024 12.2955C9.81843 12.0123 8.96025 11.6081 8.32791 11.0829C7.69557 10.5576 7.3794 9.87027 7.3794 9.02083C7.3794 8.31503 7.58943 7.6995 8.00949 7.17424C8.43406 6.64489 9.00316 6.23453 9.7168 5.94318C10.435 5.64773 11.2367 5.5 12.122 5.5C13.0163 5.5 13.8112 5.64568 14.5068 5.93703C15.2023 6.22427 15.7534 6.61821 16.1599 7.11884C16.5709 7.61948 16.7877 8.18782 16.8103 8.82386H15.1843Z"
                stroke={props.stroke || 'currentColor'}
            />
            <path d="M11.25 4V20H12.75V4H11.25Z" fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
