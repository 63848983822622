import {Server, Response} from 'miragejs'

const mockPermissionsRoutes = (server: Server) => {

    server.get('https://api.cineamo.test/users/me/permissions', (request) => {

        return new Response(200, {}, {

            "permissions": [
                "admin_administration",
                "cinema_administration",
                "cinema_administration/edit",
                "cinema_administration/delete",
                "movie_administration",
                "movie_administration/edit",
                "movie_administration/delete"
            ]

        });
    });
};

export default mockPermissionsRoutes
