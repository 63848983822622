import { Session } from 'next-auth';
import { getSession, signOut } from 'next-auth/react';

import { createDefaultAxiosClient } from './defaultAxiosClient';

const apiClient = createDefaultAxiosClient({
    baseURL: process.env.NEXT_PUBLIC_ENV_API_HOST_NAME
});

let lastSession: Session | null = null;
let cartSessionId = '';

function setCartSessionId(id: string) {
    cartSessionId = id;
}

apiClient.interceptors.request.use(async (config) => {
    if (!lastSession || new Date(lastSession.expires) < new Date()) {
        lastSession = await getSession();
    }

    if (!config.headers.Authorization) {
        // TODO: the session is always 'null' when page is pre-rendered on the server-side.
        //  Need to pass Context (or Request) as the first argument to get it working correctly.
        if (lastSession?.accessToken) {
            config.headers.Authorization = `Bearer ${lastSession.accessToken}`;
        } else if (cartSessionId) {
            // fallback to a custom CineamoCart Authorization header (for anonymous users)
            config.headers.Authorization = `CineamoCart ${cartSessionId}`;
        }
    }

    return config;
});

apiClient.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        // remove invalid session token (if any)
        if (error?.response?.status === 401 && lastSession?.accessToken) {
            signOut().then();
        }

        return Promise.reject(error);
    }
);

export { apiClient as default, setCartSessionId };
