import { useTranslation } from 'next-i18next';

import { EventRequestDto } from '@cineamo/legacy-frontend-lib/src/models/event-request/EventRequestDto.types';
import { ShowingDto } from '@cineamo/legacy-frontend-lib/src/models/showing/ShowingDto.types';

import { Time } from '@cineamo/legacy-frontend-lib/src/types/Time.types';

import { formatDay, formatTime } from '@cineamo/legacy-frontend-lib/src/helper/date-helper';
import { stringFormat } from '@cineamo/ui/src/helper/string-helper';

export const currentUsersTimezoneOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;

/**
 * @param duration - Duration in minutes
 * @param unitHours - Unit for Hours (E.g. 'h')
 * @param unitMinutes - Unit for Minutes (E.g. 'min')
 */
export function formatDuration(duration: number, unitHours?: string, unitMinutes?: string): string {
    const hours = parseInt(Math.floor(duration / 60).toFixed(0));
    const hoursStr = hours > 0 ? hours + (unitHours ? ` ${unitHours}` : 'h') : '';
    const minutes = duration % 60;
    const minutesStr = minutes > 0 ? minutes + (unitMinutes ? ` ${unitMinutes}` : 'm') : '';
    return hoursStr + (hours > 0 && minutes > 0 ? ' ' : '') + minutesStr;
}

export function timeAgo(fromDateTimestamp: Date | string, toDate = null, options?: { t? }): string {
    const t = options?.t ?? useTranslation().t;

    const _fromDate: Date = new Date(fromDateTimestamp);
    const _toDate: Date = toDate || new Date();
    const _diffMs = _toDate.getTime() - _fromDate.getTime();
    const seconds: number = _diffMs / 1000;
    const minutes: number = _diffMs / 1000 / 60;
    const hours: number = _diffMs / 1000 / 60 / 60;
    const days: number = _diffMs / 1000 / 60 / 60 / 24;

    let unit: string;
    let diff = 0;

    if (seconds < 60) {
        if (Math.floor(seconds) == 1) {
            unit = t('common.time.before.second');
        } else {
            unit = t('common.time.before.seconds');
        }
        diff = seconds; // SECONDS
    } else if (minutes < 60) {
        if (Math.floor(minutes) == 1) {
            unit = t('common.time.before.minute');
        } else {
            unit = t('common.time.before.minutes');
        }
        diff = minutes; // MINUTES
    } else if (hours < 24) {
        if (Math.floor(hours) == 1) {
            unit = t('common.time.before.hour');
        } else {
            unit = t('common.time.before.hours');
        }
        diff = hours; // HOURS
    } else if (days >= 7) {
        if (days > 365) {
            if (Math.floor(days / 365) == 1) {
                unit = t('common.time.before.year');
            } else {
                unit = t('common.time.before.years');
            }
            diff = days / 365; // YEARS
        } else if (days > 30) {
            if (Math.floor(days / 30) == 1) {
                unit = t('common.time.before.month');
            } else {
                unit = t('common.time.before.months');
            }
            diff = days / 30; // MONTHS
        } else {
            if (Math.floor(days / 7) == 1) {
                unit = t('common.time.before.week');
            } else {
                unit = t('common.time.before.weeks');
            }
            diff = days / 7; // WEEKS
        }
    } else if (days < 7) {
        if (Math.floor(days) == 1) {
            unit = t('common.time.before.day');
        } else {
            unit = t('common.time.before.days');
        }
        diff = days; // DAYS
    }

    if (Math.floor(diff) > 0) {
        const base = t('common.time.before.base', {
            0: '{0}',
            1: '{1}'
        });

        return stringFormat(base, Math.floor(diff).toString(), unit);
    }

    return t('common.time.before.just-now');
}

export function getSecondsFromTime(time: Time): number {
    return time.hour * 3600 + time.minute * 60;
}

export function getMinutesFromHoursAndMinutes(hours: number, minutes: number): number {
    return hours * 60 + minutes;
}

export function getHoursAndMinutesFromSeconds(totalSeconds: number): Time {
    const totalMinutes = Math.floor(totalSeconds / 60);

    return {
        hour: Math.floor(totalMinutes / 60),
        minute: totalMinutes % 60
    };
}

export function formatTimeFromMinutes_HHmm(minutes: number): string {
    const hoursAndMinutes = getHoursAndMinutesFromSeconds(minutes * 60);
    return (
        (hoursAndMinutes.hour < 10 ? '0' + hoursAndMinutes.hour : hoursAndMinutes.hour) +
        ':' +
        (hoursAndMinutes.minute < 10 ? '0' + hoursAndMinutes.minute : hoursAndMinutes.minute)
    );
}

export function formatTimeFromSeconds(seconds: number): string {
    const hoursAndMinutes = getHoursAndMinutesFromSeconds(seconds);
    return (
        (hoursAndMinutes.hour < 10 ? '0' + hoursAndMinutes.hour : hoursAndMinutes.hour) +
        ':' +
        (hoursAndMinutes.minute < 10 ? '0' + hoursAndMinutes.minute : hoursAndMinutes.minute)
    );
}

/**
 * 0=Monday 6=Sunday
 *
 * @param dayOfWeekIndex
 * @param locale
 * @param format
 */
export function getDayOfWeekName(
    dayOfWeekIndex: number,
    locale?: string,
    format?: 'long' | 'short' | 'narrow'
): string {
    const tmpDate = new Date();
    const currentDay = tmpDate.getDay();
    const distance = (dayOfWeekIndex - currentDay + 1) % 7; // 1 day offset because otherwise 0=sunday
    tmpDate.setDate(new Date().getDate() + distance);
    return formatDay(tmpDate, locale, {
        weekday: format || 'short'
    });
}

export function isDatetimePast(datetime: string | Date, useNowMinus30Minutes: boolean): boolean {
    const datetimeNow = new Date();
    if (useNowMinus30Minutes) {
        datetimeNow.setMinutes(datetimeNow.getMinutes() - 30);
    }
    const datetimeParam = new Date(datetime);
    return datetimeNow > datetimeParam;
}

/**
 * Returns the number of weeks that have passed since a specific date.
 *
 * @param dateString
 */
export function getPastWeeks(dateString: string | Date): number {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate.getTime() - givenDate.getTime();
    const weeks = diffInMilliseconds / (7 * 24 * 60 * 60 * 1000);
    return Math.floor(weeks);
}

export function getShowingOrEventRequestDatetime(showing: ShowingDto | EventRequestDto): string | Date {
    const showingData: ShowingDto = showing as ShowingDto;
    const eventRequestData: EventRequestDto = showing as EventRequestDto;

    return showingData?.startDatetime || eventRequestData?.finalDatetime;
}

export function getEndDatetimeFromStartDatetimeAndDuration(
    startDatetime: Date | string,
    durationInMinutes: number,
    locale?: string
) {
    const returnEndDatetime = new Date(startDatetime);

    returnEndDatetime.setMinutes(returnEndDatetime.getMinutes() + durationInMinutes);

    return formatTime(returnEndDatetime, locale);
}

export function formatStartAndEndTime(startDatetime: Date | string, durationInMinutes: number, locale?: string) {
    const endDatetime = getEndDatetimeFromStartDatetimeAndDuration(startDatetime, durationInMinutes, locale);

    return `${formatTime(startDatetime, locale)} - ${endDatetime}`;
}

export const QUARTERLY_MINUTES_ARRAY = ['00', '15', '30', '45'];
