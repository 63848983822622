import Image from 'next/image';
import React, { AriaRole, HTMLAttributeAnchorTarget } from 'react';

import Button from '../ui/buttons/Button';
import TwoColumnsOnLg from '../ui/layout-components/TwoColumnsOnLg';
import LinkWithStyle from '../ui/link/LinkWithStyle';

export type PageHeaderProps = {
    tag?: string;
    title?: string;
    subtitle?: string | React.JSX.Element;
    subtitle2?: string;
    customElement?: React.JSX.Element;
    customElement2?: React.JSX.Element;
    socialProof?: boolean;
    appStoreBadges?: boolean;
    buttonTitle?: string;
    buttonHref?: string;
    buttonHrefTarget?: HTMLAttributeAnchorTarget;
    buttonAction?: () => void;
    image?: string | StaticImageData;
    imageAlt?: string;
    imageRole?: AriaRole;
    showBreadcrumbNavigation?: boolean;
};

function WebsiteHeader(props: PageHeaderProps): React.JSX.Element {
    if (props.image && !props.imageAlt) throw new Error('If you use image prop then add an imageAlt prop also!');

    return (
        <TwoColumnsOnLg
            leftExtraClass="order-2 lg:order-1"
            left={
                <div className="flex flex-col w-full m-0 text-gray-light my-auto space-y-50 py-48 xl:py-0">
                    <hgroup>
                        {props.tag && (
                            <div className="mb-24 uppercase text-cyan font-montserrat-semibold">{props.tag}</div>
                        )}
                        {props.title && <h1 className="header-title whitespace-pre-wrap">{props.title}</h1>}
                        {props.subtitle && <h2 className="mt-24 whitespace-pre-line text-body-20">{props.subtitle}</h2>}
                        {props.subtitle2 && (
                            <h2 className="mt-24 whitespace-pre-line text-body-20">{props.subtitle2}</h2>
                        )}
                    </hgroup>

                    {props.customElement && React.isValidElement(props.customElement) ? props.customElement : null}

                    {props.buttonTitle && (
                        <LinkWithStyle
                            className=""
                            href={props.buttonAction ? null : props.buttonHref}
                            target={props.buttonHrefTarget}>
                            <Button
                                className="w-full md:w-auto"
                                onClick={() => {
                                    if (props.buttonAction) props.buttonAction();
                                }}>
                                {props.buttonTitle}
                            </Button>
                        </LinkWithStyle>
                    )}

                    {props.customElement2 && React.isValidElement(props.customElement2) ? props.customElement2 : null}
                </div>
            }
            rightExtraClass="order-1 lg:order-2"
            right={
                props.image && (
                    <Image
                        className="relative self-center w-full"
                        src={props.image}
                        width={(typeof props.image !== 'string' ? props.image.width : 10000) ?? 10000}
                        height={(typeof props.image !== 'string' ? props.image.height : 10000) ?? 10000}
                        alt={props.imageAlt}
                        role={props.imageRole}
                    />
                )
            }
        />
    );
}

export default WebsiteHeader;
