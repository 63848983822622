import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconPieChart48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M25 0V23H48C48 22.3262 47.971 21.6591 47.9142 21C46.9575 9.89305 38.1069 1.04248 27 0.0857512C26.3409 0.0289764 25.6738 0 25 0ZM27 2.094V21H45.906C44.9611 10.9984 37.0016 3.03893 27 2.094Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23 25V2C22.3262 2 21.6591 2.02898 21 2.08575C9.23394 3.09926 0 12.9713 0 25C0 37.7025 10.2975 48 23 48C35.0287 48 44.9007 38.7661 45.9142 27C45.971 26.3409 46 25.6738 46 25H23ZM43.906 27H21V4.094C10.3401 5.10112 2 14.0766 2 25C2 36.598 11.402 46 23 46C33.9234 46 42.8989 37.6599 43.906 27Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

    );
}

export function IconPieChart24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13 0V11H24C24 10.317 23.9378 9.64855 23.8186 9C22.9972 4.52832 19.4717 1.00276 15 0.181376C14.3514 0.0622466 13.683 0 13 0ZM15 2.22302V9H21.777C21.0135 5.63525 18.3648 2.98652 15 2.22302Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11 13V2C10.317 2 9.64855 2.06225 9 2.18138C3.87976 3.12189 0 7.60786 0 13C0 19.0751 4.92487 24 11 24C16.3921 24 20.8781 20.1202 21.8186 15C21.9378 14.3514 22 13.683 22 13H11ZM19.777 15H9V4.22302C4.99202 5.13247 2 8.71683 2 13C2 17.9706 6.02944 22 11 22C15.2832 22 18.8675 19.008 19.777 15Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPieChart16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 0V8H16C16 7.3094 15.9125 6.63924 15.748 6C15.0244 3.18879 12.8112 0.975592 10 0.252035C9.36076 0.087506 8.6906 0 8 0ZM10 2.34141V6H13.6586C13.056 4.29517 11.7048 2.94398 10 2.34141Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7 9V2C6.30503 2 5.63371 2.10128 5 2.28988C2.10851 3.15043 0 5.82898 0 9C0 12.866 3.13401 16 7 16C10.171 16 12.8496 13.8915 13.7101 11C13.8987 10.3663 14 9.69497 14 9H7ZM11.584 11H5V4.41604C3.2341 5.1876 2 6.94968 2 9C2 11.7614 4.23858 14 7 14C9.05032 14 10.8124 12.7659 11.584 11Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
