import React, { PropsWithChildren, ReactChild } from 'react';

import { UserDto } from '../models/user/UserDto.types';
import { UserMeDto } from '../models/user/UserMeDto.types';

import useUserGetPermissions, { Permission } from './useUserGetPermissions';

export enum PermissionGateMode {
    default = 'default',
    hide = 'hide',
    error = 'error',
    detailedError = 'detailedError'
}

export type Props = PropsWithChildren<{
    user: UserMeDto;
    necessaryPermission?: Permission | null;
    mode: PermissionGateMode;
}>;

const hasPermission = (permissions: Permission[], necessaryPermission: Permission) =>
    permissions.includes(necessaryPermission);

function PermissionsGate(props: Props): JSX.Element | null {
    const { children, user, necessaryPermission, mode } = props;
    const permissions = useUserGetPermissions(user);

    if (permissions) {
        const permissionGranted = necessaryPermission ? hasPermission(permissions, necessaryPermission) : true;
        if (!permissionGranted) {
            switch (mode) {
                case PermissionGateMode.default:
                    return <span className="bg-red bg-cyan">P E R M I S S I O N &#32;&#32; D E N I E D</span>;
                case PermissionGateMode.hide:
                    return null;
                case PermissionGateMode.error:
                    return <span className="bg-red bg-cyan">P E R M I S S I O N &#32;&#32; D E N I E D</span>;
                case PermissionGateMode.detailedError:
                    return (
                        <div className="">
                            You miss the permission:
                            <pre className="bg-red">{necessaryPermission}</pre>
                            Please contact you admin!
                        </div>
                    );
            }
        } else {
            return <>{children}</>;
        }
    } else {
        return <></>;
    }
}

export default PermissionsGate;
