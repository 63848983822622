import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconSend48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48"
             viewBox="0 0 48 48" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M16.3035 24L6.90855 40.4412L41.3567 24L6.90855 7.55886L16.3035 24ZM14 24L5.17206 8.55114C4.19967 6.84946 6.00121 4.9097 7.77 5.7539L44.1091 23.0976C44.8681 23.4598 44.8681 24.5403 44.1091 24.9025L7.77 42.2462C6.00121 43.0904 4.19967 41.1506 5.17206 39.4489L14 24Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 24C15 23.4477 15.4477 23 16 23H41C41.5523 23 42 23.4477 42 24C42 24.5523 41.5523 25 41 25H16C15.4477 25 15 24.5523 15 24Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSend24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24" 
             viewBox="0 0 24 24"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M7.36909 12L2.80955 20.3592L19.5279 12L2.80955 3.64084L7.36909 12ZM5.09092 12L1.05376 4.59854C0.115965 2.87925 1.95231 0.976147 3.70397 1.85198L22.2112 11.1056C22.9482 11.4741 22.9482 12.5259 22.2112 12.8944L3.70397 22.148C1.95231 23.0238 0.115964 21.1207 1.05376 19.4015L5.09092 12Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M5 12C5 11.4477 5.44772 11 6 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L6 13C5.44772 13 5 12.5523 5 12Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSend16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="17"
             height="16" 
             viewBox="0 0 17 16"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M6.72564 8.00001L4.1082 12.3673L13.328 8L4.10818 3.63268L6.72564 8.00001ZM4.39395 8.00001L0.696335 1.8304C0.18784 0.981953 1.08824 -0.0108544 1.98217 0.41259L16.0921 7.09627C16.8546 7.45748 16.8546 8.54253 16.0921 8.90374L1.98218 15.5874C1.08824 16.0108 0.18785 15.018 0.696348 14.1695L4.39395 8.00001Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M5 8C5 7.44772 5.44772 7 6 7L14 7C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9L6 9C5.44772 9 5 8.55228 5 8Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}