export const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024;
export const isDesktop = typeof window !== 'undefined' && window.innerWidth >= 1024;

// eslint-disable-next-line deprecation/deprecation
const isBrowser = typeof window !== 'undefined' && window.document && window.document.createElement;

const supportsTouchEvents = isBrowser && 'ontouchstart' in window;

export const isTouchScreen = supportsTouchEvents || (isBrowser && window.navigator.maxTouchPoints > 1);

type TailwindSizes = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type UseScreenSizeReturn = Record<TailwindSizes | string, boolean>;
