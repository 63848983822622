import React, { ReactNode, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import SideNavLayout from '@/components/layouts/sub-layouts/SideNavLayout';
import SidebarNavigation from '@/components/navigation/SidebarNavigation';
import FetchTmdbMovieModal from '@/components/ui/modal/FetchTmdbMovieModal';

import { getMessages } from '@cineamo/legacy-frontend-lib/src/api/message/message-api';

import { apiResponseHandler } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/ApiResponseHelper';

import { useNotificationsStore } from '@cineamo/legacy-frontend-lib/src/store/notifications/notificationsStore';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

export type BasicLayoutProps = {
    children?: ReactNode;
    pageProps: Record<string, unknown>;
};

function BasicLayout(props: BasicLayoutProps): JSX.Element {
    const { children } = props;

    const { setNotifications } = useNotificationsStore();
    const { user } = useUserStore();

    const { data: messagesResponse } = useQuery(['messages', user], async () => getMessages({ userId: user.id }));

    const [fetchTmdbMovieModalIsOpen, setFetchTmdbMovieModalIsOpen] = useState(false);

    useEffect(() => {
        apiResponseHandler(messagesResponse, (successResponse) => setNotifications(successResponse._embedded.messages));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesResponse]);

    return (
        <div className="h-[100dvh] flex flex-col font-montserrat-regular tracking-regular">
            {/* this modal is invoked from sidebar menu item */}
            <FetchTmdbMovieModal
                modalIsOpen={fetchTmdbMovieModalIsOpen}
                setModalIsOpen={setFetchTmdbMovieModalIsOpen}
            />

            <SideNavLayout
                nav={<SidebarNavigation modalOpeners={{ fetchTmdbMovieModal: setFetchTmdbMovieModalIsOpen }} />}
                pageProps={props.pageProps}
                content={children}
            />
        </div>
    );
}

export default BasicLayout;
