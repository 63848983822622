'use client';

import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import popcornAnimation from '../../../assets/animation/popcornLoading.json';

function LoadingAnimation(props: { loadingText?: string }): React.JSX.Element {
    return (
        <div className="select-none bg-darkBlue-transparent fixed top-0 left-0 right-0 bottom-0 w-full h-full my-auto z-loading-overlay">
            <div className="flex flex-col h-full md:h-auto items-center justify-center space-y-24">
                <div className="w-148 md:w-200 mx-auto mt-1/10">
                    <Player
                        autoplay
                        loop
                        src={popcornAnimation}
                        className=""
                    />
                </div>
                {props.loadingText && (
                    <span className="text-headline-20 lg:text-headline-24 text-center text-white">
                        {props.loadingText}
                    </span>
                )}
            </div>
        </div>
    );
}

export default LoadingAnimation;
