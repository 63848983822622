import create from 'zustand';

import { MessageDto } from '../../models/message/MessageDto.types';
import { IsomorphicStore, createIsomorphicStore } from '../index';

import { NotificationsState, NotificationsStore } from './notificationStore.types';

const initialState: NotificationsState = {
    notifications: undefined,
    unreadNotifications: undefined,
    unreadNotificationsCount: undefined
};

const initializeStore: IsomorphicStore<NotificationsStore, NotificationsState> = (preloadedState) => {
    return create<NotificationsStore>((set) => ({
        ...initialState,
        ...preloadedState,
        setNotifications(notifications?: MessageDto[] | null) {
            set({
                notifications: notifications
            });
        },
        setUnreadNotifications(unreadNotifications?: MessageDto[] | null) {
            set({
                unreadNotifications: unreadNotifications
            });
        },
        setUnreadNotificationsCount(unreadNotificationsCount?: number | null) {
            set({
                unreadNotificationsCount: unreadNotificationsCount
            });
        }
    }));
};

const {
    StoreProvider: NotificationsStoreProvider,
    useStore: useNotificationsStore,
    useCreateStore: useCreateNotificationsStore
} = createIsomorphicStore<typeof initializeStore, NotificationsStore, NotificationsState>(initializeStore);

export { NotificationsStoreProvider, useNotificationsStore, useCreateNotificationsStore };
