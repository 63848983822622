import classnames from 'classnames';
import React from 'react';

import WebsiteContent from './WebsiteContent';

export type WebsitePageProps = {
    header?: JSX.Element;
    headerClassName?: string;
    content?: JSX.Element;
    contentClassName?: string;
    withClip?: boolean;
};

function WebsitePage(props: WebsitePageProps): JSX.Element {
    const { header, headerClassName, content, contentClassName, withClip } = props;

    return (
        <>
            {header && (
                <section className={classnames(headerClassName, 'flex justify-center')}>
                    <div className="container">{header}</div>
                </section>
            )}

            {withClip && <div className="bg-darkBlue clip-top space-y-16 w-full h-52" />}

            {content && (
                <WebsiteContent
                    content={content}
                    contentClassName={classnames(contentClassName, 'py-96 lg:py-72')}
                />
            )}
        </>
    );
}

export default WebsitePage;
