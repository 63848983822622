import { signIn, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';

import MakeAppointmentCard from '@/components/ui/cards/MakeAppointmentCard';
import LoadingAnimation from '@cineamo/legacy-frontend-lib/src/components/ui/animation/LoadingAnimation';
import WebsiteHeader from '@cineamo/legacy-frontend-lib/src/components/website/WebsiteHeader';
import WebsitePage from '@cineamo/legacy-frontend-lib/src/components/website/WebsitePage';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

const imageNoAccess = loadAsset('/images/illustrations/im-notAuthorized.png');

export type SessionProtectionProps = {
    children?: ReactNode;
};

function SessionProtection(props: SessionProtectionProps): JSX.Element {
    const { children } = props;

    const { data: session } = useSession();

    const { t } = useTranslation('common');

    const { user } = useUserStore();

    const isAuthenticated = session && user;
    const isLoading = session === undefined && user;

    return (
        <>
            {isAuthenticated && children}

            {!isAuthenticated && (
                <div className="flex flex-col px-16 md:px-24 lg:px-48 min-h-[100dvh] justify-center">
                    <WebsitePage
                        headerClassName="items-center h-full"
                        header={
                            <WebsiteHeader
                                tag={t('access.session.tag')}
                                title={t('access.session.title')}
                                subtitle={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('access.session.subtitle')
                                        }}
                                    />
                                }
                                buttonTitle={t('sign-in')}
                                buttonAction={() => signIn('cineamo')}
                                image={imageNoAccess}
                                imageAlt={t('no-access.image')}
                                customElement2={<MakeAppointmentCard t={t} />}
                            />
                        }
                    />
                </div>
            )}

            {isLoading && <LoadingAnimation />}
        </>
    );
}

export default SessionProtection;
