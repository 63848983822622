import { LegacyRef, MutableRefObject, useEffect, useRef } from 'react';

type ClickOutsideReturn = {
    ref?: LegacyRef<HTMLDivElement>;
};

function useClickOutside(onClickOutside: () => void, customRef: MutableRefObject<unknown> = null): ClickOutsideReturn {
    const ref = (customRef as MutableRefObject<HTMLDivElement>) || useRef<HTMLDivElement>();

    useEffect(() => {
        if (document && typeof window !== 'undefined') {
            document.addEventListener('mousedown', onOutsideClick);
        }
        return () => {
            if (document && typeof window !== 'undefined') {
                document.removeEventListener('mousedown', onOutsideClick);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onOutsideClick(event) {
        if (ref?.current && ref?.current?.contains && !ref?.current?.contains(event.target)) {
            onClickOutside();
        }
    }

    return {
        ref: ref
    };
}

export default useClickOutside;
