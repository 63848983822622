import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconErrorSolid48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47ZM21.0711 23.8995L14 16.8284L16.8284 14L23.8995 21.0711L30.9706 14L33.799 16.8284L26.7279 23.8995L33.799 30.9706L30.9706 33.799L23.8995 26.7279L16.8284 33.799L14 30.9706L21.0711 23.8995Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconErrorSolid24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 13.4142L16.2928 17.7071L17.7071 16.2929L13.4142 12L17.7071 7.70712L16.2928 6.29291L12 10.5858L7.70706 6.29291L6.29285 7.70712L10.5857 12L6.29285 16.2929L7.70706 17.7071L12 13.4142Z"
                  fill={props.foregroundcolor || 'white'}/>
        </svg>
    );
}

export function IconErrorSolid16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.58579 8L4.29289 5.70711L5.70711 4.29289L8 6.58579L10.2929 4.29289L11.7071 5.70711L9.41421 8L11.7071 10.2929L10.2929 11.7071L8 9.41421L5.70711 11.7071L4.29289 10.2929L6.58579 8Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}