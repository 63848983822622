import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconProgram48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.00004 9.66663L1.9999 15.8366C5.3511 17.0341 7.75004 20.2358 7.75004 24C7.75004 27.7641 5.3511 30.9658 1.9999 32.1633L2.00004 38.3333H46L46.0005 32.1634C42.6491 30.966 40.25 27.7642 40.25 24C40.25 20.2357 42.6491 17.0339 46.0005 15.8365L46 9.66663H2.00004ZM4.10083e-05 9.62494C5.37041e-05 8.54339 0.876825 7.66663 1.95837 7.66663H46.0417C47.1232 7.66663 48 8.54336 48 9.62489C48 9.62491 48 9.62494 48 9.62496L48.0005 16.5746C48.0005 17.031 47.6916 17.4294 47.2497 17.5432C44.3739 18.2831 42.25 20.8948 42.25 24C42.25 27.1051 44.3739 29.7168 47.2497 30.4568C47.6916 30.5705 48.0005 30.9689 48.0005 31.4253L48 38.375C48 38.375 48 38.375 48 38.375C48 39.4566 47.1232 40.3333 46.0417 40.3333H1.95837C0.876816 40.3333 4.10083e-05 39.4565 4.10083e-05 38.375L-0.00012207 31.4252C-0.000132799 30.9689 0.30874 30.5704 0.750641 30.4567C3.62631 29.7166 5.75004 27.105 5.75004 24C5.75004 20.8949 3.62631 18.2833 0.750641 17.5432C0.30874 17.4295 -0.000132799 17.0311 -0.00012207 16.5748L4.10083e-05 9.62496C4.10083e-05 9.62495 4.10083e-05 9.62494 4.10083e-05 9.62494Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.4495 17.1652C17.7301 16.9801 18.085 16.9484 18.3939 17.0809L32.3939 23.0808C32.7616 23.2384 33 23.5999 33 23.9999C33 24.4 32.7616 24.7615 32.3939 24.9191L18.3939 30.9191C18.085 31.0516 17.7301 31.0199 17.4495 30.8348C17.1689 30.6498 17 30.3361 17 30V18C17 17.6639 17.1689 17.3502 17.4495 17.1652ZM19 19.5165V28.4835L29.4614 24L19 19.5165Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconProgram24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.00006 6L2.00002 7.89639C3.60318 8.59464 4.75004 10.1506 4.75004 12C4.75004 13.8494 3.60318 15.4054 2.00002 16.1036L2.00006 18H21.9998L22 16.1037C20.3967 15.4054 19.2498 13.8495 19.2498 12C19.2498 10.1505 20.3967 8.59457 22 7.89634L21.9998 6H2.00006ZM7.80228e-05 5.43748C9.16722e-05 4.60026 0.697337 4 1.45841 4H22.5414C23.3025 4 23.9997 4.60024 23.9998 5.43743C23.9998 5.43745 23.9998 5.43748 23.9998 5.4375L24 8.61014C24 9.07057 23.6857 9.47152 23.2385 9.58135C22.0644 9.86975 21.2498 10.8692 21.2498 12C21.2498 13.1308 22.0644 14.1303 23.2385 14.4187C23.6857 14.5285 24 14.9294 24 15.3899L23.9998 18.5625C23.9998 18.5625 23.9998 18.5625 23.9998 18.5626C23.9997 19.3998 23.3025 20 22.5414 20H1.45841C0.697336 20 9.17915e-05 19.3997 7.80228e-05 18.5625C7.80228e-05 18.5625 7.80228e-05 18.5625 7.80228e-05 18.5625L3.06001e-10 15.3898C-1.13246e-05 14.9294 0.314323 14.5285 0.76143 14.4186C1.93554 14.1302 2.75004 13.1308 2.75004 12C2.75004 10.8692 1.93554 9.86981 0.76143 9.58137C0.314323 9.47153 -1.13246e-05 9.07062 3.06001e-10 8.61022L7.80228e-05 5.4375C7.80228e-05 5.43749 7.80228e-05 5.43748 7.80228e-05 5.43748Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.44951 8.16516C8.73013 7.98012 9.08495 7.94845 9.39392 8.08086L16.3939 11.0808C16.7616 11.2384 17 11.5999 17 12C17 12.4 16.7616 12.7615 16.3939 12.9191L9.39392 15.9191C9.08496 16.0516 8.73014 16.0199 8.44951 15.8348C8.16888 15.6498 8 15.3361 8 15V9C8 8.66386 8.16888 8.35021 8.44951 8.16516ZM10 10.5165V13.4835L13.4614 12L10 10.5165Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconProgram16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.00003 5L2.00002 5.36018C2.99386 5.84486 3.75003 6.79437 3.75003 8C3.75003 9.20563 2.99386 10.1551 2.00002 10.6398L2.00003 11H13.9999L13.9999 10.6398C13.0061 10.1552 12.2499 9.20566 12.2499 8C12.2499 6.79434 13.0061 5.84483 13.9999 5.36015L13.9999 5H2.00003ZM4.96511e-05 4.24997C6.56847e-05 3.42058 0.728514 3 1.29171 3H14.7082C15.2714 3 15.9998 3.42056 15.9999 4.24992C15.9999 4.24995 15.9999 4.24997 15.9999 4.25L16 6.0629C16 6.53222 15.6737 6.93842 15.2154 7.03951C14.554 7.18537 14.2499 7.64419 14.2499 8C14.2499 8.35581 14.554 8.81463 15.2154 8.96049C15.6737 9.06158 16 9.46778 16 9.9371L15.9999 11.75C15.9999 11.75 15.9999 11.7501 15.9999 11.7501C15.9998 12.5794 15.2714 13 14.7082 13H1.29171C0.728513 13 6.55655e-05 12.5794 4.96511e-05 11.75C4.96511e-05 11.75 4.96511e-05 11.75 4.96511e-05 11.75L3.80947e-10 9.93703C-1.28742e-05 9.46774 0.326311 9.06157 0.784583 8.96048C1.44588 8.8146 1.75003 8.3558 1.75003 8C1.75003 7.6442 1.44588 7.1854 0.784583 7.03952C0.326311 6.93843 -1.28742e-05 6.53226 3.80947e-10 6.06297L4.96511e-05 4.25C4.96511e-05 4.24999 4.96511e-05 4.24998 4.96511e-05 4.24997Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M9.59433 7.54037L6.69696 6.29866C6.36702 6.15726 6 6.39927 6 6.75823V9.24169C6 9.60065 6.36703 9.84266 6.69696 9.70126L9.59433 8.45952C9.99839 8.28635 9.99838 7.71354 9.59433 7.54037Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}