import React from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

export type QueryClientProviderProps = {
    children?: React.ReactNode;
    pageProps: Record<string, unknown>;
};

function QueryClientProviderWrapper(props: QueryClientProviderProps): JSX.Element {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider
            client={queryClient}
            contextSharing={true}>
            <Hydrate state={props.pageProps.dehydratedState}>{props.children}</Hydrate>
        </QueryClientProvider>
    );
}

export default QueryClientProviderWrapper;
