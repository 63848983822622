import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconProfile48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3333 12.0007C14.1705 10.8863 14.6667 9.5011 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 9.5011 1.82945 10.8863 2.66667 12.0007V12C2.66667 10.1591 4.15905 8.66667 6 8.66667H10C11.8409 8.66667 13.3333 10.1591 13.3333 12V12.0007ZM12.0007 13.3333H12V12C12 10.8954 11.1046 10 10 10H6C4.89543 10 4 10.8954 4 12V13.3333H3.99935C5.11368 14.1705 6.4989 14.6667 8 14.6667C9.5011 14.6667 10.8863 14.1705 12.0007 13.3333ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 6.66667C8.73638 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 8.73638 4 8 4C7.26362 4 6.66667 4.59695 6.66667 5.33333C6.66667 6.06971 7.26362 6.66667 8 6.66667ZM8 8C9.47276 8 10.6667 6.80609 10.6667 5.33333C10.6667 3.86057 9.47276 2.66667 8 2.66667C6.52724 2.66667 5.33333 3.86057 5.33333 5.33333C5.33333 6.80609 6.52724 8 8 8Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconProfile24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 18.001C21.2558 16.3295 22 14.2516 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.2516 2.74418 16.3295 4 18.001V18C4 15.2386 6.23858 13 9 13H15C17.7614 13 20 15.2386 20 18V18.001ZM18.001 20H18V18C18 16.3431 16.6569 15 15 15H9C7.34315 15 6 16.3431 6 18V20H5.99903C7.67051 21.2558 9.74835 22 12 22C14.2516 22 16.3295 21.2558 18.001 20ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10ZM12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
                fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconProfile16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3333 12.0007C14.1705 10.8863 14.6667 9.5011 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 9.5011 1.82945 10.8863 2.66667 12.0007V12C2.66667 10.1591 4.15905 8.66667 6 8.66667H10C11.8409 8.66667 13.3333 10.1591 13.3333 12V12.0007ZM12.0007 13.3333H12V12C12 10.8954 11.1046 10 10 10H6C4.89543 10 4 10.8954 4 12V13.3333H3.99935C5.11368 14.1705 6.4989 14.6667 8 14.6667C9.5011 14.6667 10.8863 14.1705 12.0007 13.3333ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 6.66667C8.73638 6.66667 9.33333 6.06971 9.33333 5.33333C9.33333 4.59695 8.73638 4 8 4C7.26362 4 6.66667 4.59695 6.66667 5.33333C6.66667 6.06971 7.26362 6.66667 8 6.66667ZM8 8C9.47276 8 10.6667 6.80609 10.6667 5.33333C10.6667 3.86057 9.47276 2.66667 8 2.66667C6.52724 2.66667 5.33333 3.86057 5.33333 5.33333C5.33333 6.80609 6.52724 8 8 8Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
