import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconStar48(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M29.6273 16.8625L23.9999 4L18.3726 16.8625L4.13188 18.5379L14.1417 29.3819L11.6834 43.3124L23.9999 36.7435L36.3164 43.3124L33.8581 29.3819L43.8679 18.5379L29.6273 16.8625ZM16.9999 15.0102L3.8982 16.5516C2.27527 16.7425 1.55388 18.6937 2.66228 19.8945L11.9999 30.0102L9.7138 42.9648C9.42399 44.607 11.1531 45.8618 12.6245 45.0771L23.9999 39.0102L35.3753 45.0771C36.8467 45.8618 38.5758 44.607 38.286 42.9648L35.9999 30.0102L45.3375 19.8945C46.4459 18.6937 45.7245 16.7425 44.1016 16.5516L30.9999 15.0102L25.8322 3.19836C25.1332 1.60055 22.8666 1.60055 22.1676 3.19836L16.9999 15.0102Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconStar24(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M14.0711 9.76591L12 5.35351L9.92893 9.76591L5.15746 10.2418L8.65003 14.0775L7.74022 18.9054L12 16.6184L16.2598 18.9054L15.35 14.0775L18.8425 10.2418L14.0711 9.76591ZM8.60081 7.88844L2.97949 8.44907C2.16148 8.53065 1.78586 9.50955 2.33933 10.1174L6.5 14.6868L5.3874 20.5907C5.23176 21.4167 6.10261 22.0545 6.84312 21.657L12 18.8884L17.1569 21.657C17.8974 22.0545 18.7682 21.4167 18.6126 20.5907L17.5 14.6868L21.6607 10.1174C22.2141 9.50955 21.8385 8.53065 21.0205 8.44907L15.3992 7.88844L12.9052 2.5751C12.5453 1.8083 11.4547 1.8083 11.0948 2.5751L8.60081 7.88844Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconStar16(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="16" 
             height="16"
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M9.11509 6.90599L7.99998 4.53027L6.88488 6.90599L4.14965 7.3239L6.1364 9.36016L5.69268 12.0803L7.99998 10.8049L10.3073 12.0803L9.86357 9.36016L11.8503 7.3239L9.11509 6.90599ZM15.0381 5.78774C15.4404 5.8492 15.6046 6.33989 15.3204 6.63118L12 10.0344L12.7785 14.807C12.8457 15.219 12.4084 15.527 12.0431 15.3251L7.99998 13.0901L3.95685 15.3251C3.59156 15.527 3.15428 15.219 3.22148 14.807L3.99998 10.0344L0.679521 6.63118C0.395315 6.33989 0.559584 5.8492 0.961882 5.78774L5.52785 5.09012L7.54736 0.787552C7.72732 0.40415 8.27264 0.404149 8.4526 0.787551L10.4721 5.09012L15.0381 5.78774Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}