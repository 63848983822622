import {Server, Response} from 'miragejs'
import {DefaultServerResponse} from "@/src/api/defaultServerResponse.types";
import {CinemaDto} from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

const body: DefaultServerResponse<CinemaDto> = {
    error: false,
    errorMessage: undefined,
    resultValue: undefined,
    success: true,
    items: [
        // {
        //     id: 752,
        //     name: "Cinecitta",
        //     city: "Nürnberg",
        //     ticketSystemId: "853",
        //     country: "Deutschland",
        //     countryCode: "DE",
        //     currencyCode: "EUR",
        //     description: "Das CINECITTA‘ ist Deutschlands größtes Multiplexkino und hat seit seiner Eröffnung im Jahr 1995 bereits über 40 Millionen Besucher begrüßt. Der imposante, in weiten Teilen unterirdische Bau liegt im Herzen der Nürnberger Altstadt; von der Dachterrasse des CINECITTA‘ hat man den wohl schönsten Blick auf die Nürnberger Burg. 23 digital bespielte Leinwände, 5000 qm Foyerfläche, ein Bookshop, drei Restaurants sowie diverse Barbereiche laden zum cineastischen Vergnügen und Verweilen ein.",
        //     email: "info@cinecitta.de",
        //     facebookLink: "https://www.facebook.com/cinecittanbg/",
        //     imageUrl: "admin/uploads/cinema/cinema-61c1a3451839d7-36969489.jpg",
        //     instagramLink: "https://www.instagram.com/cinecittamultiplexkino/?hl=de",
        //     isCustomer: true,
        //     latitude: 49.451999664306640000000000000000,
        //     longitude: 11.083100318908691000000000000000,
        //     phone: "+49911206666",
        //     showtimesUpdated: "2022-03-22 02:27:50.000000",
        //     slug: "cinecitta",
        //     state: "Bayern",
        //     stateAbbr: "BY",
        //     street: "Gewerbemuseumsplatz 3",
        //     postalCode: "90403",
        // }
    ],
}

const bodyGroup: DefaultServerResponse<CinemaDto> = {
    error: false,
    errorMessage: undefined,
    resultValue: undefined,
    success: true,
    items: [
        // {
        //     id: 752,
        //     name: "Cinecitta",
        //     city: "Nürnberg",
        //     ticketSystemId: "853",
        //     country: "Deutschland",
        //     countryCode: "DE",
        //     currencyCode: "EUR",
        //     description: "Das CINECITTA‘ ist Deutschlands größtes Multiplexkino und hat seit seiner Eröffnung im Jahr 1995 bereits über 40 Millionen Besucher begrüßt. Der imposante, in weiten Teilen unterirdische Bau liegt im Herzen der Nürnberger Altstadt; von der Dachterrasse des CINECITTA‘ hat man den wohl schönsten Blick auf die Nürnberger Burg. 23 digital bespielte Leinwände, 5000 qm Foyerfläche, ein Bookshop, drei Restaurants sowie diverse Barbereiche laden zum cineastischen Vergnügen und Verweilen ein.",
        //     email: "info@cinecitta.de",
        //     facebookLink: "https://www.facebook.com/cinecittanbg/",
        //     imageUrl: "admin/uploads/cinema/cinema-61c1a3451839d7-36969489.jpg",
        //     instagramLink: "https://www.instagram.com/cinecittamultiplexkino/?hl=de",
        //     isCustomer: true,
        //     latitude: 49.451999664306640000000000000000,
        //     longitude: 11.083100318908691000000000000000,
        //     phone: "+49911206666",
        //     showtimesUpdated: "2022-03-22 02:27:50.000000",
        //     slug: "cinecitta",
        //     state: "Bayern",
        //     stateAbbr: "BY",
        //     street: "Gewerbemuseumsplatz 3",
        //     postalCode: "90403",
        // },
        // {
        //     id: 6,
        //     ticketSystemId: "146",
        //     "name": "Cineworld Dettelbach",
        //     "slug": "cineworld-dettelbach",
        //     "description": "Das Cineworld im Mainfrankenpark ist Unterfrankens gr\u00f6\u00dftes Multiplexkino mit 2789 Sitzpl\u00e4tzen, verteilt auf neun S\u00e4le. Diese sind mit Komfortsesseln, gro\u00dfz\u00fcgigem Reihenabstand und modernster Multiplex-Technik ausgestattet, so dass Sie Filme in Top-Qualit\u00e4t entspannt genie\u00dfen k\u00f6nnen. F\u00fcr beste Bild- und Tonqualit\u00e4t sorgen Digital-Projektoren der neuesten Generation sowie digitale Tonsysteme (Dolby 7.1) mit 6 bzw. 8 getrennten Sound-Kan\u00e4len. Ein gestochen scharfes Bild und ein multidimensionaler Sound entf\u00fchren Sie in die Welt des Films und lassen Sie das Geschehen hautnah erleben. Neben den neun Kinos\u00e4len verf\u00fcgt das Cineworld \u00fcber ein Open-Air-Kino, das sich auf der Dachterrasse befindet. Der Spielplan des Erlebniskinos ist sehr vielseitig und deckt vom Blockbuster bis zum Arthausfilm alle Genres ab.",
        //     "instagramLink": "",
        //     "facebookLink": "",
        //     "email": "kontakt@cineworld-main.de",
        //     "phone": "+499302931304",
        //     "street": "Mainfrankenpark 21",
        //     "postalCode": "97337",
        //     "city": "Dettelbach",
        //     "state": "111111111111111",
        //     "stateAbbr": "BY",
        //     "country": "Deutschland",
        //     "countryCode": "DE",
        //     "currencyCode": "EUR",
        //     "imageUrl": "admin\/uploads\/cinema\/cinema-61af5eb2410df2-42027915.jpg",
        //     "longitude": 10.068217277526855000000000000000,
        //     "latitude": 49.777996063232420000000000000000,
        //     "isCustomer": true,
        //     "showtimesUpdated": "2022-04-13 01:00:02.000000"
        // }
    ],
}

const mockCinemaRoutes = (server: Server) => {

    server.get('https://api.cineamo.test/cinema', () => {
        return new Response(200, {}, body);
    });

    server.get('https://api.cineamo.test/cinemas', () => {
        return new Response(200, {}, bodyGroup);
    });
}

export const dummyCinemaServerResponse = body
export const dummyCinemaArrayServerResponse = bodyGroup

export default mockCinemaRoutes
