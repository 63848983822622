import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconForth48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M38.4142 24L15.7071 46.7071L14.2929 45.2929L35.5858 24L14.2929 2.7071L15.7071 1.29289L38.4142 24Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconForth24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.4142 12L9.70708 20.7071L8.29287 19.2929L15.5858 12L8.29286 4.70711L9.70708 3.29289L18.4142 12Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconForth16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16" 
             viewBox="0 0 16 16"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.4142 8L6.70708 13.7071L5.29286 12.2929L9.58576 8L5.29286 3.70711L6.70708 2.29289L12.4142 8Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconForth12(props:CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.75 6L5.25 10.5L4.18934 9.43934L7.62868 6L4.18934 2.56066L5.25 1.5L9.75 6Z"
                fill={props.fill || 'currentColor'}
            />
        </svg>
    )
}