import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconClose48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M40.293 41.7071L6.29294 7.70714L7.70716 6.29293L41.7072 40.2929L40.293 41.7071Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.2929 40.2929L40.2929 6.29287L41.7071 7.70709L7.70712 41.7071L6.2929 40.2929Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconClose24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.2928 20.7071L3.29286 4.70707L4.70708 3.29286L20.7071 19.2929L19.2928 20.7071Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.2929 19.2929L19.2929 3.29287L20.7071 4.70709L4.70712 20.7071L3.2929 19.2929Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconClose16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.2928 13.7071L2.29288 3.70704L3.7071 2.29284L13.7071 12.2929L12.2928 13.7071Z" fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.2929 12.2929L12.2929 2.29287L13.7071 3.70709L3.70712 13.7071L2.2929 12.2929Z" fill={props.fill || 'currentColor'}/>
        </svg>
    );
}