import { useTranslation } from 'next-i18next';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';
import BasicInput, { BasicInputStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/input/BasicInput';
import Modal, { ModalProps } from '@cineamo/legacy-frontend-lib/src/components/ui/modal/Modal';

import { fetchTmdbMovie } from '@cineamo/legacy-frontend-lib/src/api/movie/movie-api';

import { apiResponseHandler } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/ApiResponseHelper';
import { onInvalidSetErrorMessage } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/form/FormErrorHelper';
import { showToastNew } from '@cineamo/legacy-frontend-lib/src/helper/toast-helper';

import { IconErrorSolid24 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-errorSolid';
import { IconSuccessfulOutline16 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-successfulOutline';

export type FetchTmdbMovieModalProps = ModalProps;

type FormFields = { movieId: number | string };

export default function FetchTmdbMovieModal(props: FetchTmdbMovieModalProps): JSX.Element {
    const { t } = useTranslation('movie');

    const { setModalIsOpen } = props;

    const { register, setError, clearErrors, reset, handleSubmit } = useForm<FormFields>();

    const onSubmit: SubmitHandler<FormFields> = async (data) => {
        const res = await fetchTmdbMovie(data.movieId);
        apiResponseHandler(
            res,
            () => {
                setModalIsOpen(false);

                showToastNew({
                    toastType: toast.info,
                    messageIcon: <IconSuccessfulOutline16 />,
                    messageTitle: t('synchronization-started')
                });
            },
            () => {
                showToastNew({
                    toastType: toast.info,
                    messageIcon: <IconErrorSolid24 className="text-red" />,
                    messageTitle: t('synchronization-failed')
                });
            }
        );
    };

    return (
        <Modal
            {...props}
            title={t('sync-movie-with-tmdb')}
            onClose={reset}
            t={t}>
            <div className="flex flex-col items-center h-[40rem] overflow-y-auto">
                <form
                    className="w-full flex flex-col space-y-24"
                    onSubmit={handleSubmit(onSubmit, (error) => onInvalidSetErrorMessage(error, setError, t))}>
                    <div className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-16 w-full h-auto lg:items-center">
                        <BasicInput
                            style={BasicInputStyle.light}
                            placeholder={t('tmdb-movie-id')}
                            formFieldName="movieId"
                            register={register}
                            onChange={() => {
                                clearErrors('movieId');
                            }}
                            required
                        />

                        <Button
                            className="flex-auto h-auto w-full sm:w-auto bg-cyan-middle"
                            type="submit">
                            {t('sync')}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}
