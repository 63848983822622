import { Dialog } from '@headlessui/react';
import classnames from 'classnames';
import { TFunction as I18NextTFunction } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { Dispatch, KeyboardEvent, SetStateAction, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';

import { IconClose24 } from '../../../icons/menu/ic-close';
import { ActionIcon } from '../buttons/ActionIcon';
import { TooltipDirection } from '../tooltip/Tooltip';

export type ModalProps = {
    children?: React.ReactNode;
    className?: string;
    styleDialogPanel?: CSSProperties;
    zIndex?: number;
    title?: string | JSX.Element;
    titleCenter?: boolean;
    titleClassName?: string;
    subTitle?: string | JSX.Element;
    subTitleClassName?: string;
    modalIsOpen?: boolean;
    setModalIsOpen?: Dispatch<SetStateAction<boolean>>;
    closeable?: boolean;
    showCloseButton?: boolean;
    showBackground?: boolean;
    showBackgroundBlur?: boolean;
    showBackgroundImage?: boolean;
    backgroundColor?: string;
    enableOutsideActions?: boolean;
    onClose?: () => void;
    beforeClose?: () => boolean | void;
    allowEnter?: boolean;
    t?: I18NextTFunction;
};

function Modal(props: ModalProps): JSX.Element {
    const { className, styleDialogPanel, onClose, beforeClose, allowEnter } = props;

    const t = props.t;

    const router = useRouter();

    const closeButton = useRef(null);

    let modalIsOpen = props?.modalIsOpen;
    let setModalIsOpen = props?.setModalIsOpen;
    const [state, setState] = useState<boolean>(true);
    if (!modalIsOpen && !setModalIsOpen) {
        modalIsOpen = state;
        setModalIsOpen = setState;
    }

    const closeable = props.closeable !== false;

    function onCloseInternal() {
        if (beforeClose && beforeClose() === false) {
            return;
        }
        setModalIsOpen(false);
        if (onClose) {
            onClose();
        } else if (onClose === undefined) {
            router.back();
        }
    }

    if (modalIsOpen === false) {
        return null;
    }

    return (
        <Dialog
            initialFocus={closeButton}
            open={modalIsOpen}
            onClose={() => {
                if (closeable) {
                    onCloseInternal();
                }
            }}
            onKeyDown={(e: KeyboardEvent<HTMLDivElement> & { enterKey }) => {
                if (allowEnter) {
                    return;
                }
                // eslint-disable-next-line deprecation/deprecation
                const key = e.which || e.keyCode;
                if (key === e.enterKey || key === 13) {
                    e.preventDefault();
                }
            }}
            className={classnames('fixed z-modal-view inset-0', {
                'pointer-events-none': props.enableOutsideActions
            })}
            style={{
                zIndex: props.zIndex
            }}>
            <div className="relative flex items-center justify-center w-full h-full py-22 md:p-24">
                <Dialog.Overlay
                    className={classnames('absolute inset-0 transition-all duration-300', {
                        'bg-overlay': props.showBackground !== false,
                        'backdrop-blur-sm': props.showBackgroundBlur !== false,
                        [props.backgroundColor || '']: !props.backgroundColor?.startsWith('#')
                    })}
                    style={{
                        backgroundColor: props.backgroundColor?.startsWith('#')
                            ? `${props.backgroundColor.slice(0, 7)}80`
                            : undefined
                    }}
                />

                {/* If justify center is needed pass it down from where you need it */}
                <Dialog.Panel
                    className={classnames(
                        className,
                        'relative bg-white flex flex-col p-8 md:p-24 text-darkBlue',
                        'w-full mx-16 min-h-1/2 max-h-[98%]',
                        'sm:h-auto sm:min-h-0 sm:max-h-full',
                        'modal-window',
                        'rounded-8 relative',
                        {
                            'pointer-events-auto': props.enableOutsideActions
                        }
                    )}
                    style={styleDialogPanel}>
                    <Dialog.Title
                        className={classnames(
                            'flex-none pb-18 md:pb-16 mt-24 md:mt-0',
                            props.titleClassName || 'text-headline-24',
                            {
                                'text-center mx-auto': props.titleCenter
                            }
                        )}>
                        {props.title}
                        {props.subTitle && (
                            <div
                                className={classnames(
                                    props.subTitleClassName || 'text-body-14 tracking-wide text-dark-gray'
                                )}>
                                {props.subTitle}
                            </div>
                        )}
                    </Dialog.Title>

                    {closeable && props.showCloseButton !== false && (
                        <div className="absolute right-8 top-8 md:top-24 md:right-24 text-gray-middle">
                            <ActionIcon
                                icon={<IconClose24 className={'text-dark-gray'} />}
                                action={onCloseInternal}
                                tooltipDirection={TooltipDirection.Left}
                                tooltipText={t('common:close')}
                            />
                        </div>
                    )}

                    {props.children}
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}

export default Modal;
