import { useLayoutEffect } from 'react';
import { UseBoundStore } from 'zustand';
import createContext from 'zustand/context';

export type IsomorphicStore<TStore extends object, TState extends object = TStore> = (
    preloadedState: TState
) => UseBoundStore<TStore>;

/**
 * See STORE_README.md for usage
 */
export const createIsomorphicStore = <
    T extends IsomorphicStore<TStore, TState>,
    TStore extends object,
    TState extends object = TStore
>(
    initializeStore: T
) => {
    let store: UseBoundStore<TStore>;

    // This context will be used to provide and consume the Zustand store
    const zustandContext = createContext<TStore>();

    // Store provider
    const StoreProvider = zustandContext.Provider;

    // Store consumer
    const useStore = zustandContext.useStore;

    const useCreateStore = (initialHydrateState: TState) => {
        if (typeof window === 'undefined') {
            return () => initializeStore(initialHydrateState);
        }

        store = store ?? initializeStore(initialHydrateState);
        // For SSR & SSG, always use a new store.
        if (typeof window !== 'undefined') {
            // For CSR, always re-use same store.

            // useEffect(() => {
            //     console.log('### useEffect called:', initialHydrateState)
            //     console.log('###', JSON.stringify(store.getState()) !== JSON.stringify(initialHydrateState))
            //     store.setState(initialHydrateState)
            // }, [initialHydrateState])

            // eslint complaining "React Hooks must be called in the exact same order in every component render"
            // is ignorable as this code runs in the same order in a given environment (CSR/SSR/SSG)
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useLayoutEffect(() => {
                const updatedState = {
                    ...store.getState(),
                    ...initialHydrateState
                };
                if (JSON.stringify(store.getState()) !== JSON.stringify(updatedState)) store.setState(updatedState);
            }, [initialHydrateState]);
        }

        return () => store;
    };

    return {
        StoreProvider,
        useStore,
        useCreateStore
    };
};
