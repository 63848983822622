import React from 'react';

import { CustomSVGProps } from './CustomSVGProps.types';

export function IconMerge48(props: CustomSVGProps): React.JSX.Element {
    return (
        <svg
            {...props}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 31L23 2L25 2L25 31L23 31Z"
                fill="black"
            />
            <path
                d="M8 46V46C16.8366 46 24 38.8366 24 30V24"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
            <path
                d="M40 46V46C31.1634 46 24 38.8366 24 30V24"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
            <path
                d="M16 10L23.9853 2.00001L32 10"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
        </svg>
    );
}

export function IconMerge24(props: CustomSVGProps): React.JSX.Element {
    return (
        <svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 16L11 2L13 2L13 16H11Z"
                fill="black"
            />
            <path
                d="M4 22V22C8.41828 22 12 18.4183 12 14V2"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
            <path
                d="M20 22V22C15.5817 22 12 18.4183 12 14V2"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
            <path
                d="M7 7L11.9999 2.00005L17 7"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
        </svg>
    );
}

export function IconMerge16(props: CustomSVGProps): React.JSX.Element {
    return (
        <svg
            {...props}
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 10L7 2L9 2L9 10H7Z"
                fill="black"
            />
            <path
                d="M2 16V16C5.31371 16 8 13.3137 8 10V4"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
            <path
                d="M14 16V16C10.6863 16 8 13.3137 8 10V4"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
            <path
                d="M4 6L8 2L12 6"
                stroke={props.stroke || 'currentColor'}
                strokeWidth="2"
            />
        </svg>
    );
}
