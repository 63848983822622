import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconCinemaChain48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
         width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            d="M30.4694 23.3603L30.3591 23.5256L30.2848 23.6249L30.2777 23.6346C30.2443 23.6802 30.2105 23.7255 30.1762 23.7704L30.1688 23.78L24 32L17.8312 23.78L17.8238 23.7704C17.7895 23.7255 17.7557 23.6802 17.7223 23.6346L17.7152 23.6248L17.6409 23.5256L17.5306 23.3603C16.7042 22.1222 15.9302 20.6352 15.9302 19.0286C15.9302 14.7207 19.7045 11 24 11C28.2955 11 31.9394 14.7207 31.9394 19.0286C31.9394 20.6352 31.2958 22.1222 30.4694 23.3603ZM31.9394 25.1268C31.9835 25.0691 32.027 25.011 32.0698 24.9523L32.3333 24.6H32.3161L32.3218 24.5913L32.3333 24.574C32.7844 23.8937 33.1549 23.1549 33.431 22.3714C33.7995 21.3259 34 20.2007 34 19.0286C34 13.4899 29.5228 9 24 9C18.4772 9 14 13.4899 14 19.0286C14 20.2007 14.2005 21.3259 14.569 22.3714C14.8451 23.1549 15.2156 23.8937 15.6667 24.574L15.6782 24.5913L15.6839 24.6H15.6667L15.9302 24.9523C15.973 25.011 16.0165 25.0691 16.0606 25.1268L23.1111 34.5543C23.5556 35.1486 24.4444 35.1486 24.8889 34.5543L31.9394 25.1268ZM27 19C27 20.6569 25.6569 22 24 22C22.3431 22 21 20.6569 21 19C21 17.3431 22.3431 16 24 16C25.6569 16 27 17.3431 27 19ZM24 24C26.7614 24 29 21.7614 29 19C29 16.2386 26.7614 14 24 14C21.2386 14 19 16.2386 19 19C19 21.7614 21.2386 24 24 24Z"
            fill={props.fill || 'currentColor'}/>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.2072 20.5271C35.3064 20.9413 38.0598 21.5804 40.3029 22.3748C42.3505 23.1 43.8654 23.9171 44.83 24.7248C45.8051 25.5413 46 26.1503 46 26.4999C46 26.8496 45.8051 27.4586 44.83 28.2751C43.8654 29.0827 42.3505 29.8999 40.3029 30.6251C37.7187 31.5403 34.457 32.2494 30.7696 32.646L29.1798 34.8015C39.9448 33.9627 48 30.5645 48 26.4999C48 22.82 41.3971 19.6862 32.1584 18.5037C32.1931 19.0523 32.2096 19.614 32.2096 20.1857C32.2096 20.3031 32.2089 20.4167 32.2072 20.5271ZM15.0706 18.6077C6.23771 19.8624 0 22.9232 0 26.4999C0 30.6202 8.27734 34.0557 19.2639 34.8345L17.6845 32.693C13.8106 32.307 10.3869 31.5777 7.69713 30.6251C5.64954 29.8999 4.13455 29.0827 3.17003 28.2751C2.19485 27.4586 2 26.8496 2 26.4999C2 26.1503 2.19485 25.5413 3.17003 24.7248C4.13455 23.9171 5.64954 23.1 7.69713 22.3748C9.75494 21.646 12.2423 21.0479 15.0322 20.6343C15.0114 20.4905 15 20.3414 15 20.1857C15 19.6501 15.0236 19.1233 15.0706 18.6077Z"
            fill={props.fill || 'currentColor'}/>
    </svg>);
}

export function IconCinemaChain24(props: CustomSVGProps): JSX.Element {
    return (
    <svg {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M16.0389 13.0174L16.0853 12.9552L16.159 12.8443L16.1646 12.8359C16.4955 12.3351 16.7394 11.7723 16.8755 11.1687C16.9567 10.8088 17 10.4325 17 10.0435C17 7.24635 14.7498 5 12 5C9.25023 5 7 7.24635 7 10.0435C7 10.4325 7.04329 10.8088 7.12446 11.1687C7.26057 11.7723 7.50441 12.3349 7.83528 12.8357L7.84095 12.8442L7.91478 12.9552L7.96113 13.0174L7.9675 13.0261C7.989 13.0556 8.01083 13.0849 8.03297 13.114L8.03953 13.1226L12 18.436L15.9605 13.1226L15.967 13.114C15.9892 13.0849 16.011 13.0556 16.0325 13.0261L16.0389 13.0174ZM5.26128 11.9565C5.46075 12.6694 5.76893 13.3364 6.16667 13.9383L6.17472 13.9504L6.17876 13.9565H6.16667L6.35111 14.204C6.38112 14.2452 6.41156 14.286 6.44243 14.3265L11.0667 20.5304C11.5333 21.1565 12.4667 21.1565 12.9333 20.5304L17.5576 14.3265C17.5884 14.286 17.6189 14.2452 17.6489 14.204L17.8333 13.9565H17.8212L17.8252 13.9505L17.8333 13.9383C18.2311 13.3364 18.5392 12.6694 18.7387 11.9565C18.7708 11.8417 18.8001 11.7258 18.8265 11.6087C18.9401 11.1053 19 10.5814 19 10.0435C19 6.15347 15.866 3 12 3C8.13401 3 5 6.15347 5 10.0435C5 10.5814 5.05994 11.1053 5.17346 11.6087C5.19986 11.7258 5.22916 11.8417 5.26128 11.9565Z"
              fill={props.fill || 'currentColor'}/>
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
              fill={props.fill || 'currentColor'}/>
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M17.9143 10.9495C18.5068 11.1605 19.049 11.4004 19.5327 11.6624C21.4374 12.6942 22 13.7873 22 14.5C22 15.2128 21.4374 16.3059 19.5327 17.3376C19.121 17.5607 18.6668 17.7677 18.1754 17.9545L16.3082 20.5686C20.8073 19.6308 24 17.2674 24 14.5C24 12.0719 21.5421 9.95475 17.9004 8.83875C17.9658 9.19368 18 9.55956 18 9.93342C18 10.2797 17.9707 10.6192 17.9143 10.9495ZM6.09963 8.83875C2.45795 9.95474 0 12.0719 0 14.5C0 17.2674 3.19273 19.6308 7.69181 20.5686L5.8246 17.9545C5.33319 17.7677 4.87904 17.5607 4.46729 17.3376C2.56255 16.3059 2 15.2128 2 14.5C2 13.7873 2.56255 12.6942 4.46729 11.6624C4.95101 11.4004 5.49324 11.1605 6.08568 10.9495C6.02934 10.6192 6 10.2797 6 9.93342C6 9.55956 6.03419 9.19368 6.09963 8.83875Z"
              fill={props.fill || 'currentColor'}/>
    </svg>
    );
}

