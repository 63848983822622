import {Server, Response} from 'miragejs'

const mockEventTypeRoutes = (server: Server) => {


    server.get('https://api.cineamo.test/event-requests/1', (_, request) => {

        return new Response(200, {}, {

            "eventRequests": [
                {
                    "statistics": null,
                    "requestId": "2656807",
                    "cmsId": null,
                    "eventTypeId": "84039",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Die Familien Strelzyk und Wetzel leben in der DDR und wollen weg. Im Sommer 1979 ist es so weit: Nach zwei Jahren harter Arbeit starten Peter, seine Frau Doris und ihre beiden Kinder  sowie das Ehepaar Günter und Petra mit seinen zwei Söhnen endlich ihren Fluchtversuch in einem selbstgebauten Heißluftballon. Doch die Flucht aus ihrer Heimat in Thüringen endet kurz vor der innerdeutschen Grenze, als der Ballon abstürzt. Die beiden Familien arbeiten fieberhaft an einem neuen Ballon, denn mittlerweile ist ihnen die Stasi auf die Schliche gekommen und beginnt mit den Ermittlungen. Noch kennt die DDR-Geheimpolizei den Absturzort nicht, doch die Schlinge zieht sich immer enger zu. Es beginnt ein gnadenloser Wettlauf gegen die Zeit…",
                    "expireDate": "2020-10-31 11:15:00",
                    "duration": null,
                    "name": "Ballon",
                    "slug": "",
                    "hospitality": null,
                    "category": "0",
                    "creationDate": "2020-10-27 11:20:54",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": false,
                    "uniqueId": null,
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "483983m",
                    "externalData": {
                        "lastUpdated": 1640944652,
                        "id": 483983,
                        "cineamoId": "483983m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ow2lN2vRDsBM9rW1oo8hoe68302.jpg",
                        "genres": [
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 36,
                                "name": "Historie"
                            },
                            {
                                "id": 53,
                                "name": "Thriller"
                            }
                        ],
                        "homepage": null,
                        "imdbId": "tt7125774",
                        "originalLanguage": "de",
                        "originalTitle": "Ballon",
                        "overview": "Die Familien Strelzyk und Wetzel leben in der DDR und wollen weg. Im Sommer 1979 ist es so weit: Nach zwei Jahren harter Arbeit starten Peter, seine Frau Doris und ihre beiden Kinder  sowie das Ehepaar Günter und Petra mit seinen zwei Söhnen endlich ihren Fluchtversuch in einem selbstgebauten Heißluftballon. Doch die Flucht aus ihrer Heimat in Thüringen endet kurz vor der innerdeutschen Grenze, als der Ballon abstürzt. Die beiden Familien arbeiten fieberhaft an einem neuen Ballon, denn mittlerweile ist ihnen die Stasi auf die Schliche gekommen und beginnt mit den Ermittlungen. Noch kennt die DDR-Geheimpolizei den Absturzort nicht, doch die Schlinge zieht sich immer enger zu. Es beginnt ein gnadenloser Wettlauf gegen die Zeit…",
                        "popularity": 12.47,
                        "posterPath": "/eVINvKpRVZ6AZH3H6S527TkJeET.jpg",
                        "releaseDate": "2018-09-27",
                        "revenue": 11859396,
                        "runtime": 125,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für die Freiheit riskierten sie alles",
                        "title": "Ballon",
                        "video": false,
                        "voteAverage": 7.5,
                        "voteCount": 309,
                        "images": {
                            "id": null,
                            "backdrops": [
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/ow2lN2vRDsBM9rW1oo8hoe68302.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.388,
                                    "voteCount": 4,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/sKK1fb08uCcTc0w6PlVsx3BXJdg.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/1xW34f4ssetgxiEN9MJkJ0RbkPl.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/7KQ13lm55mamlZofVUDKFaEr37T.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/ucGATR9TZnKJtSQdvvImIjUH47h.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/5QBXU02ShUNHd3ozn9cgf9oDCr7.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/txx9qGex4TbjSKBRnMS1wPweoOW.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/6Q3uwM2ITDf2eQHLbevZZaf9JBW.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/6yh5CiTEFkmWatb8ufoKbknMGNT.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/jBlUJ0c9PKmOvcBuQidQ9MXY2z9.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/2nOdolR466dcX2c1gzYYGqhCTgZ.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/fSszS0Rk1n9UqvxshlERmYy9nnq.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.18,
                                    "voteCount": 3,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/kZCuA5lmhmLRXY1H5pZQbQeLp1L.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.78,
                                    "filePath": "/tYlDZmpBBnAGZc9wTGHY4eHNupC.jpg",
                                    "height": 1990,
                                    "iso6391": null,
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 3543
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/28zMyhsvtzpGeyrNVQO0DEnhlSG.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/5lL8qvEUtYSpx5XCXT2yMDfLxaa.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.78,
                                    "filePath": "/uFhkpUy3QSHgR4Yd70FbslxM.jpg",
                                    "height": 723,
                                    "iso6391": null,
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1287
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/m0q7dvIUbP2Jbd6hZOxlrwH8smK.jpg",
                                    "height": 1080,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1920
                                }
                            ],
                            "posters": [
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/nRs8A0zp0jU211j8Zay3ApIzlpx.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.388,
                                    "voteCount": 4,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/eVINvKpRVZ6AZH3H6S527TkJeET.jpg",
                                    "height": 3000,
                                    "iso6391": "de",
                                    "voteAverage": 5.384,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/gMsNvfCPho3dGPNdSC4cj3q9WoZ.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.252,
                                    "voteCount": 4,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/l6qeLQVmZODkUDWbw5VqKhU5I8A.jpg",
                                    "height": 3000,
                                    "iso6391": "de",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/zn5DIpyrRqXJqFnWNq3noA19M1T.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/7ECVm77B74S7yqTuTHOiNofxl6V.jpg",
                                    "height": 3000,
                                    "iso6391": "de",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.666,
                                    "filePath": "/irR1Zz0pbe8eqylOuP4dO7dPdkP.jpg",
                                    "height": 922,
                                    "iso6391": "de",
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 614
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/4T4oaWjPo8FZk0EJ73LJTF06IXv.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/3AOaH5v0cDErT4NeMVcPo6W0ykH.jpg",
                                    "height": 3000,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.675,
                                    "filePath": "/kOZQlz33Pf7nCU5MgCWaILLqnSU.jpg",
                                    "height": 1000,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 675
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/86UUYZKZLyMMCMAY3Wu7dSuI39V.jpg",
                                    "height": 1500,
                                    "iso6391": "de",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/lsUKHM61YwWqPH5YYfwmPRlSh3z.jpg",
                                    "height": 3000,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                }
                            ]
                        },
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "5baa53590e0a26611b01d082",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "IUM_s2EusXI",
                                    "name": "BALLON | Trailer & Filmclips deutsch german [HD]",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2018-09-17T13:31:54.000Z",
                                    "official": false
                                },
                                {
                                    "id": "5c83adaf925141277d1ed0e7",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "GI-2LrQqWVQ",
                                    "name": "BALLON Trailer Deutsch | Jetzt im Kino!",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "official": true,
                                    "publishedAt": "2018-06-28T09:54:35.000Z"
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2020-10-23T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-06-14T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-04-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "11",
                                            "iso6391": null,
                                            "releaseDate": "2019-08-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2020-01-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2019-07-18T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-08-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2019-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-07-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2018-09-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2020-02-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "11",
                                            "iso6391": null,
                                            "releaseDate": "2019-11-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-09-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CZ",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2018-11-15T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-08-17T00:00:00.000Z",
                                            "type": 2,
                                            "note": "The Norwegian International Film Festival Haugesund"
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2019-12-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2020-03-30T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2018-11-15T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-06-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12세 이상 관람가",
                                            "iso6391": null,
                                            "releaseDate": "2020-01-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 0,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 7055413,
                                    "item_count": 1239,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Filme"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 6695847,
                                    "item_count": 1282,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Top Titles from France - IMDb"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102480,
                                    "item_count": 1036,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "All Watched Films"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 1053,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7061987,
                                    "item_count": 508,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "UHD"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7061989,
                                    "item_count": 1217,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Alle Medien"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7082756,
                                    "item_count": 593,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "Films vus en famille"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 86267,
                                    "item_count": 498,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "FILMS2020"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 126302,
                                    "item_count": 8133,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Abstrusest 2"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102621,
                                    "item_count": 168,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Foreign Films"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 135983,
                                    "item_count": 256,
                                    "iso_639_1": "el",
                                    "list_type": "movie",
                                    "name": "ΤΑΙΝΙΕΣ"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102207,
                                    "item_count": 2121,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 142816,
                                    "item_count": 1026,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Terror"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7111644,
                                    "item_count": 445,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Terror"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 8168824,
                                    "item_count": 77,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Filme mit B"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7062484,
                                    "item_count": 207,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "seen Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7077448,
                                    "item_count": 404,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Gesehen Drama"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7081938,
                                    "item_count": 1511,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Filme"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 123577,
                                    "item_count": 24,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "test"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7103310,
                                    "item_count": 51,
                                    "iso_639_1": "pt",
                                    "list_type": "movie",
                                    "name": "Tesla Nikola"
                                }
                            ],
                            "total_pages": 3,
                            "total_results": 43
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/der-ballon",
                                "voteCount": 1084
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "0",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "event_completed",
                    "date": [
                        {
                            "requestDateId": "1207",
                            "requestId": "2656807",
                            "suggestedDate": "2020-10-31 11:15:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": true,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-10-27 11:20:54",
                            "updateTimestamp": "2020-10-28 10:21:10",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-10-27 11:20:54",
                    "updateTimestamp": "2020-10-31 11:16:27",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2684443",
                    "cmsId": null,
                    "eventTypeId": "67479",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                    "expireDate": "2020-11-20 16:15:00",
                    "duration": "128",
                    "name": "La La Land",
                    "slug": "",
                    "hospitality": "",
                    "category": "0",
                    "creationDate": "2020-11-14 16:18:26",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": true,
                    "uniqueId": "n5Ks7uQ6JCnjRD6bfCVeLduT4Qcp4E4EH0yUYHoiwzhfB9DCfbNRYznF5z-Mlvqu7MJytWWrs-XM2pCjpIaJIg",
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "313369m",
                    "externalData": {
                        "lastUpdated": 1636161142,
                        "id": 313369,
                        "cineamoId": "313369m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ik2D3KqxFD0O0Bc3Wv1CZm8sOg8.jpg",
                        "genres": [
                            {
                                "id": 35,
                                "name": "Komödie"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 10749,
                                "name": "Liebesfilm"
                            },
                            {
                                "id": 10402,
                                "name": "Musik"
                            }
                        ],
                        "homepage": "http://www.lalaland.movie",
                        "imdbId": "tt3783958",
                        "originalLanguage": "en",
                        "originalTitle": "La La Land",
                        "overview": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                        "popularity": 19.859,
                        "posterPath": "/cRHTEMqPHYEfV9d1n6WF2sw5q7X.jpg",
                        "releaseDate": "2016-11-29",
                        "revenue": 448900000,
                        "runtime": 128,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für alle die zu träumen wagen.",
                        "title": "La La Land",
                        "video": false,
                        "voteAverage": 7.9,
                        "voteCount": 13698,
                        "images": null,
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "57bd7570925141558501255c",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "_bTCzofkxeY",
                                    "name": "LA LA LAND Trailer 2 German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-08-23 16:15:18 UTC",
                                    "official": false
                                },
                                {
                                    "id": "5787612492514113e5004eb0",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "2Amyioxaii4",
                                    "name": "LA LA LAND Trailer German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-07-13 16:00:01 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": "S",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "UA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-06-09T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-02-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "AL",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "0",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ID",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": "APTA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-09-02T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Telluride Film Festival"
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2017-04-11T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2017-02-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TW",
                                    "releaseDates": [
                                        {
                                            "certification": "普通級",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "普遍級",
                                            "iso6391": null,
                                            "releaseDate": "2020-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": "重新上映"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "RU",
                                    "releaseDates": [
                                        {
                                            "certification": "16+",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SG",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": null,
                                            "releaseDate": "2016-11-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IL",
                                    "releaseDates": [
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2017-11-21T00:00:00.000Z",
                                            "type": 6,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": "fr",
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "L",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2016-08-31T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Venice Film Festival"
                                        },
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IN",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-09-12T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Toronto International Film Festival"
                                        },
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-25T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "12A",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-08T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-15T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 30000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 122968,
                                    "item_count": 225,
                                    "iso_639_1": "pl",
                                    "list_type": "movie",
                                    "name": "SEE SOON"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 134725,
                                    "item_count": 644,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Lista film guardati by Federico Margutti",
                                    "favorite_count": 0,
                                    "id": 6064,
                                    "item_count": 745,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "FILM guardati FM",
                                    "poster_path": "/893bPAcgEwZY5tIMjbhQFSZ40A8.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 122265,
                                    "item_count": 702,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Film"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072430,
                                    "item_count": 1022,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Seen"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7144,
                                    "item_count": 5152,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Latest Releases"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 974,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8780,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7096412,
                                    "item_count": 295,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Carl Vitasa's Recommendations "
                                },
                                {
                                    "description": "Movie List",
                                    "favorite_count": 0,
                                    "id": 143237,
                                    "item_count": 175,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "List"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19888,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63036,
                                    "item_count": 11218,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2018;US;All"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24949,
                                    "item_count": 2606,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I have seen"
                                },
                                {
                                    "description": "All the Great Movies I've Watched So Far",
                                    "favorite_count": 0,
                                    "id": 7092449,
                                    "item_count": 454,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Películas que me han gustado",
                                    "favorite_count": 0,
                                    "id": 79032,
                                    "item_count": 76,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas que me gustaron"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 109598,
                                    "item_count": 1629,
                                    "iso_639_1": "nl",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7051469,
                                    "item_count": 239,
                                    "iso_639_1": "uk",
                                    "list_type": "movie",
                                    "name": "Переглянула"
                                },
                                {
                                    "description": "DO NOT DELETE",
                                    "favorite_count": 0,
                                    "id": 110683,
                                    "item_count": 1093,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex"
                                },
                                {
                                    "description": "My movies collection",
                                    "favorite_count": 0,
                                    "id": 7082030,
                                    "item_count": 951,
                                    "iso_639_1": "ro",
                                    "list_type": "movie",
                                    "name": "Movies Collection"
                                },
                                {
                                    "description": "List for tracking Plex requests for ADENN",
                                    "favorite_count": 0,
                                    "id": 7091172,
                                    "item_count": 107,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex Requests"
                                }
                            ],
                            "total_pages": 45,
                            "total_results": 894
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/la-la-land--2",
                                "voteCount": 4417
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "1",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "declined_cinema",
                    "date": [
                        {
                            "requestDateId": "1214",
                            "requestId": "2684443",
                            "suggestedDate": "2020-11-20 16:15:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-11-14 16:18:26",
                            "updateTimestamp": "2020-11-14 16:18:26",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-11-14 16:18:26",
                    "updateTimestamp": "2020-11-20 16:16:21",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2684444",
                    "cmsId": null,
                    "eventTypeId": "145302",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Animationsfilm nach Astrid Lindgren, der die Pippi-Langstrumpf-Geschichte erzählt. Nachdem Pippi von ihrem Vater getrennt wird, zieht sie in die Villa Kunterbunt ein und gewinnt schnell neue Freunde in Form der Nachbarskinder Annika und Tommy. Zusammem erleben sie die wildesten Abenteuer. Bis Pippis Vater zurückkehrt und Pippi vor eine schwierige Entscheidung stellt.",
                    "expireDate": "2020-11-20 16:15:00",
                    "duration": null,
                    "name": "Pippi Langstrumpf",
                    "slug": "",
                    "hospitality": null,
                    "category": "0",
                    "creationDate": "2020-11-14 16:21:57",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": false,
                    "uniqueId": null,
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "12630m",
                    "externalData": {
                        "lastUpdated": 1636331843,
                        "id": 12630,
                        "cineamoId": "12630m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/8LEZ1KqLRcktagPnSr6wC5FzuQr.jpg",
                        "genres": [
                            {
                                "id": 12,
                                "name": "Abenteuer"
                            },
                            {
                                "id": 16,
                                "name": "Animation"
                            },
                            {
                                "id": 35,
                                "name": "Komödie"
                            },
                            {
                                "id": 10751,
                                "name": "Familie"
                            }
                        ],
                        "homepage": null,
                        "imdbId": "tt0119899",
                        "originalLanguage": "en",
                        "originalTitle": "Pippi Longstocking",
                        "overview": "Animationsfilm nach Astrid Lindgren, der die Pippi-Langstrumpf-Geschichte erzählt. Nachdem Pippi von ihrem Vater getrennt wird, zieht sie in die Villa Kunterbunt ein und gewinnt schnell neue Freunde in Form der Nachbarskinder Annika und Tommy. Zusammem erleben sie die wildesten Abenteuer. Bis Pippis Vater zurückkehrt und Pippi vor eine schwierige Entscheidung stellt.",
                        "popularity": 5.365,
                        "posterPath": "/nndjAapR3epDSxuNSpgRdfP7L6v.jpg",
                        "releaseDate": "1997-08-20",
                        "revenue": 0,
                        "runtime": 75,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": null,
                        "title": "Pippi Langstrumpf",
                        "video": false,
                        "voteAverage": 5.7,
                        "voteCount": 25,
                        "images": null,
                        "videos": null,
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "Barntillåten",
                                            "iso6391": null,
                                            "releaseDate": "1997-08-20T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1997-08-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 0,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 108971,
                                    "item_count": 384,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Want to see"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7101262,
                                    "item_count": 1622,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies to watch"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7074473,
                                    "item_count": 223,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Digalodeon Man-Of-The-Hour"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 84599,
                                    "item_count": 192,
                                    "iso_639_1": "sv",
                                    "list_type": "movie",
                                    "name": "Filmer på Svenska "
                                },
                                {
                                    "description": "Filme für Jonas",
                                    "favorite_count": 0,
                                    "id": 6903,
                                    "item_count": 47,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Kinderfilme",
                                    "poster_path": "/lUzZncdnKppPRGn94ypRg4Q9dsi.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 4113,
                                    "item_count": 367,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Min filmopplevelse",
                                    "poster_path": "/fQcuQ9xLHLDF8SmtHtcsVJ2DjNR.jpg"
                                },
                                {
                                    "description": "Family movies",
                                    "favorite_count": 0,
                                    "id": 2655,
                                    "item_count": 59,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Family",
                                    "poster_path": "/2MYHrJ59VVHL8659n4qa0aUOZCu.jpg"
                                }
                            ],
                            "total_pages": 1,
                            "total_results": 7
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 4.5,
                                "link": "http://www.moviepilot.de/movies/pippi-langstrumpf",
                                "voteCount": 88
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "0",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "accepted_customer",
                    "date": [
                        {
                            "requestDateId": "1215",
                            "requestId": "2684444",
                            "suggestedDate": "2020-11-20 16:15:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-11-14 16:21:57",
                            "updateTimestamp": "2020-11-14 16:21:57",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-11-14 16:21:57",
                    "updateTimestamp": "2020-11-20 16:16:23",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2686459",
                    "cmsId": null,
                    "eventTypeId": "67479",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                    "expireDate": "2020-12-04 17:30:00",
                    "duration": "128",
                    "name": "La La Land",
                    "slug": "",
                    "hospitality": "",
                    "category": "0",
                    "creationDate": "2020-11-30 17:24:47",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": true,
                    "uniqueId": "D73MRs1R9zZziVM3DYM88NmTVJL99YFdTv0LCM4PbaWFxgs7jfPHivdqIM2EWNpMlXez77iXQ2H6WSL7cDp5ag",
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "313369m",
                    "externalData": {
                        "lastUpdated": 1636161142,
                        "id": 313369,
                        "cineamoId": "313369m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ik2D3KqxFD0O0Bc3Wv1CZm8sOg8.jpg",
                        "genres": [
                            {
                                "id": 35,
                                "name": "Komödie"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 10749,
                                "name": "Liebesfilm"
                            },
                            {
                                "id": 10402,
                                "name": "Musik"
                            }
                        ],
                        "homepage": "http://www.lalaland.movie",
                        "imdbId": "tt3783958",
                        "originalLanguage": "en",
                        "originalTitle": "La La Land",
                        "overview": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                        "popularity": 19.859,
                        "posterPath": "/cRHTEMqPHYEfV9d1n6WF2sw5q7X.jpg",
                        "releaseDate": "2016-11-29",
                        "revenue": 448900000,
                        "runtime": 128,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für alle die zu träumen wagen.",
                        "title": "La La Land",
                        "video": false,
                        "voteAverage": 7.9,
                        "voteCount": 13698,
                        "images": null,
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "57bd7570925141558501255c",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "_bTCzofkxeY",
                                    "name": "LA LA LAND Trailer 2 German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-08-23 16:15:18 UTC",
                                    "official": false
                                },
                                {
                                    "id": "5787612492514113e5004eb0",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "2Amyioxaii4",
                                    "name": "LA LA LAND Trailer German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-07-13 16:00:01 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": "S",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "UA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-06-09T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-02-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "AL",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "0",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ID",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": "APTA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-09-02T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Telluride Film Festival"
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2017-04-11T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2017-02-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TW",
                                    "releaseDates": [
                                        {
                                            "certification": "普通級",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "普遍級",
                                            "iso6391": null,
                                            "releaseDate": "2020-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": "重新上映"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "RU",
                                    "releaseDates": [
                                        {
                                            "certification": "16+",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SG",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": null,
                                            "releaseDate": "2016-11-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IL",
                                    "releaseDates": [
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2017-11-21T00:00:00.000Z",
                                            "type": 6,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": "fr",
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "L",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2016-08-31T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Venice Film Festival"
                                        },
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IN",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-09-12T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Toronto International Film Festival"
                                        },
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-25T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "12A",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-08T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-15T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 30000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 122968,
                                    "item_count": 225,
                                    "iso_639_1": "pl",
                                    "list_type": "movie",
                                    "name": "SEE SOON"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 134725,
                                    "item_count": 644,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Lista film guardati by Federico Margutti",
                                    "favorite_count": 0,
                                    "id": 6064,
                                    "item_count": 745,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "FILM guardati FM",
                                    "poster_path": "/893bPAcgEwZY5tIMjbhQFSZ40A8.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 122265,
                                    "item_count": 702,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Film"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072430,
                                    "item_count": 1022,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Seen"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7144,
                                    "item_count": 5152,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Latest Releases"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 974,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8780,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7096412,
                                    "item_count": 295,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Carl Vitasa's Recommendations "
                                },
                                {
                                    "description": "Movie List",
                                    "favorite_count": 0,
                                    "id": 143237,
                                    "item_count": 175,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "List"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19888,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63036,
                                    "item_count": 11218,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2018;US;All"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24949,
                                    "item_count": 2606,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I have seen"
                                },
                                {
                                    "description": "All the Great Movies I've Watched So Far",
                                    "favorite_count": 0,
                                    "id": 7092449,
                                    "item_count": 454,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Películas que me han gustado",
                                    "favorite_count": 0,
                                    "id": 79032,
                                    "item_count": 76,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas que me gustaron"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 109598,
                                    "item_count": 1629,
                                    "iso_639_1": "nl",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7051469,
                                    "item_count": 239,
                                    "iso_639_1": "uk",
                                    "list_type": "movie",
                                    "name": "Переглянула"
                                },
                                {
                                    "description": "DO NOT DELETE",
                                    "favorite_count": 0,
                                    "id": 110683,
                                    "item_count": 1093,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex"
                                },
                                {
                                    "description": "My movies collection",
                                    "favorite_count": 0,
                                    "id": 7082030,
                                    "item_count": 951,
                                    "iso_639_1": "ro",
                                    "list_type": "movie",
                                    "name": "Movies Collection"
                                },
                                {
                                    "description": "List for tracking Plex requests for ADENN",
                                    "favorite_count": 0,
                                    "id": 7091172,
                                    "item_count": 107,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex Requests"
                                }
                            ],
                            "total_pages": 45,
                            "total_results": 894
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/la-la-land--2",
                                "voteCount": 4417
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "1",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "event_canceled",
                    "date": [
                        {
                            "requestDateId": "1233",
                            "requestId": "2686459",
                            "suggestedDate": "2020-12-04 17:30:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-11-30 17:24:47",
                            "updateTimestamp": "2020-11-30 17:24:47",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-11-30 17:24:47",
                    "updateTimestamp": "2020-12-04 17:31:20",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2686460",
                    "cmsId": null,
                    "eventTypeId": "145609",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Die Studenten Jim Morrison und Ray Manzarek gründen am Strand von Venice die Rockgruppe The Doors. Nach skandalumwitterten Auftritten gelingt den Doors im \"Summer of Love\" der Durchbruch. Der todessehnsüchtige Morrison steigert sich in Alkohol- und Drogenexzesse und hat - trotz seiner Bindung an Pamela - unzählige Frauengeschichten. Der körperliche Raubbau beeinträchtigt die Qualität der Musik. Als Morrison bei einem Konzert in Florida 1969 angeblich die Hosen runterläßt, sind die Doors bereits ausgebrannt. Fett und aufgeschwemmt verläßt Morrison die Band, um in Paris auf den Spuren Beaudelaires zu wandeln. Im Juli 1971 findet man Morrison 27jährig tot in seinem Hotel.",
                    "expireDate": "2020-12-04 18:00:00",
                    "duration": "140",
                    "name": "The Doors",
                    "slug": "",
                    "hospitality": "",
                    "category": "0",
                    "creationDate": "2020-11-30 18:05:20",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": true,
                    "uniqueId": "gXtzXz0L9E4M6ssACUH4TMgwdv5tVCQ8vaLMAsKRSQyARBhXd3gRfu6JUI9nfTAhGpxexcqi6MAHUCZ6SHmByg",
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "10537m",
                    "externalData": {
                        "lastUpdated": 1634850618,
                        "id": 10537,
                        "cineamoId": "10537m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/dZjxrjuE4GPmo4iOAyfxZVumEDx.jpg",
                        "genres": [
                            {
                                "id": 10402,
                                "name": "Musik"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            }
                        ],
                        "homepage": null,
                        "imdbId": "tt0101761",
                        "originalLanguage": "en",
                        "originalTitle": "The Doors",
                        "overview": "Die Studenten Jim Morrison und Ray Manzarek gründen am Strand von Venice die Rockgruppe The Doors. Nach skandalumwitterten Auftritten gelingt den Doors im \"Summer of Love\" der Durchbruch. Der todessehnsüchtige Morrison steigert sich in Alkohol- und Drogenexzesse und hat - trotz seiner Bindung an Pamela - unzählige Frauengeschichten. Der körperliche Raubbau beeinträchtigt die Qualität der Musik. Als Morrison bei einem Konzert in Florida 1969 angeblich die Hosen runterläßt, sind die Doors bereits ausgebrannt. Fett und aufgeschwemmt verläßt Morrison die Band, um in Paris auf den Spuren Beaudelaires zu wandeln. Im Juli 1971 findet man Morrison 27jährig tot in seinem Hotel.",
                        "popularity": 14.988,
                        "posterPath": "/3sOroJIEyGBQhBLEnHOF4Fe74UN.jpg",
                        "releaseDate": "1991-03-01",
                        "revenue": 34416893,
                        "runtime": 140,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": null,
                        "title": "The Doors",
                        "video": false,
                        "voteAverage": 7.1,
                        "voteCount": 988,
                        "images": {
                            "id": null,
                            "backdrops": [
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/dZjxrjuE4GPmo4iOAyfxZVumEDx.jpg",
                                    "height": 720,
                                    "iso6391": "en",
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 1280
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/xwfgCZkGKtbb2KabOcdrMLKRWYe.jpg",
                                    "height": 1406,
                                    "iso6391": null,
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 2500
                                },
                                {
                                    "aspectRatio": 1.775,
                                    "filePath": "/t0ftTmMMdqPI31iLYMrDV3Z1Mwd.jpg",
                                    "height": 721,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1280
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/mJeQoCL4XOO0WnfZEpmLqHcJVJb.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/71xDmqLqjajb3TdGtyd18owEh1a.jpg",
                                    "height": 1080,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/q59PcA9eMpKBvYzU7CVOdKzl5Dk.jpg",
                                    "height": 1406,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2500
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/oqaOht092FfohbxOP9dadJCXo0.jpg",
                                    "height": 1406,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2500
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/kmoirBb1BbzAETiKKby7bJPh00f.jpg",
                                    "height": 720,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1280
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/cvPkwxsV2DEBiECAexZvsLOO7j0.jpg",
                                    "height": 720,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1280
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/htDVvCWCDaYmlH0w3CCmY1rb3Vj.jpg",
                                    "height": 720,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1280
                                }
                            ],
                            "posters": [
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/14Jw0oYnpRoJ2BPoJwje3wrNOwp.jpg",
                                    "height": 1426,
                                    "iso6391": "en",
                                    "voteAverage": 5.384,
                                    "voteCount": 2,
                                    "width": 951
                                },
                                {
                                    "aspectRatio": 0.67,
                                    "filePath": "/ehwkp9sTDtil3BTeqeN459pOsGr.jpg",
                                    "height": 1590,
                                    "iso6391": "en",
                                    "voteAverage": 5.384,
                                    "voteCount": 2,
                                    "width": 1065
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/cXpoD87BVsVEqch7BDddm9RnXfZ.jpg",
                                    "height": 1500,
                                    "iso6391": "en",
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.67,
                                    "filePath": "/2mCJgrWFIfRjMBQM38wKnRjIggD.jpg",
                                    "height": 1590,
                                    "iso6391": "en",
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 1065
                                },
                                {
                                    "aspectRatio": 0.674,
                                    "filePath": "/4cr3hbn60Q6ue8r2dpt6IWLOz3f.jpg",
                                    "height": 1792,
                                    "iso6391": "en",
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 1207
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/x1LM3dzGuG6xOz0aT2e71o11vhu.jpg",
                                    "height": 1500,
                                    "iso6391": "en",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.714,
                                    "filePath": "/ty0kPC9UDoK5Qq5gPofTCfCjqwL.jpg",
                                    "height": 979,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 699
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/jh9kStGXHNYvzbqO3bgorgpTOC5.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/wTaMwstPmh6SrIxw0EDN4mmIo1R.jpg",
                                    "height": 2100,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1400
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/3sOroJIEyGBQhBLEnHOF4Fe74UN.jpg",
                                    "height": 1500,
                                    "iso6391": "de",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/oECbN59aijp9gndA7IqiykU4RYs.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/8XngRlTVsdd9T4knOqFoudHFIRy.jpg",
                                    "height": 1500,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/buZfcMGcdMwfN060cArcgbh7hJc.jpg",
                                    "height": 750,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 500
                                }
                            ]
                        },
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "5acf6dcb0e0a2631cc0602f8",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "Q9mndb2Vt5M",
                                    "name": "The Doors ≣ 1991 ≣ Trailer",
                                    "site": "YouTube",
                                    "size": 360,
                                    "type": "Trailer",
                                    "publishedAt": "2016-02-03 22:14:56 UTC",
                                    "official": false
                                },
                                {
                                    "id": "5574b3179251413efe00215f",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "ZHiH2AsJZ2I",
                                    "name": "The Doors - Trailer",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2015-01-23 00:12:13 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2001-11-29T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/18",
                                            "iso6391": null,
                                            "releaseDate": "1991-04-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CZ",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1992-02-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IE",
                                    "releaseDates": [
                                        {
                                            "certification": "18",
                                            "iso6391": null,
                                            "releaseDate": "1991-05-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SU",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1991-07-08T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Moscow Film Festival"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "18",
                                            "iso6391": null,
                                            "releaseDate": "1991-03-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "MX",
                                    "releaseDates": [
                                        {
                                            "certification": "C",
                                            "iso6391": null,
                                            "releaseDate": "1991-05-31T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Foro Internacional de la Cineteca"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "MA15+",
                                            "iso6391": null,
                                            "releaseDate": "1991-05-30T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1991-06-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "1991-09-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "16",
                                            "iso6391": null,
                                            "releaseDate": "1991-05-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "16",
                                            "iso6391": null,
                                            "releaseDate": "2001-12-15T00:00:00.000Z",
                                            "type": 6,
                                            "note": "Nederland 1"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1991-02-23T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Los Angeles, California"
                                        },
                                        {
                                            "certification": "R",
                                            "iso6391": null,
                                            "releaseDate": "1991-03-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "R",
                                            "iso6391": null,
                                            "releaseDate": "1997-08-19T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD Release"
                                        },
                                        {
                                            "certification": "R",
                                            "iso6391": null,
                                            "releaseDate": "2008-08-12T00:00:00.000Z",
                                            "type": 5,
                                            "note": "Blu-Ray Release"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "18",
                                            "iso6391": null,
                                            "releaseDate": "1991-04-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "15",
                                            "iso6391": null,
                                            "releaseDate": "1991-04-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1991-04-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": "18",
                                            "iso6391": null,
                                            "releaseDate": "1991-07-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "11",
                                            "iso6391": null,
                                            "releaseDate": "1991-07-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": "K-16",
                                            "iso6391": null,
                                            "releaseDate": "1991-05-17T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "1991-09-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "16",
                                            "iso6391": null,
                                            "releaseDate": "1991-05-03T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TR",
                                    "releaseDates": [
                                        {
                                            "certification": "18+",
                                            "iso6391": null,
                                            "releaseDate": "1991-09-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": "18A",
                                            "iso6391": null,
                                            "releaseDate": "1991-03-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": "18",
                                            "iso6391": null,
                                            "releaseDate": "1991-04-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 38000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 7082756,
                                    "item_count": 581,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "Films vus en famille"
                                },
                                {
                                    "description": "All movies and shows I've seen",
                                    "favorite_count": 0,
                                    "id": 7111094,
                                    "item_count": 2138,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I've seen"
                                },
                                {
                                    "description": "Cette liste regroupe l'ensemble des vidéos disponible sur la canal TMK - Chat.",
                                    "favorite_count": 0,
                                    "id": 139130,
                                    "item_count": 1056,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "TMK - Ciné 🍿"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102714,
                                    "item_count": 907,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "My Movies At Home"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8756,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 12882,
                                    "item_count": 216,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "Da vedere - 90s / 00s"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072243,
                                    "item_count": 2388,
                                    "iso_639_1": "nl",
                                    "list_type": "movie",
                                    "name": "Verzameling "
                                },
                                {
                                    "description": "my collection",
                                    "favorite_count": 0,
                                    "id": 35027,
                                    "item_count": 2934,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "DVD"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 137159,
                                    "item_count": 1298,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "All Film's A - H"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 44770,
                                    "item_count": 2911,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "ML Watchlist"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 113345,
                                    "item_count": 952,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "MY DOCUMENTARIES"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 78677,
                                    "item_count": 8047,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7092101,
                                    "item_count": 13403,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "for radarr"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19890,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 85047,
                                    "item_count": 760,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Pelis Vistas"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7061987,
                                    "item_count": 358,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "UHD"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7061989,
                                    "item_count": 1176,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Alle Medien"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 126302,
                                    "item_count": 7021,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Abstrusest 2"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102264,
                                    "item_count": 9,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Angels"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7077448,
                                    "item_count": 404,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Gesehen Drama"
                                }
                            ],
                            "total_pages": 6,
                            "total_results": 116
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.3,
                                "link": "http://www.moviepilot.de/movies/the-doors",
                                "voteCount": 2564
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "1",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "event_canceled",
                    "date": [
                        {
                            "requestDateId": "1234",
                            "requestId": "2686460",
                            "suggestedDate": "2020-12-04 18:00:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-11-30 18:05:20",
                            "updateTimestamp": "2020-11-30 18:05:20",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-11-30 18:05:20",
                    "updateTimestamp": "2020-12-04 18:01:19",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2686463",
                    "cmsId": null,
                    "eventTypeId": "145611",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "New York, 1961. Was macht ein erfolgloser New Yorker Musiker ohne ein Zuhause? Was passiert, wenn er fast jede Nacht auf einer anderen Couch schläft und dabei mal die Frau eines Freundes schwängert und mal die geliebte Katze eines anderen aussperrt? Und was, wenn er diese Katze dann einfach nicht mehr los wird? Llewyn Davis lebt für die Folkmusik, doch der große Durchbruch lässt auf sich warten.",
                    "expireDate": "2020-12-04 18:45:00",
                    "duration": "104",
                    "name": "Inside Llewyn Davis",
                    "slug": "",
                    "hospitality": "",
                    "category": "5",
                    "creationDate": "2020-11-30 18:40:57",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": true,
                    "uniqueId": "2TwLxlWDSSgwzPhrpDTBQqISy4xDA4wYUbn-pRAM-eYy2coX59-DjNFyNXSqnOmO2yoIehFAVhjt5QZkZb5sRQ",
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "86829m",
                    "externalData": {
                        "lastUpdated": 1635189351,
                        "id": 86829,
                        "cineamoId": "86829m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/6QOZ7RGucRJLGvm7TH5k2BN0xSp.jpg",
                        "genres": [
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 10402,
                                "name": "Musik"
                            }
                        ],
                        "homepage": "http://insidellewyndavis.de/",
                        "imdbId": "tt2042568",
                        "originalLanguage": "en",
                        "originalTitle": "Inside Llewyn Davis",
                        "overview": "New York, 1961. Was macht ein erfolgloser New Yorker Musiker ohne ein Zuhause? Was passiert, wenn er fast jede Nacht auf einer anderen Couch schläft und dabei mal die Frau eines Freundes schwängert und mal die geliebte Katze eines anderen aussperrt? Und was, wenn er diese Katze dann einfach nicht mehr los wird? Llewyn Davis lebt für die Folkmusik, doch der große Durchbruch lässt auf sich warten.",
                        "popularity": 9.969,
                        "posterPath": "/cjiE3mMvDSYqVBh1iTmPs3RNY3x.jpg",
                        "releaseDate": "2013-10-13",
                        "revenue": 32935319,
                        "runtime": 104,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": null,
                        "title": "Inside Llewyn Davis",
                        "video": false,
                        "voteAverage": 7.2,
                        "voteCount": 2066,
                        "images": {
                            "id": null,
                            "backdrops": [
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/6QOZ7RGucRJLGvm7TH5k2BN0xSp.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 5.454,
                                    "voteCount": 3,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/cQ8HfwQc8mbi1D0j67hmDVuc5Ip.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/9YPaiySAPdXTBsmMg7eSsq9vDro.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 3840
                                }
                            ],
                            "posters": [
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/cjiE3mMvDSYqVBh1iTmPs3RNY3x.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.318,
                                    "voteCount": 3,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/nNxK3pC3DMpPpWKMvo2p3liREVT.jpg",
                                    "height": 1200,
                                    "iso6391": "en",
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 800
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/1kNdpu3nr7IWYkloBWMVOdnqmsq.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.18,
                                    "voteCount": 3,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/zf3p0Twgf0Glz8fhxjrZHM31DFh.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/6zIbdBcYi1HIZ0kJ8Zx7lC9RDH6.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/cly9K0QumuAhZgDIjbX7iWJsDfy.jpg",
                                    "height": 1500,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                }
                            ]
                        },
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "58bb0b93c3a36851cd00a44a",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "JTxqkCaBNvc",
                                    "name": "INSIDE LLEWYN DAVIS | Teaser-Trailer german deutsch [HD]",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2013-05-21 12:38:05 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "15",
                                            "iso6391": null,
                                            "releaseDate": "2014-01-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-01-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-11-21T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-11-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "RU",
                                    "releaseDates": [
                                        {
                                            "certification": "16+",
                                            "iso6391": "ru",
                                            "releaseDate": "2014-01-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": "«Парадиз»"
                                        },
                                        {
                                            "certification": "16+",
                                            "iso6391": "ru",
                                            "releaseDate": "2014-03-25T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD «Парадиз / Активижен»"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "MX",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-01-02T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IS",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-01-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IE",
                                    "releaseDates": [
                                        {
                                            "certification": "15A",
                                            "iso6391": null,
                                            "releaseDate": "2014-01-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-02-21T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": "en",
                                            "releaseDate": "2014-01-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-02-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KZ",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-11-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SI",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-11-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BE",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-12-04T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-02-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "15",
                                            "iso6391": null,
                                            "releaseDate": "2014-01-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-10-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "7",
                                            "iso6391": null,
                                            "releaseDate": "2014-03-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "R",
                                            "iso6391": "en",
                                            "releaseDate": "2013-09-28T00:00:00.000Z",
                                            "type": 1,
                                            "note": "New York Film Festival"
                                        },
                                        {
                                            "certification": "R",
                                            "iso6391": "en",
                                            "releaseDate": "2013-12-06T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "R",
                                            "iso6391": "en",
                                            "releaseDate": "2013-12-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-12-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-05-19T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Cannes Film Festival"
                                        },
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-11-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2014-01-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-05-30T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2013-12-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2014-04-08T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2016-03-11T00:00:00.000Z",
                                            "type": 6,
                                            "note": "NPO 3"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-01-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2013-10-18T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2014-01-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 11000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "description": "All movies and shows I've seen",
                                    "favorite_count": 0,
                                    "id": 7111094,
                                    "item_count": 2263,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I've seen"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102714,
                                    "item_count": 930,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "My Movies At Home"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7111801,
                                    "item_count": 41,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Favorites"
                                },
                                {
                                    "description": "Películas que ya he visto. \n\n",
                                    "favorite_count": 0,
                                    "id": 12553,
                                    "item_count": 1962,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas vistas"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 97238,
                                    "item_count": 669,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched"
                                },
                                {
                                    "description": "All the movies i have seen in my life ",
                                    "favorite_count": 0,
                                    "id": 119711,
                                    "item_count": 2416,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "All The Movies I Have Seen "
                                },
                                {
                                    "description": "Every movie I have seen since start of 2020 quarantine extravaganza",
                                    "favorite_count": 0,
                                    "id": 7082247,
                                    "item_count": 83,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "John's Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072430,
                                    "item_count": 1015,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Seen"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63036,
                                    "item_count": 11221,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2018;US;All"
                                },
                                {
                                    "description": "Films released between 2010 and 2019",
                                    "favorite_count": 0,
                                    "id": 102924,
                                    "item_count": 130,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2019"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7059058,
                                    "item_count": 1371,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "PROMO BLU-RAY"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 128576,
                                    "item_count": 361,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "To Get"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7076630,
                                    "item_count": 836,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Drama"
                                },
                                {
                                    "description": "<3",
                                    "favorite_count": 0,
                                    "id": 101542,
                                    "item_count": 990,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Vaached❤"
                                },
                                {
                                    "description": "my collection",
                                    "favorite_count": 0,
                                    "id": 35027,
                                    "item_count": 2934,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "DVD"
                                },
                                {
                                    "description": "Filmer som är sedda på bio någon gång.",
                                    "favorite_count": 0,
                                    "id": 14776,
                                    "item_count": 1887,
                                    "iso_639_1": "sv",
                                    "list_type": "movie",
                                    "name": "Filmer sedda på bio."
                                },
                                {
                                    "description": "Liste pour les films récents",
                                    "favorite_count": 0,
                                    "id": 142289,
                                    "item_count": 124,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "Films Récents"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 80639,
                                    "item_count": 737,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 78677,
                                    "item_count": 8047,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19890,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                }
                            ],
                            "total_pages": 11,
                            "total_results": 217
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/inside-llewyn-davis",
                                "voteCount": 3961
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "1",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "new",
                    "date": [
                        {
                            "requestDateId": "1237",
                            "requestId": "2686463",
                            "suggestedDate": "2020-12-04 18:45:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-11-30 18:40:57",
                            "updateTimestamp": "2020-11-30 18:40:57",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-11-30 18:40:57",
                    "updateTimestamp": "2020-12-04 18:46:19",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2686927",
                    "cmsId": null,
                    "eventTypeId": "67479",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                    "expireDate": "2020-12-06 10:00:00",
                    "duration": "128",
                    "name": "La La Land",
                    "slug": "",
                    "hospitality": "",
                    "category": "5",
                    "creationDate": "2020-12-02 09:59:38",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": true,
                    "uniqueId": "H5iYbzDzr6KOgr3x2viqqZJN66VnC1cFIoyQQX5Qr7DA6T4d8jW8uaAaCSYGnwFeX2cf4ZikAK6VqmL-L6sHwA",
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "313369m",
                    "externalData": {
                        "lastUpdated": 1636161142,
                        "id": 313369,
                        "cineamoId": "313369m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ik2D3KqxFD0O0Bc3Wv1CZm8sOg8.jpg",
                        "genres": [
                            {
                                "id": 35,
                                "name": "Komödie"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 10749,
                                "name": "Liebesfilm"
                            },
                            {
                                "id": 10402,
                                "name": "Musik"
                            }
                        ],
                        "homepage": "http://www.lalaland.movie",
                        "imdbId": "tt3783958",
                        "originalLanguage": "en",
                        "originalTitle": "La La Land",
                        "overview": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                        "popularity": 19.859,
                        "posterPath": "/cRHTEMqPHYEfV9d1n6WF2sw5q7X.jpg",
                        "releaseDate": "2016-11-29",
                        "revenue": 448900000,
                        "runtime": 128,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für alle die zu träumen wagen.",
                        "title": "La La Land",
                        "video": false,
                        "voteAverage": 7.9,
                        "voteCount": 13698,
                        "images": null,
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "57bd7570925141558501255c",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "_bTCzofkxeY",
                                    "name": "LA LA LAND Trailer 2 German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-08-23 16:15:18 UTC",
                                    "official": false
                                },
                                {
                                    "id": "5787612492514113e5004eb0",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "2Amyioxaii4",
                                    "name": "LA LA LAND Trailer German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-07-13 16:00:01 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": "S",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "UA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-06-09T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-02-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "AL",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "0",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ID",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": "APTA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-09-02T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Telluride Film Festival"
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2017-04-11T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2017-02-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TW",
                                    "releaseDates": [
                                        {
                                            "certification": "普通級",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "普遍級",
                                            "iso6391": null,
                                            "releaseDate": "2020-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": "重新上映"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "RU",
                                    "releaseDates": [
                                        {
                                            "certification": "16+",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SG",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": null,
                                            "releaseDate": "2016-11-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IL",
                                    "releaseDates": [
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2017-11-21T00:00:00.000Z",
                                            "type": 6,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": "fr",
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "L",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2016-08-31T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Venice Film Festival"
                                        },
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IN",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-09-12T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Toronto International Film Festival"
                                        },
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-25T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "12A",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-08T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-15T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 30000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 122968,
                                    "item_count": 225,
                                    "iso_639_1": "pl",
                                    "list_type": "movie",
                                    "name": "SEE SOON"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 134725,
                                    "item_count": 644,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Lista film guardati by Federico Margutti",
                                    "favorite_count": 0,
                                    "id": 6064,
                                    "item_count": 745,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "FILM guardati FM",
                                    "poster_path": "/893bPAcgEwZY5tIMjbhQFSZ40A8.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 122265,
                                    "item_count": 702,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Film"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072430,
                                    "item_count": 1022,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Seen"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7144,
                                    "item_count": 5152,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Latest Releases"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 974,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8780,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7096412,
                                    "item_count": 295,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Carl Vitasa's Recommendations "
                                },
                                {
                                    "description": "Movie List",
                                    "favorite_count": 0,
                                    "id": 143237,
                                    "item_count": 175,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "List"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19888,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63036,
                                    "item_count": 11218,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2018;US;All"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24949,
                                    "item_count": 2606,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I have seen"
                                },
                                {
                                    "description": "All the Great Movies I've Watched So Far",
                                    "favorite_count": 0,
                                    "id": 7092449,
                                    "item_count": 454,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Películas que me han gustado",
                                    "favorite_count": 0,
                                    "id": 79032,
                                    "item_count": 76,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas que me gustaron"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 109598,
                                    "item_count": 1629,
                                    "iso_639_1": "nl",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7051469,
                                    "item_count": 239,
                                    "iso_639_1": "uk",
                                    "list_type": "movie",
                                    "name": "Переглянула"
                                },
                                {
                                    "description": "DO NOT DELETE",
                                    "favorite_count": 0,
                                    "id": 110683,
                                    "item_count": 1093,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex"
                                },
                                {
                                    "description": "My movies collection",
                                    "favorite_count": 0,
                                    "id": 7082030,
                                    "item_count": 951,
                                    "iso_639_1": "ro",
                                    "list_type": "movie",
                                    "name": "Movies Collection"
                                },
                                {
                                    "description": "List for tracking Plex requests for ADENN",
                                    "favorite_count": 0,
                                    "id": 7091172,
                                    "item_count": 107,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex Requests"
                                }
                            ],
                            "total_pages": 45,
                            "total_results": 894
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/la-la-land--2",
                                "voteCount": 4417
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "1",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "initial",
                    "date": [
                        {
                            "requestDateId": "1238",
                            "requestId": "2686927",
                            "suggestedDate": "2020-12-06 10:00:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-12-02 09:59:38",
                            "updateTimestamp": "2020-12-02 09:59:38",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-12-02 09:59:38",
                    "updateTimestamp": "2020-12-06 10:01:22",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2687506",
                    "cmsId": null,
                    "eventTypeId": "67479",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                    "expireDate": null,
                    "duration": null,
                    "name": "La La Land",
                    "slug": "",
                    "hospitality": null,
                    "category": "0",
                    "creationDate": "2020-12-07 09:21:40",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": false,
                    "uniqueId": null,
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": "",
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "313369m",
                    "externalData": {
                        "lastUpdated": 1636161142,
                        "id": 313369,
                        "cineamoId": "313369m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ik2D3KqxFD0O0Bc3Wv1CZm8sOg8.jpg",
                        "genres": [
                            {
                                "id": 35,
                                "name": "Komödie"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 10749,
                                "name": "Liebesfilm"
                            },
                            {
                                "id": 10402,
                                "name": "Musik"
                            }
                        ],
                        "homepage": "http://www.lalaland.movie",
                        "imdbId": "tt3783958",
                        "originalLanguage": "en",
                        "originalTitle": "La La Land",
                        "overview": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                        "popularity": 19.859,
                        "posterPath": "/cRHTEMqPHYEfV9d1n6WF2sw5q7X.jpg",
                        "releaseDate": "2016-11-29",
                        "revenue": 448900000,
                        "runtime": 128,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für alle die zu träumen wagen.",
                        "title": "La La Land",
                        "video": false,
                        "voteAverage": 7.9,
                        "voteCount": 13698,
                        "images": null,
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "57bd7570925141558501255c",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "_bTCzofkxeY",
                                    "name": "LA LA LAND Trailer 2 German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-08-23 16:15:18 UTC",
                                    "official": false
                                },
                                {
                                    "id": "5787612492514113e5004eb0",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "2Amyioxaii4",
                                    "name": "LA LA LAND Trailer German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-07-13 16:00:01 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": "S",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "UA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-06-09T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-02-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "AL",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "0",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ID",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": "APTA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-09-02T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Telluride Film Festival"
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2017-04-11T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2017-02-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TW",
                                    "releaseDates": [
                                        {
                                            "certification": "普通級",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "普遍級",
                                            "iso6391": null,
                                            "releaseDate": "2020-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": "重新上映"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "RU",
                                    "releaseDates": [
                                        {
                                            "certification": "16+",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SG",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": null,
                                            "releaseDate": "2016-11-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IL",
                                    "releaseDates": [
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2017-11-21T00:00:00.000Z",
                                            "type": 6,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": "fr",
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "L",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2016-08-31T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Venice Film Festival"
                                        },
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IN",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-09-12T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Toronto International Film Festival"
                                        },
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-25T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "12A",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-08T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-15T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 30000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 122968,
                                    "item_count": 225,
                                    "iso_639_1": "pl",
                                    "list_type": "movie",
                                    "name": "SEE SOON"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 134725,
                                    "item_count": 644,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Lista film guardati by Federico Margutti",
                                    "favorite_count": 0,
                                    "id": 6064,
                                    "item_count": 745,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "FILM guardati FM",
                                    "poster_path": "/893bPAcgEwZY5tIMjbhQFSZ40A8.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 122265,
                                    "item_count": 702,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Film"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072430,
                                    "item_count": 1022,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Seen"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7144,
                                    "item_count": 5152,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Latest Releases"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 974,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8780,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7096412,
                                    "item_count": 295,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Carl Vitasa's Recommendations "
                                },
                                {
                                    "description": "Movie List",
                                    "favorite_count": 0,
                                    "id": 143237,
                                    "item_count": 175,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "List"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19888,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63036,
                                    "item_count": 11218,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2018;US;All"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24949,
                                    "item_count": 2606,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I have seen"
                                },
                                {
                                    "description": "All the Great Movies I've Watched So Far",
                                    "favorite_count": 0,
                                    "id": 7092449,
                                    "item_count": 454,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Películas que me han gustado",
                                    "favorite_count": 0,
                                    "id": 79032,
                                    "item_count": 76,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas que me gustaron"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 109598,
                                    "item_count": 1629,
                                    "iso_639_1": "nl",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7051469,
                                    "item_count": 239,
                                    "iso_639_1": "uk",
                                    "list_type": "movie",
                                    "name": "Переглянула"
                                },
                                {
                                    "description": "DO NOT DELETE",
                                    "favorite_count": 0,
                                    "id": 110683,
                                    "item_count": 1093,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex"
                                },
                                {
                                    "description": "My movies collection",
                                    "favorite_count": 0,
                                    "id": 7082030,
                                    "item_count": 951,
                                    "iso_639_1": "ro",
                                    "list_type": "movie",
                                    "name": "Movies Collection"
                                },
                                {
                                    "description": "List for tracking Plex requests for ADENN",
                                    "favorite_count": 0,
                                    "id": 7091172,
                                    "item_count": 107,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex Requests"
                                }
                            ],
                            "total_pages": 45,
                            "total_results": 894
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/la-la-land--2",
                                "voteCount": 4417
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "0",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": "2020-12-14 09:49:10",
                    "state": "check_rights",
                    "date": [
                        {
                            "requestDateId": "1247",
                            "requestId": "2687506",
                            "suggestedDate": "2021-12-03 09:15:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-12-07 09:21:40",
                            "updateTimestamp": "2020-12-07 09:21:40",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "success_not_expected",
                    "declineReasonLabel": "Kein Erfolg erwartet",
                    "businessRequest": null,
                    "insertTimestamp": "2020-12-07 09:21:40",
                    "updateTimestamp": "2020-12-14 09:49:10",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2688216",
                    "cmsId": null,
                    "eventTypeId": "67479",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                    "expireDate": "2020-12-18 09:45:00",
                    "duration": null,
                    "name": "La La Land",
                    "slug": "",
                    "hospitality": null,
                    "category": "0",
                    "creationDate": "2020-12-14 09:48:49",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": false,
                    "uniqueId": null,
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "313369m",
                    "externalData": {
                        "lastUpdated": 1636161142,
                        "id": 313369,
                        "cineamoId": "313369m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ik2D3KqxFD0O0Bc3Wv1CZm8sOg8.jpg",
                        "genres": [
                            {
                                "id": 35,
                                "name": "Komödie"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 10749,
                                "name": "Liebesfilm"
                            },
                            {
                                "id": 10402,
                                "name": "Musik"
                            }
                        ],
                        "homepage": "http://www.lalaland.movie",
                        "imdbId": "tt3783958",
                        "originalLanguage": "en",
                        "originalTitle": "La La Land",
                        "overview": "Mia ist eine leidenschaftliche Schauspielerin, die ihr Glück in Los Angeles sucht. Sebastian will dort ebenfalls seinen Durchbruch schaffen, allerdings nicht als Schauspieler, sondern als Musiker, der Menschen des 21. Jahrhunderts für traditionellen Jazz begeistern möchte. Mia und Sebastian müssen sich mit Nebenjobs durchschlagen, um ihren Lebensunterhalt zu sichern – sie arbeitet in Cafés, er sitzt in Clubs wie dem von Boss am Keyboard. Nachdem sie einander vorm Klavier begegnet und schließlich ein Paar geworden sind, geben sich gegenseitig Kraft. Von nun an arbeiten sie zu zweit daran, groß rauszukommen. Doch schnell müssen Mia und Sebastian feststellen, dass ihre Bestrebungen auch Opfer fordern und ihre Beziehung auf eine harte Probe stellen. Verrät sich Sebastian selbst, wenn er in der Band von Keith Musik spielt, die er gar nicht mag? Und kann Mia ihre Zeilen nicht auch mit ihrem Freund auf Tour lernen, oder muss sie dazu wirklich in L.A. bleiben?",
                        "popularity": 19.859,
                        "posterPath": "/cRHTEMqPHYEfV9d1n6WF2sw5q7X.jpg",
                        "releaseDate": "2016-11-29",
                        "revenue": 448900000,
                        "runtime": 128,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für alle die zu träumen wagen.",
                        "title": "La La Land",
                        "video": false,
                        "voteAverage": 7.9,
                        "voteCount": 13698,
                        "images": null,
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "57bd7570925141558501255c",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "_bTCzofkxeY",
                                    "name": "LA LA LAND Trailer 2 German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-08-23 16:15:18 UTC",
                                    "official": false
                                },
                                {
                                    "id": "5787612492514113e5004eb0",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "2Amyioxaii4",
                                    "name": "LA LA LAND Trailer German Deutsch (2017) Ryan Gosling, Emma Stone Musical",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2016-07-13 16:00:01 UTC",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": "S",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "UA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "Btl",
                                            "iso6391": null,
                                            "releaseDate": "2017-06-09T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "JP",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-02-24T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "AL",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": "DVD"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "0",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "6",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-24T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ID",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": "APTA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-09-02T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Telluride Film Festival"
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2017-04-11T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2017-02-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TW",
                                    "releaseDates": [
                                        {
                                            "certification": "普通級",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "普遍級",
                                            "iso6391": null,
                                            "releaseDate": "2020-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": "重新上映"
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "RU",
                                    "releaseDates": [
                                        {
                                            "certification": "16+",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SG",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-01T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CL",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": null,
                                            "releaseDate": "2016-11-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IL",
                                    "releaseDates": [
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-08T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "הותר לכל",
                                            "iso6391": null,
                                            "releaseDate": "2017-11-21T00:00:00.000Z",
                                            "type": 6,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": "fr",
                                            "releaseDate": "2017-01-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "L",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-19T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-22T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2016-08-31T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Venice Film Festival"
                                        },
                                        {
                                            "certification": "T",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IN",
                                    "releaseDates": [
                                        {
                                            "certification": "A",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-09-12T00:00:00.000Z",
                                            "type": 1,
                                            "note": "Toronto International Film Festival"
                                        },
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2016-12-25T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "12A",
                                            "iso6391": null,
                                            "releaseDate": "2017-01-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-08T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2017-05-15T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2016-12-29T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 30000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 122968,
                                    "item_count": 225,
                                    "iso_639_1": "pl",
                                    "list_type": "movie",
                                    "name": "SEE SOON"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 134725,
                                    "item_count": 644,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Lista film guardati by Federico Margutti",
                                    "favorite_count": 0,
                                    "id": 6064,
                                    "item_count": 745,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "FILM guardati FM",
                                    "poster_path": "/893bPAcgEwZY5tIMjbhQFSZ40A8.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 122265,
                                    "item_count": 702,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Film"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7072430,
                                    "item_count": 1022,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Seen"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7144,
                                    "item_count": 5152,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Latest Releases"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 974,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8780,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7096412,
                                    "item_count": 295,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Carl Vitasa's Recommendations "
                                },
                                {
                                    "description": "Movie List",
                                    "favorite_count": 0,
                                    "id": 143237,
                                    "item_count": 175,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "List"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 64690,
                                    "item_count": 19888,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "1970-2018;US;20+vote"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63036,
                                    "item_count": 11218,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010-2018;US;All"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24949,
                                    "item_count": 2606,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies I have seen"
                                },
                                {
                                    "description": "All the Great Movies I've Watched So Far",
                                    "favorite_count": 0,
                                    "id": 7092449,
                                    "item_count": 454,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched Movies"
                                },
                                {
                                    "description": "Películas que me han gustado",
                                    "favorite_count": 0,
                                    "id": 79032,
                                    "item_count": 76,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas que me gustaron"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 109598,
                                    "item_count": 1629,
                                    "iso_639_1": "nl",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7051469,
                                    "item_count": 239,
                                    "iso_639_1": "uk",
                                    "list_type": "movie",
                                    "name": "Переглянула"
                                },
                                {
                                    "description": "DO NOT DELETE",
                                    "favorite_count": 0,
                                    "id": 110683,
                                    "item_count": 1093,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex"
                                },
                                {
                                    "description": "My movies collection",
                                    "favorite_count": 0,
                                    "id": 7082030,
                                    "item_count": 951,
                                    "iso_639_1": "ro",
                                    "list_type": "movie",
                                    "name": "Movies Collection"
                                },
                                {
                                    "description": "List for tracking Plex requests for ADENN",
                                    "favorite_count": 0,
                                    "id": 7091172,
                                    "item_count": 107,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex Requests"
                                }
                            ],
                            "total_pages": 45,
                            "total_results": 894
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/la-la-land--2",
                                "voteCount": 4417
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "0",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "check_date",
                    "date": [
                        {
                            "requestDateId": "1249",
                            "requestId": "2688216",
                            "suggestedDate": "2020-12-18 09:45:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": false,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-12-14 09:48:49",
                            "updateTimestamp": "2020-12-14 09:48:49",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-12-14 09:48:49",
                    "updateTimestamp": "2020-12-18 09:46:20",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                },
                {
                    "statistics": null,
                    "requestId": "2690573",
                    "cmsId": null,
                    "eventTypeId": "145921",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Als am 17. Februar 1965 ein Schwarzer von der Polizei niedergeschossen wird und anschließend seinen Verletzungen erliegt, explodiert der Unmut über systematische Diskriminierungen. Bürgerrechtler Martin Luther King Jr. will das nicht hinnehmen und einen Protestmarsch von der Stadt Selma nach Montgomery organisieren. Doch sein Vorhaben wird erschwert: Präsident Lyndon B. Johnson ist nur schwer zu überzeugen und wiegelt mit dem Argument ab, dass das Problem der Schwarzen nicht so wichtig sei. Und George Wallace, Gouverneur von Alabama, geht sogar noch weiter und will alle Bemühungen von King und seinen Anhängern sabotieren. Zu allem Überfluss muss sich King auch noch privaten Problemen stellen, als plötzlich Anschuldigungen laut werden, er wäre seiner Frau untreu gewesen...",
                    "expireDate": "2021-01-26 09:00:00",
                    "duration": "127",
                    "name": "Selma",
                    "slug": "",
                    "hospitality": "",
                    "category": "5",
                    "creationDate": "2021-01-12 09:06:13",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": true,
                    "uniqueId": "ptZodPgDmQH7wsA0fyxnm4QMCJpfikTJikt-JmQ4O3GByoCc5oTIyHEH-8wl1Tdt5xO0FUlWBzT-ij3cpBFNjg",
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "273895m",
                    "externalData": {
                        "lastUpdated": 1641090319,
                        "id": 273895,
                        "cineamoId": "273895m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/wGxLAd738UCzUIJkzQOcjkpHu46.jpg",
                        "genres": [
                            {
                                "id": 36,
                                "name": "Historie"
                            },
                            {
                                "id": 18,
                                "name": "Drama"
                            }
                        ],
                        "homepage": "https://www.paramount.com/movies/selma",
                        "imdbId": "tt1020072",
                        "originalLanguage": "en",
                        "originalTitle": "Selma",
                        "overview": "Als am 17. Februar 1965 ein Schwarzer von der Polizei niedergeschossen wird und anschließend seinen Verletzungen erliegt, explodiert der Unmut über systematische Diskriminierungen. Bürgerrechtler Martin Luther King Jr. will das nicht hinnehmen und einen Protestmarsch von der Stadt Selma nach Montgomery organisieren. Doch sein Vorhaben wird erschwert: Präsident Lyndon B. Johnson ist nur schwer zu überzeugen und wiegelt mit dem Argument ab, dass das Problem der Schwarzen nicht so wichtig sei. Und George Wallace, Gouverneur von Alabama, geht sogar noch weiter und will alle Bemühungen von King und seinen Anhängern sabotieren. Zu allem Überfluss muss sich King auch noch privaten Problemen stellen, als plötzlich Anschuldigungen laut werden, er wäre seiner Frau untreu gewesen...",
                        "popularity": 9.921,
                        "posterPath": "/mGsq6QtmJ9LBeSJ0GSVaf5MRJKf.jpg",
                        "releaseDate": "2014-12-25",
                        "revenue": 66787908,
                        "runtime": 127,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": null,
                        "title": "Selma",
                        "video": false,
                        "voteAverage": 7.4,
                        "voteCount": 1868,
                        "images": {
                            "id": null,
                            "backdrops": [
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/wGxLAd738UCzUIJkzQOcjkpHu46.jpg",
                                    "height": 720,
                                    "iso6391": null,
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 1280
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/b6nrEayYzc8jhrrkhQll0OPYKTx.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/5i2XtRgiTyw0GxIllAPOX12ouSV.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/gUIYncyWzvgyyNeYe0z3GqEjugv.jpg",
                                    "height": 2160,
                                    "iso6391": "en",
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/bwDqnxnPXsQ9rBb89qPpy8lwYHa.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.777,
                                    "filePath": "/bTnUxw2qH4oBGDMM0w4r6KQ11BD.jpg",
                                    "height": 1688,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 3000
                                }
                            ],
                            "posters": [
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/mGsq6QtmJ9LBeSJ0GSVaf5MRJKf.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.384,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/wq4lhMc4BuOyQqe1ZGzhxLyy3Uk.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.666,
                                    "filePath": "/cJqdtH76FNCXP3C52k8jUGHtyHR.jpg",
                                    "height": 1426,
                                    "iso6391": "en",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 950
                                },
                                {
                                    "aspectRatio": 0.666,
                                    "filePath": "/yCZaJNKTxu5VGbJu4fwu2yIBQAz.jpg",
                                    "height": 1426,
                                    "iso6391": "en",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 950
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/vhHIKlBOQwDwY4mzkTMcakh5Cso.jpg",
                                    "height": 1185,
                                    "iso6391": "en",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 790
                                },
                                {
                                    "aspectRatio": 0.675,
                                    "filePath": "/iA5h4P1VIumIiqJaZy9i4BCN6xd.jpg",
                                    "height": 1185,
                                    "iso6391": "en",
                                    "voteAverage": 5.18,
                                    "voteCount": 3,
                                    "width": 800
                                },
                                {
                                    "aspectRatio": 0.699,
                                    "filePath": "/aGJSjytLxA93fr1R2llHpHSrT7B.jpg",
                                    "height": 1465,
                                    "iso6391": "en",
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1024
                                },
                                {
                                    "aspectRatio": 0.701,
                                    "filePath": "/7Yg3Z4LNj5j76SAYnLKbFLnHNJP.jpg",
                                    "height": 1426,
                                    "iso6391": "en",
                                    "voteAverage": 5.044,
                                    "voteCount": 3,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.701,
                                    "filePath": "/lPn0txuqyZauv8Zj5GHRDQcJJPL.jpg",
                                    "height": 1426,
                                    "iso6391": "en",
                                    "voteAverage": 5.044,
                                    "voteCount": 3,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.701,
                                    "filePath": "/vYJ7LHnfZpnA9qzsCDEFblsgXmr.jpg",
                                    "height": 1426,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.701,
                                    "filePath": "/d1OTAniFuC0GRWDOs7YPWpgawrF.jpg",
                                    "height": 1426,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/2QyUcitU0hrxy6B2zkyJ0EhNlqm.jpg",
                                    "height": 2100,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1400
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/xjZFfcy1T1mN4Cha7No6orhicR9.jpg",
                                    "height": 1200,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 800
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/mc0LoVK2Be5u4NwM9V9a0UxiSpj.jpg",
                                    "height": 1500,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                }
                            ]
                        },
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "55aa66c99251411e060058a7",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "kVL3oFRKSSI",
                                    "name": "SELMA Trailer German Deutsch (2015)",
                                    "site": "YouTube",
                                    "size": 360,
                                    "type": "Trailer",
                                    "publishedAt": "2014-12-29T14:19:26.000Z",
                                    "official": false
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "IT",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2015-02-12T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2015-03-11T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "US",
                                    "releaseDates": [
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2014-11-11T00:00:00.000Z",
                                            "type": 1,
                                            "note": "AFI Fest"
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2014-12-25T00:00:00.000Z",
                                            "type": 2,
                                            "note": null
                                        },
                                        {
                                            "certification": "PG-13",
                                            "iso6391": null,
                                            "releaseDate": "2015-01-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NZ",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2015-02-04T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "15",
                                            "iso6391": null,
                                            "releaseDate": "2015-03-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2015-02-18T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "14",
                                            "iso6391": null,
                                            "releaseDate": "2014-12-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "IE",
                                    "releaseDates": [
                                        {
                                            "certification": "12A",
                                            "iso6391": null,
                                            "releaseDate": "2015-02-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "TR",
                                    "releaseDates": [
                                        {
                                            "certification": "13+",
                                            "iso6391": null,
                                            "releaseDate": "2015-02-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2015-02-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2015-03-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "PT",
                                    "releaseDates": [
                                        {
                                            "certification": "M/12",
                                            "iso6391": null,
                                            "releaseDate": "2015-02-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": "M",
                                            "iso6391": null,
                                            "releaseDate": "2015-02-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2015-02-05T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2015-07-23T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 20000000,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "description": "Películas que ya he visto. \n\n",
                                    "favorite_count": 0,
                                    "id": 12553,
                                    "item_count": 2062,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas vistas"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 104711,
                                    "item_count": 876,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "watched movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102714,
                                    "item_count": 1614,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "My Movies At Home"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7144,
                                    "item_count": 5271,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Latest Releases"
                                },
                                {
                                    "description": "Master List In Progress",
                                    "favorite_count": 0,
                                    "id": 51108,
                                    "item_count": 8925,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "My Movies"
                                },
                                {
                                    "description": "DO NOT DELETE",
                                    "favorite_count": 0,
                                    "id": 110683,
                                    "item_count": 1105,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Plex"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 6695847,
                                    "item_count": 1282,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Top Titles from France - IMDb"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 80639,
                                    "item_count": 743,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Películas"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 38016,
                                    "item_count": 338,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "RadarrScruby"
                                },
                                {
                                    "description": "desc",
                                    "favorite_count": 0,
                                    "id": 63130,
                                    "item_count": 12661,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "All BluRay Movies EVER"
                                },
                                {
                                    "description": "View any of these movies when you borrow a Roku streaming stick from the Merrick Library.",
                                    "favorite_count": 0,
                                    "id": 115804,
                                    "item_count": 573,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "On the Rokus: Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7113871,
                                    "item_count": 797,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "flex-great"
                                },
                                {
                                    "description": "Tutti i film visti (by joeki)",
                                    "favorite_count": 0,
                                    "id": 337,
                                    "item_count": 1470,
                                    "iso_639_1": "it",
                                    "list_type": "movie",
                                    "name": "Seen movies",
                                    "poster_path": "/svSvwoP1A8P7rzr0XVO7NdSpCd5.jpg"
                                },
                                {
                                    "description": "This List Will Contain All Movies That I Saw After the Year 2000.",
                                    "favorite_count": 0,
                                    "id": 8530,
                                    "item_count": 880,
                                    "iso_639_1": "pt",
                                    "list_type": "movie",
                                    "name": "Todos Que Já Assisti 2.",
                                    "poster_path": "/2p1qC3OBNHy09Rr9fnmcwADr14c.jpg"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7419787,
                                    "item_count": 221,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "BIOPIC"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 8168884,
                                    "item_count": 1374,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched: The Official List"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 46653,
                                    "item_count": 743,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Watched"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 137160,
                                    "item_count": 663,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "All Film's Q - Z"
                                },
                                {
                                    "description": "my collection",
                                    "favorite_count": 0,
                                    "id": 35027,
                                    "item_count": 2982,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "DVD"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 8169864,
                                    "item_count": 71,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "2010s Hollywood Essentials"
                                }
                            ],
                            "total_pages": 12,
                            "total_results": 227
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.1,
                                "link": "http://www.moviepilot.de/movies/selma",
                                "voteCount": 1262
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "1",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "event_planned",
                    "date": [
                        {
                            "requestDateId": "1259",
                            "requestId": "2690573",
                            "suggestedDate": "2021-01-26 09:00:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": true,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2021-01-12 09:06:13",
                            "updateTimestamp": "2021-01-12 11:42:48",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2021-01-12 09:06:13",
                    "updateTimestamp": "2021-06-22 17:21:20",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                }
            ]

        });

    });
    server.get('https://api.cineamo.test/event-request/1', (_, request) => {

        return new Response(200, {}, {

            "eventRequest":
                {
                    "statistics": null,
                    "requestId": "2656807",
                    "cmsId": null,
                    "eventTypeId": "84039",
                    "userId": "1553",
                    "username": "Philip Christoph Käfer",
                    "userImageUrl": "admin/uploads/person-data/person-61a8e0edb2c4c3-89254441.jpg",
                    "cinemaId": "752",
                    "cinemaName": "Cinecitta",
                    "description": "Die Familien Strelzyk und Wetzel leben in der DDR und wollen weg. Im Sommer 1979 ist es so weit: Nach zwei Jahren harter Arbeit starten Peter, seine Frau Doris und ihre beiden Kinder  sowie das Ehepaar Günter und Petra mit seinen zwei Söhnen endlich ihren Fluchtversuch in einem selbstgebauten Heißluftballon. Doch die Flucht aus ihrer Heimat in Thüringen endet kurz vor der innerdeutschen Grenze, als der Ballon abstürzt. Die beiden Familien arbeiten fieberhaft an einem neuen Ballon, denn mittlerweile ist ihnen die Stasi auf die Schliche gekommen und beginnt mit den Ermittlungen. Noch kennt die DDR-Geheimpolizei den Absturzort nicht, doch die Schlinge zieht sich immer enger zu. Es beginnt ein gnadenloser Wettlauf gegen die Zeit…",
                    "expireDate": "2020-10-31 11:15:00",
                    "duration": null,
                    "name": "Ballon",
                    "slug": "",
                    "hospitality": null,
                    "category": "0",
                    "creationDate": "2020-10-27 11:20:54",
                    "acceptedCinema": false,
                    "acceptedUser": false,
                    "pending": false,
                    "private": false,
                    "uniqueId": null,
                    "assignedToCustomer": false,
                    "externalIdCount": null,
                    "copyrightId": null,
                    "commentCinema": null,
                    "commentCustomer": null,
                    "tmsUrl": null,
                    "pricePerTicket": null,
                    "originalVoice": false,
                    "threeDimensional": false,
                    "viewCount": "0",
                    "likeCount": "0",
                    "confirmCount": "0",
                    "commentCount": "0",
                    "countVisitor": null,
                    "rating": null,
                    "regularEvent": false,
                    "createdByCinema": false,
                    "roomId": null,
                    "roomName": null,
                    "ageRating": null,
                    "facebookId": null,
                    "lastNotificationSent": null,
                    "externalId": "483983m",
                    "externalData": {
                        "lastUpdated": 1640944652,
                        "id": 483983,
                        "cineamoId": "483983m",
                        "series": false,
                        "selectedByCinema": false,
                        "adult": false,
                        "backdropPath": "/ow2lN2vRDsBM9rW1oo8hoe68302.jpg",
                        "genres": [
                            {
                                "id": 18,
                                "name": "Drama"
                            },
                            {
                                "id": 36,
                                "name": "Historie"
                            },
                            {
                                "id": 53,
                                "name": "Thriller"
                            }
                        ],
                        "homepage": null,
                        "imdbId": "tt7125774",
                        "originalLanguage": "de",
                        "originalTitle": "Ballon",
                        "overview": "Die Familien Strelzyk und Wetzel leben in der DDR und wollen weg. Im Sommer 1979 ist es so weit: Nach zwei Jahren harter Arbeit starten Peter, seine Frau Doris und ihre beiden Kinder  sowie das Ehepaar Günter und Petra mit seinen zwei Söhnen endlich ihren Fluchtversuch in einem selbstgebauten Heißluftballon. Doch die Flucht aus ihrer Heimat in Thüringen endet kurz vor der innerdeutschen Grenze, als der Ballon abstürzt. Die beiden Familien arbeiten fieberhaft an einem neuen Ballon, denn mittlerweile ist ihnen die Stasi auf die Schliche gekommen und beginnt mit den Ermittlungen. Noch kennt die DDR-Geheimpolizei den Absturzort nicht, doch die Schlinge zieht sich immer enger zu. Es beginnt ein gnadenloser Wettlauf gegen die Zeit…",
                        "popularity": 12.47,
                        "posterPath": "https://www.themoviedb.org/t/p/w1280/eVINvKpRVZ6AZH3H6S527TkJeET.jpg",
                        "releaseDate": "2018-09-27",
                        "revenue": 11859396,
                        "runtime": 125,
                        "firstAirDate": null,
                        "status": "Released",
                        "tagline": "Für die Freiheit riskierten sie alles",
                        "title": "Ballon",
                        "video": false,
                        "voteAverage": 7.5,
                        "voteCount": 309,
                        "images": {
                            "id": null,
                            "backdrops": [
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/ow2lN2vRDsBM9rW1oo8hoe68302.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.388,
                                    "voteCount": 4,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/sKK1fb08uCcTc0w6PlVsx3BXJdg.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 5.312,
                                    "voteCount": 1,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/1xW34f4ssetgxiEN9MJkJ0RbkPl.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/7KQ13lm55mamlZofVUDKFaEr37T.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/ucGATR9TZnKJtSQdvvImIjUH47h.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/5QBXU02ShUNHd3ozn9cgf9oDCr7.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/txx9qGex4TbjSKBRnMS1wPweoOW.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/6Q3uwM2ITDf2eQHLbevZZaf9JBW.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/6yh5CiTEFkmWatb8ufoKbknMGNT.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/jBlUJ0c9PKmOvcBuQidQ9MXY2z9.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/2nOdolR466dcX2c1gzYYGqhCTgZ.jpg",
                                    "height": 2160,
                                    "iso6391": null,
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 3840
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/fSszS0Rk1n9UqvxshlERmYy9nnq.jpg",
                                    "height": 1980,
                                    "iso6391": null,
                                    "voteAverage": 5.18,
                                    "voteCount": 3,
                                    "width": 3520
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/kZCuA5lmhmLRXY1H5pZQbQeLp1L.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.78,
                                    "filePath": "/tYlDZmpBBnAGZc9wTGHY4eHNupC.jpg",
                                    "height": 1990,
                                    "iso6391": null,
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 3543
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/28zMyhsvtzpGeyrNVQO0DEnhlSG.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/5lL8qvEUtYSpx5XCXT2yMDfLxaa.jpg",
                                    "height": 1080,
                                    "iso6391": null,
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1920
                                },
                                {
                                    "aspectRatio": 1.78,
                                    "filePath": "/uFhkpUy3QSHgR4Yd70FbslxM.jpg",
                                    "height": 723,
                                    "iso6391": null,
                                    "voteAverage": 5.106,
                                    "voteCount": 2,
                                    "width": 1287
                                },
                                {
                                    "aspectRatio": 1.778,
                                    "filePath": "/m0q7dvIUbP2Jbd6hZOxlrwH8smK.jpg",
                                    "height": 1080,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1920
                                }
                            ],
                            "posters": [
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/nRs8A0zp0jU211j8Zay3ApIzlpx.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.388,
                                    "voteCount": 4,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/eVINvKpRVZ6AZH3H6S527TkJeET.jpg",
                                    "height": 3000,
                                    "iso6391": "de",
                                    "voteAverage": 5.384,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/gMsNvfCPho3dGPNdSC4cj3q9WoZ.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.252,
                                    "voteCount": 4,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/l6qeLQVmZODkUDWbw5VqKhU5I8A.jpg",
                                    "height": 3000,
                                    "iso6391": "de",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/zn5DIpyrRqXJqFnWNq3noA19M1T.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/7ECVm77B74S7yqTuTHOiNofxl6V.jpg",
                                    "height": 3000,
                                    "iso6391": "de",
                                    "voteAverage": 5.246,
                                    "voteCount": 2,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.666,
                                    "filePath": "/irR1Zz0pbe8eqylOuP4dO7dPdkP.jpg",
                                    "height": 922,
                                    "iso6391": "de",
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 614
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/4T4oaWjPo8FZk0EJ73LJTF06IXv.jpg",
                                    "height": 3000,
                                    "iso6391": "en",
                                    "voteAverage": 5.172,
                                    "voteCount": 1,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/3AOaH5v0cDErT4NeMVcPo6W0ykH.jpg",
                                    "height": 3000,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                },
                                {
                                    "aspectRatio": 0.675,
                                    "filePath": "/kOZQlz33Pf7nCU5MgCWaILLqnSU.jpg",
                                    "height": 1000,
                                    "iso6391": "en",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 675
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/86UUYZKZLyMMCMAY3Wu7dSuI39V.jpg",
                                    "height": 1500,
                                    "iso6391": "de",
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 1000
                                },
                                {
                                    "aspectRatio": 0.667,
                                    "filePath": "/lsUKHM61YwWqPH5YYfwmPRlSh3z.jpg",
                                    "height": 3000,
                                    "iso6391": null,
                                    "voteAverage": 0,
                                    "voteCount": 0,
                                    "width": 2000
                                }
                            ]
                        },
                        "videos": {
                            "id": null,
                            "results": [
                                {
                                    "id": "5baa53590e0a26611b01d082",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "IUM_s2EusXI",
                                    "name": "BALLON | Trailer & Filmclips deutsch german [HD]",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "publishedAt": "2018-09-17T13:31:54.000Z",
                                    "official": false
                                },
                                {
                                    "id": "5c83adaf925141277d1ed0e7",
                                    "iso6391": "de",
                                    "iso31661": "DE",
                                    "key": "GI-2LrQqWVQ",
                                    "name": "BALLON Trailer Deutsch | Jetzt im Kino!",
                                    "site": "YouTube",
                                    "size": 1080,
                                    "type": "Trailer",
                                    "official": true,
                                    "publishedAt": "2018-06-28T09:54:35.000Z"
                                }
                            ]
                        },
                        "releaseDates": {
                            "id": null,
                            "results": [
                                {
                                    "iso31661": "BR",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2020-10-23T00:00:00.000Z",
                                            "type": 4,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "GB",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-06-14T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HU",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-04-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SE",
                                    "releaseDates": [
                                        {
                                            "certification": "11",
                                            "iso6391": null,
                                            "releaseDate": "2019-08-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "BH",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2020-01-09T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NL",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2019-07-18T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FI",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-08-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "FR",
                                    "releaseDates": [
                                        {
                                            "certification": "U",
                                            "iso6391": null,
                                            "releaseDate": "2019-04-10T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CA",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-07-26T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DE",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2018-09-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "HK",
                                    "releaseDates": [
                                        {
                                            "certification": "IIA",
                                            "iso6391": null,
                                            "releaseDate": "2020-02-13T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "DK",
                                    "releaseDates": [
                                        {
                                            "certification": "11",
                                            "iso6391": null,
                                            "releaseDate": "2019-11-07T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "ES",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-09-06T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "CZ",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2018-11-15T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "NO",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-08-17T00:00:00.000Z",
                                            "type": 2,
                                            "note": "The Norwegian International Film Festival Haugesund"
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2019-12-25T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        },
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2020-03-30T00:00:00.000Z",
                                            "type": 5,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "SK",
                                    "releaseDates": [
                                        {
                                            "certification": "12",
                                            "iso6391": null,
                                            "releaseDate": "2018-11-15T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "AU",
                                    "releaseDates": [
                                        {
                                            "certification": null,
                                            "iso6391": null,
                                            "releaseDate": "2019-06-27T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                },
                                {
                                    "iso31661": "KR",
                                    "releaseDates": [
                                        {
                                            "certification": "12세 이상 관람가",
                                            "iso6391": null,
                                            "releaseDate": "2020-01-16T00:00:00.000Z",
                                            "type": 3,
                                            "note": null
                                        }
                                    ]
                                }
                            ]
                        },
                        "noRightsAvailable": false,
                        "noRightsTimestamp": null,
                        "budget": 0,
                        "lists": {
                            "page": 1,
                            "results": [
                                {
                                    "favorite_count": 0,
                                    "id": 7055413,
                                    "item_count": 1239,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Filme"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 6695847,
                                    "item_count": 1282,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Top Titles from France - IMDb"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102480,
                                    "item_count": 1036,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "All Watched Films"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 24413,
                                    "item_count": 1053,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "电影"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7061987,
                                    "item_count": 508,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "UHD"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7061989,
                                    "item_count": 1217,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Alle Medien"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7082756,
                                    "item_count": 593,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "Films vus en famille"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 86267,
                                    "item_count": 498,
                                    "iso_639_1": "fr",
                                    "list_type": "movie",
                                    "name": "FILMS2020"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 126302,
                                    "item_count": 8133,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Abstrusest 2"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102621,
                                    "item_count": 168,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Foreign Films"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 135983,
                                    "item_count": 256,
                                    "iso_639_1": "el",
                                    "list_type": "movie",
                                    "name": "ΤΑΙΝΙΕΣ"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7102207,
                                    "item_count": 2121,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 142816,
                                    "item_count": 1026,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Terror"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7111644,
                                    "item_count": 445,
                                    "iso_639_1": "es",
                                    "list_type": "movie",
                                    "name": "Terror"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 8168824,
                                    "item_count": 77,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Filme mit B"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7062484,
                                    "item_count": 207,
                                    "iso_639_1": "en",
                                    "list_type": "movie",
                                    "name": "seen Movies"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7077448,
                                    "item_count": 404,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Gesehen Drama"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7081938,
                                    "item_count": 1511,
                                    "iso_639_1": "de",
                                    "list_type": "movie",
                                    "name": "Filme"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 123577,
                                    "item_count": 24,
                                    "iso_639_1": "zh",
                                    "list_type": "movie",
                                    "name": "test"
                                },
                                {
                                    "favorite_count": 0,
                                    "id": 7103310,
                                    "item_count": 51,
                                    "iso_639_1": "pt",
                                    "list_type": "movie",
                                    "name": "Tesla Nikola"
                                }
                            ],
                            "total_pages": 3,
                            "total_results": 43
                        },
                        "thirdParties": {
                            "moviePilot": {
                                "voteAverage": 7.4,
                                "link": "http://www.moviepilot.de/movies/der-ballon",
                                "voteCount": 1084
                            }
                        }
                    },
                    "initComment": null,
                    "finalDate": null,
                    "ownContent": false,
                    "countPeople": "0",
                    "cinemaMarketing": false,
                    "corporation": false,
                    "eventImage": null,
                    "parentId": null,
                    "acceptedByCinemaTimestamp": null,
                    "finalAcceptedTimestamp": null,
                    "finalDeclinedTimestamp": null,
                    "state": "event_completed",
                    "date": [
                        {
                            "requestDateId": "1207",
                            "requestId": "2656807",
                            "suggestedDate": "2020-10-31 11:15:00",
                            "priority": "1",
                            "pending": true,
                            "acceptedCinema": true,
                            "commentCinema": null,
                            "parentId": null,
                            "insertTimestamp": "2020-10-27 11:20:54",
                            "updateTimestamp": "2020-10-28 10:21:10",
                            "deleteEntity": false,
                            "historyDate": null,
                            "historyUserId": null,
                            "historyId": null
                        }
                    ],
                    "declineReason": "expired",
                    "declineReasonLabel": "Abgelaufen",
                    "businessRequest": null,
                    "insertTimestamp": "2020-10-27 11:20:54",
                    "updateTimestamp": "2020-10-31 11:16:27",
                    "shouldBePublished": true,
                    "historyDate": null,
                    "historyUserId": null,
                    "historyId": null
                }
        });
    });
}

export default mockEventTypeRoutes
