import { ApiErrorResponse } from '../../api/ApiErrorResponse.types';
import { ApiResponse } from '../../api/ApiResponse.types';

export function apiResponseHandler<TGet, TPost = TGet>(
    apiResponse: ApiResponse<TGet> | ApiErrorResponse<TPost extends TGet ? TGet : TPost>,
    onSuccess?: (successResponse: ApiResponse<TGet>) => void,
    onError?: (errorResponse: ApiErrorResponse<TPost extends TGet ? TGet : TPost>) => void
) {
    if (!apiResponse) {
        return onError ? onError(undefined) : apiResponse;
    } else if (typeof apiResponse === 'object' && 'isAxiosError' in apiResponse) {
        return onError ? onError(apiResponse || undefined) : apiResponse;
    }
    return onSuccess ? onSuccess(apiResponse) : apiResponse;
}

export function apiResponseHandlerNew<TGet extends Record<string, unknown>, TPost = TGet>(
    apiResponse: ApiResponse<unknown, TGet> | ApiErrorResponse<TPost extends TGet ? TGet : TPost>,
    onSuccess?: (successResponse: ApiResponse<unknown, TGet>) => void,
    onError?: (errorResponse: ApiErrorResponse<TPost extends TGet ? TGet : TPost>) => void
) {
    if (!apiResponse) {
        return onError ? onError(undefined) : apiResponse;
    } else if ('isAxiosError' in apiResponse) {
        return onError ? onError(apiResponse || undefined) : apiResponse;
    }
    return onSuccess ? onSuccess(apiResponse) : apiResponse;
}
