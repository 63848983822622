import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconDown48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M24 35.4142L7.2929 18.7071L8.70711 17.2929L24 32.5858L39.2929 17.2929L40.7071 18.7071L24 35.4142Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    )
}

export function IconDown24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width={props.width || '24'}
             height={props.height || '24'}
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M12 18.4142L3.29289 9.7071L4.70711 8.29289L12 15.5858L19.2929 8.29289L20.7071 9.7071L12 18.4142Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    )
}

export function IconDown16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" 
             height="16" 
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M8 12.4142L2.29289 6.70714L3.70711 5.29293L8 9.58582L12.2929 5.29293L13.7071 6.70714L8 12.4142Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    )
}