import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconDashboard48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 36L7 42C3.32607 37.3213 2 34 2 28C2 15.8497 11.8497 6 24 6C36.1503 6 46 15.8497 46 28C46 34 45 38.4 41 42L35 36"
                stroke={props.stroke || 'currentColor'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 28H9"
                  stroke={props.stroke || 'currentColor'} strokeWidth="2" strokeLinecap="round"/>
            <path d="M39 28H45"
                  stroke={props.stroke || 'currentColor'} strokeWidth="2" strokeLinecap="round"/>
            <path d="M24 7L24 13"
                  stroke={props.stroke || 'currentColor'} strokeWidth="2" strokeLinecap="round"/>
            <path d="M39 13L35 17"
                  stroke={props.stroke || 'currentColor'} strokeWidth="2" strokeLinecap="round"/>
            <path d="M9 13L13 17"
                  stroke={props.stroke || 'currentColor'} strokeWidth="2" strokeLinecap="round"/>
            <path d="M25 25L28 18"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2" strokeLinecap="round"/>
            <circle cx="24.0001" cy="28" r="3" transform="rotate(30 24.0001 28)"
                    stroke={props.stroke || 'currentColor'}
                    strokeWidth="2"/>
        </svg>
    );
}

export function IconDashboard24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 14H4"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 14H22"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 7L6.41421 8.41421"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M11.1056 13.5528C10.8586 14.0468 11.0588 14.6474 11.5528 14.8944C12.0468 15.1414 12.6474 14.9412 12.8944 14.4472L11.1056 13.5528ZM17.8944 4.44721C18.1414 3.95324 17.9412 3.35256 17.4472 3.10557C16.9532 2.85858 16.3526 3.05881 16.1056 3.55279L17.8944 4.44721ZM12.8944 14.4472L17.8944 4.44721L16.1056 3.55279L11.1056 13.5528L12.8944 14.4472Z"
                fill={props.fill || 'currentColor'}/>
            <circle cx="12" cy="14" r="2" fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.8537 4.92674C19.8079 5.06703 19.7513 5.20568 19.6833 5.34164L18.9494 6.80934C20.8304 8.62762 22 11.1772 22 14C22 16.9593 20.9457 19.4151 18.9976 21.1963L16.6402 19.2318C16.2159 18.8782 15.5853 18.9355 15.2318 19.3598C14.8782 19.7841 14.9355 20.4147 15.3598 20.7682L18.3598 23.2682C18.7203 23.5686 19.2411 23.578 19.6121 23.2908C22.4734 21.0758 24 17.8277 24 14C24 10.3759 22.3935 7.12705 19.8537 4.92674ZM13.5836 4.12468C13.0678 4.04262 12.5389 4 12 4C6.47715 4 2 8.47715 2 14C2 16.5982 3.19144 19.1704 4.90701 21.0155L6.21913 19.3753C6.56414 18.944 7.19343 18.8741 7.62469 19.2191C8.05596 19.5641 8.12588 20.1934 7.78087 20.6247L5.78087 23.1247C5.61015 23.3381 5.35953 23.4723 5.08729 23.4962C4.81505 23.52 4.5449 23.4314 4.33967 23.251C1.8644 21.0745 0 17.622 0 14C0 7.37258 5.37258 2 12 2C12.8743 2 13.7267 2.09349 14.5478 2.27103C14.4625 2.39239 14.3851 2.52163 14.3167 2.65836L13.5836 4.12468Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}