import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconRoleAdministration48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="8" r="7"
                    stroke={props.stroke || 'currentColor'}
                    strokeWidth="2"/>
            <path d="M11 25C11 20.5817 14.5817 17 19 17H29C33.4183 17 37 20.5817 37 25V28H11L11 25Z"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <path d="M6 42V35H42V42"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <line x1="24" y1="28" x2="24" y2="42"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <circle cx="6" cy="44" r="3"
                    stroke={props.stroke || 'currentColor'}
                    strokeWidth="2"/>
            <circle cx="24" cy="44" r="3"
                    stroke={props.stroke || 'currentColor'}
                    strokeWidth="2"/>
            <circle cx="42" cy="44" r="3"
                    stroke={props.stroke || 'currentColor'}
                    strokeWidth="2"/>
        </svg>
    );
}

export function IconRoleAdministration24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="4" r="3"
                    stroke={props.stroke || 'currentColor'}
                    strokeWidth="2"/>
            <path d="M6 12C6 9.79086 7.79086 8 10 8H14C16.2091 8 18 9.79086 18 12V13H6L6 12Z"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <path d="M3 22V18H21V22"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <line x1="12" y1="13" x2="12" y2="22"
                  stroke={props.stroke || 'currentColor'}
                  strokeWidth="2"/>
            <rect y="22" width="6" height="2"
                  fill={props.fill || 'currentColor'}/>
            <rect x="9" y="22" width="6" height="2"
                  fill={props.fill || 'currentColor'}/>
            <rect x="18" y="22" width="6" height="2"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
