import { TFunction, useTranslation } from 'next-i18next';
import Image from 'next/future/image';

import Button from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

const ImageKerstenNeubert = loadAsset('/images/cineamo-team/im-teamKerstenNeubertMarketingProfile.png');

export type MakeAppointmentCardProps = {
    t: TFunction;
};

function MakeAppointmentCard(props: MakeAppointmentCardProps): JSX.Element {
    const t = props.t || useTranslation('common').t;

    return (
        <div className="flex flex-col lg:items-start">
            <div className="flex flex-col space-y-16">
                <span className="text-body-bold-12 text-white whitespace-pre">
                    {t('common:access.gaining-access-question')}
                </span>
                <div className="flex flex-row bg-white px-16 py-8 space-x-16 rounded-8 items-center">
                    <Image
                        className="w-96 h-96 rounded-full overflow-hidden object-cover object-center flex-none"
                        src={ImageKerstenNeubert}
                        width={600}
                        height={600}
                        alt="Kersten Neubert"
                    />
                    <div className="flex flex-col space-y-8 justify-center text-darkBlue">
                        <div className="flex flex-col space-y-2 justify-center">
                            <span className="text-body-bold-12">Kersten Neubert</span>
                            <span className="text-body-12">+49 (0)931 / 497372 - 11</span>
                            <span className="text-body-12">kersten.neubert@cineamo.com</span>
                        </div>
                        <a
                            href="https://outlook.office365.com/owa/calendar/CineamoGmbH@cineamo.com/bookings/"
                            target="_blank"
                            rel="noreferrer">
                            <Button className="bg-cyan-middle">{t('common:make-an-appointment')}</Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MakeAppointmentCard;
