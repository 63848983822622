import create from 'zustand';

import { Role } from '../../constants/role-constants';
import { UserMeDto } from '../../models/user/UserMeDto.types';
import { IsomorphicStore, createIsomorphicStore } from '../index';

import { UserState, UserStore } from './userStore.types';

const initialState: UserState = {
    user: undefined,
    userType: [Role.ROLE_USER],
    isLoggedIn: false,
    isAdmin: false,
    isLoading: null
};

const initializeStore: IsomorphicStore<UserStore, UserState> = (preloadedState) => {
    return create<UserStore>((set) => ({
        ...initialState,
        ...preloadedState,
        setUser(user?: UserMeDto | undefined | null) {
            set({
                user,
                isLoading: user === undefined
            });
        },
        setIsLoading(state?: boolean) {
            set({
                isLoading: state
            });
        },
        setUserType(userType: Role[] | null) {
            set({
                userType
            });
        },
        setIsAdmin(state: boolean) {
            set({
                isAdmin: state
            });
        },
        logout() {
            set({
                user: undefined,
                isLoading: null
            });
        }
    }));
};

const {
    StoreProvider: UserStoreProvider,
    useStore: useUserStore,
    useCreateStore: useCreateUserStore
} = createIsomorphicStore<typeof initializeStore, UserStore, UserState>(initializeStore);

export { UserStoreProvider, useUserStore, useCreateUserStore };
