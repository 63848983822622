import { SortingKey } from '../types/SortingKey.types';

export type UrlQueryParameter = {
    page?: number;
    perPage?: number;
    sort?: SortingKey;
    order?: 'asc' | 'desc';
    query?: string;
} & Record<string, string | number | boolean | string[] | number[]>;

export function buildUrlQuery(params?: UrlQueryParameter): string {
    return params
        ? Object.keys(params)
              .filter((key) => params[key] !== undefined && params[key] !== null && params[key]?.toString().length > 0)
              .reduce<string>((prev, curr) => {
                  const param = params[curr];
                  /* TODO: The following lines will check if the param looks like this 'paramKey: "[firstValue, secondValue, ...]"'
                   * The paramKey value is a string which looks like an array. If this is the case, then we want to convert it to
                   * a real array.
                   * This needs to be refactored and is necessary because of some filtering. (E.g. filtering event-requests for
                   * multiple states at the same time ('?state[]=state1&state[]=state2&...')
                   * Maybe this logic can sit in the FilterPopup or Table component!
                   */
                  if (param instanceof Array) {
                      const paramArray = param
                          .map((paramArrayValue) => `${curr}[]=${encodeQueryValue(paramArrayValue)}`)
                          .join('&');
                      return `${prev}&${paramArray}`;
                  }
                  // if (param.startsWith('[') && param.endsWith(']')) {
                  //
                  // }
                  return `${prev}&${curr}=${encodeQueryValue(params[curr])}`;
              }, '')
              .replace(/&/, '?')
        : '';
}

function encodeQueryValue(value: string | number | boolean | string[] | number[]) {
    if (typeof value === 'string') {
        return encodeURIComponent(value);
    }
    return value;
}
