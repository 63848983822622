import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconMenu48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M6.00006 8.99997L42 9.00085L42 11.0009L6.00002 11L6.00006 8.99997Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M6.00006 23L42 23.0009L42 25.0009L6.00002 25L6.00006 23Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M6.00006 37L42 37.0009L42 39.0009L6.00002 39L6.00006 37Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconMenu24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24" 
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M24 21L-3.47901e-07 21L7.47917e-07 19L24 19L24 21Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M-4.43112e-06 3.00002L24 2.99997L24 4.99997L0 5.00002L-4.43112e-06 3.00002Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M-5.61131e-07 11L24 11L24 13L-4.76837e-07 13L-5.61131e-07 11Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconMenu16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16" 
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M16 14L-1.4764e-07 14L2.73828e-07 12H16L16 14Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M-4.42324e-06 1.66672L16 1.66669L16 3.66669L0 3.66672L-4.42324e-06 1.66672Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M-5.6196e-08 7L16 7L16 9L0 9L-5.6196e-08 7Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}