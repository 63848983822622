import { CustomSVGProps } from '../types/CustomSVGProps.types';

export function IconSlate48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M42 21H5.99995V42C5.99995 42.5523 6.44767 43 6.99995 43H41C41.5522 43 42 42.5523 42 42V21ZM3.99995 19V42C3.99995 43.6569 5.3431 45 6.99995 45H41C42.6568 45 44 43.6569 44 42V19H3.99995Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M9.01176 20.9251L15.5118 27.9251L16.4881 26.0749L9.98814 19.0749L9.01176 20.9251Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M30.5349 20.9491L37.5349 27.9491L38.465 26.0509L31.465 19.0509L30.5349 20.9491Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M19.5349 20.9491L26.5349 27.9491L27.465 26.0509L20.465 19.0509L19.5349 20.9491Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M43 28H4.99995V26H43V28Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M35.7156 5.43469L31.2488 13.8785L29.8268 12.344L34.2936 3.90016L35.7156 5.43469Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M14.8951 10.8905L9.94534 19.4637L8.55557 17.8709L13.5053 9.29764L14.8951 10.8905Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M25.5203 8.04348L20.5705 16.6167L19.1808 15.0239L24.1305 6.45064L25.5203 8.04348Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M3.12677 15.1317C2.69795 13.5313 3.64769 11.8863 5.24809 11.4575L38.0896 2.65765C39.69 2.22883 41.335 3.17858 41.7638 4.77898L43.3167 10.5745L4.67969 20.9273L3.12677 15.1317ZM5.76573 13.3894C5.23226 13.5323 4.91568 14.0806 5.05862 14.6141L6.0939 18.4778L40.8672 9.16032L39.832 5.29661C39.689 4.76315 39.1407 4.44656 38.6072 4.58951L5.76573 13.3894Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSlate24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M20 13H4.00003V20C4.00003 20.5523 4.44775 21 5.00003 21H19C19.5523 21 20 20.5523 20 20V13ZM2.00003 11V20C2.00003 21.6568 3.34318 23 5.00003 23H19C20.6569 23 22 21.6568 22 20V11H2.00003Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M3.28383 9.52679L2.54931 7.32327L18.7162 2.47319L19.4507 4.6767L3.28383 9.52679ZM2.00005 12L0.651948 7.95573C0.295142 6.88532 0.893893 5.73183 1.97462 5.40762L18.1415 0.557537C19.1769 0.24692 20.2718 0.815222 20.6136 1.84073L22 5.99997L2.00005 12Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M9.00003 3.99998L5.00003 9.99998H3.00003L7.00003 3.99998L9.00003 3.99998Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M14 2.45434L10 8.45434H8.00003L12 2.45434L14 2.45434Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M19 0.999978L15 6.99998H13L17 0.999978L19 0.999978Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSlate16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M1 8C1 7.44771 1.44772 7 2 7H15C15.5523 7 16 7.44771 16 8V15C16 15.5523 15.5523 16 15 16H2C1.44772 16 1 15.5523 1 15V8ZM3 9V14H14V9H3Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M0.0340782 4.62347C-0.108864 4.09 0.207719 3.54167 0.741185 3.39873L13.2982 0.0340783C13.8317 -0.108864 14.38 0.207719 14.523 0.741185L15.2994 3.63896C15.4424 4.17243 15.1258 4.72077 14.5923 4.86371L2.03528 8.22835C1.50181 8.3713 0.953477 8.05471 0.810535 7.52125L0.0340782 4.62347ZM2.22475 5.07176L2.48357 6.03768L13.1088 3.19067L12.8499 2.22475L2.22475 5.07176Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M6.96151 3.27472L5.96153 6.77472L4.03848 6.22529L5.03846 2.72529L6.96151 3.27472Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M11.9487 2.31623L10.9487 5.31622L9.05132 4.68376L10.0513 1.68378L11.9487 2.31623Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}