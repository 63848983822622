import { AxiosRequestConfig } from 'axios';

import { MessageDto } from '../../models/message/MessageDto.types';
import { MessageInputDto } from '../../models/message/MessageInputDto.types';
import '../../models/notification-message/NotificationMessageDto.types';
import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import { MessagesListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------  Messages ------------------------>

type GetMessagesUrlQueryParams = UrlQueryParameter & {
    userId?: number | string;
    isRead?: boolean;
};

/**
 * This endpoint returns a list of messages.
 *
 * @example
 * // Get a list of messages:
 * @returns {ApiResponse<MessagesListResponse>}
 */

export async function getMessages(
    urlParams?: GetMessagesUrlQueryParams,
    config?: AxiosRequestConfig
): Promise<ApiResponse<MessagesListResponse>> {
    return apiClient
        .get(`/messages${buildUrlQuery(urlParams)}`, config)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Message ------------------------>

/**
 * This endpoint returns a Message.
 *
 * @example
 * // Get a message by id:
 * getMessageById(1);
 *
 * @param messageId
 * @returns {ApiResponse<MessageDto>}
 */

export async function getMessageById(messageId: number): Promise<ApiResponse<MessageDto>> {
    return apiClient
        .get(`/messages/${messageId}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Update Message ------------------------>

type PatchMessageType = {
    isRead?: boolean;
    isClicked?: boolean;
    isReceivedClient?: boolean;
    isDeleted?: boolean;
    baseMessage?: number;
};

/**
 * This endpoint returns a Message.
 *
 * @example
 * // Update a message:
 * updateMessageById(1, {...message});
 *
 * @param messageId
 * @param message
 * @returns {ApiResponse<MessageDto>}
 */

export async function updateMessageById(
    messageId: number,
    message?: PatchMessageType
): Promise<ApiResponse<MessageDto>> {
    return apiClient
        .patch(`messages/${messageId}`, message)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return null;
        });
}

// <------------------------ Update Message List ------------------------>

/**
 * This endpoint returns an array of read/unread Messages.
 *
 * @example
 * // Update all a user's messages:
 * updateMessagesAll({ userId: userId, isRead: true});
 *
 * @param messages
 * @returns {ApiResponse<MessageDto[]>}
 */

export async function updateMessagesAll(messages: MessageInputDto): Promise<ApiResponse<MessagesListResponse>> {
    return apiClient
        .patch('/messages', messages)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            return null;
        });
}

// <------------------------ Delete Message ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete a message by id:
 * deleteMessageById(1);
 *
 * @param messageId
 * @returns {boolean}
 */

export async function deleteMessageById(messageId: number | string): Promise<boolean> {
    return apiClient
        .delete(`messages/${messageId}`)
        .then((response) => response.status === 204)
        .catch(() => false);
}
