import { createServer } from "miragejs"
import mockUserRoutes from "@/test/mirage/routes/mockUserRoutes";
import mockEventTypeRoutes from "@/test/mirage/routes/mockEventTypeRoutes";
import mockPermissionsRoutes from "@/test/mirage/routes/mockPermissionsRoutes";
import mockEventRequestRoutes from "@/test/mirage/routes/mockEventRequestRoutes";
import mockCinemaRoutes from "@/test/mirage/routes/mockCinemaRoutes";

export function makeServer({environment = "development"} = {}) {
    return createServer({
        environment,
        routes() {

            mockUserRoutes(this);
            mockEventTypeRoutes(this);
            mockEventRequestRoutes(this);
            mockPermissionsRoutes(this);
            mockCinemaRoutes(this);

            this.passthrough();
            // this.passthrough("https://api.cineamo.test/movies");
        },

    })
}

