export enum Role {
    ROLE_ADMIN = 1,
    ROLE_CINEMA = 2,
    ROLE_DISTRIBUTOR = 3,
    ROLE_USER = 4,
    ROLE_INTERNAL = 5
}

export enum RoleName {
    ADMIN = 'admin',
    CINEMA = 'cinema',
    DISTRIBUTOR = 'distributor',
    USER = 'user'
}
