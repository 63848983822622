export const DEVELOPMENT_MODE = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';
export const LOCAL_MODE = process.env.NEXT_PUBLIC_NODE_ENV != 'production' && process.env.NEXT_PUBLIC_NODE_ENV != 'staging';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const verboseLogger = (...args: (string | object)[]) => {
    if (DEVELOPMENT_MODE) {
        console.log(args);
    }
};

const logger = {
    info: verboseLogger,
    log: verboseLogger,
    warn: verboseLogger,
    error: console.log,
};

export default logger;
