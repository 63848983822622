import classnames from 'classnames';
import { signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import SidebarNavigationItem from '@/components/navigation/SidebarNavigationItem';

import { SIGNOUT_URL } from '@cineamo/legacy-frontend-lib/src/constants/http-constants';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconDown16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-down';
import { IconId24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-id';

const profileDefaultAvatar = loadAsset('/images/placeholders/im-avatar.png');

function SideNavigationProfile() {
    const { t } = useTranslation('navigation');

    const { user, logout } = useUserStore();

    const [open, setOpen] = useState(false);
    const router = useRouter();
    const { locale } = router;

    const { data: session } = useSession();

    const profileImage: string | StaticImageData = user?.profileImageUrl ? user.profileImageUrl : profileDefaultAvatar;

    function logoutUser() {
        signOut({ callbackUrl: SIGNOUT_URL(locale) }).then(() => {
            logout();
        });
    }

    return (
        <>
            <button
                className={classnames(
                    'flex w-full text-white pl-20 pr-16 py-14 space-x-16 items-center',
                    'transition-all duration-100',
                    'hover:bg-darkBlue'
                )}
                onClick={() => setOpen(!open)}>
                <div className="flex-none relative w-24 h-24 flex-none rounded-full overflow-hidden">
                    <Image
                        src={profileImage}
                        blurDataURL={typeof profileImage === 'string' ? profileImage : profileImage}
                        width="200"
                        height="200"
                        alt="User Profile Image"
                    />
                </div>
                <div className="flex-auto text-left text-body-bold-14">{session.user.name}</div>
                <IconDown16
                    className={classnames('transition-all duration-250', {
                        'transform -rotate-180': open
                    })}
                />
            </button>

            <div
                className={classnames(
                    'bg-white bg-opacity-5 overflow-hidden',
                    'transition-all duration-300',
                    open ? 'max-h-screen' : 'max-h-0'
                )}>
                <SidebarNavigationItem
                    text={t('navigation.profile-account')}
                    link={'/account-settings'}
                    icon={<IconId24 />}
                />
                <SidebarNavigationItem
                    text={t('navigation.profile-logout')}
                    onClick={logoutUser}
                    className="text-red uppercase"
                />
            </div>
        </>
    );
}

export default SideNavigationProfile;
