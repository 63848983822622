import {Server, Response} from 'miragejs'

const mockEventTypeRoutes = (server: Server) => {


    server.get('https://api.cineamo.test/event-types/1', (_, request) => {

        return new Response(200, {}, {

            "eventTypes": [
            {
                "eventTypeId": "205629",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "James Bond hat seine Lizenz zum Töten im Auftrag des britischen Geheimdienstes abgegeben und genießt seinen Ruhestand in Jamaika. Die friedliche Zeit nimmt ein unerwartetes Ende, als sein alter CIA-Kollege Felix Leiter auftaucht und ihn um Hilfe bittet. Ein bedeutender Wissenschaftler ist entführt worden und muss so schnell wie möglich gefunden werden. Was als simple Rettungsmission beginnt, erreicht bald einen bedrohlichen Wendepunkt, denn Bond kommt einem geheimnisvollen Gegenspieler auf die Spur, der im Besitz einer brandgefährlichen neuen Technologie ist.",
                "duration": "163",
                "name": "James Bond 007: Keine Zeit zu sterben",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "370172m",
                "externalData": {
                    "lastUpdated": 1639756438,
                    "id": 370172,
                    "cineamoId": "370172m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/r2GAjd4rNOHJh6i6Y0FntmYuPQW.jpg",
                    "genres": [
                        {
                            "id": 12,
                            "name": "Abenteuer"
                        },
                        {
                            "id": 28,
                            "name": "Action"
                        },
                        {
                            "id": 53,
                            "name": "Thriller"
                        }
                    ],
                    "homepage": "https://www.007.com/no-time-to-die/",
                    "imdbId": "tt2382320",
                    "originalLanguage": "en",
                    "originalTitle": "No Time to Die",
                    "overview": "James Bond hat seine Lizenz zum Töten im Auftrag des britischen Geheimdienstes abgegeben und genießt seinen Ruhestand in Jamaika. Die friedliche Zeit nimmt ein unerwartetes Ende, als sein alter CIA-Kollege Felix Leiter auftaucht und ihn um Hilfe bittet. Ein bedeutender Wissenschaftler ist entführt worden und muss so schnell wie möglich gefunden werden. Was als simple Rettungsmission beginnt, erreicht bald einen bedrohlichen Wendepunkt, denn Bond kommt einem geheimnisvollen Gegenspieler auf die Spur, der im Besitz einer brandgefährlichen neuen Technologie ist.",
                    "popularity": 1359.019,
                    "posterPath": "/wCNebHozTH9Z1hh9nQ1aXdishcq.jpg",
                    "releaseDate": "2021-09-29",
                    "revenue": 771000000,
                    "runtime": 163,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": "Die Mission, die alles verändert, beginnt …",
                    "title": "James Bond 007 - Keine Zeit zu sterben",
                    "video": false,
                    "voteAverage": 7.5,
                    "voteCount": 2578,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/r2GAjd4rNOHJh6i6Y0FntmYuPQW.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.334,
                                "voteCount": 11,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/rmGtDWrcYP9c0qgyuuzNQRxzKy2.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.252,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/dnxrremCrghG7z97zDeGPNrl75A.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.252,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/1953j0QEbtN17WFFTnJHIm6bn6I.jpg",
                                "height": 1080,
                                "iso6391": "de",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/dMuMiCrUDZ0AN0TNmBKkJ1wgKVM.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.22,
                                "voteCount": 13,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/q5K3ZqwWl6pKbHNHXoIdfz9f7ve.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/18Eca3pEMrdk6GkmKWdf02jWpmo.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.156,
                                "voteCount": 12,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/jiXWozMk854sAHYpLj2pujAQPp6.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.138,
                                "voteCount": 8,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/9QkYmsucJGWWTtQrDNDvqvRYp8t.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/sYSVT2eblD4yApNrSqrHvlUxU0j.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/lir6WyT5O7bwW8DVjKZPjY62nsG.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/7UWrMVr1HQX0SbrqFch5GiePaTS.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/cSrjFMrAQibe5XUp4pwoJyGmw21.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/pMtohpZMsHMfYAPaxRvYmUtKgtF.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/2I4FFTxJOoFnHCZqTpv4NacizFU.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/kcvKEvTWqIGMjpVJdbDVRHdIt4C.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.11,
                                "voteCount": 15,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/mijhSLcawX2T7i1mQOlymTfJA4X.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/hMWwdbI3MKsMOGRljMZrUqpNjqi.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/eBcqBH3jTkCNQSSbuC3YPan8JEg.jpg",
                                "height": 1080,
                                "iso6391": "de",
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wBOFZk5VfKUhbdTkStXmfml93Ab.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/4yS6G9qfONlzBxsg0C5YQQR4tJd.jpg",
                                "height": 1079,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1918
                            },
                            {
                                "aspectRatio": 1.776,
                                "filePath": "/9nTljR8hF8HSqLBXUN2gV5hMr3s.jpg",
                                "height": 953,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1693
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/zfG8GAqvjyLGncRU2QhfA0k2vK5.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/sb15prw9rAAdYDIDmRgwNhVflGU.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/v697nKoDujxV1o7A0HtaSkRufcu.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/oHzU51rALEdq8WIr5teLJUK3RFU.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/zQMKeWU7kqcmSq9RXfrsvmgkubG.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.08,
                                "voteCount": 9,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/tYYhP0CVl0VhGU5JtYdCOo2duyH.jpg",
                                "height": 924,
                                "iso6391": null,
                                "voteAverage": 5.068,
                                "voteCount": 7,
                                "width": 1643
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/tPsDrUjguOS7bxssz3TqvEzBn3F.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.068,
                                "voteCount": 7,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/uoMygXRAXg1SOS1dqHUSi6yAVQA.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.056,
                                "voteCount": 5,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/zdeuKmRosmvQZMiZhJkUl4GQbQN.jpg",
                                "height": 720,
                                "iso6391": "en",
                                "voteAverage": 4.996,
                                "voteCount": 6,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/64oEFJusPULAkC9RA53hSwZUAYq.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 4.982,
                                "voteCount": 13,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/rOueAuhcQrHR2f3TnFVJZL6PTd2.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 4.982,
                                "voteCount": 4,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/x9td0NQha0t44K2qgcMipLOGjVi.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/4N2u2X314yNG8vlpsh0sWgIXPZi.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/9Pt4RFV87juQ9MqPdFdIyYJB1dj.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/iUgygt3fscRoKWCV1d0C7FbM9TP.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.462,
                                "voteCount": 39,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/eqWaeh21e4ZgHjwpULZVHCGIq9X.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.362,
                                "voteCount": 29,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/wCNebHozTH9Z1hh9nQ1aXdishcq.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/nvp1F7UZZPD4wgJSVItC4btgifz.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/dVL1HhSXntN6Rt6SHBOF7ZNICDa.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/iOPXG7q6ZqxSFp2iSY2CvgeRXyC.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.284,
                                "voteCount": 39,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/ep4eNdF37uhadB2UzdL8IDU6IxO.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.258,
                                "voteCount": 6,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/bw9Eeiq692dS8BQwWVAZUOKjX8a.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/7M0GR5g68znahgnBXvJ13vEEn7v.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/7w5SBqOx473x4bt8ysbSEryOpcq.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.668,
                                "filePath": "/9Ne0P39am0aQkPp6YdtE2Dj0BTM.jpg",
                                "height": 2988,
                                "iso6391": "de",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1996
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/pvwIbvusXaGMIKeMWDDq7hXZR5I.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/vPkzJTolwKwXnjGvFn1Oci064m4.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.238,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/pjvJHLPCtULHkNUOvpHNHgXpwTJ.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.19,
                                "voteCount": 5,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/leeozefichQsg5ZNcR5BlebTuau.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/7rHxXzKXHYtSUQi6jWjP9iayfNe.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/rCts163T9vvwqKGV0ckDz6UYHqM.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/rCvhV7bApnUNQ0h6GOjifTDve4j.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/3tXlStayyRPB8vnTDetAxtUWyGc.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/lpy5sLm8DdpSiwCLnOKBN5D5OqX.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/w0oZEHvERujPV5JdsSp1r6rO7sl.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.158,
                                "voteCount": 27,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/iCsgRP85EGsJdJt9utiqoQOXWlR.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.128,
                                "voteCount": 6,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/kAeJYL6DPhT5u5o1itlHyXlz8iT.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/k4yI50vBWes7YXgwUQkP9wyTNeJ.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.701,
                                "filePath": "/xUvFSC2EVkqnLxQDlLxAcbjgjmU.jpg",
                                "height": 1426,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/saP4oXiEzS227a6RpO5wJtNjwbx.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/qTJiBMGFbqMBIVt5ChIGBY39R4k.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/bvabjXTdgVMjdQHdEoFqcWj5OQi.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.681,
                                "filePath": "/aRmei0c0JdPKWjaEZhnTOHVopjL.jpg",
                                "height": 1242,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 846
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/yBnqXgtazapnmHf9FP26yImr5is.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.11,
                                "voteCount": 15,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/kxdMGRsZQCWZb3RqiYpxqGHkZuo.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.11,
                                "voteCount": 15,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.668,
                                "filePath": "/lWd7XqO1RhrR9wXcKcaq8geCkJL.jpg",
                                "height": 2988,
                                "iso6391": "de",
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1996
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/3fBWD5wqAsbUCvCc5YUTqdYs54v.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.104,
                                "voteCount": 26,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/n3F5KdolXSPCiQI6ktuwXzFJ9qi.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.09,
                                "voteCount": 11,
                                "width": 1012
                            },
                            {
                                "aspectRatio": 0.666,
                                "filePath": "/vd6w8M7ixwPHmiVauphyGVjkflA.jpg",
                                "height": 2000,
                                "iso6391": "en",
                                "voteAverage": 5.086,
                                "voteCount": 22,
                                "width": 1333
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/mRBHuUJULS9V6KaYIHPeMzD4AEk.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.086,
                                "voteCount": 22,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/dCzJDBwf0ux8Q57QbXGPTwe4Wsn.jpg",
                                "height": 1482,
                                "iso6391": "en",
                                "voteAverage": 5.08,
                                "voteCount": 9,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/cDJPMB1UdHgGV0E6dh1YxFBa0K9.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/8bKXLC0R6csu3VpHQ27zLq4u6MM.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.022,
                                "voteCount": 10,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/cHF3Z4OaqBHvjKH1csDmnBvj3CA.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.01,
                                "voteCount": 8,
                                "width": 1012
                            },
                            {
                                "aspectRatio": 0.701,
                                "filePath": "/yTIsgo4QAvqe7LiYwUcM8boOinI.jpg",
                                "height": 1426,
                                "iso6391": "en",
                                "voteAverage": 4.996,
                                "voteCount": 6,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.68,
                                "filePath": "/8BOj2Q837qHlZV4Gbe1QVIZ27Bp.jpg",
                                "height": 883,
                                "iso6391": "en",
                                "voteAverage": 4.996,
                                "voteCount": 6,
                                "width": 600
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/wJu1lQU6RgUmBUlS33Ewu6IcDxv.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 4.996,
                                "voteCount": 6,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/oObKEBjknBoJY712jTADbVWdTfu.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 4.982,
                                "voteCount": 4,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/6NZNncj1G1a3gpMml82vL8C5wT5.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 4.954,
                                "voteCount": 9,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hnmgKfUFCs79zgTd1wT2CevaSnu.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 4.938,
                                "voteCount": 7,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/yhN3wjWkFbWtlrmYA7TZzZBBrii.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 4.922,
                                "voteCount": 5,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/btf0qtKQBTVugx7PS9AP3JK5G81.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 4.922,
                                "voteCount": 5,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/dm5F04mYExOqtOvAopvf24n6YYo.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 4.922,
                                "voteCount": 5,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gjQJJ597EM2DppDqbiHQTzQW6b8.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 4.866,
                                "voteCount": 6,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/wTy3ABb9ndH11PKh63JidMeJbZs.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 4.866,
                                "voteCount": 6,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/q2P7bskW7ACBAwBLpOpQYne0oJe.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/39HB2n5vlszd6O8yyz9XlyxsBo3.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/m212pPxqusbd3OXOL4RrnSJNrDp.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/7HCU5ikiQKeV5eSE279btCwOqTQ.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/lE3bsaXTo3FtoATEQlExP3O4fgO.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/68ZcV7ksPfFvns55GdDp6ozMFuX.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/D6bUTp1ojNbJxRP1fud5UKOFBc.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/tstcwdpuBwIHIaYEoM6Q6p5f9al.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/nAHu0D7WDs0DuSUdK5yHFe2h1bA.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/6aeuw04T801UM3PiYRqpVxCFkVR.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/fmJzCnEUBkW7dHlRWQl7imxkuRL.jpg",
                                "height": 1920,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1296
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/55bfexTAyPP2ca7lMJtGzbpzmLK.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gCMlo83fJOEdAcHqvr9tkAjHjHf.jpg",
                                "height": 1500,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/3iO3fIQDv49Rw0GpyL3vhz7TKnl.jpg",
                                "height": 750,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 500
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/btX7J0sMe0DeL7o1RPIznU9N54W.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/fmI1F0n25qxCdaxJgIH80WamWNx.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.679,
                                "filePath": "/4i9L7ieqUE4Z187aKSx1tCVodfw.jpg",
                                "height": 1236,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 839
                            },
                            {
                                "aspectRatio": 0.679,
                                "filePath": "/zzu0lTdXApGLvPln78BUqZyyR1x.jpg",
                                "height": 1236,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 839
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/adYocZrF7GFH3ooThCR1WoO2kpe.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/pgZVPMWnUE7Ej2y1HW5MGR6g8MT.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/wOPiNd2QfaNJlwm9EwHqsf38Cbz.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/r4j0lIBPoVe2Dke0I2Iml8A24bs.jpg",
                                "height": 2100,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1400
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/5v6XAgnikDDqQpzP2dy0hcuQwJG.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.701,
                                "filePath": "/gZ4UZXKhuIHUOXlZwEvI3NQuLvM.jpg",
                                "height": 1426,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/bqq2dlwWAm5O5RVPGHVwwxhESK1.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/fmCRpJb9VOpvKndrT1HmCcGVOtp.jpg",
                                "height": 900,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 600
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 0.927,
                                "height": 2908,
                                "iso_639_1": "en",
                                "file_path": "/kjBI7crJSztrhPCAodqbTvpt3ov.png",
                                "vote_average": 5.384,
                                "vote_count": 2,
                                "width": 2696
                            },
                            {
                                "aspect_ratio": 1.414,
                                "height": 2481,
                                "iso_639_1": "en",
                                "file_path": "/mAeXOTPGMQtPtx1fOYaazC1xLeQ.svg",
                                "vote_average": 5.384,
                                "vote_count": 2,
                                "width": 3508
                            },
                            {
                                "aspect_ratio": 0.926,
                                "height": 1292,
                                "iso_639_1": "en",
                                "file_path": "/bSRbiQEQbdJRMJTot6XPKrjG4h3.png",
                                "vote_average": 5.312,
                                "vote_count": 1,
                                "width": 1197
                            },
                            {
                                "aspect_ratio": 0.925,
                                "height": 454,
                                "iso_639_1": "en",
                                "file_path": "/v6v5srjeXksflu3qpLXgWCnf3it.png",
                                "vote_average": 5.246,
                                "vote_count": 2,
                                "width": 420
                            },
                            {
                                "aspect_ratio": 2.211,
                                "height": 454,
                                "iso_639_1": "de",
                                "file_path": "/4n3ru9OS4Qbw6pFYPLCY7nfOK74.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 1004
                            },
                            {
                                "aspect_ratio": 0.938,
                                "height": 480,
                                "iso_639_1": "en",
                                "file_path": "/h02wT4F1aPBkQ94NlSx3ypOI5xl.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 450
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "612f8e9ac1ffbd008b5b7f7b",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "TXp-oF5TCo0",
                                "name": "Keine Zeit zu sterben - Finaler internationaler Trailer - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-08-31 19:09:46 UTC"
                            },
                            {
                                "id": "6068c978c14fee00581c3e24",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "WjKN5qCx3jU",
                                "name": "Keine Zeit zu sterben - Trailer - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2020-10-12 08:35:56 UTC"
                            },
                            {
                                "id": "6068ca1ad036b60070c89c8c",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "tparQ8kd-F8",
                                "name": "Keine Zeit zu sterben - Trailer #2 - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2020-10-12 08:35:47 UTC"
                            },
                            {
                                "id": "5de7bc08a7e363001472268f",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "miJsoS1ITVo",
                                "name": "Keine Zeit zu sterben - Trailer - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2019-12-04 13:25:17 UTC"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "FI",
                                "releaseDates": [
                                    {
                                        "certification": "K-16",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CN",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-29T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "EE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "MC",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-29T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Opéra de Monte-Carlo"
                                    }
                                ]
                            },
                            {
                                "iso31661": "EG",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": "12+",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RO",
                                "releaseDates": [
                                    {
                                        "certification": "A.P. - 12",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KW",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-28T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Royal Albert Hall"
                                    },
                                    {
                                        "certification": "12A",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "12A",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-20T00:00:00.000Z",
                                        "type": 5,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ZA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IN",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "JP",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SI",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SE",
                                "releaseDates": [
                                    {
                                        "certification": "15",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IT",
                                "releaseDates": [
                                    {
                                        "certification": "T",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NO",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "UY",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-28T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Zurich Film Festival"
                                    },
                                    {
                                        "certification": "14",
                                        "iso6391": "de",
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "14",
                                        "iso6391": "fr",
                                        "releaseDate": "2021-09-29T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "14",
                                        "iso6391": "it",
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IE",
                                "releaseDates": [
                                    {
                                        "certification": "12A",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TW",
                                "releaseDates": [
                                    {
                                        "certification": "輔12",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "LT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 2,
                                        "note": null
                                    },
                                    {
                                        "certification": "N-13",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NL",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DK",
                                "releaseDates": [
                                    {
                                        "certification": "11",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CO",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KR",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-29T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "LV",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PL",
                                "releaseDates": [
                                    {
                                        "certification": "15",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CY",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HK",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HU",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 4,
                                        "note": "PVOD"
                                    }
                                ]
                            },
                            {
                                "iso31661": "MX",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 5,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "LU",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RS",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-06T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2022-02-16T00:00:00.000Z",
                                        "type": 5,
                                        "note": "DVD, Blu-Ray & 4K UHD"
                                    }
                                ]
                            },
                            {
                                "iso31661": "UA",
                                "releaseDates": [
                                    {
                                        "certification": "12+",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IS",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "LB",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AU",
                                "releaseDates": [
                                    {
                                        "certification": "M",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-11T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SK",
                                "releaseDates": [
                                    {
                                        "certification": "15",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BG",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": "14",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "14",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-09T00:00:00.000Z",
                                        "type": 4,
                                        "note": "VOD"
                                    }
                                ]
                            },
                            {
                                "iso31661": "ID",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SG",
                                "releaseDates": [
                                    {
                                        "certification": "PG13",
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": "PG-13",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "PG-13",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-09T00:00:00.000Z",
                                        "type": 4,
                                        "note": "Premium VOD"
                                    }
                                ]
                            },
                            {
                                "iso31661": "MY",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-09-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 242000000,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 138550,
                                "item_count": 75,
                                "iso_639_1": "fi",
                                "list_type": "movie",
                                "name": "Tulossa"
                            },
                            {
                                "description": "Peliculas en Pache Kodi",
                                "favorite_count": 0,
                                "id": 145800,
                                "item_count": 1500,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "KODI Katalogo - Peliculas"
                            },
                            {
                                "description": "Cette liste regroupe l'ensemble des vidéos disponible sur la canal TMK - Chat.",
                                "favorite_count": 0,
                                "id": 139130,
                                "item_count": 1098,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "TMK - Ciné 🍿"
                            },
                            {
                                "favorite_count": 0,
                                "id": 24413,
                                "item_count": 1042,
                                "iso_639_1": "zh",
                                "list_type": "movie",
                                "name": "电影"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7109840,
                                "item_count": 5806,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD"
                            },
                            {
                                "description": "Master List In Progress",
                                "favorite_count": 0,
                                "id": 51108,
                                "item_count": 8887,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "My Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7053491,
                                "item_count": 3098,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD (Popular)"
                            },
                            {
                                "description": "theGuardian.com's end-of-year list\nwill update as it is completed\n\nOriginal list + reviews here:\n\nhttps://www.theguardian.com/film/2021/nov/30/the-50-best-films-of-2021-in-the-us",
                                "favorite_count": 0,
                                "id": 8168556,
                                "item_count": 51,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "The 50 best films of 2021 in the US"
                            },
                            {
                                "favorite_count": 0,
                                "id": 143813,
                                "item_count": 5035,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "MNR"
                            },
                            {
                                "description": "A list of Movies running in Theaters",
                                "favorite_count": 0,
                                "id": 63802,
                                "item_count": 47,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Now Running"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8169093,
                                "item_count": 4,
                                "iso_639_1": "tr",
                                "list_type": "movie",
                                "name": "CINEMAQ "
                            },
                            {
                                "favorite_count": 0,
                                "id": 7070382,
                                "item_count": 604,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies Watched 2021"
                            },
                            {
                                "description": "La liste de tous les films que j'ai déjà vus, tout simplement",
                                "favorite_count": 0,
                                "id": 99133,
                                "item_count": 339,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Films vus"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7111582,
                                "item_count": 33,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7113671,
                                "item_count": 3049,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "radarr"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112717,
                                "item_count": 1824,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies in my Digital Collection"
                            },
                            {
                                "description": "watched movies",
                                "favorite_count": 0,
                                "id": 44662,
                                "item_count": 1848,
                                "iso_639_1": "ar",
                                "list_type": "movie",
                                "name": "Watched"
                            },
                            {
                                "description": "https://www.imdb.com/list/ls027244689/",
                                "favorite_count": 0,
                                "id": 7057475,
                                "item_count": 748,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "IMDB: 027244689 [Movies]"
                            },
                            {
                                "description": "The greatest films and shows of all time",
                                "favorite_count": 0,
                                "id": 7112834,
                                "item_count": 521,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Films & Shows of the Centuries"
                            },
                            {
                                "favorite_count": 0,
                                "id": 127215,
                                "item_count": 317,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "movielist"
                            }
                        ],
                        "total_pages": 30,
                        "total_results": 581
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 6.9,
                            "link": "http://www.moviepilot.de/movies/james-bond-007-keine-zeit-zu-sterben",
                            "voteCount": 1895
                        }
                    }
                },
                "ownContent": false,
                "eventImage": "https://image.tmdb.org/t/p/original/ep4endf37uhadb2uzdl8idu6ixo.jpg",
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544293",
                        "cmsId": "61ccb9b997599fbeaafe09a5",
                        "eventTypeId": "205629",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Bond has left active service and is enjoying a tranquil life in Jamaica. His peace is short-lived when his old friend Felix Leiter from the CIA turns up asking for help. The mission to rescue a kidnapped scientist turns out to be far more treacherous than expected, leading Bond onto the trail of a mysterious villain armed with dangerous new technology.",
                        "expireDate": null,
                        "duration": "163",
                        "name": "James Bond 007: Keine Zeit zu sterben",
                        "slug": "james-bond-007-keine-zeit-zu-sterben",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:42",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172068",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "370172m",
                        "externalData": {
                            "lastUpdated": 1639756438,
                            "id": 370172,
                            "cineamoId": "370172m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/r2GAjd4rNOHJh6i6Y0FntmYuPQW.jpg",
                            "genres": [
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 53,
                                    "name": "Thriller"
                                }
                            ],
                            "homepage": "https://www.007.com/no-time-to-die/",
                            "imdbId": "tt2382320",
                            "originalLanguage": "en",
                            "originalTitle": "No Time to Die",
                            "overview": "James Bond hat seine Lizenz zum Töten im Auftrag des britischen Geheimdienstes abgegeben und genießt seinen Ruhestand in Jamaika. Die friedliche Zeit nimmt ein unerwartetes Ende, als sein alter CIA-Kollege Felix Leiter auftaucht und ihn um Hilfe bittet. Ein bedeutender Wissenschaftler ist entführt worden und muss so schnell wie möglich gefunden werden. Was als simple Rettungsmission beginnt, erreicht bald einen bedrohlichen Wendepunkt, denn Bond kommt einem geheimnisvollen Gegenspieler auf die Spur, der im Besitz einer brandgefährlichen neuen Technologie ist.",
                            "popularity": 1359.019,
                            "posterPath": "/wCNebHozTH9Z1hh9nQ1aXdishcq.jpg",
                            "releaseDate": "2021-09-29",
                            "revenue": 771000000,
                            "runtime": 163,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Die Mission, die alles verändert, beginnt …",
                            "title": "James Bond 007 - Keine Zeit zu sterben",
                            "video": false,
                            "voteAverage": 7.5,
                            "voteCount": 2578,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/r2GAjd4rNOHJh6i6Y0FntmYuPQW.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.334,
                                        "voteCount": 11,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rmGtDWrcYP9c0qgyuuzNQRxzKy2.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/dnxrremCrghG7z97zDeGPNrl75A.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1953j0QEbtN17WFFTnJHIm6bn6I.jpg",
                                        "height": 1080,
                                        "iso6391": "de",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/dMuMiCrUDZ0AN0TNmBKkJ1wgKVM.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.22,
                                        "voteCount": 13,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/q5K3ZqwWl6pKbHNHXoIdfz9f7ve.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/18Eca3pEMrdk6GkmKWdf02jWpmo.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.156,
                                        "voteCount": 12,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/jiXWozMk854sAHYpLj2pujAQPp6.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.138,
                                        "voteCount": 8,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9QkYmsucJGWWTtQrDNDvqvRYp8t.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/sYSVT2eblD4yApNrSqrHvlUxU0j.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lir6WyT5O7bwW8DVjKZPjY62nsG.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/7UWrMVr1HQX0SbrqFch5GiePaTS.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/cSrjFMrAQibe5XUp4pwoJyGmw21.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/pMtohpZMsHMfYAPaxRvYmUtKgtF.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/2I4FFTxJOoFnHCZqTpv4NacizFU.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/kcvKEvTWqIGMjpVJdbDVRHdIt4C.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.11,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/mijhSLcawX2T7i1mQOlymTfJA4X.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/hMWwdbI3MKsMOGRljMZrUqpNjqi.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/eBcqBH3jTkCNQSSbuC3YPan8JEg.jpg",
                                        "height": 1080,
                                        "iso6391": "de",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wBOFZk5VfKUhbdTkStXmfml93Ab.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/4yS6G9qfONlzBxsg0C5YQQR4tJd.jpg",
                                        "height": 1079,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1918
                                    },
                                    {
                                        "aspectRatio": 1.776,
                                        "filePath": "/9nTljR8hF8HSqLBXUN2gV5hMr3s.jpg",
                                        "height": 953,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1693
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/zfG8GAqvjyLGncRU2QhfA0k2vK5.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/sb15prw9rAAdYDIDmRgwNhVflGU.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/v697nKoDujxV1o7A0HtaSkRufcu.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/oHzU51rALEdq8WIr5teLJUK3RFU.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/zQMKeWU7kqcmSq9RXfrsvmgkubG.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.08,
                                        "voteCount": 9,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/tYYhP0CVl0VhGU5JtYdCOo2duyH.jpg",
                                        "height": 924,
                                        "iso6391": null,
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 1643
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/tPsDrUjguOS7bxssz3TqvEzBn3F.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/uoMygXRAXg1SOS1dqHUSi6yAVQA.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.056,
                                        "voteCount": 5,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/zdeuKmRosmvQZMiZhJkUl4GQbQN.jpg",
                                        "height": 720,
                                        "iso6391": "en",
                                        "voteAverage": 4.996,
                                        "voteCount": 6,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/64oEFJusPULAkC9RA53hSwZUAYq.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 4.982,
                                        "voteCount": 13,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rOueAuhcQrHR2f3TnFVJZL6PTd2.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 4.982,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/x9td0NQha0t44K2qgcMipLOGjVi.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/4N2u2X314yNG8vlpsh0sWgIXPZi.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9Pt4RFV87juQ9MqPdFdIyYJB1dj.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/iUgygt3fscRoKWCV1d0C7FbM9TP.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.462,
                                        "voteCount": 39,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/eqWaeh21e4ZgHjwpULZVHCGIq9X.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.362,
                                        "voteCount": 29,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wCNebHozTH9Z1hh9nQ1aXdishcq.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/nvp1F7UZZPD4wgJSVItC4btgifz.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dVL1HhSXntN6Rt6SHBOF7ZNICDa.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/iOPXG7q6ZqxSFp2iSY2CvgeRXyC.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.284,
                                        "voteCount": 39,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/ep4eNdF37uhadB2UzdL8IDU6IxO.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.258,
                                        "voteCount": 6,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/bw9Eeiq692dS8BQwWVAZUOKjX8a.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/7M0GR5g68znahgnBXvJ13vEEn7v.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/7w5SBqOx473x4bt8ysbSEryOpcq.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.668,
                                        "filePath": "/9Ne0P39am0aQkPp6YdtE2Dj0BTM.jpg",
                                        "height": 2988,
                                        "iso6391": "de",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1996
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/pvwIbvusXaGMIKeMWDDq7hXZR5I.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/vPkzJTolwKwXnjGvFn1Oci064m4.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.238,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/pjvJHLPCtULHkNUOvpHNHgXpwTJ.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/leeozefichQsg5ZNcR5BlebTuau.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/7rHxXzKXHYtSUQi6jWjP9iayfNe.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/rCts163T9vvwqKGV0ckDz6UYHqM.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/rCvhV7bApnUNQ0h6GOjifTDve4j.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/3tXlStayyRPB8vnTDetAxtUWyGc.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/lpy5sLm8DdpSiwCLnOKBN5D5OqX.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/w0oZEHvERujPV5JdsSp1r6rO7sl.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.158,
                                        "voteCount": 27,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/iCsgRP85EGsJdJt9utiqoQOXWlR.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.128,
                                        "voteCount": 6,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/kAeJYL6DPhT5u5o1itlHyXlz8iT.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/k4yI50vBWes7YXgwUQkP9wyTNeJ.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.701,
                                        "filePath": "/xUvFSC2EVkqnLxQDlLxAcbjgjmU.jpg",
                                        "height": 1426,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/saP4oXiEzS227a6RpO5wJtNjwbx.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/qTJiBMGFbqMBIVt5ChIGBY39R4k.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/bvabjXTdgVMjdQHdEoFqcWj5OQi.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.681,
                                        "filePath": "/aRmei0c0JdPKWjaEZhnTOHVopjL.jpg",
                                        "height": 1242,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 846
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/yBnqXgtazapnmHf9FP26yImr5is.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.11,
                                        "voteCount": 15,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/kxdMGRsZQCWZb3RqiYpxqGHkZuo.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.11,
                                        "voteCount": 15,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.668,
                                        "filePath": "/lWd7XqO1RhrR9wXcKcaq8geCkJL.jpg",
                                        "height": 2988,
                                        "iso6391": "de",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1996
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/3fBWD5wqAsbUCvCc5YUTqdYs54v.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.104,
                                        "voteCount": 26,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/n3F5KdolXSPCiQI6ktuwXzFJ9qi.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.09,
                                        "voteCount": 11,
                                        "width": 1012
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/vd6w8M7ixwPHmiVauphyGVjkflA.jpg",
                                        "height": 2000,
                                        "iso6391": "en",
                                        "voteAverage": 5.086,
                                        "voteCount": 22,
                                        "width": 1333
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/mRBHuUJULS9V6KaYIHPeMzD4AEk.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.086,
                                        "voteCount": 22,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/dCzJDBwf0ux8Q57QbXGPTwe4Wsn.jpg",
                                        "height": 1482,
                                        "iso6391": "en",
                                        "voteAverage": 5.08,
                                        "voteCount": 9,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/cDJPMB1UdHgGV0E6dh1YxFBa0K9.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/8bKXLC0R6csu3VpHQ27zLq4u6MM.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.022,
                                        "voteCount": 10,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/cHF3Z4OaqBHvjKH1csDmnBvj3CA.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.01,
                                        "voteCount": 8,
                                        "width": 1012
                                    },
                                    {
                                        "aspectRatio": 0.701,
                                        "filePath": "/yTIsgo4QAvqe7LiYwUcM8boOinI.jpg",
                                        "height": 1426,
                                        "iso6391": "en",
                                        "voteAverage": 4.996,
                                        "voteCount": 6,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.68,
                                        "filePath": "/8BOj2Q837qHlZV4Gbe1QVIZ27Bp.jpg",
                                        "height": 883,
                                        "iso6391": "en",
                                        "voteAverage": 4.996,
                                        "voteCount": 6,
                                        "width": 600
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wJu1lQU6RgUmBUlS33Ewu6IcDxv.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 4.996,
                                        "voteCount": 6,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/oObKEBjknBoJY712jTADbVWdTfu.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 4.982,
                                        "voteCount": 4,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6NZNncj1G1a3gpMml82vL8C5wT5.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 4.954,
                                        "voteCount": 9,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hnmgKfUFCs79zgTd1wT2CevaSnu.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 4.938,
                                        "voteCount": 7,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/yhN3wjWkFbWtlrmYA7TZzZBBrii.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 4.922,
                                        "voteCount": 5,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/btf0qtKQBTVugx7PS9AP3JK5G81.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 4.922,
                                        "voteCount": 5,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dm5F04mYExOqtOvAopvf24n6YYo.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 4.922,
                                        "voteCount": 5,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gjQJJ597EM2DppDqbiHQTzQW6b8.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 4.866,
                                        "voteCount": 6,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wTy3ABb9ndH11PKh63JidMeJbZs.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 4.866,
                                        "voteCount": 6,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/q2P7bskW7ACBAwBLpOpQYne0oJe.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/39HB2n5vlszd6O8yyz9XlyxsBo3.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/m212pPxqusbd3OXOL4RrnSJNrDp.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/7HCU5ikiQKeV5eSE279btCwOqTQ.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/lE3bsaXTo3FtoATEQlExP3O4fgO.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/68ZcV7ksPfFvns55GdDp6ozMFuX.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/D6bUTp1ojNbJxRP1fud5UKOFBc.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/tstcwdpuBwIHIaYEoM6Q6p5f9al.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/nAHu0D7WDs0DuSUdK5yHFe2h1bA.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/6aeuw04T801UM3PiYRqpVxCFkVR.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/fmJzCnEUBkW7dHlRWQl7imxkuRL.jpg",
                                        "height": 1920,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1296
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/55bfexTAyPP2ca7lMJtGzbpzmLK.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gCMlo83fJOEdAcHqvr9tkAjHjHf.jpg",
                                        "height": 1500,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/3iO3fIQDv49Rw0GpyL3vhz7TKnl.jpg",
                                        "height": 750,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/btX7J0sMe0DeL7o1RPIznU9N54W.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fmI1F0n25qxCdaxJgIH80WamWNx.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/4i9L7ieqUE4Z187aKSx1tCVodfw.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/zzu0lTdXApGLvPln78BUqZyyR1x.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/adYocZrF7GFH3ooThCR1WoO2kpe.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/pgZVPMWnUE7Ej2y1HW5MGR6g8MT.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wOPiNd2QfaNJlwm9EwHqsf38Cbz.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/r4j0lIBPoVe2Dke0I2Iml8A24bs.jpg",
                                        "height": 2100,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5v6XAgnikDDqQpzP2dy0hcuQwJG.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.701,
                                        "filePath": "/gZ4UZXKhuIHUOXlZwEvI3NQuLvM.jpg",
                                        "height": 1426,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/bqq2dlwWAm5O5RVPGHVwwxhESK1.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fmCRpJb9VOpvKndrT1HmCcGVOtp.jpg",
                                        "height": 900,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 600
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 0.927,
                                        "height": 2908,
                                        "iso_639_1": "en",
                                        "file_path": "/kjBI7crJSztrhPCAodqbTvpt3ov.png",
                                        "vote_average": 5.384,
                                        "vote_count": 2,
                                        "width": 2696
                                    },
                                    {
                                        "aspect_ratio": 1.414,
                                        "height": 2481,
                                        "iso_639_1": "en",
                                        "file_path": "/mAeXOTPGMQtPtx1fOYaazC1xLeQ.svg",
                                        "vote_average": 5.384,
                                        "vote_count": 2,
                                        "width": 3508
                                    },
                                    {
                                        "aspect_ratio": 0.926,
                                        "height": 1292,
                                        "iso_639_1": "en",
                                        "file_path": "/bSRbiQEQbdJRMJTot6XPKrjG4h3.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 1197
                                    },
                                    {
                                        "aspect_ratio": 0.925,
                                        "height": 454,
                                        "iso_639_1": "en",
                                        "file_path": "/v6v5srjeXksflu3qpLXgWCnf3it.png",
                                        "vote_average": 5.246,
                                        "vote_count": 2,
                                        "width": 420
                                    },
                                    {
                                        "aspect_ratio": 2.211,
                                        "height": 454,
                                        "iso_639_1": "de",
                                        "file_path": "/4n3ru9OS4Qbw6pFYPLCY7nfOK74.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 1004
                                    },
                                    {
                                        "aspect_ratio": 0.938,
                                        "height": 480,
                                        "iso_639_1": "en",
                                        "file_path": "/h02wT4F1aPBkQ94NlSx3ypOI5xl.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 450
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "612f8e9ac1ffbd008b5b7f7b",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "TXp-oF5TCo0",
                                        "name": "Keine Zeit zu sterben - Finaler internationaler Trailer - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-08-31 19:09:46 UTC"
                                    },
                                    {
                                        "id": "6068c978c14fee00581c3e24",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "WjKN5qCx3jU",
                                        "name": "Keine Zeit zu sterben - Trailer - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2020-10-12 08:35:56 UTC"
                                    },
                                    {
                                        "id": "6068ca1ad036b60070c89c8c",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "tparQ8kd-F8",
                                        "name": "Keine Zeit zu sterben - Trailer #2 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2020-10-12 08:35:47 UTC"
                                    },
                                    {
                                        "id": "5de7bc08a7e363001472268f",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "miJsoS1ITVo",
                                        "name": "Keine Zeit zu sterben - Trailer - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2019-12-04 13:25:17 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": "K-16",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CN",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-29T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "EE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "MC",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-29T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Opéra de Monte-Carlo"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "EG",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": "12+",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": "A.P. - 12",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KW",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-28T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Royal Albert Hall"
                                            },
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-20T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ZA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IN",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "JP",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": "T",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NO",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UY",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-28T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Zurich Film Festival"
                                            },
                                            {
                                                "certification": "14",
                                                "iso6391": "de",
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "14",
                                                "iso6391": "fr",
                                                "releaseDate": "2021-09-29T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "14",
                                                "iso6391": "it",
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IE",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": "輔12",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "LT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 2,
                                                "note": null
                                            },
                                            {
                                                "certification": "N-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DK",
                                        "releaseDates": [
                                            {
                                                "certification": "11",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CO",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-29T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "LV",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CY",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 4,
                                                "note": "PVOD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "MX",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "LU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RS",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-06T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-02-16T00:00:00.000Z",
                                                "type": 5,
                                                "note": "DVD, Blu-Ray & 4K UHD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "12+",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IS",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "LB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "M",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-11T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BG",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "14",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "14",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-09T00:00:00.000Z",
                                                "type": 4,
                                                "note": "VOD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ID",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG13",
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-09T00:00:00.000Z",
                                                "type": 4,
                                                "note": "Premium VOD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "MY",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-09-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 242000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 138550,
                                        "item_count": 75,
                                        "iso_639_1": "fi",
                                        "list_type": "movie",
                                        "name": "Tulossa"
                                    },
                                    {
                                        "description": "Peliculas en Pache Kodi",
                                        "favorite_count": 0,
                                        "id": 145800,
                                        "item_count": 1500,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "KODI Katalogo - Peliculas"
                                    },
                                    {
                                        "description": "Cette liste regroupe l'ensemble des vidéos disponible sur la canal TMK - Chat.",
                                        "favorite_count": 0,
                                        "id": 139130,
                                        "item_count": 1098,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "TMK - Ciné 🍿"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 24413,
                                        "item_count": 1042,
                                        "iso_639_1": "zh",
                                        "list_type": "movie",
                                        "name": "电影"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5806,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "description": "Master List In Progress",
                                        "favorite_count": 0,
                                        "id": 51108,
                                        "item_count": 8887,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3098,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "description": "theGuardian.com's end-of-year list\nwill update as it is completed\n\nOriginal list + reviews here:\n\nhttps://www.theguardian.com/film/2021/nov/30/the-50-best-films-of-2021-in-the-us",
                                        "favorite_count": 0,
                                        "id": 8168556,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "The 50 best films of 2021 in the US"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143813,
                                        "item_count": 5035,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MNR"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 47,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8169093,
                                        "item_count": 4,
                                        "iso_639_1": "tr",
                                        "list_type": "movie",
                                        "name": "CINEMAQ "
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7070382,
                                        "item_count": 604,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies Watched 2021"
                                    },
                                    {
                                        "description": "La liste de tous les films que j'ai déjà vus, tout simplement",
                                        "favorite_count": 0,
                                        "id": 99133,
                                        "item_count": 339,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Films vus"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7111582,
                                        "item_count": 33,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7113671,
                                        "item_count": 3049,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "radarr"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112717,
                                        "item_count": 1824,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies in my Digital Collection"
                                    },
                                    {
                                        "description": "watched movies",
                                        "favorite_count": 0,
                                        "id": 44662,
                                        "item_count": 1848,
                                        "iso_639_1": "ar",
                                        "list_type": "movie",
                                        "name": "Watched"
                                    },
                                    {
                                        "description": "https://www.imdb.com/list/ls027244689/",
                                        "favorite_count": 0,
                                        "id": 7057475,
                                        "item_count": 748,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "IMDB: 027244689 [Movies]"
                                    },
                                    {
                                        "description": "The greatest films and shows of all time",
                                        "favorite_count": 0,
                                        "id": 7112834,
                                        "item_count": 521,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Films & Shows of the Centuries"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 127215,
                                        "item_count": 317,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "movielist"
                                    }
                                ],
                                "total_pages": 30,
                                "total_results": 581
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 6.9,
                                    "link": "http://www.moviepilot.de/movies/james-bond-007-keine-zeit-zu-sterben",
                                    "voteCount": 1895
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 17:15:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/dvl1hhsxntn6rt6shbof7znicda.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:42",
                        "updateTimestamp": "2022-01-03 10:31:42",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "215625",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "Ida muss umziehen. Weg von ihrem Zuhause, ihrer Schule und vor allem: Weg von ihren Freunden. In der neuen Klasse tut sie sich schwer. Eines Tages verkündet ihre Klassenlehrerin Miss Cornfield, dass demnächst jedes Kind in der Klasse ein magisches Tier als Begleiter bekommt. Ausgerechnet die beiden Außenseiter Ida und Benni erhalten als erste ihre neuen Gefährten, die Freunde fürs Leben werden sollen. Ida wird der Fuchs Rabbat an die Seite gestellt, Benni die Schildkröte Henrietta. Die magischen Tiere können nicht nur sprechen, sie haben alle auch einen ganz eigenen Charakter. Während Henrietta zwar etwas langsam, aber dafür fast 200 Jahre alt und sehr weise ist, ist Rabbat ebenso pfiffig wie gewitzt. Durch Rabbat wird Ida plötzlich zum Klassenstar und jeder möchte mit ihr befreundet sein.",
                "duration": "0",
                "name": "Die Schule der magischen Tiere",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "793937m",
                "externalData": {
                    "lastUpdated": 1641227371,
                    "id": 793937,
                    "cineamoId": "793937m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/1100JRh1VbDkGiKBciy3lSuLxX7.jpg",
                    "genres": [
                        {
                            "id": 12,
                            "name": "Abenteuer"
                        },
                        {
                            "id": 10751,
                            "name": "Familie"
                        }
                    ],
                    "homepage": "http://www.die-schule-der-magischen-tiere-film.de/",
                    "imdbId": "tt11455260",
                    "originalLanguage": "de",
                    "originalTitle": "Die Schule der magischen Tiere",
                    "overview": "Ida muss umziehen. Weg von ihrem Zuhause, ihrer Schule und vor allem: Weg von ihren Freunden. In der neuen Klasse tut sie sich schwer. Eines Tages verkündet ihre Klassenlehrerin Miss Cornfield, dass demnächst jedes Kind in der Klasse ein magisches Tier als Begleiter bekommt. Ausgerechnet die beiden Außenseiter Ida und Benni erhalten als erste ihre neuen Gefährten, die Freunde fürs Leben werden sollen. Ida wird der Fuchs Rabbat an die Seite gestellt, Benni die Schildkröte Henrietta. Die magischen Tiere können nicht nur sprechen, sie haben alle auch einen ganz eigenen Charakter. Während Henrietta zwar etwas langsam, aber dafür fast 200 Jahre alt und sehr weise ist, ist Rabbat ebenso pfiffig wie gewitzt. Durch Rabbat wird Ida plötzlich zum Klassenstar und jeder möchte mit ihr befreundet sein.",
                    "popularity": 3.242,
                    "posterPath": "/mcw49B8Uuo2n4woIMLeKC5EREti.jpg",
                    "releaseDate": "2021-10-14",
                    "revenue": 0,
                    "runtime": 93,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": null,
                    "title": "Die Schule der magischen Tiere",
                    "video": false,
                    "voteAverage": 4,
                    "voteCount": 1,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/1100JRh1VbDkGiKBciy3lSuLxX7.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/iM5vP7dGCPKKR1BTQv8tIdGQtm3.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/1rg9efPwGLKhFD9cITr95jBrcT8.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1280
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/puAQv2Vo5MQJYLCdJh5cfdwA8Vc.jpg",
                                "height": 750,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 500
                            },
                            {
                                "aspectRatio": 0.712,
                                "filePath": "/mcw49B8Uuo2n4woIMLeKC5EREti.jpg",
                                "height": 2560,
                                "iso6391": "de",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1823
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/fK5bfNUKgZg3mZ4p46idqwJ8DIe.jpg",
                                "height": 750,
                                "iso6391": "de",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 500
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "61719f79df86a800436a47c5",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "WcidVROdWRY",
                                "name": "DIE SCHULE DER MAGISCHEN TIERE Trailer German Deutsch (2021) Exklusiv",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": false,
                                "publishedAt": "2021-08-17T09:02:03.000Z"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "KR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-07-08T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Busan International Kids & Youth Film Festival"
                                    }
                                ]
                            },
                            {
                                "iso31661": "PL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-09T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Warsaw Film Festival"
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": "6+",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-11T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Schlingel Film Festival"
                                    },
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-14T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 11700000,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 7094572,
                                "item_count": 57,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "rough"
                            }
                        ],
                        "total_pages": 1,
                        "total_results": 1
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 0,
                            "link": "http://www.moviepilot.de/movies/die-schule-der-magischen-tiere-2",
                            "voteCount": 0
                        }
                    }
                },
                "ownContent": false,
                "eventImage": "https://image.tmdb.org/t/p/original/mcw49b8uuo2n4woimlekc5ereti.jpg",
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544354",
                        "cmsId": "61ccb9a297599fbeaafe097b",
                        "eventTypeId": "215625",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Ida muss umziehen. Weg von ihrem Zuhause, ihrer Schule und vor allem: Weg von ihren Freunden. In der neuen Klasse tut sie sich schwer. Eines Tages verkündet ihre Klassenlehrerin Miss Cornfield, dass demnächst jedes Kind in der Klasse ein magisches Tier als Begleiter bekommt. Ausgerechnet die beiden Außenseiter Ida und Benni erhalten als erste ihre neuen Gefährten, die Freunde fürs Leben werden sollen. Ida wird der Fuchs Rabbat an die Seite gestellt, Benni die Schildkröte Henrietta. Die magischen Tiere können nicht nur sprechen, sie haben alle auch einen ganz eigenen Charakter. Während Henrietta zwar etwas langsam, aber dafür fast 200 Jahre alt und sehr weise ist, ist Rabbat ebenso pfiffig wie gewitzt. Durch Rabbat wird Ida plötzlich zum Klassenstar und jeder möchte mit ihr befreundet sein.",
                        "expireDate": null,
                        "duration": "93",
                        "name": "Die Schule der magischen Tiere",
                        "slug": "die-schule-der-magischen-tiere",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:46",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/171997",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "793937m",
                        "externalData": {
                            "lastUpdated": 1641227371,
                            "id": 793937,
                            "cineamoId": "793937m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/1100JRh1VbDkGiKBciy3lSuLxX7.jpg",
                            "genres": [
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                }
                            ],
                            "homepage": "http://www.die-schule-der-magischen-tiere-film.de/",
                            "imdbId": "tt11455260",
                            "originalLanguage": "de",
                            "originalTitle": "Die Schule der magischen Tiere",
                            "overview": "Ida muss umziehen. Weg von ihrem Zuhause, ihrer Schule und vor allem: Weg von ihren Freunden. In der neuen Klasse tut sie sich schwer. Eines Tages verkündet ihre Klassenlehrerin Miss Cornfield, dass demnächst jedes Kind in der Klasse ein magisches Tier als Begleiter bekommt. Ausgerechnet die beiden Außenseiter Ida und Benni erhalten als erste ihre neuen Gefährten, die Freunde fürs Leben werden sollen. Ida wird der Fuchs Rabbat an die Seite gestellt, Benni die Schildkröte Henrietta. Die magischen Tiere können nicht nur sprechen, sie haben alle auch einen ganz eigenen Charakter. Während Henrietta zwar etwas langsam, aber dafür fast 200 Jahre alt und sehr weise ist, ist Rabbat ebenso pfiffig wie gewitzt. Durch Rabbat wird Ida plötzlich zum Klassenstar und jeder möchte mit ihr befreundet sein.",
                            "popularity": 3.242,
                            "posterPath": "/mcw49B8Uuo2n4woIMLeKC5EREti.jpg",
                            "releaseDate": "2021-10-14",
                            "revenue": 0,
                            "runtime": 93,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Die Schule der magischen Tiere",
                            "video": false,
                            "voteAverage": 4,
                            "voteCount": 1,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1100JRh1VbDkGiKBciy3lSuLxX7.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/iM5vP7dGCPKKR1BTQv8tIdGQtm3.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1rg9efPwGLKhFD9cITr95jBrcT8.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/puAQv2Vo5MQJYLCdJh5cfdwA8Vc.jpg",
                                        "height": 750,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.712,
                                        "filePath": "/mcw49B8Uuo2n4woIMLeKC5EREti.jpg",
                                        "height": 2560,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1823
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fK5bfNUKgZg3mZ4p46idqwJ8DIe.jpg",
                                        "height": 750,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "61719f79df86a800436a47c5",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "WcidVROdWRY",
                                        "name": "DIE SCHULE DER MAGISCHEN TIERE Trailer German Deutsch (2021) Exklusiv",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": false,
                                        "publishedAt": "2021-08-17T09:02:03.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-07-08T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Busan International Kids & Youth Film Festival"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-09T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Warsaw Film Festival"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": "6+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-11T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Schlingel Film Festival"
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-14T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 11700000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7094572,
                                        "item_count": 57,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "rough"
                                    }
                                ],
                                "total_pages": 1,
                                "total_results": 1
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 0,
                                    "link": "http://www.moviepilot.de/movies/die-schule-der-magischen-tiere-2",
                                    "voteCount": 0
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 13:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/mcw49b8uuo2n4woimlekc5ereti.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:46",
                        "updateTimestamp": "2022-01-03 10:31:46",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    },
                    {
                        "statistics": null,
                        "requestId": "5544355",
                        "cmsId": "61ccb9a297599fbeaafe097e",
                        "eventTypeId": "215625",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Ida muss umziehen. Weg von ihrem Zuhause, ihrer Schule und vor allem: Weg von ihren Freunden. In der neuen Klasse tut sie sich schwer. Eines Tages verkündet ihre Klassenlehrerin Miss Cornfield, dass demnächst jedes Kind in der Klasse ein magisches Tier als Begleiter bekommt. Ausgerechnet die beiden Außenseiter Ida und Benni erhalten als erste ihre neuen Gefährten, die Freunde fürs Leben werden sollen. Ida wird der Fuchs Rabbat an die Seite gestellt, Benni die Schildkröte Henrietta. Die magischen Tiere können nicht nur sprechen, sie haben alle auch einen ganz eigenen Charakter. Während Henrietta zwar etwas langsam, aber dafür fast 200 Jahre alt und sehr weise ist, ist Rabbat ebenso pfiffig wie gewitzt. Durch Rabbat wird Ida plötzlich zum Klassenstar und jeder möchte mit ihr befreundet sein.",
                        "expireDate": null,
                        "duration": "93",
                        "name": "Die Schule der magischen Tiere",
                        "slug": "die-schule-der-magischen-tiere",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:46",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172134",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "793937m",
                        "externalData": {
                            "lastUpdated": 1641227371,
                            "id": 793937,
                            "cineamoId": "793937m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/1100JRh1VbDkGiKBciy3lSuLxX7.jpg",
                            "genres": [
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                }
                            ],
                            "homepage": "http://www.die-schule-der-magischen-tiere-film.de/",
                            "imdbId": "tt11455260",
                            "originalLanguage": "de",
                            "originalTitle": "Die Schule der magischen Tiere",
                            "overview": "Ida muss umziehen. Weg von ihrem Zuhause, ihrer Schule und vor allem: Weg von ihren Freunden. In der neuen Klasse tut sie sich schwer. Eines Tages verkündet ihre Klassenlehrerin Miss Cornfield, dass demnächst jedes Kind in der Klasse ein magisches Tier als Begleiter bekommt. Ausgerechnet die beiden Außenseiter Ida und Benni erhalten als erste ihre neuen Gefährten, die Freunde fürs Leben werden sollen. Ida wird der Fuchs Rabbat an die Seite gestellt, Benni die Schildkröte Henrietta. Die magischen Tiere können nicht nur sprechen, sie haben alle auch einen ganz eigenen Charakter. Während Henrietta zwar etwas langsam, aber dafür fast 200 Jahre alt und sehr weise ist, ist Rabbat ebenso pfiffig wie gewitzt. Durch Rabbat wird Ida plötzlich zum Klassenstar und jeder möchte mit ihr befreundet sein.",
                            "popularity": 3.242,
                            "posterPath": "/mcw49B8Uuo2n4woIMLeKC5EREti.jpg",
                            "releaseDate": "2021-10-14",
                            "revenue": 0,
                            "runtime": 93,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Die Schule der magischen Tiere",
                            "video": false,
                            "voteAverage": 4,
                            "voteCount": 1,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1100JRh1VbDkGiKBciy3lSuLxX7.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/iM5vP7dGCPKKR1BTQv8tIdGQtm3.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1rg9efPwGLKhFD9cITr95jBrcT8.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/puAQv2Vo5MQJYLCdJh5cfdwA8Vc.jpg",
                                        "height": 750,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.712,
                                        "filePath": "/mcw49B8Uuo2n4woIMLeKC5EREti.jpg",
                                        "height": 2560,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1823
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fK5bfNUKgZg3mZ4p46idqwJ8DIe.jpg",
                                        "height": 750,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "61719f79df86a800436a47c5",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "WcidVROdWRY",
                                        "name": "DIE SCHULE DER MAGISCHEN TIERE Trailer German Deutsch (2021) Exklusiv",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": false,
                                        "publishedAt": "2021-08-17T09:02:03.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-07-08T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Busan International Kids & Youth Film Festival"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-09T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Warsaw Film Festival"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": "6+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-11T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Schlingel Film Festival"
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-14T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 11700000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7094572,
                                        "item_count": 57,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "rough"
                                    }
                                ],
                                "total_pages": 1,
                                "total_results": 1
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 0,
                                    "link": "http://www.moviepilot.de/movies/die-schule-der-magischen-tiere-2",
                                    "voteCount": 0
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 13:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/mcw49b8uuo2n4woimlekc5ereti.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:46",
                        "updateTimestamp": "2022-01-03 10:31:46",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "217734",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "In seiner Vorlesung hat Professor Richard Pohl (Christoph Maria Herbst) die Jura-Studentin Naima (Nilam Farooq) rassistisch und sexistisch diskriminiert. Es ist nicht der erste Zwischenfall dieser Art und noch dazu wurde Pohl bei seinen Entgleisungen gefilmt und das Video ins Internet gestellt. Der Druck auf die Johann Wolfgang Goethe-Universität in Frankfurt wächst und Pohl erhält von Universitätspräsident Alexander Lambrecht (Ernst Stötzner) eine letzte Chance: Er soll Naima bei einem wichtigen Debattierwettstreit als Mentor zur Seite stehen. So könnte der Professor seine Chancen vor dem Disziplinaraussschuss verbessern und noch einmal um seine Entlassung herum kommen. Der Dozent und die Studentin könnten unterschiedlicher nicht sein, dennoch wachsen sie zu einem starken Team zusammen.",
                "duration": "104",
                "name": "Contra",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "664698m",
                "externalData": {
                    "lastUpdated": 1641228421,
                    "id": 664698,
                    "cineamoId": "664698m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/ljhfy2PzPNY9B1LbylwQ8pYtg5m.jpg",
                    "genres": [],
                    "homepage": null,
                    "imdbId": "tt10628092",
                    "originalLanguage": "de",
                    "originalTitle": "Contra",
                    "overview": "In seiner Vorlesung hat Professor Richard Pohl (Christoph Maria Herbst) die Jura-Studentin Naima (Nilam Farooq) rassistisch und sexistisch diskriminiert. Es ist nicht der erste Zwischenfall dieser Art und noch dazu wurde Pohl bei seinen Entgleisungen gefilmt und das Video ins Internet gestellt. Der Druck auf die Johann Wolfgang Goethe-Universität in Frankfurt wächst und Pohl erhält von Universitätspräsident Alexander Lambrecht (Ernst Stötzner) eine letzte Chance: Er soll Naima bei einem wichtigen Debattierwettstreit als Mentor zur Seite stehen. So könnte der Professor seine Chancen vor dem Disziplinaraussschuss verbessern und noch einmal um seine Entlassung herum kommen. Der Dozent und die Studentin könnten unterschiedlicher nicht sein, dennoch wachsen sie zu einem starken Team zusammen.",
                    "popularity": 2.438,
                    "posterPath": "/embHtWxI3DAY1OLHeUiPqYNY9dP.jpg",
                    "releaseDate": "2021-10-28",
                    "revenue": 0,
                    "runtime": 104,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": null,
                    "title": "Contra",
                    "video": false,
                    "voteAverage": 8,
                    "voteCount": 7,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/ljhfy2PzPNY9B1LbylwQ8pYtg5m.jpg",
                                "height": 1972,
                                "iso6391": null,
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 3507
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/cDuWYSFAc2MdcXThkAWL0oWysJO.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/cgTWBHvGlg3VZBVdJDuL1LY9UT0.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/maOOLd5IEh7eKe2iesbPNH0KTtb.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1280
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.706,
                                "filePath": "/A0CAxnc29SsrrMvhh3ghIQxod67.jpg",
                                "height": 1600,
                                "iso6391": null,
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 1130
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/lZRj7k2lhnPrNiJVyAwSr2pqpNu.jpg",
                                "height": 750,
                                "iso6391": null,
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 500
                            },
                            {
                                "aspectRatio": 0.706,
                                "filePath": "/embHtWxI3DAY1OLHeUiPqYNY9dP.jpg",
                                "height": 1600,
                                "iso6391": "de",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1130
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "603ac630071650796a66bbdf",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "Y3mhiTsJsmk",
                                "name": "CONTRA Trailer German Deutsch (2021)",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": false,
                                "publishedAt": "2020-10-12T13:07:58.000Z"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "CH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2020-10-01T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Zurich Film Festival"
                                    },
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-28T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": "de",
                                        "releaseDate": "2021-10-28T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 0,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 50375,
                                "item_count": 15,
                                "iso_639_1": "de",
                                "list_type": "movie",
                                "name": "Wunschliste"
                            }
                        ],
                        "total_pages": 1,
                        "total_results": 1
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 6.7,
                            "link": "http://www.moviepilot.de/movies/contra",
                            "voteCount": 119
                        }
                    }
                },
                "ownContent": false,
                "eventImage": "https://image.tmdb.org/t/p/original/embhtwxi3day1olheuipqyny9dp.jpg",
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544297",
                        "cmsId": "61ccb99f97599fbeaafe0963",
                        "eventTypeId": "217734",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "In seiner Vorlesung hat Professor Richard Pohl (Christoph Maria Herbst) die Jura-Studentin Naima (Nilam Farooq) rassistisch und sexistisch diskriminiert. Es ist nicht der erste Zwischenfall dieser Art und noch dazu wurde Pohl bei seinen Entgleisungen gefilmt und das Video ins Internet gestellt. Der Druck auf die Johann Wolfgang Goethe-Universität in Frankfurt wächst und Pohl erhält von Universitätspräsident Alexander Lambrecht (Ernst Stötzner) eine letzte Chance: Er soll Naima bei einem wichtigen Debattierwettstreit als Mentor zur Seite stehen. So könnte der Professor seine Chancen vor dem Disziplinaraussschuss verbessern und noch einmal um seine Entlassung herum kommen. Der Dozent und die Studentin könnten unterschiedlicher nicht sein, dennoch wachsen sie zu einem starken Team zusammen.",
                        "expireDate": null,
                        "duration": "104",
                        "name": "Contra",
                        "slug": "contra",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:42",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/171978",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "664698m",
                        "externalData": {
                            "lastUpdated": 1641228421,
                            "id": 664698,
                            "cineamoId": "664698m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/ljhfy2PzPNY9B1LbylwQ8pYtg5m.jpg",
                            "genres": [],
                            "homepage": null,
                            "imdbId": "tt10628092",
                            "originalLanguage": "de",
                            "originalTitle": "Contra",
                            "overview": "In seiner Vorlesung hat Professor Richard Pohl (Christoph Maria Herbst) die Jura-Studentin Naima (Nilam Farooq) rassistisch und sexistisch diskriminiert. Es ist nicht der erste Zwischenfall dieser Art und noch dazu wurde Pohl bei seinen Entgleisungen gefilmt und das Video ins Internet gestellt. Der Druck auf die Johann Wolfgang Goethe-Universität in Frankfurt wächst und Pohl erhält von Universitätspräsident Alexander Lambrecht (Ernst Stötzner) eine letzte Chance: Er soll Naima bei einem wichtigen Debattierwettstreit als Mentor zur Seite stehen. So könnte der Professor seine Chancen vor dem Disziplinaraussschuss verbessern und noch einmal um seine Entlassung herum kommen. Der Dozent und die Studentin könnten unterschiedlicher nicht sein, dennoch wachsen sie zu einem starken Team zusammen.",
                            "popularity": 2.438,
                            "posterPath": "/embHtWxI3DAY1OLHeUiPqYNY9dP.jpg",
                            "releaseDate": "2021-10-28",
                            "revenue": 0,
                            "runtime": 104,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Contra",
                            "video": false,
                            "voteAverage": 8,
                            "voteCount": 7,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/ljhfy2PzPNY9B1LbylwQ8pYtg5m.jpg",
                                        "height": 1972,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 3507
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/cDuWYSFAc2MdcXThkAWL0oWysJO.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/cgTWBHvGlg3VZBVdJDuL1LY9UT0.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/maOOLd5IEh7eKe2iesbPNH0KTtb.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.706,
                                        "filePath": "/A0CAxnc29SsrrMvhh3ghIQxod67.jpg",
                                        "height": 1600,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1130
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/lZRj7k2lhnPrNiJVyAwSr2pqpNu.jpg",
                                        "height": 750,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.706,
                                        "filePath": "/embHtWxI3DAY1OLHeUiPqYNY9dP.jpg",
                                        "height": 1600,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1130
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "603ac630071650796a66bbdf",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "Y3mhiTsJsmk",
                                        "name": "CONTRA Trailer German Deutsch (2021)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": false,
                                        "publishedAt": "2020-10-12T13:07:58.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2020-10-01T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Zurich Film Festival"
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": "de",
                                                "releaseDate": "2021-10-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 50375,
                                        "item_count": 15,
                                        "iso_639_1": "de",
                                        "list_type": "movie",
                                        "name": "Wunschliste"
                                    }
                                ],
                                "total_pages": 1,
                                "total_results": 1
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 6.7,
                                    "link": "http://www.moviepilot.de/movies/contra",
                                    "voteCount": 119
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 16:45:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/embhtwxi3day1olheuipqyny9dp.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:42",
                        "updateTimestamp": "2022-01-03 10:31:42",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "222330",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "The Addams get tangled up in more wacky adventures and find themselves involved in hilarious run-ins with all sorts of unsuspecting characters.",
                "duration": "93",
                "name": "Die Addams Family 2",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "639721m",
                "externalData": {
                    "lastUpdated": 1638563184,
                    "id": 639721,
                    "cineamoId": "639721m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/wfrfxivLOBtGMC98tIr2LSOeKSe.jpg",
                    "genres": [
                        {
                            "id": 16,
                            "name": "Animation"
                        },
                        {
                            "id": 12,
                            "name": "Abenteuer"
                        },
                        {
                            "id": 35,
                            "name": "Komödie"
                        },
                        {
                            "id": 10751,
                            "name": "Familie"
                        }
                    ],
                    "homepage": "https://www.upig.de/micro/die-addams-family-2",
                    "imdbId": "tt11125620",
                    "originalLanguage": "en",
                    "originalTitle": "The Addams Family 2",
                    "overview": "Familie Addams wird in neue Abenteuer verwickelt.",
                    "popularity": 505.06,
                    "posterPath": "/ld7YB9vBRp1GM1DT3KmFWSmtBPB.jpg",
                    "releaseDate": "2021-10-01",
                    "revenue": 105000000,
                    "runtime": 93,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": null,
                    "title": "Die Addams Family 2",
                    "video": false,
                    "voteAverage": 7.3,
                    "voteCount": 628,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wfrfxivLOBtGMC98tIr2LSOeKSe.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.522,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/qsQn1dMeMQIcYSkcLfO7XwNJ5um.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/O2V5XY9e3BGEGPiwCyp1q8bYfe.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.252,
                                "voteCount": 4,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/gejjGGqnG4BcqU3vDOJwkfAyF7I.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/nBekrWDD4EACEcuSYEeehwzBXwu.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/kTOheVmqSBDIRGrQLv2SiSc89os.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.068,
                                "voteCount": 7,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wWvL6FmETpoyxEFtXOEnOR87ZBw.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/mjLmxkC3NLxvL25wvd1uXVTYAqa.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/9e6wp707XMouPG939o2fHunXXJR.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/3ZlO2MRWMXL11BwYkokQ15Ncf04.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/ld7YB9vBRp1GM1DT3KmFWSmtBPB.jpg",
                                "height": 2880,
                                "iso6391": "en",
                                "voteAverage": 5.522,
                                "voteCount": 6,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/yNT64G3uwcMXtQxMG6d1EL4gQTa.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/xMxbC1aWlufCpkhY2WUsI4VH72i.jpg",
                                "height": 750,
                                "iso6391": null,
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 500
                            },
                            {
                                "aspectRatio": 0.673,
                                "filePath": "/mvNbSdNwNyvFFzkWe4gBZg67Ybl.jpg",
                                "height": 1565,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1054
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/mO3jKeJ31FqN9sMlbWMDs5r8cXO.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/xYLBgw7dHyEqmcrSk2Sq3asuSq5.jpg",
                                "height": 2963,
                                "iso6391": "en",
                                "voteAverage": 5.138,
                                "voteCount": 8,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/a2MivYrMmtq9qYEtJ8wiZ3bPhBu.jpg",
                                "height": 1600,
                                "iso6391": "en",
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1080
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/fxHf5HfDksVf1AkDGZZr2iX90gj.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/oKb3JPREg8E1yOtQckz1iINcQxd.jpg",
                                "height": 2880,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 2.284,
                                "height": 1254,
                                "iso_639_1": "en",
                                "file_path": "/dvqiFkHsaKILej9EpPlXfk6FHwO.png",
                                "vote_average": 5.312,
                                "vote_count": 1,
                                "width": 2864
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "6138bebf7a1bd60042e3aaf7",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "6nLi9zeEPPc",
                                "name": "Die Addams Family 2 - Offizieller Trailer deutsch/german HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-09-07 15:01:34 UTC"
                            },
                            {
                                "id": "617bfe23a097dc009220e7e6",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "7wZ9n8XM3wc",
                                "name": "Die Addams Family 2 - Teaser Trailer HD deutsch/german",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Teaser",
                                "official": true,
                                "publishedAt": "2020-10-08 16:11:27 UTC"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "HU",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-27T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SK",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-10T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": "7",
                                        "iso6391": "es",
                                        "releaseDate": "2021-10-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": "Universal"
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RO",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NL",
                                "releaseDates": [
                                    {
                                        "certification": "6",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-06T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-28T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HK",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-09T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DK",
                                "releaseDates": [
                                    {
                                        "certification": "7",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GR",
                                "releaseDates": [
                                    {
                                        "certification": "K",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-28T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-19T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SE",
                                "releaseDates": [
                                    {
                                        "certification": "7",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-29T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": "U",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-13T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": "6",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-18T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SG",
                                "releaseDates": [
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-11T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FI",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-10-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": "L",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-07T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TW",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-01T00:00:00.000Z",
                                        "type": 4,
                                        "note": "PVOD"
                                    },
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2022-01-18T00:00:00.000Z",
                                        "type": 5,
                                        "note": "Blu-ray"
                                    }
                                ]
                            },
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-10-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 0,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 7055352,
                                "item_count": 512,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "MavoMas"
                            },
                            {
                                "description": "Meine Filme",
                                "favorite_count": 0,
                                "id": 7113987,
                                "item_count": 512,
                                "iso_639_1": "de",
                                "list_type": "movie",
                                "name": "Filme"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7072853,
                                "item_count": 701,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "Infantiles "
                            },
                            {
                                "favorite_count": 0,
                                "id": 142797,
                                "item_count": 963,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "Infantiles"
                            },
                            {
                                "description": "watched movies",
                                "favorite_count": 0,
                                "id": 44662,
                                "item_count": 1829,
                                "iso_639_1": "ar",
                                "list_type": "movie",
                                "name": "Watched"
                            },
                            {
                                "description": "Master List In Progress",
                                "favorite_count": 0,
                                "id": 51108,
                                "item_count": 8856,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "My Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7053491,
                                "item_count": 3003,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD (Popular)"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7109840,
                                "item_count": 5688,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD"
                            },
                            {
                                "description": "Peliculas en Pache Kodi",
                                "favorite_count": 0,
                                "id": 145800,
                                "item_count": 1456,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "KODI Katalogo - Peliculas"
                            },
                            {
                                "favorite_count": 0,
                                "id": 143813,
                                "item_count": 4809,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "MNR"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7070382,
                                "item_count": 581,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies Watched 2021"
                            },
                            {
                                "favorite_count": 0,
                                "id": 88120,
                                "item_count": 331,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Add Please"
                            },
                            {
                                "description": "Movie List",
                                "favorite_count": 0,
                                "id": 143237,
                                "item_count": 183,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "List"
                            },
                            {
                                "description": "Cette liste regroupe l'ensemble des vidéos disponible sur la canal TMK - Chat.",
                                "favorite_count": 0,
                                "id": 139130,
                                "item_count": 1092,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "TMK - Ciné 🍿"
                            },
                            {
                                "favorite_count": 0,
                                "id": 113444,
                                "item_count": 392,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7887892,
                                "item_count": 61,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "KIDS"
                            },
                            {
                                "description": "All the Great Movies I've Watched So Far",
                                "favorite_count": 0,
                                "id": 7092449,
                                "item_count": 462,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Watched Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7076646,
                                "item_count": 531,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "Peliculas Infantiles"
                            },
                            {
                                "description": "Either got or want",
                                "favorite_count": 0,
                                "id": 126437,
                                "item_count": 1922,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "My Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 89943,
                                "item_count": 748,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Clem2k"
                            }
                        ],
                        "total_pages": 7,
                        "total_results": 124
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 5.3,
                            "link": "http://www.moviepilot.de/movies/die-addams-family-2",
                            "voteCount": 18
                        }
                    }
                },
                "ownContent": false,
                "eventImage": null,
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544403",
                        "cmsId": "61ccb9a297599fbeaafe0991",
                        "eventTypeId": "222330",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Morticia und Gomez sind empört darüber, dass ihre Kinder erwachsen werden, Familienessen ausfallen lassen und die meiste Zeit vor dem Bildschirm verbringen. Um die familiären Bindungen zu stärken, beschließen sie, Wednesday, Pugsley, Onkel Fester und den Rest der Bande in ihr verwunschenes Wohnmobil zu zwängen und in einen letzten elenden Familienurlaub zu fahren. Zu ihnen gesellen sich ihre Cousine La Chose und einige verrückte neue Charaktere.",
                        "expireDate": null,
                        "duration": "93",
                        "name": "Die Addams Family 2",
                        "slug": "die-addams-family-2",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:48",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172003",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "639721m",
                        "externalData": {
                            "lastUpdated": 1638563184,
                            "id": 639721,
                            "cineamoId": "639721m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/wfrfxivLOBtGMC98tIr2LSOeKSe.jpg",
                            "genres": [
                                {
                                    "id": 16,
                                    "name": "Animation"
                                },
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 35,
                                    "name": "Komödie"
                                },
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                }
                            ],
                            "homepage": "https://www.upig.de/micro/die-addams-family-2",
                            "imdbId": "tt11125620",
                            "originalLanguage": "en",
                            "originalTitle": "The Addams Family 2",
                            "overview": "Familie Addams wird in neue Abenteuer verwickelt.",
                            "popularity": 505.06,
                            "posterPath": "/ld7YB9vBRp1GM1DT3KmFWSmtBPB.jpg",
                            "releaseDate": "2021-10-01",
                            "revenue": 105000000,
                            "runtime": 93,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Die Addams Family 2",
                            "video": false,
                            "voteAverage": 7.3,
                            "voteCount": 628,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wfrfxivLOBtGMC98tIr2LSOeKSe.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.522,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/qsQn1dMeMQIcYSkcLfO7XwNJ5um.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/O2V5XY9e3BGEGPiwCyp1q8bYfe.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gejjGGqnG4BcqU3vDOJwkfAyF7I.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/nBekrWDD4EACEcuSYEeehwzBXwu.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/kTOheVmqSBDIRGrQLv2SiSc89os.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wWvL6FmETpoyxEFtXOEnOR87ZBw.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/mjLmxkC3NLxvL25wvd1uXVTYAqa.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9e6wp707XMouPG939o2fHunXXJR.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3ZlO2MRWMXL11BwYkokQ15Ncf04.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ld7YB9vBRp1GM1DT3KmFWSmtBPB.jpg",
                                        "height": 2880,
                                        "iso6391": "en",
                                        "voteAverage": 5.522,
                                        "voteCount": 6,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/yNT64G3uwcMXtQxMG6d1EL4gQTa.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/xMxbC1aWlufCpkhY2WUsI4VH72i.jpg",
                                        "height": 750,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.673,
                                        "filePath": "/mvNbSdNwNyvFFzkWe4gBZg67Ybl.jpg",
                                        "height": 1565,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1054
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/mO3jKeJ31FqN9sMlbWMDs5r8cXO.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/xYLBgw7dHyEqmcrSk2Sq3asuSq5.jpg",
                                        "height": 2963,
                                        "iso6391": "en",
                                        "voteAverage": 5.138,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/a2MivYrMmtq9qYEtJ8wiZ3bPhBu.jpg",
                                        "height": 1600,
                                        "iso6391": "en",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1080
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fxHf5HfDksVf1AkDGZZr2iX90gj.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/oKb3JPREg8E1yOtQckz1iINcQxd.jpg",
                                        "height": 2880,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.284,
                                        "height": 1254,
                                        "iso_639_1": "en",
                                        "file_path": "/dvqiFkHsaKILej9EpPlXfk6FHwO.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 2864
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6138bebf7a1bd60042e3aaf7",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "6nLi9zeEPPc",
                                        "name": "Die Addams Family 2 - Offizieller Trailer deutsch/german HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-09-07 15:01:34 UTC"
                                    },
                                    {
                                        "id": "617bfe23a097dc009220e7e6",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "7wZ9n8XM3wc",
                                        "name": "Die Addams Family 2 - Teaser Trailer HD deutsch/german",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Teaser",
                                        "official": true,
                                        "publishedAt": "2020-10-08 16:11:27 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-27T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "7",
                                                "iso6391": "es",
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": "Universal"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "6",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-06T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-09T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DK",
                                        "releaseDates": [
                                            {
                                                "certification": "7",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": "K",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-19T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": "7",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-29T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-13T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "6",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-18T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-11T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "L",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 4,
                                                "note": "PVOD"
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-18T00:00:00.000Z",
                                                "type": 5,
                                                "note": "Blu-ray"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7055352,
                                        "item_count": 512,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "MavoMas"
                                    },
                                    {
                                        "description": "Meine Filme",
                                        "favorite_count": 0,
                                        "id": 7113987,
                                        "item_count": 512,
                                        "iso_639_1": "de",
                                        "list_type": "movie",
                                        "name": "Filme"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7072853,
                                        "item_count": 701,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Infantiles "
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 142797,
                                        "item_count": 963,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Infantiles"
                                    },
                                    {
                                        "description": "watched movies",
                                        "favorite_count": 0,
                                        "id": 44662,
                                        "item_count": 1829,
                                        "iso_639_1": "ar",
                                        "list_type": "movie",
                                        "name": "Watched"
                                    },
                                    {
                                        "description": "Master List In Progress",
                                        "favorite_count": 0,
                                        "id": 51108,
                                        "item_count": 8856,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3003,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5688,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "description": "Peliculas en Pache Kodi",
                                        "favorite_count": 0,
                                        "id": 145800,
                                        "item_count": 1456,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "KODI Katalogo - Peliculas"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143813,
                                        "item_count": 4809,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MNR"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7070382,
                                        "item_count": 581,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies Watched 2021"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 88120,
                                        "item_count": 331,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Add Please"
                                    },
                                    {
                                        "description": "Movie List",
                                        "favorite_count": 0,
                                        "id": 143237,
                                        "item_count": 183,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "List"
                                    },
                                    {
                                        "description": "Cette liste regroupe l'ensemble des vidéos disponible sur la canal TMK - Chat.",
                                        "favorite_count": 0,
                                        "id": 139130,
                                        "item_count": 1092,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "TMK - Ciné 🍿"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 113444,
                                        "item_count": 392,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7887892,
                                        "item_count": 61,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "KIDS"
                                    },
                                    {
                                        "description": "All the Great Movies I've Watched So Far",
                                        "favorite_count": 0,
                                        "id": 7092449,
                                        "item_count": 462,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Watched Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7076646,
                                        "item_count": 531,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Peliculas Infantiles"
                                    },
                                    {
                                        "description": "Either got or want",
                                        "favorite_count": 0,
                                        "id": 126437,
                                        "item_count": 1922,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 748,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    }
                                ],
                                "total_pages": 7,
                                "total_results": 124
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 5.3,
                                    "link": "http://www.moviepilot.de/movies/die-addams-family-2",
                                    "voteCount": 18
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 13:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": null,
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:48",
                        "updateTimestamp": "2022-01-03 10:31:48",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    },
                    {
                        "statistics": null,
                        "requestId": "5544404",
                        "cmsId": "61ccb9a297599fbeaafe0995",
                        "eventTypeId": "222330",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Morticia und Gomez sind empört darüber, dass ihre Kinder erwachsen werden, Familienessen ausfallen lassen und die meiste Zeit vor dem Bildschirm verbringen. Um die familiären Bindungen zu stärken, beschließen sie, Wednesday, Pugsley, Onkel Fester und den Rest der Bande in ihr verwunschenes Wohnmobil zu zwängen und in einen letzten elenden Familienurlaub zu fahren. Zu ihnen gesellen sich ihre Cousine La Chose und einige verrückte neue Charaktere.",
                        "expireDate": null,
                        "duration": "93",
                        "name": "Die Addams Family 2",
                        "slug": "die-addams-family-2",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:48",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172172",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "639721m",
                        "externalData": {
                            "lastUpdated": 1638563184,
                            "id": 639721,
                            "cineamoId": "639721m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/wfrfxivLOBtGMC98tIr2LSOeKSe.jpg",
                            "genres": [
                                {
                                    "id": 16,
                                    "name": "Animation"
                                },
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 35,
                                    "name": "Komödie"
                                },
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                }
                            ],
                            "homepage": "https://www.upig.de/micro/die-addams-family-2",
                            "imdbId": "tt11125620",
                            "originalLanguage": "en",
                            "originalTitle": "The Addams Family 2",
                            "overview": "Familie Addams wird in neue Abenteuer verwickelt.",
                            "popularity": 505.06,
                            "posterPath": "/ld7YB9vBRp1GM1DT3KmFWSmtBPB.jpg",
                            "releaseDate": "2021-10-01",
                            "revenue": 105000000,
                            "runtime": 93,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Die Addams Family 2",
                            "video": false,
                            "voteAverage": 7.3,
                            "voteCount": 628,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wfrfxivLOBtGMC98tIr2LSOeKSe.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.522,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/qsQn1dMeMQIcYSkcLfO7XwNJ5um.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/O2V5XY9e3BGEGPiwCyp1q8bYfe.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gejjGGqnG4BcqU3vDOJwkfAyF7I.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/nBekrWDD4EACEcuSYEeehwzBXwu.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/kTOheVmqSBDIRGrQLv2SiSc89os.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wWvL6FmETpoyxEFtXOEnOR87ZBw.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/mjLmxkC3NLxvL25wvd1uXVTYAqa.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9e6wp707XMouPG939o2fHunXXJR.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3ZlO2MRWMXL11BwYkokQ15Ncf04.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ld7YB9vBRp1GM1DT3KmFWSmtBPB.jpg",
                                        "height": 2880,
                                        "iso6391": "en",
                                        "voteAverage": 5.522,
                                        "voteCount": 6,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/yNT64G3uwcMXtQxMG6d1EL4gQTa.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/xMxbC1aWlufCpkhY2WUsI4VH72i.jpg",
                                        "height": 750,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.673,
                                        "filePath": "/mvNbSdNwNyvFFzkWe4gBZg67Ybl.jpg",
                                        "height": 1565,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1054
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/mO3jKeJ31FqN9sMlbWMDs5r8cXO.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/xYLBgw7dHyEqmcrSk2Sq3asuSq5.jpg",
                                        "height": 2963,
                                        "iso6391": "en",
                                        "voteAverage": 5.138,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/a2MivYrMmtq9qYEtJ8wiZ3bPhBu.jpg",
                                        "height": 1600,
                                        "iso6391": "en",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1080
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fxHf5HfDksVf1AkDGZZr2iX90gj.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/oKb3JPREg8E1yOtQckz1iINcQxd.jpg",
                                        "height": 2880,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.284,
                                        "height": 1254,
                                        "iso_639_1": "en",
                                        "file_path": "/dvqiFkHsaKILej9EpPlXfk6FHwO.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 2864
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6138bebf7a1bd60042e3aaf7",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "6nLi9zeEPPc",
                                        "name": "Die Addams Family 2 - Offizieller Trailer deutsch/german HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-09-07 15:01:34 UTC"
                                    },
                                    {
                                        "id": "617bfe23a097dc009220e7e6",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "7wZ9n8XM3wc",
                                        "name": "Die Addams Family 2 - Teaser Trailer HD deutsch/german",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Teaser",
                                        "official": true,
                                        "publishedAt": "2020-10-08 16:11:27 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-27T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "7",
                                                "iso6391": "es",
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": "Universal"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "6",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-06T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-09T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DK",
                                        "releaseDates": [
                                            {
                                                "certification": "7",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": "K",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-19T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": "7",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-29T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-13T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "6",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-18T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-11T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-10-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "L",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-07T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-01T00:00:00.000Z",
                                                "type": 4,
                                                "note": "PVOD"
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-18T00:00:00.000Z",
                                                "type": 5,
                                                "note": "Blu-ray"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-10-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7055352,
                                        "item_count": 512,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "MavoMas"
                                    },
                                    {
                                        "description": "Meine Filme",
                                        "favorite_count": 0,
                                        "id": 7113987,
                                        "item_count": 512,
                                        "iso_639_1": "de",
                                        "list_type": "movie",
                                        "name": "Filme"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7072853,
                                        "item_count": 701,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Infantiles "
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 142797,
                                        "item_count": 963,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Infantiles"
                                    },
                                    {
                                        "description": "watched movies",
                                        "favorite_count": 0,
                                        "id": 44662,
                                        "item_count": 1829,
                                        "iso_639_1": "ar",
                                        "list_type": "movie",
                                        "name": "Watched"
                                    },
                                    {
                                        "description": "Master List In Progress",
                                        "favorite_count": 0,
                                        "id": 51108,
                                        "item_count": 8856,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3003,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5688,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "description": "Peliculas en Pache Kodi",
                                        "favorite_count": 0,
                                        "id": 145800,
                                        "item_count": 1456,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "KODI Katalogo - Peliculas"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143813,
                                        "item_count": 4809,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MNR"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7070382,
                                        "item_count": 581,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies Watched 2021"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 88120,
                                        "item_count": 331,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Add Please"
                                    },
                                    {
                                        "description": "Movie List",
                                        "favorite_count": 0,
                                        "id": 143237,
                                        "item_count": 183,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "List"
                                    },
                                    {
                                        "description": "Cette liste regroupe l'ensemble des vidéos disponible sur la canal TMK - Chat.",
                                        "favorite_count": 0,
                                        "id": 139130,
                                        "item_count": 1092,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "TMK - Ciné 🍿"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 113444,
                                        "item_count": 392,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7887892,
                                        "item_count": 61,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "KIDS"
                                    },
                                    {
                                        "description": "All the Great Movies I've Watched So Far",
                                        "favorite_count": 0,
                                        "id": 7092449,
                                        "item_count": 462,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Watched Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7076646,
                                        "item_count": 531,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Peliculas Infantiles"
                                    },
                                    {
                                        "description": "Either got or want",
                                        "favorite_count": 0,
                                        "id": 126437,
                                        "item_count": 1922,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 748,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    }
                                ],
                                "total_pages": 7,
                                "total_results": 124
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 5.3,
                                    "link": "http://www.moviepilot.de/movies/die-addams-family-2",
                                    "voteCount": 18
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 15:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": null,
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:48",
                        "updateTimestamp": "2022-01-03 10:31:48",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "244891",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "The tale of an extraordinary family, the Madrigals, who live hidden in the mountains of Colombia, in a magical house, in a vibrant town, in a wondrous, charmed place called an Encanto. The magic of the Encanto has blessed every child in the family with a unique gift from super strength to the power to heal—every child except one, Mirabel. But when she discovers that the magic surrounding the Encanto is in danger, Mirabel decides that she, the only ordinary Madrigal, might just be her exceptional family's last hope.",
                "duration": "99",
                "name": "Encanto",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "568124m",
                "externalData": {
                    "lastUpdated": 1636147163,
                    "id": 568124,
                    "cineamoId": "568124m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/g2djzUqA6mFplzC03gDk0WSyg99.jpg",
                    "genres": [
                        {
                            "id": 10751,
                            "name": "Familie"
                        },
                        {
                            "id": 10402,
                            "name": "Musik"
                        },
                        {
                            "id": 12,
                            "name": "Abenteuer"
                        },
                        {
                            "id": 16,
                            "name": "Animation"
                        },
                        {
                            "id": 14,
                            "name": "Fantasy"
                        }
                    ],
                    "homepage": "https://movies.disney.com/encanto",
                    "imdbId": "tt2953050",
                    "originalLanguage": "en",
                    "originalTitle": "Encanto",
                    "overview": null,
                    "popularity": 255.51,
                    "posterPath": "/gKV303VdLsR2PAEJMhSeZ2cxfQ0.jpg",
                    "releaseDate": "2021-11-24",
                    "revenue": 0,
                    "runtime": 99,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": null,
                    "title": "Encanto",
                    "video": false,
                    "voteAverage": 0,
                    "voteCount": 0,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/g2djzUqA6mFplzC03gDk0WSyg99.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/9xL8gUBarG4Bd7x48BjUpSJvvlw.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/5i3ghCXVLNhewrBjTesMgy4FHT7.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gKV303VdLsR2PAEJMhSeZ2cxfQ0.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.384,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/kgxbdPlQbhJTAQ3PZ9WAyrcvPxM.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hCMD9Sn1cV6FShnvrJJEgUNP3ib.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/3geGQ1xI6PmViS4glwlOhrcD4Wm.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.19,
                                "voteCount": 5,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/fBDnpHyW4VxjR754ou43w9IbxBc.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.19,
                                "voteCount": 5,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/7wkvW7aWG4DRnppXAFNcS44nd8r.jpg",
                                "height": 2963,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/eBcm62yBEDGHdMOGRrElBe4ZtXJ.jpg",
                                "height": 2963,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.687,
                                "filePath": "/y6APPAjzCNcHtH6CUDHnno231et.jpg",
                                "height": 939,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 645
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/wMil69k9N0tncavxToM9NAa2UjS.jpg",
                                "height": 2047,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1365
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/zhsoeR291Wm5nzgkO4sXUak3OiX.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 2.482,
                                "height": 819,
                                "iso_639_1": "en",
                                "file_path": "/8n9TqYfh8vevfaxOpu4LYN2U9t5.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 2033
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "6172c42a5c5cc8002659ced0",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "sQcdkDYlyHE",
                                "name": "ENCANTO – Offizieller Trailer (deutsch/german) | Disney HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-10-21 09:00:51 UTC"
                            },
                            {
                                "id": "60eda084706e5600741184fb",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "n5yJaAiDGGc",
                                "name": "ENCANTO – Teaser Trailer (deutsch/german) | Disney HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-07-08 13:25:00 UTC"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-03T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Los Angeles, CA"
                                    },
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-24T00:00:00.000Z",
                                        "type": 4,
                                        "note": "Disney+"
                                    }
                                ]
                            },
                            {
                                "iso31661": "FI",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": "U",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "UA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": "6+",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": "APTA",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AU",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 0,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 7107699,
                                "item_count": 43,
                                "iso_639_1": "pt",
                                "list_type": "movie",
                                "name": "ESTREIAS CINEMA"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112673,
                                "item_count": 12,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "MyMovieList"
                            },
                            {
                                "description": "Movie List",
                                "favorite_count": 0,
                                "id": 143237,
                                "item_count": 175,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112596,
                                "item_count": 73,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Pulse"
                            },
                            {
                                "favorite_count": 0,
                                "id": 108493,
                                "item_count": 403,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Want to watch"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7110963,
                                "item_count": 8,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Kids Movies"
                            },
                            {
                                "description": "Radarr Movie List",
                                "favorite_count": 0,
                                "id": 124591,
                                "item_count": 377,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Radarr Movie List"
                            },
                            {
                                "description": "Bob's Wishlist",
                                "favorite_count": 0,
                                "id": 7073644,
                                "item_count": 146,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "RJVMovieList"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7104177,
                                "item_count": 9,
                                "iso_639_1": "hu",
                                "list_type": "movie",
                                "name": "FG Mesék új"
                            },
                            {
                                "favorite_count": 0,
                                "id": 6817768,
                                "item_count": 56,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies to Watch"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7064028,
                                "item_count": 240,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Nye Film"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112294,
                                "item_count": 17,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Watchlist"
                            },
                            {
                                "favorite_count": 0,
                                "id": 133213,
                                "item_count": 329,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "My List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7073287,
                                "item_count": 96,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies Anticipated"
                            },
                            {
                                "favorite_count": 0,
                                "id": 89943,
                                "item_count": 729,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Clem2k"
                            },
                            {
                                "favorite_count": 0,
                                "id": 49590,
                                "item_count": 408,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies I Want"
                            },
                            {
                                "favorite_count": 0,
                                "id": 51490,
                                "item_count": 7,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Upcoming Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 33580,
                                "item_count": 764,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Wanted Mark II"
                            },
                            {
                                "favorite_count": 0,
                                "id": 112315,
                                "item_count": 220,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Bebos Plex"
                            },
                            {
                                "favorite_count": 0,
                                "id": 78253,
                                "item_count": 477,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Upcoming Movies"
                            }
                        ],
                        "total_pages": 3,
                        "total_results": 46
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 6.8,
                            "link": "http://www.moviepilot.de/movies/encanto",
                            "voteCount": 353
                        }
                    }
                },
                "ownContent": false,
                "eventImage": null,
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544393",
                        "cmsId": "61ccb9a297599fbeaafe0985",
                        "eventTypeId": "244891",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Die Geschichte einer außergewöhnlichen Familie, den Madrigalen, die versteckt in den Bergen Kolumbiens leben, in einem magischen Haus, in einer pulsierenden Stadt, an einem wundersamen, charmanten Ort namens Encanto. Die Magie des Amuletts hat jedes Kind in der Familie mit einem einzigartigen Geschenk von Superkraft bis hin zu heilender Kraft gesegnet – jedes Kind außer einem, Mirabel. Aber als sie entdeckt, dass die Magie um den Encanto in Gefahr ist, beschließt Mirabel, dass sie, die einzige gewöhnliche Madrigalin, möglicherweise die letzte Hoffnung ihrer außergewöhnlichen Familie ist.",
                        "expireDate": null,
                        "duration": "102",
                        "name": "Encanto",
                        "slug": "encanto",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:48",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172089",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "568124m",
                        "externalData": {
                            "lastUpdated": 1636147163,
                            "id": 568124,
                            "cineamoId": "568124m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/g2djzUqA6mFplzC03gDk0WSyg99.jpg",
                            "genres": [
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                },
                                {
                                    "id": 10402,
                                    "name": "Musik"
                                },
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 16,
                                    "name": "Animation"
                                },
                                {
                                    "id": 14,
                                    "name": "Fantasy"
                                }
                            ],
                            "homepage": "https://movies.disney.com/encanto",
                            "imdbId": "tt2953050",
                            "originalLanguage": "en",
                            "originalTitle": "Encanto",
                            "overview": null,
                            "popularity": 255.51,
                            "posterPath": "/gKV303VdLsR2PAEJMhSeZ2cxfQ0.jpg",
                            "releaseDate": "2021-11-24",
                            "revenue": 0,
                            "runtime": 99,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Encanto",
                            "video": false,
                            "voteAverage": 0,
                            "voteCount": 0,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/g2djzUqA6mFplzC03gDk0WSyg99.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9xL8gUBarG4Bd7x48BjUpSJvvlw.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5i3ghCXVLNhewrBjTesMgy4FHT7.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gKV303VdLsR2PAEJMhSeZ2cxfQ0.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.384,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/kgxbdPlQbhJTAQ3PZ9WAyrcvPxM.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hCMD9Sn1cV6FShnvrJJEgUNP3ib.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/3geGQ1xI6PmViS4glwlOhrcD4Wm.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/fBDnpHyW4VxjR754ou43w9IbxBc.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/7wkvW7aWG4DRnppXAFNcS44nd8r.jpg",
                                        "height": 2963,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/eBcm62yBEDGHdMOGRrElBe4ZtXJ.jpg",
                                        "height": 2963,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.687,
                                        "filePath": "/y6APPAjzCNcHtH6CUDHnno231et.jpg",
                                        "height": 939,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 645
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wMil69k9N0tncavxToM9NAa2UjS.jpg",
                                        "height": 2047,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1365
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/zhsoeR291Wm5nzgkO4sXUak3OiX.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.482,
                                        "height": 819,
                                        "iso_639_1": "en",
                                        "file_path": "/8n9TqYfh8vevfaxOpu4LYN2U9t5.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 2033
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6172c42a5c5cc8002659ced0",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "sQcdkDYlyHE",
                                        "name": "ENCANTO – Offizieller Trailer (deutsch/german) | Disney HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-10-21 09:00:51 UTC"
                                    },
                                    {
                                        "id": "60eda084706e5600741184fb",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "n5yJaAiDGGc",
                                        "name": "ENCANTO – Teaser Trailer (deutsch/german) | Disney HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-07-08 13:25:00 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-03T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Los Angeles, CA"
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-24T00:00:00.000Z",
                                                "type": 4,
                                                "note": "Disney+"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": "6+",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "APTA",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7107699,
                                        "item_count": 43,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "ESTREIAS CINEMA"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112673,
                                        "item_count": 12,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MyMovieList"
                                    },
                                    {
                                        "description": "Movie List",
                                        "favorite_count": 0,
                                        "id": 143237,
                                        "item_count": 175,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112596,
                                        "item_count": 73,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Pulse"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 108493,
                                        "item_count": 403,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Want to watch"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7110963,
                                        "item_count": 8,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Kids Movies"
                                    },
                                    {
                                        "description": "Radarr Movie List",
                                        "favorite_count": 0,
                                        "id": 124591,
                                        "item_count": 377,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Radarr Movie List"
                                    },
                                    {
                                        "description": "Bob's Wishlist",
                                        "favorite_count": 0,
                                        "id": 7073644,
                                        "item_count": 146,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "RJVMovieList"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7104177,
                                        "item_count": 9,
                                        "iso_639_1": "hu",
                                        "list_type": "movie",
                                        "name": "FG Mesék új"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 6817768,
                                        "item_count": 56,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies to Watch"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7064028,
                                        "item_count": 240,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Nye Film"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112294,
                                        "item_count": 17,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Watchlist"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 133213,
                                        "item_count": 329,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7073287,
                                        "item_count": 96,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies Anticipated"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 729,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 49590,
                                        "item_count": 408,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies I Want"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 51490,
                                        "item_count": 7,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 33580,
                                        "item_count": 764,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Wanted Mark II"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 112315,
                                        "item_count": 220,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Bebos Plex"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 78253,
                                        "item_count": 477,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Movies"
                                    }
                                ],
                                "total_pages": 3,
                                "total_results": 46
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 6.8,
                                    "link": "http://www.moviepilot.de/movies/encanto",
                                    "voteCount": 353
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 14:45:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/zac6fcnaohmppfpopngtsmtdnlk.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:48",
                        "updateTimestamp": "2022-01-03 10:31:48",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "249127",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "Einst der Sitz der florierenden Umbrella Corporation, ist Raccoon City heute eine Geisterstadt. Doch in den unterirdischen Laboren lauert das Böse.",
                "duration": "107",
                "name": "Resident Evil: Welcome To Raccoon City",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "460458m",
                "externalData": {
                    "lastUpdated": 1637536174,
                    "id": 460458,
                    "cineamoId": "460458m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/zaY1FoAc39lHWaA6jUF93t95D7O.jpg",
                    "genres": [
                        {
                            "id": 27,
                            "name": "Horror"
                        },
                        {
                            "id": 28,
                            "name": "Action"
                        },
                        {
                            "id": 878,
                            "name": "Science Fiction"
                        }
                    ],
                    "homepage": "https://www.residentevil.movie",
                    "imdbId": "tt6920084",
                    "originalLanguage": "en",
                    "originalTitle": "Resident Evil: Welcome to Raccoon City",
                    "overview": "Einst der Sitz der florierenden Umbrella Corporation, ist Raccoon City heute eine Geisterstadt. Doch in den unterirdischen Laboren lauert das Böse.",
                    "popularity": 123.583,
                    "posterPath": "/bArhvjRHl535XMaSh9VjInF2mSZ.jpg",
                    "releaseDate": "2021-11-24",
                    "revenue": 0,
                    "runtime": 107,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": null,
                    "title": "Resident Evil: Welcome to Raccoon City",
                    "video": false,
                    "voteAverage": 6.9,
                    "voteCount": 11,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/zaY1FoAc39lHWaA6jUF93t95D7O.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.384,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.78,
                                "filePath": "/f1uY92qhYLibwUH2bZ5tTQdPAEo.jpg",
                                "height": 758,
                                "iso6391": null,
                                "voteAverage": 5.384,
                                "voteCount": 2,
                                "width": 1349
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/oCtI18YxUtIWVQ4ymtP43hJPPI5.jpg",
                                "height": 1406,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2500
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/yLpDjBdGpHlL57NNxRP7TGcYHr0.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/1lXMdnvuAkYYBURg0S5q7Ty2piF.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.779,
                                "filePath": "/dGS1iiF3AejfIgYiQHJr7CCtptN.jpg",
                                "height": 2024,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 3600
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/jl910095MqJiBbWZG3wu5GeF6mF.jpg",
                                "height": 1152,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 2048
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/mjFBnNb9tT1yFHvX9YBvSoJM2BX.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/mh9j4wVrq2uiuAv9MFisQdQZEeX.jpg",
                                "height": 1688,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3000
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/e9uZK93FIqUXbr2Xm6YaoFzKjoJ.jpg",
                                "height": 1688,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3000
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/k44TTUCbonaBehkhqqkHZtQWaZW.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.674,
                                "filePath": "/bArhvjRHl535XMaSh9VjInF2mSZ.jpg",
                                "height": 2000,
                                "iso6391": "en",
                                "voteAverage": 5.588,
                                "voteCount": 5,
                                "width": 1349
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gIELpX14PEU94ClrexFetVojO35.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.392,
                                "voteCount": 8,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/7uRbWOXxpWDMtnsd2PF3clu65jc.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.674,
                                "filePath": "/wQO1W9iwGrSKuUlktfjxb5jvr8j.jpg",
                                "height": 2000,
                                "iso6391": "en",
                                "voteAverage": 5.252,
                                "voteCount": 4,
                                "width": 1349
                            },
                            {
                                "aspectRatio": 0.674,
                                "filePath": "/2jGBMO0nJIhywLl2liXid3Qb940.jpg",
                                "height": 2048,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1381
                            },
                            {
                                "aspectRatio": 0.674,
                                "filePath": "/vXPNqoOtKksGhVgSXNHisfcYcVz.jpg",
                                "height": 2000,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1349
                            },
                            {
                                "aspectRatio": 0.666,
                                "filePath": "/jpfyRRj30VxoelY6T8rQfSES6JJ.jpg",
                                "height": 2000,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1333
                            },
                            {
                                "aspectRatio": 0.701,
                                "filePath": "/2NbuLNZabhaNjbaBDklZg98TxmG.jpg",
                                "height": 1426,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/uexqIcbpW11GaOqPW5PINStalR8.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.696,
                                "filePath": "/AiGPAYSafzMS17ybQ05vGMaR5kS.jpg",
                                "height": 2688,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1871
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/IJC9Jf7S2hdn1RbPBCsWZgkFNO.jpg",
                                "height": 1620,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 1080
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/sR3iV0Jt080jgvPBtJhs3Tta1y9.jpg",
                                "height": 1800,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1200
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/AoW8K8AFiQG0L5ApGCgJqkdqRff.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/2Vwcprn31t6z1hcRD4cH35Xo1UU.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/9GtLiRyz0wXOER7NxCIJMzxXjhP.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/9dAewgTOITzu4D0QCSLs2N9Pnoa.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "61886008e7414600439249d9",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "f5vTDuGNyn8",
                                "name": "RESIDENT EVIL: WELCOME TO RACCOON CITY | Offizieller Trailer #2",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-11-04 15:05:56 UTC"
                            },
                            {
                                "id": "6166d099a802360043eb5ef1",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "HNWdtZzSnyE",
                                "name": "RESIDENT EVIL: WELCOME TO RACCOON CITY - Offizieller Trailer",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-10-07 15:30:06 UTC"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": "18+",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SK",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "UA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": "18A",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": "R",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-03T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": "de",
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SG",
                                "releaseDates": [
                                    {
                                        "certification": "NC16",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "JP",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": "ja",
                                        "releaseDate": "2022-01-28T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IN",
                                "releaseDates": [
                                    {
                                        "certification": "A",
                                        "iso6391": "en",
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": "Also in Hindi, Tamil and Telugu."
                                    }
                                ]
                            },
                            {
                                "iso31661": "CH",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AU",
                                "releaseDates": [
                                    {
                                        "certification": "MA15+",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": "Sony"
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 0,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 7111190,
                                "item_count": 70,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Radarr"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112596,
                                "item_count": 39,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Pulse"
                            },
                            {
                                "favorite_count": 0,
                                "id": 108493,
                                "item_count": 408,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Want to watch"
                            },
                            {
                                "description": "Wishlist",
                                "favorite_count": 0,
                                "id": 7082510,
                                "item_count": 23,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Zaedum List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 51490,
                                "item_count": 5,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Upcoming Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 78253,
                                "item_count": 483,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Upcoming Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7082071,
                                "item_count": 84,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Films Populaire"
                            },
                            {
                                "favorite_count": 0,
                                "id": 86411,
                                "item_count": 390,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Film"
                            },
                            {
                                "favorite_count": 0,
                                "id": 89943,
                                "item_count": 735,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Clem2k"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112735,
                                "item_count": 10,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Plexma"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7066321,
                                "item_count": 62,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "MyList"
                            },
                            {
                                "favorite_count": 0,
                                "id": 26933,
                                "item_count": 12,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "description": "Movie List",
                                "favorite_count": 0,
                                "id": 143237,
                                "item_count": 177,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 43696,
                                "item_count": 234,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Watchlist"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7113154,
                                "item_count": 7,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Radarr"
                            },
                            {
                                "favorite_count": 0,
                                "id": 87356,
                                "item_count": 188,
                                "iso_639_1": "nl",
                                "list_type": "movie",
                                "name": "Watch"
                            },
                            {
                                "description": "Action and Horror ",
                                "favorite_count": 0,
                                "id": 7113389,
                                "item_count": 3,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Ajax"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112031,
                                "item_count": 223,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Radarr Base List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 143764,
                                "item_count": 199,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "film"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7064028,
                                "item_count": 246,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Nye Film"
                            }
                        ],
                        "total_pages": 3,
                        "total_results": 60
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 5.2,
                            "link": "http://www.moviepilot.de/movies/resident-evil-reboot",
                            "voteCount": 174
                        }
                    }
                },
                "ownContent": false,
                "eventImage": null,
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544350",
                        "cmsId": "61ccb99f97599fbeaafe0961",
                        "eventTypeId": "249127",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "1998, Raccoon City: Einst die florierende Heimat des Pharmariesen Umbrella, hat sich die Stadt im Mittleren Westen stark verändert. Der Exodus des Unternehmens hat den Ort regelrecht in den Tod gezwungen und in eine Geisterstadt verwandelt. Die größte Gefahr steht Raccoon City allerdings noch bevor, breitet sich doch eine merkwürdige Seuche aus, die die Bewohnnerinnen und Bewohner in aggressive Monster verwandelt. Die junge Claire Redfield (Kaya Scodelario) versucht, den seltsamen Vorkommnissen auf den Grund zu gehen und vor allem Umbrellas Rolle darin aufzudecken. Zur selben Zeit untersucht ihr Polizisten-Bruder Chris (Robbie Amell) in einem alten Anwesen vor den Toren der Stadt mit seiner Einheit das Verschwinden zweier Kollegen, das ebenfalls mit den Schrecken in Raccoon City verbunden ist... Reboot der „Resident Evil“-Verfilmungen mit komplett neuem Cast. Grundlage bilden die ersten beiden Videospiele aus den Neunzigern.",
                        "expireDate": null,
                        "duration": "107",
                        "name": "Resident Evil: Welcome To Raccoon City",
                        "slug": "resident-evil-welcome-to-raccoon-city",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:45",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172030",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "460458m",
                        "externalData": {
                            "lastUpdated": 1637536174,
                            "id": 460458,
                            "cineamoId": "460458m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/zaY1FoAc39lHWaA6jUF93t95D7O.jpg",
                            "genres": [
                                {
                                    "id": 27,
                                    "name": "Horror"
                                },
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 878,
                                    "name": "Science Fiction"
                                }
                            ],
                            "homepage": "https://www.residentevil.movie",
                            "imdbId": "tt6920084",
                            "originalLanguage": "en",
                            "originalTitle": "Resident Evil: Welcome to Raccoon City",
                            "overview": "Einst der Sitz der florierenden Umbrella Corporation, ist Raccoon City heute eine Geisterstadt. Doch in den unterirdischen Laboren lauert das Böse.",
                            "popularity": 123.583,
                            "posterPath": "/bArhvjRHl535XMaSh9VjInF2mSZ.jpg",
                            "releaseDate": "2021-11-24",
                            "revenue": 0,
                            "runtime": 107,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": null,
                            "title": "Resident Evil: Welcome to Raccoon City",
                            "video": false,
                            "voteAverage": 6.9,
                            "voteCount": 11,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/zaY1FoAc39lHWaA6jUF93t95D7O.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.384,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.78,
                                        "filePath": "/f1uY92qhYLibwUH2bZ5tTQdPAEo.jpg",
                                        "height": 758,
                                        "iso6391": null,
                                        "voteAverage": 5.384,
                                        "voteCount": 2,
                                        "width": 1349
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/oCtI18YxUtIWVQ4ymtP43hJPPI5.jpg",
                                        "height": 1406,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2500
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/yLpDjBdGpHlL57NNxRP7TGcYHr0.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1lXMdnvuAkYYBURg0S5q7Ty2piF.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.779,
                                        "filePath": "/dGS1iiF3AejfIgYiQHJr7CCtptN.jpg",
                                        "height": 2024,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3600
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/jl910095MqJiBbWZG3wu5GeF6mF.jpg",
                                        "height": 1152,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 2048
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/mjFBnNb9tT1yFHvX9YBvSoJM2BX.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/mh9j4wVrq2uiuAv9MFisQdQZEeX.jpg",
                                        "height": 1688,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3000
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/e9uZK93FIqUXbr2Xm6YaoFzKjoJ.jpg",
                                        "height": 1688,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3000
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/k44TTUCbonaBehkhqqkHZtQWaZW.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.674,
                                        "filePath": "/bArhvjRHl535XMaSh9VjInF2mSZ.jpg",
                                        "height": 2000,
                                        "iso6391": "en",
                                        "voteAverage": 5.588,
                                        "voteCount": 5,
                                        "width": 1349
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gIELpX14PEU94ClrexFetVojO35.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.392,
                                        "voteCount": 8,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/7uRbWOXxpWDMtnsd2PF3clu65jc.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.674,
                                        "filePath": "/wQO1W9iwGrSKuUlktfjxb5jvr8j.jpg",
                                        "height": 2000,
                                        "iso6391": "en",
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 1349
                                    },
                                    {
                                        "aspectRatio": 0.674,
                                        "filePath": "/2jGBMO0nJIhywLl2liXid3Qb940.jpg",
                                        "height": 2048,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1381
                                    },
                                    {
                                        "aspectRatio": 0.674,
                                        "filePath": "/vXPNqoOtKksGhVgSXNHisfcYcVz.jpg",
                                        "height": 2000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1349
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/jpfyRRj30VxoelY6T8rQfSES6JJ.jpg",
                                        "height": 2000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1333
                                    },
                                    {
                                        "aspectRatio": 0.701,
                                        "filePath": "/2NbuLNZabhaNjbaBDklZg98TxmG.jpg",
                                        "height": 1426,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/uexqIcbpW11GaOqPW5PINStalR8.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.696,
                                        "filePath": "/AiGPAYSafzMS17ybQ05vGMaR5kS.jpg",
                                        "height": 2688,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1871
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/IJC9Jf7S2hdn1RbPBCsWZgkFNO.jpg",
                                        "height": 1620,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 1080
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/sR3iV0Jt080jgvPBtJhs3Tta1y9.jpg",
                                        "height": 1800,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1200
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/AoW8K8AFiQG0L5ApGCgJqkdqRff.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/2Vwcprn31t6z1hcRD4cH35Xo1UU.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/9GtLiRyz0wXOER7NxCIJMzxXjhP.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/9dAewgTOITzu4D0QCSLs2N9Pnoa.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "61886008e7414600439249d9",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "f5vTDuGNyn8",
                                        "name": "RESIDENT EVIL: WELCOME TO RACCOON CITY | Offizieller Trailer #2",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-11-04 15:05:56 UTC"
                                    },
                                    {
                                        "id": "6166d099a802360043eb5ef1",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "HNWdtZzSnyE",
                                        "name": "RESIDENT EVIL: WELCOME TO RACCOON CITY - Offizieller Trailer",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-10-07 15:30:06 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": "18+",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": "18A",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "R",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-03T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": "de",
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "NC16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "JP",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": "ja",
                                                "releaseDate": "2022-01-28T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IN",
                                        "releaseDates": [
                                            {
                                                "certification": "A",
                                                "iso6391": "en",
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": "Also in Hindi, Tamil and Telugu."
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "MA15+",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": "Sony"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7111190,
                                        "item_count": 70,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Radarr"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112596,
                                        "item_count": 39,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Pulse"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 108493,
                                        "item_count": 408,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Want to watch"
                                    },
                                    {
                                        "description": "Wishlist",
                                        "favorite_count": 0,
                                        "id": 7082510,
                                        "item_count": 23,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Zaedum List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 51490,
                                        "item_count": 5,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 78253,
                                        "item_count": 483,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7082071,
                                        "item_count": 84,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Films Populaire"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 86411,
                                        "item_count": 390,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Film"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 735,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112735,
                                        "item_count": 10,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Plexma"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7066321,
                                        "item_count": 62,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MyList"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 26933,
                                        "item_count": 12,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "description": "Movie List",
                                        "favorite_count": 0,
                                        "id": 143237,
                                        "item_count": 177,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 43696,
                                        "item_count": 234,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Watchlist"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7113154,
                                        "item_count": 7,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Radarr"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 87356,
                                        "item_count": 188,
                                        "iso_639_1": "nl",
                                        "list_type": "movie",
                                        "name": "Watch"
                                    },
                                    {
                                        "description": "Action and Horror ",
                                        "favorite_count": 0,
                                        "id": 7113389,
                                        "item_count": 3,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Ajax"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112031,
                                        "item_count": 223,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Radarr Base List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143764,
                                        "item_count": 199,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "film"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7064028,
                                        "item_count": 246,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Nye Film"
                                    }
                                ],
                                "total_pages": 3,
                                "total_results": 60
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 5.2,
                                    "link": "http://www.moviepilot.de/movies/resident-evil-reboot",
                                    "voteCount": 174
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 20:15:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/barhvjrhl535xmash9vjinf2msz.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:45",
                        "updateTimestamp": "2022-01-03 10:31:45",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "253394",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "Während Emily versucht, sich zu Hause und in der Schule anzupassen, entdeckt sie einen kleinen roten Welpen, der ihr bester Freund werden soll. Als Clifford auf magische Weise einen gewaltigen Wachstumsschub erfährt, zu einem riesigen Hund wird und die Aufmerksamkeit eines Genetikunternehmens auf sich zieht, müssen Emily und ihr Onkel Casey gegen die Mächte der Gier ankämpfen, während sie sich auf die Flucht durch New York City begeben. Auf ihrem Weg beeinflusst Clifford das Leben aller Menschen um ihn herum und lehrt Emily und ihren Onkel die wahre Bedeutung von Akzeptanz und bedingungsloser Liebe.",
                "duration": "97",
                "name": "Clifford der große rote Hund",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "585245m",
                "externalData": {
                    "lastUpdated": 1635372717,
                    "id": 585245,
                    "cineamoId": "585245m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/lLWmtauksCSH09SduR5iQkTbOic.jpg",
                    "genres": [
                        {
                            "id": 10751,
                            "name": "Familie"
                        },
                        {
                            "id": 16,
                            "name": "Animation"
                        },
                        {
                            "id": 35,
                            "name": "Komödie"
                        }
                    ],
                    "homepage": null,
                    "imdbId": "tt2397461",
                    "originalLanguage": "en",
                    "originalTitle": "Clifford the Big Red Dog",
                    "overview": null,
                    "popularity": 43.783,
                    "posterPath": "/lz1SZ43tN30GoEW3uANx5cWtZSQ.jpg",
                    "releaseDate": "2021-11-10",
                    "revenue": 0,
                    "runtime": 97,
                    "firstAirDate": null,
                    "status": "Post Production",
                    "tagline": null,
                    "title": "Clifford - Der große rote Hund",
                    "video": false,
                    "voteAverage": 0,
                    "voteCount": 0,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/lLWmtauksCSH09SduR5iQkTbOic.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/fwLQZ1xlKkt53tszUYq7HoIZtLa.jpg",
                                "height": 720,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/6iu9u0jE6WJKVxMkSaVeVSXyywn.jpg",
                                "height": 1798,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3196
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/zBkHCpLmHjW2uVURs5uZkaVmgKR.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/lz1SZ43tN30GoEW3uANx5cWtZSQ.jpg",
                                "height": 2850,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 1900
                            },
                            {
                                "aspectRatio": 0.666,
                                "filePath": "/qb7MUgtv2HlJgDg5BoMVlDrNsTZ.jpg",
                                "height": 2884,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1922
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/nBB3Ise0uJEoXP22uM07RQLQApA.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 2.696,
                                "height": 634,
                                "iso_639_1": "en",
                                "file_path": "/1bb6QvKorAV86gxchO1gfMLPSZB.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 1709
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "6172c462894ed6008e42a49e",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "yPdcCkknsJI",
                                "name": "CLIFFORD DER GROSSE ROTE HUND | OFFIZIELLER TRAILER 2 | Paramount Pictures Germany",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-10-21 13:02:39 UTC"
                            },
                            {
                                "id": "611fab950f2fbd006194406e",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "3GXfzl8tIcY",
                                "name": "CLIFFORD - DER GROSSE ROTE HUND | OFFIZIELLER TRAILER | Paramount Pictures Germany",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-06-29 13:13:04 UTC"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-03T00:00:00.000Z",
                                        "type": 3,
                                        "note": "Paramount"
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-10T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-10T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-10T00:00:00.000Z",
                                        "type": 4,
                                        "note": "Paramount+"
                                    }
                                ]
                            },
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-10T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 0,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 112315,
                                "item_count": 220,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Bebos Plex"
                            },
                            {
                                "favorite_count": 0,
                                "id": 78253,
                                "item_count": 477,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Upcoming Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 89943,
                                "item_count": 725,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Clem2k"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7060771,
                                "item_count": 14,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Custom Watchlist"
                            },
                            {
                                "favorite_count": 0,
                                "id": 49590,
                                "item_count": 399,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies I Want"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7055885,
                                "item_count": 49,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "VioDB"
                            },
                            {
                                "description": "TMDbListImport",
                                "favorite_count": 0,
                                "id": 7072733,
                                "item_count": 92,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "TMDbListImport"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7070536,
                                "item_count": 40,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "sync"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7061804,
                                "item_count": 29,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Cinema"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7054333,
                                "item_count": 38,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "2020s"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7107699,
                                "item_count": 20,
                                "iso_639_1": "pt",
                                "list_type": "movie",
                                "name": "ESTREIAS CINEMA"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7109690,
                                "item_count": 77,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Upcoming Releases"
                            },
                            {
                                "description": "To Be Added Animation List",
                                "favorite_count": 0,
                                "id": 144471,
                                "item_count": 77,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Kids List To Be Added"
                            },
                            {
                                "favorite_count": 0,
                                "id": 68726,
                                "item_count": 89,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 143787,
                                "item_count": 35,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Want to See"
                            },
                            {
                                "favorite_count": 0,
                                "id": 127516,
                                "item_count": 853,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "radarr"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7103222,
                                "item_count": 29,
                                "iso_639_1": "zh",
                                "list_type": "movie",
                                "name": "英语启蒙动画片"
                            },
                            {
                                "favorite_count": 0,
                                "id": 63094,
                                "item_count": 35,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Films"
                            },
                            {
                                "favorite_count": 0,
                                "id": 6695831,
                                "item_count": 1235,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Top Titles from Canada - IMDb"
                            },
                            {
                                "description": "2020 MOVIES",
                                "favorite_count": 0,
                                "id": 128367,
                                "item_count": 88,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "2020"
                            }
                        ],
                        "total_pages": 2,
                        "total_results": 23
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 6.1,
                            "link": "http://www.moviepilot.de/movies/clifford-the-big-red-dog",
                            "voteCount": 8
                        }
                    }
                },
                "ownContent": false,
                "eventImage": "https://image.tmdb.org/t/p/original/rpnjjzoecqgvvfxf1dyqwg8y4in.jpg",
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544314",
                        "cmsId": "61ccb99f97599fbeaafe0959",
                        "eventTypeId": "253394",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Als die quirlige Emily Elizabeth (Darby Camp) von dem geheimnisvollen Mr. Bridwell (John Cleese) einen kleinen roten Welpen geschenkt bekommen hat, hätte sie es nie für möglich gehalten, dass sie eines Tages in ihrem New Yorker Appartement neben einem riesigen, drei Meter großen Hund aufwachen würde. Was kann sie nur tun, um ihren geliebten Clifford bei sich zu behalten, platzt doch die „Hütte“ aus allen Nähten? Während ihre alleinerziehende Mutter (Sienna Guillory) geschäftlich unterwegs ist, begeben sich Emily und ihr lustiger, aber ziemlich impulsiver Onkel Casey (Jack Whitehall) gemeinsam auf eine fantastische Reise, die sie die aufregendsten und coolsten Abenteuer erleben lässt. Gemeinsam erobern die drei New York, und Clifford zeigt der Welt, was es heißt, ein großes Herz zu haben!",
                        "expireDate": null,
                        "duration": "97",
                        "name": "Clifford der große rote Hund",
                        "slug": "clifford-der-grosse-rote-hund",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:43",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/171991",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "585245m",
                        "externalData": {
                            "lastUpdated": 1635372717,
                            "id": 585245,
                            "cineamoId": "585245m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/lLWmtauksCSH09SduR5iQkTbOic.jpg",
                            "genres": [
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                },
                                {
                                    "id": 16,
                                    "name": "Animation"
                                },
                                {
                                    "id": 35,
                                    "name": "Komödie"
                                }
                            ],
                            "homepage": null,
                            "imdbId": "tt2397461",
                            "originalLanguage": "en",
                            "originalTitle": "Clifford the Big Red Dog",
                            "overview": null,
                            "popularity": 43.783,
                            "posterPath": "/lz1SZ43tN30GoEW3uANx5cWtZSQ.jpg",
                            "releaseDate": "2021-11-10",
                            "revenue": 0,
                            "runtime": 97,
                            "firstAirDate": null,
                            "status": "Post Production",
                            "tagline": null,
                            "title": "Clifford - Der große rote Hund",
                            "video": false,
                            "voteAverage": 0,
                            "voteCount": 0,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lLWmtauksCSH09SduR5iQkTbOic.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/fwLQZ1xlKkt53tszUYq7HoIZtLa.jpg",
                                        "height": 720,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/6iu9u0jE6WJKVxMkSaVeVSXyywn.jpg",
                                        "height": 1798,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3196
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/zBkHCpLmHjW2uVURs5uZkaVmgKR.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/lz1SZ43tN30GoEW3uANx5cWtZSQ.jpg",
                                        "height": 2850,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1900
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/qb7MUgtv2HlJgDg5BoMVlDrNsTZ.jpg",
                                        "height": 2884,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1922
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/nBB3Ise0uJEoXP22uM07RQLQApA.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.696,
                                        "height": 634,
                                        "iso_639_1": "en",
                                        "file_path": "/1bb6QvKorAV86gxchO1gfMLPSZB.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 1709
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6172c462894ed6008e42a49e",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "yPdcCkknsJI",
                                        "name": "CLIFFORD DER GROSSE ROTE HUND | OFFIZIELLER TRAILER 2 | Paramount Pictures Germany",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-10-21 13:02:39 UTC"
                                    },
                                    {
                                        "id": "611fab950f2fbd006194406e",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "3GXfzl8tIcY",
                                        "name": "CLIFFORD - DER GROSSE ROTE HUND | OFFIZIELLER TRAILER | Paramount Pictures Germany",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-06-29 13:13:04 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-03T00:00:00.000Z",
                                                "type": 3,
                                                "note": "Paramount"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 4,
                                                "note": "Paramount+"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 112315,
                                        "item_count": 220,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Bebos Plex"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 78253,
                                        "item_count": 477,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 725,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7060771,
                                        "item_count": 14,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Custom Watchlist"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 49590,
                                        "item_count": 399,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies I Want"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7055885,
                                        "item_count": 49,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "VioDB"
                                    },
                                    {
                                        "description": "TMDbListImport",
                                        "favorite_count": 0,
                                        "id": 7072733,
                                        "item_count": 92,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDbListImport"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7070536,
                                        "item_count": 40,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "sync"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7061804,
                                        "item_count": 29,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Cinema"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7054333,
                                        "item_count": 38,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "2020s"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7107699,
                                        "item_count": 20,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "ESTREIAS CINEMA"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109690,
                                        "item_count": 77,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Releases"
                                    },
                                    {
                                        "description": "To Be Added Animation List",
                                        "favorite_count": 0,
                                        "id": 144471,
                                        "item_count": 77,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Kids List To Be Added"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 68726,
                                        "item_count": 89,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143787,
                                        "item_count": 35,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Want to See"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 127516,
                                        "item_count": 853,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "radarr"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7103222,
                                        "item_count": 29,
                                        "iso_639_1": "zh",
                                        "list_type": "movie",
                                        "name": "英语启蒙动画片"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 63094,
                                        "item_count": 35,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Films"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 6695831,
                                        "item_count": 1235,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Top Titles from Canada - IMDb"
                                    },
                                    {
                                        "description": "2020 MOVIES",
                                        "favorite_count": 0,
                                        "id": 128367,
                                        "item_count": 88,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "2020"
                                    }
                                ],
                                "total_pages": 2,
                                "total_results": 23
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 6.1,
                                    "link": "http://www.moviepilot.de/movies/clifford-the-big-red-dog",
                                    "voteCount": 8
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 13:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/rpnjjzoecqgvvfxf1dyqwg8y4in.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:43",
                        "updateTimestamp": "2022-01-03 10:31:43",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    },
                    {
                        "statistics": null,
                        "requestId": "5544315",
                        "cmsId": "61ccb99f97599fbeaafe095a",
                        "eventTypeId": "253394",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Als die quirlige Emily Elizabeth (Darby Camp) von dem geheimnisvollen Mr. Bridwell (John Cleese) einen kleinen roten Welpen geschenkt bekommen hat, hätte sie es nie für möglich gehalten, dass sie eines Tages in ihrem New Yorker Appartement neben einem riesigen, drei Meter großen Hund aufwachen würde. Was kann sie nur tun, um ihren geliebten Clifford bei sich zu behalten, platzt doch die „Hütte“ aus allen Nähten? Während ihre alleinerziehende Mutter (Sienna Guillory) geschäftlich unterwegs ist, begeben sich Emily und ihr lustiger, aber ziemlich impulsiver Onkel Casey (Jack Whitehall) gemeinsam auf eine fantastische Reise, die sie die aufregendsten und coolsten Abenteuer erleben lässt. Gemeinsam erobern die drei New York, und Clifford zeigt der Welt, was es heißt, ein großes Herz zu haben!",
                        "expireDate": null,
                        "duration": "97",
                        "name": "Clifford der große rote Hund",
                        "slug": "clifford-der-grosse-rote-hund",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:43",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/171992",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "585245m",
                        "externalData": {
                            "lastUpdated": 1635372717,
                            "id": 585245,
                            "cineamoId": "585245m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/lLWmtauksCSH09SduR5iQkTbOic.jpg",
                            "genres": [
                                {
                                    "id": 10751,
                                    "name": "Familie"
                                },
                                {
                                    "id": 16,
                                    "name": "Animation"
                                },
                                {
                                    "id": 35,
                                    "name": "Komödie"
                                }
                            ],
                            "homepage": null,
                            "imdbId": "tt2397461",
                            "originalLanguage": "en",
                            "originalTitle": "Clifford the Big Red Dog",
                            "overview": null,
                            "popularity": 43.783,
                            "posterPath": "/lz1SZ43tN30GoEW3uANx5cWtZSQ.jpg",
                            "releaseDate": "2021-11-10",
                            "revenue": 0,
                            "runtime": 97,
                            "firstAirDate": null,
                            "status": "Post Production",
                            "tagline": null,
                            "title": "Clifford - Der große rote Hund",
                            "video": false,
                            "voteAverage": 0,
                            "voteCount": 0,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lLWmtauksCSH09SduR5iQkTbOic.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/fwLQZ1xlKkt53tszUYq7HoIZtLa.jpg",
                                        "height": 720,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/6iu9u0jE6WJKVxMkSaVeVSXyywn.jpg",
                                        "height": 1798,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3196
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/zBkHCpLmHjW2uVURs5uZkaVmgKR.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/lz1SZ43tN30GoEW3uANx5cWtZSQ.jpg",
                                        "height": 2850,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1900
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/qb7MUgtv2HlJgDg5BoMVlDrNsTZ.jpg",
                                        "height": 2884,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1922
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/nBB3Ise0uJEoXP22uM07RQLQApA.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.696,
                                        "height": 634,
                                        "iso_639_1": "en",
                                        "file_path": "/1bb6QvKorAV86gxchO1gfMLPSZB.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 1709
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6172c462894ed6008e42a49e",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "yPdcCkknsJI",
                                        "name": "CLIFFORD DER GROSSE ROTE HUND | OFFIZIELLER TRAILER 2 | Paramount Pictures Germany",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-10-21 13:02:39 UTC"
                                    },
                                    {
                                        "id": "611fab950f2fbd006194406e",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "3GXfzl8tIcY",
                                        "name": "CLIFFORD - DER GROSSE ROTE HUND | OFFIZIELLER TRAILER | Paramount Pictures Germany",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-06-29 13:13:04 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-03T00:00:00.000Z",
                                                "type": 3,
                                                "note": "Paramount"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-10T00:00:00.000Z",
                                                "type": 4,
                                                "note": "Paramount+"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-10T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 0,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 112315,
                                        "item_count": 220,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Bebos Plex"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 78253,
                                        "item_count": 477,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 725,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7060771,
                                        "item_count": 14,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Custom Watchlist"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 49590,
                                        "item_count": 399,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies I Want"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7055885,
                                        "item_count": 49,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "VioDB"
                                    },
                                    {
                                        "description": "TMDbListImport",
                                        "favorite_count": 0,
                                        "id": 7072733,
                                        "item_count": 92,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDbListImport"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7070536,
                                        "item_count": 40,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "sync"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7061804,
                                        "item_count": 29,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Cinema"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7054333,
                                        "item_count": 38,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "2020s"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7107699,
                                        "item_count": 20,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "ESTREIAS CINEMA"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109690,
                                        "item_count": 77,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Upcoming Releases"
                                    },
                                    {
                                        "description": "To Be Added Animation List",
                                        "favorite_count": 0,
                                        "id": 144471,
                                        "item_count": 77,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Kids List To Be Added"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 68726,
                                        "item_count": 89,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143787,
                                        "item_count": 35,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Want to See"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 127516,
                                        "item_count": 853,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "radarr"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7103222,
                                        "item_count": 29,
                                        "iso_639_1": "zh",
                                        "list_type": "movie",
                                        "name": "英语启蒙动画片"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 63094,
                                        "item_count": 35,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Films"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 6695831,
                                        "item_count": 1235,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Top Titles from Canada - IMDb"
                                    },
                                    {
                                        "description": "2020 MOVIES",
                                        "favorite_count": 0,
                                        "id": 128367,
                                        "item_count": 88,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "2020"
                                    }
                                ],
                                "total_pages": 2,
                                "total_results": 23
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 6.1,
                                    "link": "http://www.moviepilot.de/movies/clifford-the-big-red-dog",
                                    "voteCount": 8
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 15:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/rpnjjzoecqgvvfxf1dyqwg8y4in.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:43",
                        "updateTimestamp": "2022-01-03 10:31:43",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "253395",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "Beginnend im Jahr 1970 folgt der Film den düsteren Geheimnissen und tödlichen Intrigen hinter den glamourösen Kulissen der berühmten Modedynastie. Im Mittelpunkt steht die vielschichtige Patrizia Reggiani, die Maurizio Gucci heiratet, einen der Erben des ikonischen Modehauses. Immer wieder konkurriert sie mit den Schlüsselfiguren des Familienunternehmens um Kontrolle und Macht, unter anderem mit ihrem Ehemann, dessen geschäftstüchtigem Onkel Ald, seinem risikofreudigen Cousin Paolo sowie seinem traditionsbewussten Vater Rodolfo.",
                "duration": "158",
                "name": "House of Gucci",
                "slug": "",
                "category": "0",
                "ageRating": null,
                "externalId": "644495m",
                "externalData": {
                    "lastUpdated": 1639755219,
                    "id": 644495,
                    "cineamoId": "644495m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/jdLsmpqmP1wTdFUPtmxMnYgoifT.jpg",
                    "genres": [
                        {
                            "id": 18,
                            "name": "Drama"
                        },
                        {
                            "id": 80,
                            "name": "Krimi"
                        },
                        {
                            "id": 53,
                            "name": "Thriller"
                        }
                    ],
                    "homepage": "https://www.upig.de/micro/house-of-gucci",
                    "imdbId": "tt11214590",
                    "originalLanguage": "en",
                    "originalTitle": "House of Gucci",
                    "overview": "Beginnend im Jahr 1970 folgt der Film den düsteren Geheimnissen und tödlichen Intrigen hinter den glamourösen Kulissen der berühmten Modedynastie. Im Mittelpunkt steht die vielschichtige Patrizia Reggiani, die Maurizio Gucci heiratet, einen der Erben des ikonischen Modehauses. Immer wieder konkurriert sie mit den Schlüsselfiguren des Familienunternehmens um Kontrolle und Macht, unter anderem mit ihrem Ehemann, dessen geschäftstüchtigem Onkel Ald, seinem risikofreudigen Cousin Paolo sowie seinem traditionsbewussten Vater Rodolfo.",
                    "popularity": 238.758,
                    "posterPath": "/ugwZXZiNn8Pw2iNaV2dLAEVy2Zc.jpg",
                    "releaseDate": "2021-11-24",
                    "revenue": 93000000,
                    "runtime": 158,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": "Ein name, für den es sich zu toten lohnt",
                    "title": "House of Gucci",
                    "video": false,
                    "voteAverage": 6.8,
                    "voteCount": 324,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/jdLsmpqmP1wTdFUPtmxMnYgoifT.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.52,
                                "voteCount": 8,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/6VZF8JVNOgJX56WCapYaqcVQiAw.jpg",
                                "height": 1990,
                                "iso6391": null,
                                "voteAverage": 5.39,
                                "voteCount": 6,
                                "width": 3538
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/oAy3GHA5EkUojSBt2WfWrvUvzXE.jpg",
                                "height": 1688,
                                "iso6391": null,
                                "voteAverage": 5.322,
                                "voteCount": 5,
                                "width": 3000
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/dyu5tvclVjpoXJILbLiApSB1tT1.jpg",
                                "height": 810,
                                "iso6391": null,
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 1440
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/yWVdKIIoQ05tycIsMEUGBl0Wuty.jpg",
                                "height": 1688,
                                "iso6391": null,
                                "voteAverage": 5.258,
                                "voteCount": 6,
                                "width": 3000
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/vOylMqJkzdRcMzODSMgoS8i1SKY.jpg",
                                "height": 731,
                                "iso6391": null,
                                "voteAverage": 5.252,
                                "voteCount": 4,
                                "width": 1300
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wgsOH9dZpYpF4o3TWCm4rKQKkR0.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/gyCjHoQQwGx4RaJSJYXdZEds6qX.jpg",
                                "height": 1688,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 3000
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wTxUNxAjzG4ic3kzPlVNbuotoF7.jpg",
                                "height": 1440,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2560
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/ivSzkIRjPx7HQ0Jt1PR6hf6mM9w.jpg",
                                "height": 1688,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 3000
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/hsjxqOEHzOnsNXXhTaHUQz9S853.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/7tFOyjGfJBSS2KAwu1qtr4mq6LY.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/5a2yb4YTP4Az0WC6bh2e9jNPDgk.jpg",
                                "height": 1990,
                                "iso6391": null,
                                "voteAverage": 5.206,
                                "voteCount": 9,
                                "width": 3538
                            },
                            {
                                "aspectRatio": 1.78,
                                "filePath": "/A6OokUUA5yT1dFye06DgJI2G28V.jpg",
                                "height": 1708,
                                "iso6391": null,
                                "voteAverage": 5.198,
                                "voteCount": 7,
                                "width": 3040
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wYrWHbxRKN0rYs6KvXuiYDO0TJl.jpg",
                                "height": 720,
                                "iso6391": null,
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1280
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/ySY2Oegn3LSeoV4MJXEwzuoBAhy.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/cW869iSu29S9Psb3i8DQ5dkkTAi.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1920
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/8VFI8rc0LvSKXxBD4XCnwAFc9EU.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/cy6fvTf9YqehVhReYnm5cc3GqhZ.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.292,
                                "voteCount": 18,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/23KDFPF8Kcnb6L9iUu8MWCIsQY6.jpg",
                                "height": 1800,
                                "iso6391": "en",
                                "voteAverage": 5.156,
                                "voteCount": 12,
                                "width": 1200
                            },
                            {
                                "aspectRatio": 0.669,
                                "filePath": "/ilz2pfjABWjR1sinRmetN9iXZj7.jpg",
                                "height": 1892,
                                "iso6391": null,
                                "voteAverage": 5.146,
                                "voteCount": 10,
                                "width": 1265
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/dHuhiVZPYhuQttj3T2OQ4eil6Ch.jpg",
                                "height": 1600,
                                "iso6391": "en",
                                "voteAverage": 5.128,
                                "voteCount": 6,
                                "width": 1080
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/l1948ceMBZCjgqiG2YjHAdakyBY.jpg",
                                "height": 1597,
                                "iso6391": "en",
                                "voteAverage": 5.128,
                                "voteCount": 6,
                                "width": 1078
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/oSFVm4I4znOZFKm4de3vm2tzjs0.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.128,
                                "voteCount": 6,
                                "width": 1013
                            },
                            {
                                "aspectRatio": 0.669,
                                "filePath": "/rlVYpDcVcd9s0tQZ357od6QHnmT.jpg",
                                "height": 1892,
                                "iso6391": "en",
                                "voteAverage": 5.09,
                                "voteCount": 11,
                                "width": 1265
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hxddO5tcuByfVCJ9CzihuIcUtLF.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.068,
                                "voteCount": 18,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.669,
                                "filePath": "/cs9Mrp3fVJ8MCbx4TQk34IEMEMx.jpg",
                                "height": 1892,
                                "iso6391": "en",
                                "voteAverage": 5.048,
                                "voteCount": 25,
                                "width": 1265
                            },
                            {
                                "aspectRatio": 0.666,
                                "filePath": "/lVW7OxCxqktx2r8NumEhqy2YCFu.jpg",
                                "height": 755,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 503
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/nEdUfwEIJ9KMQXW9z3JfuoYhegT.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/jzVqPS0TIvtDRovBazo4rIQMKse.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/uxRxDl2zrZpmH5VIBcUmmvBBwM0.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/mLeCRmdQGqfKFwN5LJ75Tc8urSu.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/pzcGM0RqHPHXJU0tj7Et6j6NdVG.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.669,
                                "filePath": "/gSKX2xNYhbRhF2FHSimQn7AmIgP.jpg",
                                "height": 1892,
                                "iso6391": "en",
                                "voteAverage": 5.034,
                                "voteCount": 12,
                                "width": 1265
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/2jW5QCdc8MXBssvfOK4IkJy6oeM.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.022,
                                "voteCount": 10,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/aQiYAP08t6rAnTCTfOrHXfVLhPM.jpg",
                                "height": 1600,
                                "iso6391": "en",
                                "voteAverage": 4.942,
                                "voteCount": 16,
                                "width": 1080
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/h8wKnECWdx98OYIBvp9XfgW27GW.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 4.942,
                                "voteCount": 16,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/xYzqfX04KQ7wAZUTHr9VQ1sxsMg.jpg",
                                "height": 1778,
                                "iso6391": "en",
                                "voteAverage": 4.938,
                                "voteCount": 7,
                                "width": 1200
                            },
                            {
                                "aspectRatio": 0.669,
                                "filePath": "/3cmc0AzcnEODXOoTKi6IaZT7G3u.jpg",
                                "height": 1892,
                                "iso6391": "en",
                                "voteAverage": 4.922,
                                "voteCount": 5,
                                "width": 1265
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/bEfzvQGHe4fK7FqmLn3w3m3C0sd.jpg",
                                "height": 1600,
                                "iso6391": "en",
                                "voteAverage": 4.922,
                                "voteCount": 5,
                                "width": 1080
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/2sdH6JMyBk5bV7adDXvIyngXtiR.jpg",
                                "height": 2048,
                                "iso6391": "en",
                                "voteAverage": 4.922,
                                "voteCount": 5,
                                "width": 1382
                            },
                            {
                                "aspectRatio": 0.709,
                                "filePath": "/ugwZXZiNn8Pw2iNaV2dLAEVy2Zc.jpg",
                                "height": 762,
                                "iso6391": "de",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 540
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 3.544,
                                "height": 688,
                                "iso_639_1": "en",
                                "file_path": "/gh63Hsdyk18vo60kg0YUkI6EqWr.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 2438
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "619e33d763536a002a847861",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "2Ahr59GLG2k",
                                "name": "House of Gucci - Featurette: Legacy - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Featurette",
                                "official": true,
                                "publishedAt": "2021-11-23 20:26:52 UTC"
                            },
                            {
                                "id": "619e34914f9a99002455c848",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "5S-b7_sxtdE",
                                "name": "House of Gucci - Featurette: A house divided - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Featurette",
                                "official": true,
                                "publishedAt": "2021-11-19 14:00:03 UTC"
                            },
                            {
                                "id": "619e351263536a00619cbbd3",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "yNwbk4cGfT8",
                                "name": "House of Gucci - Featurette: Lady of the house - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Featurette",
                                "official": true,
                                "publishedAt": "2021-11-17 15:53:48 UTC"
                            },
                            {
                                "id": "618e30199a8a8a0043527b34",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "JmohkE9gvY4",
                                "name": "House of Gucci - Legacy Trailer - deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-11-11 14:49:35 UTC"
                            },
                            {
                                "id": "611fa4393a4a120046e175a6",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "SJ8U0hi-EhY",
                                "name": "House of Gucci - Offizieller Trailer - deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-08-05 10:00:10 UTC"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-16T00:00:00.000Z",
                                        "type": 1,
                                        "note": "New York, NY"
                                    },
                                    {
                                        "certification": "R",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "UA",
                                "releaseDates": [
                                    {
                                        "certification": "16+",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SE",
                                "releaseDates": [
                                    {
                                        "certification": "11",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SG",
                                "releaseDates": [
                                    {
                                        "certification": "M18",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-30T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HU",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-09T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Leicester Square"
                                    },
                                    {
                                        "certification": "15",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AU",
                                "releaseDates": [
                                    {
                                        "certification": "MA15+",
                                        "iso6391": null,
                                        "releaseDate": "2022-01-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RO",
                                "releaseDates": [
                                    {
                                        "certification": "N - 15",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 2,
                                        "note": null
                                    },
                                    {
                                        "certification": "N - 15",
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": "18+",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-02T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FI",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-11-25T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 75000000,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "description": "theGuardian.com's end-of-year list\nwill update as it is completed\n\nOriginal list + reviews here:\n\nhttps://www.theguardian.com/film/2021/nov/30/the-50-best-films-of-2021-in-the-us",
                                "favorite_count": 0,
                                "id": 8168556,
                                "item_count": 51,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "The 50 best films of 2021 in the US"
                            },
                            {
                                "description": "A list of Movies running in Theaters",
                                "favorite_count": 0,
                                "id": 63802,
                                "item_count": 47,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Now Running"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7111582,
                                "item_count": 33,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "description": "The greatest films and shows of all time",
                                "favorite_count": 0,
                                "id": 7112834,
                                "item_count": 521,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Films & Shows of the Centuries"
                            },
                            {
                                "favorite_count": 0,
                                "id": 127215,
                                "item_count": 317,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "movielist"
                            },
                            {
                                "description": "Movie List",
                                "favorite_count": 0,
                                "id": 143237,
                                "item_count": 195,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112596,
                                "item_count": 68,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Pulse"
                            },
                            {
                                "description": "A list of every movie I've seen.",
                                "favorite_count": 0,
                                "id": 125217,
                                "item_count": 375,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Every Movie I've Seen"
                            },
                            {
                                "description": "Wishes",
                                "favorite_count": 0,
                                "id": 106093,
                                "item_count": 54,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Burch"
                            },
                            {
                                "description": "DO NOT DELETE",
                                "favorite_count": 0,
                                "id": 110683,
                                "item_count": 1101,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Plex"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8168884,
                                "item_count": 1368,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Watched: The Official List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 128053,
                                "item_count": 15,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Glitterprinces"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7111190,
                                "item_count": 102,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Radarr"
                            },
                            {
                                "description": "The Moive",
                                "favorite_count": 0,
                                "id": 144419,
                                "item_count": 174,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "description": "Bob's Wishlist",
                                "favorite_count": 0,
                                "id": 7073644,
                                "item_count": 176,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "RJVMovieList"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7074487,
                                "item_count": 314,
                                "iso_639_1": "pl",
                                "list_type": "movie",
                                "name": "Movies. Interested"
                            },
                            {
                                "description": "A List of things I want",
                                "favorite_count": 0,
                                "id": 7111966,
                                "item_count": 30,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Brad's List"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7072430,
                                "item_count": 1039,
                                "iso_639_1": "de",
                                "list_type": "movie",
                                "name": "Seen"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7104176,
                                "item_count": 144,
                                "iso_639_1": "hu",
                                "list_type": "movie",
                                "name": "FG Filmek új"
                            },
                            {
                                "favorite_count": 0,
                                "id": 121894,
                                "item_count": 51,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Joe D"
                            }
                        ],
                        "total_pages": 5,
                        "total_results": 87
                    },
                    "thirdParties": {
                        "moviePilot": {
                            "voteAverage": 6.6,
                            "link": "http://www.moviepilot.de/movies/house-of-gucci",
                            "voteCount": 238
                        }
                    }
                },
                "ownContent": false,
                "eventImage": null,
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544327",
                        "cmsId": "61ccb99e97599fbeaafe094f",
                        "eventTypeId": "253395",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Beginnend im Jahr 1970 folgt der Film den düsteren Geheimnissen und tödlichen Intrigen hinter den glamourösen Kulissen der berühmten Modedynastie. Im Mittelpunkt steht die vielschichtige Patrizia Reggiani, die Maurizio Gucci heiratet, einen der Erben des ikonischen Modehauses. Immer wieder konkurriert sie mit den Schlüsselfiguren des Familienunternehmens um Kontrolle und Macht, unter anderem mit ihrem Ehemann, dessen geschäftstüchtigem Onkel Ald, seinem risikofreudigen Cousin Paolo sowie seinem traditionsbewussten Vater Rodolfo.",
                        "expireDate": null,
                        "duration": "158",
                        "name": "House of Gucci",
                        "slug": "house-of-gucci",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:44",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172077",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "644495m",
                        "externalData": {
                            "lastUpdated": 1639755219,
                            "id": 644495,
                            "cineamoId": "644495m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/jdLsmpqmP1wTdFUPtmxMnYgoifT.jpg",
                            "genres": [
                                {
                                    "id": 18,
                                    "name": "Drama"
                                },
                                {
                                    "id": 80,
                                    "name": "Krimi"
                                },
                                {
                                    "id": 53,
                                    "name": "Thriller"
                                }
                            ],
                            "homepage": "https://www.upig.de/micro/house-of-gucci",
                            "imdbId": "tt11214590",
                            "originalLanguage": "en",
                            "originalTitle": "House of Gucci",
                            "overview": "Beginnend im Jahr 1970 folgt der Film den düsteren Geheimnissen und tödlichen Intrigen hinter den glamourösen Kulissen der berühmten Modedynastie. Im Mittelpunkt steht die vielschichtige Patrizia Reggiani, die Maurizio Gucci heiratet, einen der Erben des ikonischen Modehauses. Immer wieder konkurriert sie mit den Schlüsselfiguren des Familienunternehmens um Kontrolle und Macht, unter anderem mit ihrem Ehemann, dessen geschäftstüchtigem Onkel Ald, seinem risikofreudigen Cousin Paolo sowie seinem traditionsbewussten Vater Rodolfo.",
                            "popularity": 238.758,
                            "posterPath": "/ugwZXZiNn8Pw2iNaV2dLAEVy2Zc.jpg",
                            "releaseDate": "2021-11-24",
                            "revenue": 93000000,
                            "runtime": 158,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Ein name, für den es sich zu toten lohnt",
                            "title": "House of Gucci",
                            "video": false,
                            "voteAverage": 6.8,
                            "voteCount": 324,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/jdLsmpqmP1wTdFUPtmxMnYgoifT.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.52,
                                        "voteCount": 8,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/6VZF8JVNOgJX56WCapYaqcVQiAw.jpg",
                                        "height": 1990,
                                        "iso6391": null,
                                        "voteAverage": 5.39,
                                        "voteCount": 6,
                                        "width": 3538
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/oAy3GHA5EkUojSBt2WfWrvUvzXE.jpg",
                                        "height": 1688,
                                        "iso6391": null,
                                        "voteAverage": 5.322,
                                        "voteCount": 5,
                                        "width": 3000
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/dyu5tvclVjpoXJILbLiApSB1tT1.jpg",
                                        "height": 810,
                                        "iso6391": null,
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 1440
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/yWVdKIIoQ05tycIsMEUGBl0Wuty.jpg",
                                        "height": 1688,
                                        "iso6391": null,
                                        "voteAverage": 5.258,
                                        "voteCount": 6,
                                        "width": 3000
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vOylMqJkzdRcMzODSMgoS8i1SKY.jpg",
                                        "height": 731,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 1300
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wgsOH9dZpYpF4o3TWCm4rKQKkR0.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/gyCjHoQQwGx4RaJSJYXdZEds6qX.jpg",
                                        "height": 1688,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3000
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wTxUNxAjzG4ic3kzPlVNbuotoF7.jpg",
                                        "height": 1440,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2560
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/ivSzkIRjPx7HQ0Jt1PR6hf6mM9w.jpg",
                                        "height": 1688,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3000
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/hsjxqOEHzOnsNXXhTaHUQz9S853.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/7tFOyjGfJBSS2KAwu1qtr4mq6LY.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5a2yb4YTP4Az0WC6bh2e9jNPDgk.jpg",
                                        "height": 1990,
                                        "iso6391": null,
                                        "voteAverage": 5.206,
                                        "voteCount": 9,
                                        "width": 3538
                                    },
                                    {
                                        "aspectRatio": 1.78,
                                        "filePath": "/A6OokUUA5yT1dFye06DgJI2G28V.jpg",
                                        "height": 1708,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 3040
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wYrWHbxRKN0rYs6KvXuiYDO0TJl.jpg",
                                        "height": 720,
                                        "iso6391": null,
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1280
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/ySY2Oegn3LSeoV4MJXEwzuoBAhy.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/cW869iSu29S9Psb3i8DQ5dkkTAi.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1920
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/8VFI8rc0LvSKXxBD4XCnwAFc9EU.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/cy6fvTf9YqehVhReYnm5cc3GqhZ.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.292,
                                        "voteCount": 18,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/23KDFPF8Kcnb6L9iUu8MWCIsQY6.jpg",
                                        "height": 1800,
                                        "iso6391": "en",
                                        "voteAverage": 5.156,
                                        "voteCount": 12,
                                        "width": 1200
                                    },
                                    {
                                        "aspectRatio": 0.669,
                                        "filePath": "/ilz2pfjABWjR1sinRmetN9iXZj7.jpg",
                                        "height": 1892,
                                        "iso6391": null,
                                        "voteAverage": 5.146,
                                        "voteCount": 10,
                                        "width": 1265
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/dHuhiVZPYhuQttj3T2OQ4eil6Ch.jpg",
                                        "height": 1600,
                                        "iso6391": "en",
                                        "voteAverage": 5.128,
                                        "voteCount": 6,
                                        "width": 1080
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/l1948ceMBZCjgqiG2YjHAdakyBY.jpg",
                                        "height": 1597,
                                        "iso6391": "en",
                                        "voteAverage": 5.128,
                                        "voteCount": 6,
                                        "width": 1078
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/oSFVm4I4znOZFKm4de3vm2tzjs0.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.128,
                                        "voteCount": 6,
                                        "width": 1013
                                    },
                                    {
                                        "aspectRatio": 0.669,
                                        "filePath": "/rlVYpDcVcd9s0tQZ357od6QHnmT.jpg",
                                        "height": 1892,
                                        "iso6391": "en",
                                        "voteAverage": 5.09,
                                        "voteCount": 11,
                                        "width": 1265
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hxddO5tcuByfVCJ9CzihuIcUtLF.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.068,
                                        "voteCount": 18,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.669,
                                        "filePath": "/cs9Mrp3fVJ8MCbx4TQk34IEMEMx.jpg",
                                        "height": 1892,
                                        "iso6391": "en",
                                        "voteAverage": 5.048,
                                        "voteCount": 25,
                                        "width": 1265
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/lVW7OxCxqktx2r8NumEhqy2YCFu.jpg",
                                        "height": 755,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 503
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/nEdUfwEIJ9KMQXW9z3JfuoYhegT.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/jzVqPS0TIvtDRovBazo4rIQMKse.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/uxRxDl2zrZpmH5VIBcUmmvBBwM0.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/mLeCRmdQGqfKFwN5LJ75Tc8urSu.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/pzcGM0RqHPHXJU0tj7Et6j6NdVG.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.669,
                                        "filePath": "/gSKX2xNYhbRhF2FHSimQn7AmIgP.jpg",
                                        "height": 1892,
                                        "iso6391": "en",
                                        "voteAverage": 5.034,
                                        "voteCount": 12,
                                        "width": 1265
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/2jW5QCdc8MXBssvfOK4IkJy6oeM.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.022,
                                        "voteCount": 10,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/aQiYAP08t6rAnTCTfOrHXfVLhPM.jpg",
                                        "height": 1600,
                                        "iso6391": "en",
                                        "voteAverage": 4.942,
                                        "voteCount": 16,
                                        "width": 1080
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/h8wKnECWdx98OYIBvp9XfgW27GW.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 4.942,
                                        "voteCount": 16,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/xYzqfX04KQ7wAZUTHr9VQ1sxsMg.jpg",
                                        "height": 1778,
                                        "iso6391": "en",
                                        "voteAverage": 4.938,
                                        "voteCount": 7,
                                        "width": 1200
                                    },
                                    {
                                        "aspectRatio": 0.669,
                                        "filePath": "/3cmc0AzcnEODXOoTKi6IaZT7G3u.jpg",
                                        "height": 1892,
                                        "iso6391": "en",
                                        "voteAverage": 4.922,
                                        "voteCount": 5,
                                        "width": 1265
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/bEfzvQGHe4fK7FqmLn3w3m3C0sd.jpg",
                                        "height": 1600,
                                        "iso6391": "en",
                                        "voteAverage": 4.922,
                                        "voteCount": 5,
                                        "width": 1080
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/2sdH6JMyBk5bV7adDXvIyngXtiR.jpg",
                                        "height": 2048,
                                        "iso6391": "en",
                                        "voteAverage": 4.922,
                                        "voteCount": 5,
                                        "width": 1382
                                    },
                                    {
                                        "aspectRatio": 0.709,
                                        "filePath": "/ugwZXZiNn8Pw2iNaV2dLAEVy2Zc.jpg",
                                        "height": 762,
                                        "iso6391": "de",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 540
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 3.544,
                                        "height": 688,
                                        "iso_639_1": "en",
                                        "file_path": "/gh63Hsdyk18vo60kg0YUkI6EqWr.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 2438
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "619e33d763536a002a847861",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "2Ahr59GLG2k",
                                        "name": "House of Gucci - Featurette: Legacy - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Featurette",
                                        "official": true,
                                        "publishedAt": "2021-11-23 20:26:52 UTC"
                                    },
                                    {
                                        "id": "619e34914f9a99002455c848",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "5S-b7_sxtdE",
                                        "name": "House of Gucci - Featurette: A house divided - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Featurette",
                                        "official": true,
                                        "publishedAt": "2021-11-19 14:00:03 UTC"
                                    },
                                    {
                                        "id": "619e351263536a00619cbbd3",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "yNwbk4cGfT8",
                                        "name": "House of Gucci - Featurette: Lady of the house - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Featurette",
                                        "official": true,
                                        "publishedAt": "2021-11-17 15:53:48 UTC"
                                    },
                                    {
                                        "id": "618e30199a8a8a0043527b34",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "JmohkE9gvY4",
                                        "name": "House of Gucci - Legacy Trailer - deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-11-11 14:49:35 UTC"
                                    },
                                    {
                                        "id": "611fa4393a4a120046e175a6",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "SJ8U0hi-EhY",
                                        "name": "House of Gucci - Offizieller Trailer - deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-08-05 10:00:10 UTC"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-16T00:00:00.000Z",
                                                "type": 1,
                                                "note": "New York, NY"
                                            },
                                            {
                                                "certification": "R",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "16+",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": "11",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "M18",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-30T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-09T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Leicester Square"
                                            },
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "MA15+",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": "N - 15",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 2,
                                                "note": null
                                            },
                                            {
                                                "certification": "N - 15",
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": "18+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-02T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-11-25T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 75000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "description": "theGuardian.com's end-of-year list\nwill update as it is completed\n\nOriginal list + reviews here:\n\nhttps://www.theguardian.com/film/2021/nov/30/the-50-best-films-of-2021-in-the-us",
                                        "favorite_count": 0,
                                        "id": 8168556,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "The 50 best films of 2021 in the US"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 47,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7111582,
                                        "item_count": 33,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "description": "The greatest films and shows of all time",
                                        "favorite_count": 0,
                                        "id": 7112834,
                                        "item_count": 521,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Films & Shows of the Centuries"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 127215,
                                        "item_count": 317,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "movielist"
                                    },
                                    {
                                        "description": "Movie List",
                                        "favorite_count": 0,
                                        "id": 143237,
                                        "item_count": 195,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112596,
                                        "item_count": 68,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Pulse"
                                    },
                                    {
                                        "description": "A list of every movie I've seen.",
                                        "favorite_count": 0,
                                        "id": 125217,
                                        "item_count": 375,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Every Movie I've Seen"
                                    },
                                    {
                                        "description": "Wishes",
                                        "favorite_count": 0,
                                        "id": 106093,
                                        "item_count": 54,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Burch"
                                    },
                                    {
                                        "description": "DO NOT DELETE",
                                        "favorite_count": 0,
                                        "id": 110683,
                                        "item_count": 1101,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Plex"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168884,
                                        "item_count": 1368,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Watched: The Official List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 128053,
                                        "item_count": 15,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Glitterprinces"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7111190,
                                        "item_count": 102,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Radarr"
                                    },
                                    {
                                        "description": "The Moive",
                                        "favorite_count": 0,
                                        "id": 144419,
                                        "item_count": 174,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "description": "Bob's Wishlist",
                                        "favorite_count": 0,
                                        "id": 7073644,
                                        "item_count": 176,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "RJVMovieList"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7074487,
                                        "item_count": 314,
                                        "iso_639_1": "pl",
                                        "list_type": "movie",
                                        "name": "Movies. Interested"
                                    },
                                    {
                                        "description": "A List of things I want",
                                        "favorite_count": 0,
                                        "id": 7111966,
                                        "item_count": 30,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Brad's List"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7072430,
                                        "item_count": 1039,
                                        "iso_639_1": "de",
                                        "list_type": "movie",
                                        "name": "Seen"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7104176,
                                        "item_count": 144,
                                        "iso_639_1": "hu",
                                        "list_type": "movie",
                                        "name": "FG Filmek új"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 121894,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Joe D"
                                    }
                                ],
                                "total_pages": 5,
                                "total_results": 87
                            },
                            "thirdParties": {
                                "moviePilot": {
                                    "voteAverage": 6.6,
                                    "link": "http://www.moviepilot.de/movies/house-of-gucci",
                                    "voteCount": 238
                                }
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 19:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/ugwzxzinn8pw2inav2dlaevy2zc.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:44",
                        "updateTimestamp": "2022-01-03 10:31:44",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "256715",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                "duration": "159",
                "name": "Spider-Man: No Way Home",
                "slug": "spider-man-no-way-home",
                "category": "0",
                "ageRating": null,
                "externalId": "634649m",
                "externalData": {
                    "lastUpdated": 1641303020,
                    "id": 634649,
                    "cineamoId": "634649m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                    "genres": [
                        {
                            "id": 28,
                            "name": "Action"
                        },
                        {
                            "id": 12,
                            "name": "Abenteuer"
                        },
                        {
                            "id": 878,
                            "name": "Science Fiction"
                        }
                    ],
                    "homepage": "https://www.sonypictures.de/film/spider-man-no-way-home",
                    "imdbId": "tt10872600",
                    "originalLanguage": "en",
                    "originalTitle": "Spider-Man: No Way Home",
                    "overview": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                    "popularity": 10314.745,
                    "posterPath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                    "releaseDate": "2021-12-15",
                    "revenue": 1368892000,
                    "runtime": 159,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": "Das entfesselte Multiversum!",
                    "title": "Spider-Man: No Way Home",
                    "video": false,
                    "voteAverage": 8.4,
                    "voteCount": 3379,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                                "height": 1125,
                                "iso6391": null,
                                "voteAverage": 5.396,
                                "voteCount": 12,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 1.775,
                                "filePath": "/vq4Wjw77JdACKVg3tWCzegbL4Uz.jpg",
                                "height": 845,
                                "iso6391": null,
                                "voteAverage": 5.264,
                                "voteCount": 8,
                                "width": 1500
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/n7sgwV1TNHUXOf7Aoh7Cr8gWvlZ.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.252,
                                "voteCount": 4,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/npCYreQhp6XUBhRO4JZbCbv6sV2.jpg",
                                "height": 920,
                                "iso6391": null,
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 1636
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/aRNMwIvEnYEMwGHLCAACyuq2I1K.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.226,
                                "voteCount": 15,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/5BjetzdVpWkPNKUIlpasnXVSwCP.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.226,
                                "voteCount": 15,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/vIPIyTJqcgOKgKcExCvTDpLpTYW.jpg",
                                "height": 1897,
                                "iso6391": null,
                                "voteAverage": 5.22,
                                "voteCount": 13,
                                "width": 3373
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/rWiPrQ4XqbuLg4wP2ufM0dsBRCQ.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.206,
                                "voteCount": 9,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/wA2t7nsO10swf2Snpd6XM5a6yLR.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.198,
                                "voteCount": 7,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/vK18znei8Uha2z7ZhZtBa40HIrm.jpg",
                                "height": 1449,
                                "iso6391": null,
                                "voteAverage": 5.198,
                                "voteCount": 7,
                                "width": 2576
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/8L4mdjd9Zuxju3cDB3spgJ8AOPw.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.19,
                                "voteCount": 5,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/jv2TYSSh74C7xToMAzmcbOqOvQu.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/VlHt27nCqOuTnuX6bku8QZapzO.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.18,
                                "voteCount": 18,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/lCGjoVl2MRGIO9bAJ9LHL2XiHOQ.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/5Ra4VVJTPCx79mCRDo8CLvM0qRz.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/n96AMk265MLWbjnOwWsXAAIXpem.jpg",
                                "height": 1890,
                                "iso6391": null,
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 3360
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/gespPE81A2RYvMxl9XaVNvIgevS.jpg",
                                "height": 1080,
                                "iso6391": null,
                                "voteAverage": 5.146,
                                "voteCount": 10,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/3HOIbNlir4Xej4E4sfefyLGZXo.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.09,
                                "voteCount": 11,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/p50iHxNWIo8teI2m0QVFGuOl0od.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/uhYpkLSnlOXTQDmNnu1X4MHMt1S.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/iQFcwSGbZXMkeyKrxbPnwnRo5fl.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/1g0dhYtq4irTY1GPXvft6k4YLjm.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.976,
                                "voteCount": 52,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/1z7ZQhga4AcSkQNqEU3Tf9XpxoX.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.648,
                                "voteCount": 8,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.522,
                                "voteCount": 4,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/1IZbnnJYXIvgQOwqxSzvxA0bwmp.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/ly6sDd1TTW7c8aXp0GohAq8zrbV.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/2W2Wi6nlnmVvlmJumueejpj5wzO.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/cmUwP6WPTmDCBPYY1Jpxw97KOKF.jpg",
                                "height": 1500,
                                "iso6391": "de",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/1gxZrx9gL9ov2c1NpXimEUzMTmh.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.276,
                                "voteCount": 35,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/9lnlNvYzQ5FnbZLXzYaYHyRCFWO.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.264,
                                "voteCount": 8,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/5pVJ9SuuO72IgN6i9kMwQwnhGHG.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.242,
                                "voteCount": 40,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.666,
                                "filePath": "/zAEV3KFOKgITIvl8dSS9yiWsApF.jpg",
                                "height": 980,
                                "iso6391": "en",
                                "voteAverage": 5.19,
                                "voteCount": 5,
                                "width": 653
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/vwo6JMU0tOnnlEmrdXF8DlJr6Yb.jpg",
                                "height": 2100,
                                "iso6391": "de",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 1400
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/34Ks9lSiOAPpW13tb0m4PMUkVYc.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/7kMZKtPH8p0P9HKdDQ2veJU8BkK.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/ttvFnwlLPuvOu3ksp8L7AtwjO2m.jpg",
                                "height": 2100,
                                "iso6391": "de",
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1400
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hQDShpLeeSNAqKCdtv0LBEEoy5t.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/jtu4rWm87QkUsHLoe66O9cmYqEP.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.08,
                                "voteCount": 9,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/eBsGzQP1H0qzztUfXAkCJ2rVlqT.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.068,
                                "voteCount": 7,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/5RjyUm9HP2ZRwvqCqK9sDqlQHxa.jpg",
                                "height": 1668,
                                "iso6391": "en",
                                "voteAverage": 5.048,
                                "voteCount": 25,
                                "width": 1112
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/chf1DV4fxX1vGNEu4tu9sOnrCvw.jpg",
                                "height": 1800,
                                "iso6391": "en",
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 1215
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/kkv8BYhO1WesSRoRWfb8cpApI97.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.034,
                                "voteCount": 12,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/byOlXN8rf9cQNobtV5t8t4gJ0uT.jpg",
                                "height": 2965,
                                "iso6391": "en",
                                "voteAverage": 4.968,
                                "voteCount": 11,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/s7IEL0hDL1A8rzVS4qMfFRAPw8g.jpg",
                                "height": 1200,
                                "iso6391": "en",
                                "voteAverage": 4.956,
                                "voteCount": 18,
                                "width": 800
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/j0k3FQUwDwCYbA0km9ETx64NcR0.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/3fxf7OTYZRx6Rm3u7oWTbnlLB06.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gh9pIIZaO2mqzxQ9YpvLRFzEu0M.jpg",
                                "height": 750,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 500
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 2.858,
                                "height": 465,
                                "iso_639_1": "en",
                                "file_path": "/2yb6AxqyeB0CeYg8kGxWfrMl7xZ.png",
                                "vote_average": 5.318,
                                "vote_count": 3,
                                "width": 1329
                            },
                            {
                                "aspect_ratio": 4.248,
                                "height": 294,
                                "iso_639_1": "en",
                                "file_path": "/wpEeWDClcIflQ9hIk3ziFwf6cgE.png",
                                "vote_average": 5.312,
                                "vote_count": 1,
                                "width": 1249
                            },
                            {
                                "aspect_ratio": 2.926,
                                "height": 1293,
                                "iso_639_1": "en",
                                "file_path": "/12vuNpBY8NmLqQ24awLt2WOc0GN.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 3783
                            },
                            {
                                "aspect_ratio": 2.938,
                                "height": 1293,
                                "iso_639_1": "en",
                                "file_path": "/utO7Y2AZpPhCDHmtRflKopiVii5.png",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 3799
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "6194cd08d768fe0062d9a90d",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "X5ibkB5ftH0",
                                "name": "Spider-Man: No Way Home – Offizieller Trailer (deutsch HD)",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-11-17T08:20:28.000Z"
                            },
                            {
                                "id": "6124955bd6c30000469de42a",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "HNc3QiYtPwI",
                                "name": "Spider-Man: No Way Home – Trailer A (deutsch HD)",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-08-24T02:03:32.000Z"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "HU",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 1,
                                        "note": null
                                    },
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-17T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SK",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AU",
                                "releaseDates": [
                                    {
                                        "certification": "M",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-23T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": "PG-13",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-13T00:00:00.000Z",
                                        "type": 1,
                                        "note": "Los Angeles, California"
                                    },
                                    {
                                        "certification": "PG-13",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-17T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NL",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-17T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SG",
                                "releaseDates": [
                                    {
                                        "certification": "PG",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PH",
                                "releaseDates": [
                                    {
                                        "certification": "R-13",
                                        "iso6391": null,
                                        "releaseDate": "2022-01-08T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PE",
                                "releaseDates": [
                                    {
                                        "certification": "PG-12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 1,
                                        "note": null
                                    },
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TW",
                                "releaseDates": [
                                    {
                                        "certification": "普遍級",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IE",
                                "releaseDates": [
                                    {
                                        "certification": "12A",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RO",
                                "releaseDates": [
                                    {
                                        "certification": "A.P. - 12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-17T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HK",
                                "releaseDates": [
                                    {
                                        "certification": "IIA",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-17T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FI",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KR",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "UA",
                                "releaseDates": [
                                    {
                                        "certification": "12+",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": "U",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2022-04-15T00:00:00.000Z",
                                        "type": 5,
                                        "note": "DVD, Blu-Ray & 4K UHD"
                                    }
                                ]
                            },
                            {
                                "iso31661": "NZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2022-06-29T00:00:00.000Z",
                                        "type": 5,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IN",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-17T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "MX",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": "12A",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-15T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 200000000,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "description": "Movies I want to see",
                                "favorite_count": 0,
                                "id": 126845,
                                "item_count": 11,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Wanna See"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7104032,
                                "item_count": 13,
                                "iso_639_1": "tr",
                                "list_type": "movie",
                                "name": "Özel"
                            },
                            {
                                "description": "A list of Movies running in Theaters",
                                "favorite_count": 0,
                                "id": 63802,
                                "item_count": 30,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Now Running"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8169542,
                                "item_count": 33,
                                "iso_639_1": "pt",
                                "list_type": "movie",
                                "name": "Quero ver"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7053491,
                                "item_count": 3171,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD (Popular)"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7109840,
                                "item_count": 5918,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD"
                            },
                            {
                                "description": "List of movies to watch/get!",
                                "favorite_count": 0,
                                "id": 111568,
                                "item_count": 9,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies to Get"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8168656,
                                "item_count": 51,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "TMDBNinjaList"
                            },
                            {
                                "description": "I FILM PIÙ BELLI DA VEDERE",
                                "favorite_count": 0,
                                "id": 7106817,
                                "item_count": 488,
                                "iso_639_1": "it",
                                "list_type": "movie",
                                "name": "I FILM PIÙ BELLI DA VEDERE "
                            },
                            {
                                "favorite_count": 0,
                                "id": 7112629,
                                "item_count": 18,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "High"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7088287,
                                "item_count": 191,
                                "iso_639_1": "pt",
                                "list_type": "movie",
                                "name": "Filmes que Assisti"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7067111,
                                "item_count": 127,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies I want to see"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8173905,
                                "item_count": 18,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Best of 2021"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7064739,
                                "item_count": 208,
                                "iso_639_1": "sv",
                                "list_type": "movie",
                                "name": "Sedda Filmer"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8168662,
                                "item_count": 55,
                                "iso_639_1": "be",
                                "list_type": "movie",
                                "name": "lista"
                            },
                            {
                                "favorite_count": 0,
                                "id": 113444,
                                "item_count": 400,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 89943,
                                "item_count": 787,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Clem2k"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8174000,
                                "item_count": 1,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Niki collection"
                            },
                            {
                                "description": "Después de EndGame ",
                                "favorite_count": 0,
                                "id": 112287,
                                "item_count": 124,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "Películas vistas D.E."
                            },
                            {
                                "description": "The movies I've watched. Shortfilms and feature films",
                                "favorite_count": 0,
                                "id": 6449,
                                "item_count": 942,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "vistas/viewed",
                                "poster_path": "/aCof58P1vfvoyVZs4OgkJghwSKH.jpg"
                            }
                        ],
                        "total_pages": 24,
                        "total_results": 467
                    }
                },
                "ownContent": false,
                "eventImage": "https://image.tmdb.org/t/p/original/ttvfnwllpuvou3ksp8l7atwjo2m.jpg",
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544366",
                        "cmsId": "61ccb97697599fbeaafe0934",
                        "eventTypeId": "256715",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                        "expireDate": null,
                        "duration": "159",
                        "name": "Spider-Man: No Way Home",
                        "slug": "spider-man-no-way-home",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:46",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172026",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "634649m",
                        "externalData": {
                            "lastUpdated": 1641303020,
                            "id": 634649,
                            "cineamoId": "634649m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                            "genres": [
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 878,
                                    "name": "Science Fiction"
                                }
                            ],
                            "homepage": "https://www.sonypictures.de/film/spider-man-no-way-home",
                            "imdbId": "tt10872600",
                            "originalLanguage": "en",
                            "originalTitle": "Spider-Man: No Way Home",
                            "overview": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                            "popularity": 10314.745,
                            "posterPath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                            "releaseDate": "2021-12-15",
                            "revenue": 1368892000,
                            "runtime": 159,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Das entfesselte Multiversum!",
                            "title": "Spider-Man: No Way Home",
                            "video": false,
                            "voteAverage": 8.4,
                            "voteCount": 3379,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                                        "height": 1125,
                                        "iso6391": null,
                                        "voteAverage": 5.396,
                                        "voteCount": 12,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 1.775,
                                        "filePath": "/vq4Wjw77JdACKVg3tWCzegbL4Uz.jpg",
                                        "height": 845,
                                        "iso6391": null,
                                        "voteAverage": 5.264,
                                        "voteCount": 8,
                                        "width": 1500
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/n7sgwV1TNHUXOf7Aoh7Cr8gWvlZ.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/npCYreQhp6XUBhRO4JZbCbv6sV2.jpg",
                                        "height": 920,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1636
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/aRNMwIvEnYEMwGHLCAACyuq2I1K.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.226,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5BjetzdVpWkPNKUIlpasnXVSwCP.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.226,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vIPIyTJqcgOKgKcExCvTDpLpTYW.jpg",
                                        "height": 1897,
                                        "iso6391": null,
                                        "voteAverage": 5.22,
                                        "voteCount": 13,
                                        "width": 3373
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rWiPrQ4XqbuLg4wP2ufM0dsBRCQ.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.206,
                                        "voteCount": 9,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wA2t7nsO10swf2Snpd6XM5a6yLR.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vK18znei8Uha2z7ZhZtBa40HIrm.jpg",
                                        "height": 1449,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 2576
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/8L4mdjd9Zuxju3cDB3spgJ8AOPw.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/jv2TYSSh74C7xToMAzmcbOqOvQu.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/VlHt27nCqOuTnuX6bku8QZapzO.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 18,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lCGjoVl2MRGIO9bAJ9LHL2XiHOQ.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5Ra4VVJTPCx79mCRDo8CLvM0qRz.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/n96AMk265MLWbjnOwWsXAAIXpem.jpg",
                                        "height": 1890,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 3360
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gespPE81A2RYvMxl9XaVNvIgevS.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.146,
                                        "voteCount": 10,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3HOIbNlir4Xej4E4sfefyLGZXo.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.09,
                                        "voteCount": 11,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/p50iHxNWIo8teI2m0QVFGuOl0od.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/uhYpkLSnlOXTQDmNnu1X4MHMt1S.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/iQFcwSGbZXMkeyKrxbPnwnRo5fl.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1g0dhYtq4irTY1GPXvft6k4YLjm.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.976,
                                        "voteCount": 52,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1z7ZQhga4AcSkQNqEU3Tf9XpxoX.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.648,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.522,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1IZbnnJYXIvgQOwqxSzvxA0bwmp.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ly6sDd1TTW7c8aXp0GohAq8zrbV.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/2W2Wi6nlnmVvlmJumueejpj5wzO.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/cmUwP6WPTmDCBPYY1Jpxw97KOKF.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1gxZrx9gL9ov2c1NpXimEUzMTmh.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.276,
                                        "voteCount": 35,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/9lnlNvYzQ5FnbZLXzYaYHyRCFWO.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.264,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5pVJ9SuuO72IgN6i9kMwQwnhGHG.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.242,
                                        "voteCount": 40,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/zAEV3KFOKgITIvl8dSS9yiWsApF.jpg",
                                        "height": 980,
                                        "iso6391": "en",
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 653
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/vwo6JMU0tOnnlEmrdXF8DlJr6Yb.jpg",
                                        "height": 2100,
                                        "iso6391": "de",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/34Ks9lSiOAPpW13tb0m4PMUkVYc.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/7kMZKtPH8p0P9HKdDQ2veJU8BkK.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ttvFnwlLPuvOu3ksp8L7AtwjO2m.jpg",
                                        "height": 2100,
                                        "iso6391": "de",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hQDShpLeeSNAqKCdtv0LBEEoy5t.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/jtu4rWm87QkUsHLoe66O9cmYqEP.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.08,
                                        "voteCount": 9,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/eBsGzQP1H0qzztUfXAkCJ2rVlqT.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5RjyUm9HP2ZRwvqCqK9sDqlQHxa.jpg",
                                        "height": 1668,
                                        "iso6391": "en",
                                        "voteAverage": 5.048,
                                        "voteCount": 25,
                                        "width": 1112
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/chf1DV4fxX1vGNEu4tu9sOnrCvw.jpg",
                                        "height": 1800,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 1215
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/kkv8BYhO1WesSRoRWfb8cpApI97.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.034,
                                        "voteCount": 12,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/byOlXN8rf9cQNobtV5t8t4gJ0uT.jpg",
                                        "height": 2965,
                                        "iso6391": "en",
                                        "voteAverage": 4.968,
                                        "voteCount": 11,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/s7IEL0hDL1A8rzVS4qMfFRAPw8g.jpg",
                                        "height": 1200,
                                        "iso6391": "en",
                                        "voteAverage": 4.956,
                                        "voteCount": 18,
                                        "width": 800
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/j0k3FQUwDwCYbA0km9ETx64NcR0.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/3fxf7OTYZRx6Rm3u7oWTbnlLB06.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gh9pIIZaO2mqzxQ9YpvLRFzEu0M.jpg",
                                        "height": 750,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.858,
                                        "height": 465,
                                        "iso_639_1": "en",
                                        "file_path": "/2yb6AxqyeB0CeYg8kGxWfrMl7xZ.png",
                                        "vote_average": 5.318,
                                        "vote_count": 3,
                                        "width": 1329
                                    },
                                    {
                                        "aspect_ratio": 4.248,
                                        "height": 294,
                                        "iso_639_1": "en",
                                        "file_path": "/wpEeWDClcIflQ9hIk3ziFwf6cgE.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 1249
                                    },
                                    {
                                        "aspect_ratio": 2.926,
                                        "height": 1293,
                                        "iso_639_1": "en",
                                        "file_path": "/12vuNpBY8NmLqQ24awLt2WOc0GN.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 3783
                                    },
                                    {
                                        "aspect_ratio": 2.938,
                                        "height": 1293,
                                        "iso_639_1": "en",
                                        "file_path": "/utO7Y2AZpPhCDHmtRflKopiVii5.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 3799
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6194cd08d768fe0062d9a90d",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "X5ibkB5ftH0",
                                        "name": "Spider-Man: No Way Home – Offizieller Trailer (deutsch HD)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-11-17T08:20:28.000Z"
                                    },
                                    {
                                        "id": "6124955bd6c30000469de42a",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "HNc3QiYtPwI",
                                        "name": "Spider-Man: No Way Home – Trailer A (deutsch HD)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-08-24T02:03:32.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "M",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-13T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Los Angeles, California"
                                            },
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PH",
                                        "releaseDates": [
                                            {
                                                "certification": "R-13",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PE",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": "普遍級",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IE",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": "A.P. - 12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HK",
                                        "releaseDates": [
                                            {
                                                "certification": "IIA",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "12+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-04-15T00:00:00.000Z",
                                                "type": 5,
                                                "note": "DVD, Blu-Ray & 4K UHD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2022-06-29T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IN",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "MX",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 200000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "description": "Movies I want to see",
                                        "favorite_count": 0,
                                        "id": 126845,
                                        "item_count": 11,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Wanna See"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7104032,
                                        "item_count": 13,
                                        "iso_639_1": "tr",
                                        "list_type": "movie",
                                        "name": "Özel"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 30,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8169542,
                                        "item_count": 33,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Quero ver"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3171,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5918,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "description": "List of movies to watch/get!",
                                        "favorite_count": 0,
                                        "id": 111568,
                                        "item_count": 9,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies to Get"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168656,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDBNinjaList"
                                    },
                                    {
                                        "description": "I FILM PIÙ BELLI DA VEDERE",
                                        "favorite_count": 0,
                                        "id": 7106817,
                                        "item_count": 488,
                                        "iso_639_1": "it",
                                        "list_type": "movie",
                                        "name": "I FILM PIÙ BELLI DA VEDERE "
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112629,
                                        "item_count": 18,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "High"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7088287,
                                        "item_count": 191,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Filmes que Assisti"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7067111,
                                        "item_count": 127,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies I want to see"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173905,
                                        "item_count": 18,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Best of 2021"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7064739,
                                        "item_count": 208,
                                        "iso_639_1": "sv",
                                        "list_type": "movie",
                                        "name": "Sedda Filmer"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168662,
                                        "item_count": 55,
                                        "iso_639_1": "be",
                                        "list_type": "movie",
                                        "name": "lista"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 113444,
                                        "item_count": 400,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 787,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8174000,
                                        "item_count": 1,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Niki collection"
                                    },
                                    {
                                        "description": "Después de EndGame ",
                                        "favorite_count": 0,
                                        "id": 112287,
                                        "item_count": 124,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Películas vistas D.E."
                                    },
                                    {
                                        "description": "The movies I've watched. Shortfilms and feature films",
                                        "favorite_count": 0,
                                        "id": 6449,
                                        "item_count": 942,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "vistas/viewed",
                                        "poster_path": "/aCof58P1vfvoyVZs4OgkJghwSKH.jpg"
                                    }
                                ],
                                "total_pages": 24,
                                "total_results": 467
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 16:45:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/inkf4d0azoj9glq8zyg3wzaqibl.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:46",
                        "updateTimestamp": "2022-01-03 10:31:46",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    },
                    {
                        "statistics": null,
                        "requestId": "5544367",
                        "cmsId": "61cef30bdf29503e117ad3e5",
                        "eventTypeId": "256715",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                        "expireDate": null,
                        "duration": "159",
                        "name": "Spider-Man: No Way Home",
                        "slug": "spider-man-no-way-home",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:46",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172180",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "634649m",
                        "externalData": {
                            "lastUpdated": 1641303020,
                            "id": 634649,
                            "cineamoId": "634649m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                            "genres": [
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 878,
                                    "name": "Science Fiction"
                                }
                            ],
                            "homepage": "https://www.sonypictures.de/film/spider-man-no-way-home",
                            "imdbId": "tt10872600",
                            "originalLanguage": "en",
                            "originalTitle": "Spider-Man: No Way Home",
                            "overview": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                            "popularity": 10314.745,
                            "posterPath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                            "releaseDate": "2021-12-15",
                            "revenue": 1368892000,
                            "runtime": 159,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Das entfesselte Multiversum!",
                            "title": "Spider-Man: No Way Home",
                            "video": false,
                            "voteAverage": 8.4,
                            "voteCount": 3379,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                                        "height": 1125,
                                        "iso6391": null,
                                        "voteAverage": 5.396,
                                        "voteCount": 12,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 1.775,
                                        "filePath": "/vq4Wjw77JdACKVg3tWCzegbL4Uz.jpg",
                                        "height": 845,
                                        "iso6391": null,
                                        "voteAverage": 5.264,
                                        "voteCount": 8,
                                        "width": 1500
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/n7sgwV1TNHUXOf7Aoh7Cr8gWvlZ.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/npCYreQhp6XUBhRO4JZbCbv6sV2.jpg",
                                        "height": 920,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1636
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/aRNMwIvEnYEMwGHLCAACyuq2I1K.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.226,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5BjetzdVpWkPNKUIlpasnXVSwCP.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.226,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vIPIyTJqcgOKgKcExCvTDpLpTYW.jpg",
                                        "height": 1897,
                                        "iso6391": null,
                                        "voteAverage": 5.22,
                                        "voteCount": 13,
                                        "width": 3373
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rWiPrQ4XqbuLg4wP2ufM0dsBRCQ.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.206,
                                        "voteCount": 9,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wA2t7nsO10swf2Snpd6XM5a6yLR.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vK18znei8Uha2z7ZhZtBa40HIrm.jpg",
                                        "height": 1449,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 2576
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/8L4mdjd9Zuxju3cDB3spgJ8AOPw.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/jv2TYSSh74C7xToMAzmcbOqOvQu.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/VlHt27nCqOuTnuX6bku8QZapzO.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 18,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lCGjoVl2MRGIO9bAJ9LHL2XiHOQ.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5Ra4VVJTPCx79mCRDo8CLvM0qRz.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/n96AMk265MLWbjnOwWsXAAIXpem.jpg",
                                        "height": 1890,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 3360
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gespPE81A2RYvMxl9XaVNvIgevS.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.146,
                                        "voteCount": 10,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3HOIbNlir4Xej4E4sfefyLGZXo.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.09,
                                        "voteCount": 11,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/p50iHxNWIo8teI2m0QVFGuOl0od.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/uhYpkLSnlOXTQDmNnu1X4MHMt1S.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/iQFcwSGbZXMkeyKrxbPnwnRo5fl.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1g0dhYtq4irTY1GPXvft6k4YLjm.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.976,
                                        "voteCount": 52,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1z7ZQhga4AcSkQNqEU3Tf9XpxoX.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.648,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.522,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1IZbnnJYXIvgQOwqxSzvxA0bwmp.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ly6sDd1TTW7c8aXp0GohAq8zrbV.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/2W2Wi6nlnmVvlmJumueejpj5wzO.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/cmUwP6WPTmDCBPYY1Jpxw97KOKF.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1gxZrx9gL9ov2c1NpXimEUzMTmh.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.276,
                                        "voteCount": 35,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/9lnlNvYzQ5FnbZLXzYaYHyRCFWO.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.264,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5pVJ9SuuO72IgN6i9kMwQwnhGHG.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.242,
                                        "voteCount": 40,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/zAEV3KFOKgITIvl8dSS9yiWsApF.jpg",
                                        "height": 980,
                                        "iso6391": "en",
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 653
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/vwo6JMU0tOnnlEmrdXF8DlJr6Yb.jpg",
                                        "height": 2100,
                                        "iso6391": "de",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/34Ks9lSiOAPpW13tb0m4PMUkVYc.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/7kMZKtPH8p0P9HKdDQ2veJU8BkK.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ttvFnwlLPuvOu3ksp8L7AtwjO2m.jpg",
                                        "height": 2100,
                                        "iso6391": "de",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hQDShpLeeSNAqKCdtv0LBEEoy5t.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/jtu4rWm87QkUsHLoe66O9cmYqEP.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.08,
                                        "voteCount": 9,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/eBsGzQP1H0qzztUfXAkCJ2rVlqT.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5RjyUm9HP2ZRwvqCqK9sDqlQHxa.jpg",
                                        "height": 1668,
                                        "iso6391": "en",
                                        "voteAverage": 5.048,
                                        "voteCount": 25,
                                        "width": 1112
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/chf1DV4fxX1vGNEu4tu9sOnrCvw.jpg",
                                        "height": 1800,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 1215
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/kkv8BYhO1WesSRoRWfb8cpApI97.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.034,
                                        "voteCount": 12,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/byOlXN8rf9cQNobtV5t8t4gJ0uT.jpg",
                                        "height": 2965,
                                        "iso6391": "en",
                                        "voteAverage": 4.968,
                                        "voteCount": 11,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/s7IEL0hDL1A8rzVS4qMfFRAPw8g.jpg",
                                        "height": 1200,
                                        "iso6391": "en",
                                        "voteAverage": 4.956,
                                        "voteCount": 18,
                                        "width": 800
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/j0k3FQUwDwCYbA0km9ETx64NcR0.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/3fxf7OTYZRx6Rm3u7oWTbnlLB06.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gh9pIIZaO2mqzxQ9YpvLRFzEu0M.jpg",
                                        "height": 750,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.858,
                                        "height": 465,
                                        "iso_639_1": "en",
                                        "file_path": "/2yb6AxqyeB0CeYg8kGxWfrMl7xZ.png",
                                        "vote_average": 5.318,
                                        "vote_count": 3,
                                        "width": 1329
                                    },
                                    {
                                        "aspect_ratio": 4.248,
                                        "height": 294,
                                        "iso_639_1": "en",
                                        "file_path": "/wpEeWDClcIflQ9hIk3ziFwf6cgE.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 1249
                                    },
                                    {
                                        "aspect_ratio": 2.926,
                                        "height": 1293,
                                        "iso_639_1": "en",
                                        "file_path": "/12vuNpBY8NmLqQ24awLt2WOc0GN.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 3783
                                    },
                                    {
                                        "aspect_ratio": 2.938,
                                        "height": 1293,
                                        "iso_639_1": "en",
                                        "file_path": "/utO7Y2AZpPhCDHmtRflKopiVii5.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 3799
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6194cd08d768fe0062d9a90d",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "X5ibkB5ftH0",
                                        "name": "Spider-Man: No Way Home – Offizieller Trailer (deutsch HD)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-11-17T08:20:28.000Z"
                                    },
                                    {
                                        "id": "6124955bd6c30000469de42a",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "HNc3QiYtPwI",
                                        "name": "Spider-Man: No Way Home – Trailer A (deutsch HD)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-08-24T02:03:32.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "M",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-13T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Los Angeles, California"
                                            },
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PH",
                                        "releaseDates": [
                                            {
                                                "certification": "R-13",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PE",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": "普遍級",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IE",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": "A.P. - 12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HK",
                                        "releaseDates": [
                                            {
                                                "certification": "IIA",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "12+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-04-15T00:00:00.000Z",
                                                "type": 5,
                                                "note": "DVD, Blu-Ray & 4K UHD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2022-06-29T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IN",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "MX",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 200000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "description": "Movies I want to see",
                                        "favorite_count": 0,
                                        "id": 126845,
                                        "item_count": 11,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Wanna See"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7104032,
                                        "item_count": 13,
                                        "iso_639_1": "tr",
                                        "list_type": "movie",
                                        "name": "Özel"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 30,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8169542,
                                        "item_count": 33,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Quero ver"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3171,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5918,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "description": "List of movies to watch/get!",
                                        "favorite_count": 0,
                                        "id": 111568,
                                        "item_count": 9,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies to Get"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168656,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDBNinjaList"
                                    },
                                    {
                                        "description": "I FILM PIÙ BELLI DA VEDERE",
                                        "favorite_count": 0,
                                        "id": 7106817,
                                        "item_count": 488,
                                        "iso_639_1": "it",
                                        "list_type": "movie",
                                        "name": "I FILM PIÙ BELLI DA VEDERE "
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112629,
                                        "item_count": 18,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "High"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7088287,
                                        "item_count": 191,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Filmes que Assisti"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7067111,
                                        "item_count": 127,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies I want to see"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173905,
                                        "item_count": 18,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Best of 2021"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7064739,
                                        "item_count": 208,
                                        "iso_639_1": "sv",
                                        "list_type": "movie",
                                        "name": "Sedda Filmer"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168662,
                                        "item_count": 55,
                                        "iso_639_1": "be",
                                        "list_type": "movie",
                                        "name": "lista"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 113444,
                                        "item_count": 400,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 787,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8174000,
                                        "item_count": 1,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Niki collection"
                                    },
                                    {
                                        "description": "Después de EndGame ",
                                        "favorite_count": 0,
                                        "id": 112287,
                                        "item_count": 124,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Películas vistas D.E."
                                    },
                                    {
                                        "description": "The movies I've watched. Shortfilms and feature films",
                                        "favorite_count": 0,
                                        "id": 6449,
                                        "item_count": 942,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "vistas/viewed",
                                        "poster_path": "/aCof58P1vfvoyVZs4OgkJghwSKH.jpg"
                                    }
                                ],
                                "total_pages": 24,
                                "total_results": 467
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 17:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/inkf4d0azoj9glq8zyg3wzaqibl.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:46",
                        "updateTimestamp": "2022-01-03 10:31:46",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    },
                    {
                        "statistics": null,
                        "requestId": "5544368",
                        "cmsId": "61ccb97697599fbeaafe092d",
                        "eventTypeId": "256715",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                        "expireDate": null,
                        "duration": "159",
                        "name": "Spider-Man: No Way Home",
                        "slug": "spider-man-no-way-home",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:46",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172020",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "634649m",
                        "externalData": {
                            "lastUpdated": 1641303020,
                            "id": 634649,
                            "cineamoId": "634649m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                            "genres": [
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 12,
                                    "name": "Abenteuer"
                                },
                                {
                                    "id": 878,
                                    "name": "Science Fiction"
                                }
                            ],
                            "homepage": "https://www.sonypictures.de/film/spider-man-no-way-home",
                            "imdbId": "tt10872600",
                            "originalLanguage": "en",
                            "originalTitle": "Spider-Man: No Way Home",
                            "overview": "Peter Parker ist demaskiert und kann sein normales Leben nicht mehr von den hohen Einsätzen als Superheld trennen. Als er Doctor Strange um Hilfe bittet, wird die Lage noch gefährlicher und er muss entdecken, was es wirklich bedeutet, Spider-Man zu sein.",
                            "popularity": 10314.745,
                            "posterPath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                            "releaseDate": "2021-12-15",
                            "revenue": 1368892000,
                            "runtime": 159,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Das entfesselte Multiversum!",
                            "title": "Spider-Man: No Way Home",
                            "video": false,
                            "voteAverage": 8.4,
                            "voteCount": 3379,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/1Rr5SrvHxMXHu5RjKpaMba8VTzi.jpg",
                                        "height": 1125,
                                        "iso6391": null,
                                        "voteAverage": 5.396,
                                        "voteCount": 12,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 1.775,
                                        "filePath": "/vq4Wjw77JdACKVg3tWCzegbL4Uz.jpg",
                                        "height": 845,
                                        "iso6391": null,
                                        "voteAverage": 5.264,
                                        "voteCount": 8,
                                        "width": 1500
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/n7sgwV1TNHUXOf7Aoh7Cr8gWvlZ.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.252,
                                        "voteCount": 4,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/npCYreQhp6XUBhRO4JZbCbv6sV2.jpg",
                                        "height": 920,
                                        "iso6391": null,
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 1636
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/aRNMwIvEnYEMwGHLCAACyuq2I1K.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.226,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5BjetzdVpWkPNKUIlpasnXVSwCP.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.226,
                                        "voteCount": 15,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vIPIyTJqcgOKgKcExCvTDpLpTYW.jpg",
                                        "height": 1897,
                                        "iso6391": null,
                                        "voteAverage": 5.22,
                                        "voteCount": 13,
                                        "width": 3373
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rWiPrQ4XqbuLg4wP2ufM0dsBRCQ.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.206,
                                        "voteCount": 9,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/wA2t7nsO10swf2Snpd6XM5a6yLR.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/vK18znei8Uha2z7ZhZtBa40HIrm.jpg",
                                        "height": 1449,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 2576
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/8L4mdjd9Zuxju3cDB3spgJ8AOPw.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/jv2TYSSh74C7xToMAzmcbOqOvQu.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/VlHt27nCqOuTnuX6bku8QZapzO.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 18,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lCGjoVl2MRGIO9bAJ9LHL2XiHOQ.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/5Ra4VVJTPCx79mCRDo8CLvM0qRz.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/n96AMk265MLWbjnOwWsXAAIXpem.jpg",
                                        "height": 1890,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 3360
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gespPE81A2RYvMxl9XaVNvIgevS.jpg",
                                        "height": 1080,
                                        "iso6391": null,
                                        "voteAverage": 5.146,
                                        "voteCount": 10,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3HOIbNlir4Xej4E4sfefyLGZXo.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.09,
                                        "voteCount": 11,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/p50iHxNWIo8teI2m0QVFGuOl0od.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/uhYpkLSnlOXTQDmNnu1X4MHMt1S.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/iQFcwSGbZXMkeyKrxbPnwnRo5fl.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1g0dhYtq4irTY1GPXvft6k4YLjm.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.976,
                                        "voteCount": 52,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1z7ZQhga4AcSkQNqEU3Tf9XpxoX.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.648,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/iNKf4D0AzOj9GLq8ZyG3WZaqibL.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.522,
                                        "voteCount": 4,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1IZbnnJYXIvgQOwqxSzvxA0bwmp.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ly6sDd1TTW7c8aXp0GohAq8zrbV.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/2W2Wi6nlnmVvlmJumueejpj5wzO.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/cmUwP6WPTmDCBPYY1Jpxw97KOKF.jpg",
                                        "height": 1500,
                                        "iso6391": "de",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/1gxZrx9gL9ov2c1NpXimEUzMTmh.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.276,
                                        "voteCount": 35,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/9lnlNvYzQ5FnbZLXzYaYHyRCFWO.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.264,
                                        "voteCount": 8,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5pVJ9SuuO72IgN6i9kMwQwnhGHG.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.242,
                                        "voteCount": 40,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.666,
                                        "filePath": "/zAEV3KFOKgITIvl8dSS9yiWsApF.jpg",
                                        "height": 980,
                                        "iso6391": "en",
                                        "voteAverage": 5.19,
                                        "voteCount": 5,
                                        "width": 653
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/vwo6JMU0tOnnlEmrdXF8DlJr6Yb.jpg",
                                        "height": 2100,
                                        "iso6391": "de",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/34Ks9lSiOAPpW13tb0m4PMUkVYc.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/7kMZKtPH8p0P9HKdDQ2veJU8BkK.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ttvFnwlLPuvOu3ksp8L7AtwjO2m.jpg",
                                        "height": 2100,
                                        "iso6391": "de",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1400
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hQDShpLeeSNAqKCdtv0LBEEoy5t.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/jtu4rWm87QkUsHLoe66O9cmYqEP.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.08,
                                        "voteCount": 9,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/eBsGzQP1H0qzztUfXAkCJ2rVlqT.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.068,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5RjyUm9HP2ZRwvqCqK9sDqlQHxa.jpg",
                                        "height": 1668,
                                        "iso6391": "en",
                                        "voteAverage": 5.048,
                                        "voteCount": 25,
                                        "width": 1112
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/chf1DV4fxX1vGNEu4tu9sOnrCvw.jpg",
                                        "height": 1800,
                                        "iso6391": "en",
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 1215
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/kkv8BYhO1WesSRoRWfb8cpApI97.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.034,
                                        "voteCount": 12,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/byOlXN8rf9cQNobtV5t8t4gJ0uT.jpg",
                                        "height": 2965,
                                        "iso6391": "en",
                                        "voteAverage": 4.968,
                                        "voteCount": 11,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/s7IEL0hDL1A8rzVS4qMfFRAPw8g.jpg",
                                        "height": 1200,
                                        "iso6391": "en",
                                        "voteAverage": 4.956,
                                        "voteCount": 18,
                                        "width": 800
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/j0k3FQUwDwCYbA0km9ETx64NcR0.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/3fxf7OTYZRx6Rm3u7oWTbnlLB06.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gh9pIIZaO2mqzxQ9YpvLRFzEu0M.jpg",
                                        "height": 750,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 500
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 2.858,
                                        "height": 465,
                                        "iso_639_1": "en",
                                        "file_path": "/2yb6AxqyeB0CeYg8kGxWfrMl7xZ.png",
                                        "vote_average": 5.318,
                                        "vote_count": 3,
                                        "width": 1329
                                    },
                                    {
                                        "aspect_ratio": 4.248,
                                        "height": 294,
                                        "iso_639_1": "en",
                                        "file_path": "/wpEeWDClcIflQ9hIk3ziFwf6cgE.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 1249
                                    },
                                    {
                                        "aspect_ratio": 2.926,
                                        "height": 1293,
                                        "iso_639_1": "en",
                                        "file_path": "/12vuNpBY8NmLqQ24awLt2WOc0GN.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 3783
                                    },
                                    {
                                        "aspect_ratio": 2.938,
                                        "height": 1293,
                                        "iso_639_1": "en",
                                        "file_path": "/utO7Y2AZpPhCDHmtRflKopiVii5.png",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 3799
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "6194cd08d768fe0062d9a90d",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "X5ibkB5ftH0",
                                        "name": "Spider-Man: No Way Home – Offizieller Trailer (deutsch HD)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-11-17T08:20:28.000Z"
                                    },
                                    {
                                        "id": "6124955bd6c30000469de42a",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "HNc3QiYtPwI",
                                        "name": "Spider-Man: No Way Home – Trailer A (deutsch HD)",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-08-24T02:03:32.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SK",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "M",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-13T00:00:00.000Z",
                                                "type": 1,
                                                "note": "Los Angeles, California"
                                            },
                                            {
                                                "certification": "PG-13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PH",
                                        "releaseDates": [
                                            {
                                                "certification": "R-13",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-08T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PE",
                                        "releaseDates": [
                                            {
                                                "certification": "PG-12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": "普遍級",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IE",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RO",
                                        "releaseDates": [
                                            {
                                                "certification": "A.P. - 12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HK",
                                        "releaseDates": [
                                            {
                                                "certification": "IIA",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "12+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-04-15T00:00:00.000Z",
                                                "type": 5,
                                                "note": "DVD, Blu-Ray & 4K UHD"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2022-06-29T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IN",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-17T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "MX",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "12A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-15T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 200000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "description": "Movies I want to see",
                                        "favorite_count": 0,
                                        "id": 126845,
                                        "item_count": 11,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Wanna See"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7104032,
                                        "item_count": 13,
                                        "iso_639_1": "tr",
                                        "list_type": "movie",
                                        "name": "Özel"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 30,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8169542,
                                        "item_count": 33,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Quero ver"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3171,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5918,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "description": "List of movies to watch/get!",
                                        "favorite_count": 0,
                                        "id": 111568,
                                        "item_count": 9,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies to Get"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168656,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDBNinjaList"
                                    },
                                    {
                                        "description": "I FILM PIÙ BELLI DA VEDERE",
                                        "favorite_count": 0,
                                        "id": 7106817,
                                        "item_count": 488,
                                        "iso_639_1": "it",
                                        "list_type": "movie",
                                        "name": "I FILM PIÙ BELLI DA VEDERE "
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7112629,
                                        "item_count": 18,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "High"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7088287,
                                        "item_count": 191,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Filmes que Assisti"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7067111,
                                        "item_count": 127,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies I want to see"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173905,
                                        "item_count": 18,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Best of 2021"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7064739,
                                        "item_count": 208,
                                        "iso_639_1": "sv",
                                        "list_type": "movie",
                                        "name": "Sedda Filmer"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168662,
                                        "item_count": 55,
                                        "iso_639_1": "be",
                                        "list_type": "movie",
                                        "name": "lista"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 113444,
                                        "item_count": 400,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 787,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8174000,
                                        "item_count": 1,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Niki collection"
                                    },
                                    {
                                        "description": "Después de EndGame ",
                                        "favorite_count": 0,
                                        "id": 112287,
                                        "item_count": 124,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Películas vistas D.E."
                                    },
                                    {
                                        "description": "The movies I've watched. Shortfilms and feature films",
                                        "favorite_count": 0,
                                        "id": 6449,
                                        "item_count": 942,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "vistas/viewed",
                                        "poster_path": "/aCof58P1vfvoyVZs4OgkJghwSKH.jpg"
                                    }
                                ],
                                "total_pages": 24,
                                "total_results": 467
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 19:45:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/inkf4d0azoj9glq8zyg3wzaqibl.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:46",
                        "updateTimestamp": "2022-01-03 10:31:46",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            },
            {
                "eventTypeId": "261487",
                "cinemaId": "1020",
                "cinemaName": null,
                "description": "Von seltsamen Erinnerungen geplagt, nimmt Neos Leben eine unerwartete Wendung, als er sich in der Matrix wiederfindet.",
                "duration": "148",
                "name": "Matrix Resurrections",
                "slug": "matrix-resurrections",
                "category": "0",
                "ageRating": null,
                "externalId": "624860m",
                "externalData": {
                    "lastUpdated": 1641296625,
                    "id": 624860,
                    "cineamoId": "624860m",
                    "series": false,
                    "selectedByCinema": false,
                    "adult": false,
                    "backdropPath": "/hv7o3VgfsairBoQFAawgaQ4cR1m.jpg",
                    "genres": [
                        {
                            "id": 28,
                            "name": "Action"
                        },
                        {
                            "id": 878,
                            "name": "Science Fiction"
                        }
                    ],
                    "homepage": "https://www.whatisthematrix.com",
                    "imdbId": "tt10838180",
                    "originalLanguage": "en",
                    "originalTitle": "The Matrix Resurrections",
                    "overview": "Thomas Anderson (Keanu Reeves) lebt ein normales Leben, arbeitet für eine große Firma und geht regelmäßig zur Therapie, wo sein Therapeut (Neil Patrick Harris) versucht, ihm seine Traumata auszutreiben. Denn Thomas hat Probleme damit, Realität und Fiktion auseinanderzuhalten. Er kann doch nicht wirklich einst als Auserwählter Neo in den Krieg gegen die Maschinen gezogen sein, oder? Mithilfe von Medikamenten (jeder Menge blauer Pillen) gelingt es Thomas Anderson jedoch, ein geregeltes Leben zu führen. Doch dann trifft er eines Tages in einem Café auf eine Frau namens Tiffany (Carrie-Anne Moss), die ihm irgendwie bekannt vorkommt, die ihn allerdings nicht zu erkennen scheint. Doch Thomas ist sich sicher: Hier ist irgendetwas faul – und er wird herausfinden, was…",
                    "popularity": 8479.71,
                    "posterPath": "/hF7jgEhP4ypR2oS5BQD1pxbg7QY.jpg",
                    "releaseDate": "2021-12-16",
                    "revenue": 69000000,
                    "runtime": 148,
                    "firstAirDate": null,
                    "status": "Released",
                    "tagline": "Zurück zum Ursprung",
                    "title": "Matrix Resurrections",
                    "video": false,
                    "voteAverage": 7.1,
                    "voteCount": 1596,
                    "images": {
                        "id": null,
                        "backdrops": [
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/hv7o3VgfsairBoQFAawgaQ4cR1m.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.392,
                                "voteCount": 8,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/dB0CxRwLXJoOrRPUvsJjb2hnPtq.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.258,
                                "voteCount": 6,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/hjAI67s5P3QO8Tl04nLV0rpqbID.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/3bmJdYwXIEVa48l5iL5qxpkSDp4.jpg",
                                "height": 2160,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/eNI7PtK6DEYgZmHWP9gQNuff8pv.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.198,
                                "voteCount": 7,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/3NiiRAKt2L5bUuAvSOkv6Yn7u6T.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.138,
                                "voteCount": 8,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/9Rj9Z9jwzaKVqjF4Uv4WqrU0tqU.jpg",
                                "height": 1080,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/pwZZjwx4PS00uwB37451S80CbA8.jpg",
                                "height": 1080,
                                "iso6391": "en",
                                "voteAverage": 5.118,
                                "voteCount": 4,
                                "width": 1920
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/l3SrBvDk9XyqWF7jK0wXBsQ2jGb.jpg",
                                "height": 1555,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 2764
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/4E1fP7IyU3Ky8iJZy7X5ZrD1N9V.jpg",
                                "height": 1555,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 2764
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/lthbvAMIjuA44jSSdNCHNTx6jM3.jpg",
                                "height": 1555,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 2764
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/k0Cuybu9ECuc47ODlHcIP8TJCZi.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.106,
                                "voteCount": 2,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/gsRugpNWZoU4gEnvuUWVSkeKN9v.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.777,
                                "filePath": "/gzPdp9IVQHqFRaQ9e6qcENG1ys.jpg",
                                "height": 1555,
                                "iso6391": null,
                                "voteAverage": 5.044,
                                "voteCount": 3,
                                "width": 2764
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/4VGFbdUMLzVHiwTRWyn2H8ABhDg.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 4.996,
                                "voteCount": 6,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/rWZC1dOapXyhNfJ7xUU1P6giTwc.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/uo2cEWr8Sl1qQ8xBTxEZg1rKTiy.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            },
                            {
                                "aspectRatio": 1.778,
                                "filePath": "/lOfNGZFGonu8ZJ2SSE3wp2ZLAtX.jpg",
                                "height": 2160,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 3840
                            }
                        ],
                        "posters": [
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/8c4a8kE7PizaGQQnditMmI1xbRp.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.456,
                                "voteCount": 11,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/aSZiRMu05JqjvjUac8W3Y5TbtgG.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.456,
                                "voteCount": 7,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hF7jgEhP4ypR2oS5BQD1pxbg7QY.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 5.384,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/xLNtaLaHudIzOqdEZ7R3lcDLrQQ.jpg",
                                "height": 2048,
                                "iso6391": "en",
                                "voteAverage": 5.346,
                                "voteCount": 17,
                                "width": 1382
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gjpM7NNfI5memp40mwqF1zxlLfz.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.326,
                                "voteCount": 7,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/6n0hjR5Z1gqx9abEBzjSThpGCj0.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/cyLN1SPX5OhMEuUMGAIXrEqQRON.jpg",
                                "height": 3000,
                                "iso6391": "de",
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.698,
                                "filePath": "/tBA09E7q4iKGgWoFWh1QaD227kQ.jpg",
                                "height": 1200,
                                "iso6391": "en",
                                "voteAverage": 5.318,
                                "voteCount": 3,
                                "width": 837
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/dUux8uVOTIRvnhHQPM37IQEZOso.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/gZlZLxJMfnSeS60abFZMh1IvODQ.jpg",
                                "height": 2250,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 1500
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/vl9x5pX7szzsBn08tgn4yE5iz7s.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 5.312,
                                "voteCount": 1,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/ijQafdZa2tINIzd9D4DDAEMjRaS.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.282,
                                "voteCount": 14,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/6aNRxxRjLF4xH2m2bLr4aC2rDea.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.27,
                                "voteCount": 10,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/y5bUHUJsvni24e8Dz3J8FZUL0dW.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.258,
                                "voteCount": 6,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/te1osuYNkmYOUj2olwFrfE3V6bz.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hNzmOOldBI90tuZHYGYfsgH6Z25.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.246,
                                "voteCount": 2,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/tOqTTslEfjyvoBoBM4B29aeqntt.jpg",
                                "height": 948,
                                "iso6391": "en",
                                "voteAverage": 5.198,
                                "voteCount": 7,
                                "width": 640
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/eqYvTerwJBo5TfwH1EP5lZHEPsK.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.198,
                                "voteCount": 7,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.675,
                                "filePath": "/yNvhHdcRrRDaCPj1prehRxDv8Vc.jpg",
                                "height": 1186,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 800
                            },
                            {
                                "aspectRatio": 0.714,
                                "filePath": "/xjUYbSdXbgXoA5KOrWlFps52DCD.jpg",
                                "height": 1131,
                                "iso6391": "en",
                                "voteAverage": 5.18,
                                "voteCount": 3,
                                "width": 808
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/o67Kak3mE6lupmolB0lZiMkJT2i.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/dLZoXkbwaKhsDv25LTkFa5nwHqV.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/YEXAgc82jnPMUfJ9xvEKOUs6Pg.jpg",
                                "height": 750,
                                "iso6391": "en",
                                "voteAverage": 5.172,
                                "voteCount": 1,
                                "width": 500
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/Al3xmuW8xe5KPEGqy6wJejiLURv.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/zzCvzEyr5ewNZ2jyJV7zY9XR105.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/m1KcDQIznHIctIFsUiATW6bSsRi.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/daThrZDJeqRnhqAVl652QK7lM6e.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/A7pYQrXvvYcrjdykLY0mfKDISQ.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/6ufUN4AEY6GVUogEygbdSP30FsU.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/pN0n2IBve1dtyzQg7gv5MyRYALh.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.679,
                                "filePath": "/iSHxJvp6DY0BzaBlF8z8GbIoQiN.jpg",
                                "height": 1236,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 839
                            },
                            {
                                "aspectRatio": 0.679,
                                "filePath": "/7D2BSfUM9NKDDELVIfURikgHzEq.jpg",
                                "height": 1236,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 839
                            },
                            {
                                "aspectRatio": 0.679,
                                "filePath": "/2Vx98vFryEr6TVBpHIctg8szIeq.jpg",
                                "height": 1236,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 839
                            },
                            {
                                "aspectRatio": 0.679,
                                "filePath": "/AtbGhz8zHqof53atBp6Ze8UwylA.jpg",
                                "height": 1236,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 839
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/5OHdnI7jjIn6jJXEBdgM0r0IbNe.jpg",
                                "height": 900,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 600
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/hxd5duFW23jEck2oXykqUdeeMQh.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/s5WJzgyhIxBnpkf1S9qteY2KovN.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/dFKynRGqACG1nVXMCFq6zEGFTTV.jpg",
                                "height": 1500,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 1000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/dnF1P5FmjUHJUHUduxAoXgpd3nM.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/xaJy6zESJMn2wXcRBYLqjLF4fZ0.jpg",
                                "height": 3000,
                                "iso6391": "en",
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            },
                            {
                                "aspectRatio": 0.667,
                                "filePath": "/wQBoiOLeCxbLFjoAUzv0Phhr8Yc.jpg",
                                "height": 3000,
                                "iso6391": null,
                                "voteAverage": 0,
                                "voteCount": 0,
                                "width": 2000
                            }
                        ],
                        "logos": [
                            {
                                "aspect_ratio": 4.502,
                                "height": 956,
                                "iso_639_1": "en",
                                "file_path": "/miTr6IeG51il2U2HDykozKfu0Xn.png",
                                "vote_average": 5.384,
                                "vote_count": 2,
                                "width": 4304
                            },
                            {
                                "aspect_ratio": 4.503,
                                "height": 173,
                                "iso_639_1": "en",
                                "file_path": "/yjxeUjfIWeyIZxhNXs8TAP8kij2.png",
                                "vote_average": 5.312,
                                "vote_count": 1,
                                "width": 779
                            },
                            {
                                "aspect_ratio": 4.539,
                                "height": 362,
                                "iso_639_1": "en",
                                "file_path": "/oocKoWbxXnCImeGSkgNSHRg6s4N.png",
                                "vote_average": 5.172,
                                "vote_count": 1,
                                "width": 1643
                            },
                            {
                                "aspect_ratio": 4.562,
                                "height": 384,
                                "iso_639_1": "en",
                                "file_path": "/sE6lFIwfgIYLGNhuJSWtN6nehKY.svg",
                                "vote_average": 0,
                                "vote_count": 0,
                                "width": 1752
                            }
                        ]
                    },
                    "videos": {
                        "id": null,
                        "results": [
                            {
                                "id": "61b7a07ee263bb0041cb80de",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "5vXLo9GJTxk",
                                "name": "Matrix Resurrections - Featurette - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Featurette",
                                "official": true,
                                "publishedAt": "2021-12-07T15:22:44.000Z"
                            },
                            {
                                "id": "61af10a56dea3a0060a9afa5",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "TCVe1eY8cYs",
                                "name": "Matrix Resurrections - Trailer #2 - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-12-06T17:00:16.000Z"
                            },
                            {
                                "id": "613a07b3c68b69002bc2eb57",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "IJ6zlh5enpc",
                                "name": "Matrix Resurrections - Offizieller Trailer #1 - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Trailer",
                                "official": true,
                                "publishedAt": "2021-09-09T13:00:17.000Z"
                            },
                            {
                                "id": "6138bb70da9ef20090c5905f",
                                "iso6391": "de",
                                "iso31661": "DE",
                                "key": "XvsLA8WmAf0",
                                "name": "Matrix Resurrections - Teaser Trailer #1 - Deutsch HD",
                                "site": "YouTube",
                                "size": 1080,
                                "type": "Teaser",
                                "official": true,
                                "publishedAt": "2021-09-07T16:00:14.000Z"
                            }
                        ]
                    },
                    "releaseDates": {
                        "id": null,
                        "results": [
                            {
                                "iso31661": "GB",
                                "releaseDates": [
                                    {
                                        "certification": "15",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TW",
                                "releaseDates": [
                                    {
                                        "certification": "保護級",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "KR",
                                "releaseDates": [
                                    {
                                        "certification": "15",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AU",
                                "releaseDates": [
                                    {
                                        "certification": "M",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-26T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IE",
                                "releaseDates": [
                                    {
                                        "certification": "15A",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "ES",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "RU",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PL",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SG",
                                "releaseDates": [
                                    {
                                        "certification": "PG13",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CZ",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-23T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "GR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-23T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "SE",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "IT",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2022-01-01T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "NL",
                                "releaseDates": [
                                    {
                                        "certification": "12",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-16T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "AR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-23T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FR",
                                "releaseDates": [
                                    {
                                        "certification": "U",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "CA",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "DE",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-23T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2022-06-30T00:00:00.000Z",
                                        "type": 5,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "UA",
                                "releaseDates": [
                                    {
                                        "certification": "16+",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "FI",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "BR",
                                "releaseDates": [
                                    {
                                        "certification": "14",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "14",
                                        "iso6391": null,
                                        "releaseDate": "2022-01-26T00:00:00.000Z",
                                        "type": 4,
                                        "note": "HBO Max"
                                    }
                                ]
                            },
                            {
                                "iso31661": "US",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-18T00:00:00.000Z",
                                        "type": 1,
                                        "note": "San Francisco, CA"
                                    },
                                    {
                                        "certification": "R",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    },
                                    {
                                        "certification": "R",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 4,
                                        "note": "HBO Max"
                                    }
                                ]
                            },
                            {
                                "iso31661": "CH",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "PT",
                                "releaseDates": [
                                    {
                                        "certification": "M/14",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "TR",
                                "releaseDates": [
                                    {
                                        "certification": null,
                                        "iso6391": null,
                                        "releaseDate": "2021-12-24T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            },
                            {
                                "iso31661": "HU",
                                "releaseDates": [
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-22T00:00:00.000Z",
                                        "type": 1,
                                        "note": null
                                    },
                                    {
                                        "certification": "16",
                                        "iso6391": null,
                                        "releaseDate": "2021-12-23T00:00:00.000Z",
                                        "type": 3,
                                        "note": null
                                    }
                                ]
                            }
                        ]
                    },
                    "noRightsAvailable": false,
                    "noRightsTimestamp": null,
                    "budget": 190000000,
                    "lists": {
                        "page": 1,
                        "results": [
                            {
                                "favorite_count": 0,
                                "id": 7104032,
                                "item_count": 13,
                                "iso_639_1": "tr",
                                "list_type": "movie",
                                "name": "Özel"
                            },
                            {
                                "description": "A list of Movies running in Theaters",
                                "favorite_count": 0,
                                "id": 63802,
                                "item_count": 30,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Now Running"
                            },
                            {
                                "description": "watched movies",
                                "favorite_count": 0,
                                "id": 44662,
                                "item_count": 1863,
                                "iso_639_1": "ar",
                                "list_type": "movie",
                                "name": "Watched"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7053491,
                                "item_count": 3171,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD (Popular)"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7109840,
                                "item_count": 5918,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "New HD"
                            },
                            {
                                "favorite_count": 0,
                                "id": 143813,
                                "item_count": 5336,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "MNR"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8173719,
                                "item_count": 10,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "To watch"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8168656,
                                "item_count": 51,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "TMDBNinjaList"
                            },
                            {
                                "favorite_count": 0,
                                "id": 80639,
                                "item_count": 745,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "Películas"
                            },
                            {
                                "description": "Filmes que assisti em 2022, independente do ano de lançamento...",
                                "favorite_count": 0,
                                "id": 8173897,
                                "item_count": 3,
                                "iso_639_1": "pt",
                                "list_type": "movie",
                                "name": "Filmes 2022"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8173900,
                                "item_count": 8,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Want to Watch"
                            },
                            {
                                "description": "Master List In Progress",
                                "favorite_count": 0,
                                "id": 51108,
                                "item_count": 8926,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "My Movies"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8174019,
                                "item_count": 3,
                                "iso_639_1": "de",
                                "list_type": "movie",
                                "name": "Muss man nicht sehen"
                            },
                            {
                                "favorite_count": 0,
                                "id": 8168662,
                                "item_count": 55,
                                "iso_639_1": "be",
                                "list_type": "movie",
                                "name": "lista"
                            },
                            {
                                "favorite_count": 0,
                                "id": 89943,
                                "item_count": 787,
                                "iso_639_1": "fr",
                                "list_type": "movie",
                                "name": "Clem2k"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7111843,
                                "item_count": 11,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "awk-player.watch-later-list.#preset_0"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7050333,
                                "item_count": 756,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Seen"
                            },
                            {
                                "favorite_count": 0,
                                "id": 142602,
                                "item_count": 1092,
                                "iso_639_1": "es",
                                "list_type": "movie",
                                "name": "Accion"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7144,
                                "item_count": 5278,
                                "iso_639_1": "en",
                                "list_type": "movie",
                                "name": "Latest Releases"
                            },
                            {
                                "favorite_count": 0,
                                "id": 7107699,
                                "item_count": 64,
                                "iso_639_1": "pt",
                                "list_type": "movie",
                                "name": "ESTREIAS CINEMA"
                            }
                        ],
                        "total_pages": 15,
                        "total_results": 288
                    }
                },
                "ownContent": false,
                "eventImage": "https://image.tmdb.org/t/p/original/hf7jgehp4ypr2os5bqd1pxbg7qy.jpg",
                "events": [
                    {
                        "statistics": null,
                        "requestId": "5544336",
                        "cmsId": "61ccb97697599fbeaafe091b",
                        "eventTypeId": "261487",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Thomas Anderson (Keanu Reeves) lebt ein normales Leben, arbeitet für eine große Firma und geht regelmäßig zur Therapie, wo sein Therapeut (Neil Patrick Harris) versucht, ihm seine Traumata auszutreiben. Denn Thomas hat Probleme damit, Realität und Fiktion auseinanderzuhalten. Er kann doch nicht wirklich einst als Auserwählter Neo in den Krieg gegen die Maschinen gezogen sein, oder? Mithilfe von Medikamenten (jeder Menge blauer Pillen) gelingt es Thomas Anderson jedoch, ein geregeltes Leben zu führen. Doch dann trifft er eines Tages in einem Café auf eine Frau namens Tiffany (Carrie-Anne Moss), die ihm irgendwie bekannt vorkommt, die ihn allerdings nicht zu erkennen scheint. Doch Thomas ist sich sicher: Hier ist irgendetwas faul – und er wird herausfinden, was…",
                        "expireDate": null,
                        "duration": "148",
                        "name": "Matrix Resurrections",
                        "slug": "matrix-resurrections",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:45",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172040",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "624860m",
                        "externalData": {
                            "lastUpdated": 1641296625,
                            "id": 624860,
                            "cineamoId": "624860m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/hv7o3VgfsairBoQFAawgaQ4cR1m.jpg",
                            "genres": [
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 878,
                                    "name": "Science Fiction"
                                }
                            ],
                            "homepage": "https://www.whatisthematrix.com",
                            "imdbId": "tt10838180",
                            "originalLanguage": "en",
                            "originalTitle": "The Matrix Resurrections",
                            "overview": "Thomas Anderson (Keanu Reeves) lebt ein normales Leben, arbeitet für eine große Firma und geht regelmäßig zur Therapie, wo sein Therapeut (Neil Patrick Harris) versucht, ihm seine Traumata auszutreiben. Denn Thomas hat Probleme damit, Realität und Fiktion auseinanderzuhalten. Er kann doch nicht wirklich einst als Auserwählter Neo in den Krieg gegen die Maschinen gezogen sein, oder? Mithilfe von Medikamenten (jeder Menge blauer Pillen) gelingt es Thomas Anderson jedoch, ein geregeltes Leben zu führen. Doch dann trifft er eines Tages in einem Café auf eine Frau namens Tiffany (Carrie-Anne Moss), die ihm irgendwie bekannt vorkommt, die ihn allerdings nicht zu erkennen scheint. Doch Thomas ist sich sicher: Hier ist irgendetwas faul – und er wird herausfinden, was…",
                            "popularity": 8479.71,
                            "posterPath": "/hF7jgEhP4ypR2oS5BQD1pxbg7QY.jpg",
                            "releaseDate": "2021-12-16",
                            "revenue": 69000000,
                            "runtime": 148,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Zurück zum Ursprung",
                            "title": "Matrix Resurrections",
                            "video": false,
                            "voteAverage": 7.1,
                            "voteCount": 1596,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/hv7o3VgfsairBoQFAawgaQ4cR1m.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.392,
                                        "voteCount": 8,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/dB0CxRwLXJoOrRPUvsJjb2hnPtq.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.258,
                                        "voteCount": 6,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/hjAI67s5P3QO8Tl04nLV0rpqbID.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3bmJdYwXIEVa48l5iL5qxpkSDp4.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/eNI7PtK6DEYgZmHWP9gQNuff8pv.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3NiiRAKt2L5bUuAvSOkv6Yn7u6T.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.138,
                                        "voteCount": 8,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9Rj9Z9jwzaKVqjF4Uv4WqrU0tqU.jpg",
                                        "height": 1080,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/pwZZjwx4PS00uwB37451S80CbA8.jpg",
                                        "height": 1080,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/l3SrBvDk9XyqWF7jK0wXBsQ2jGb.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/4E1fP7IyU3Ky8iJZy7X5ZrD1N9V.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/lthbvAMIjuA44jSSdNCHNTx6jM3.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/k0Cuybu9ECuc47ODlHcIP8TJCZi.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gsRugpNWZoU4gEnvuUWVSkeKN9v.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/gzPdp9IVQHqFRaQ9e6qcENG1ys.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/4VGFbdUMLzVHiwTRWyn2H8ABhDg.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 4.996,
                                        "voteCount": 6,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rWZC1dOapXyhNfJ7xUU1P6giTwc.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/uo2cEWr8Sl1qQ8xBTxEZg1rKTiy.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lOfNGZFGonu8ZJ2SSE3wp2ZLAtX.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/8c4a8kE7PizaGQQnditMmI1xbRp.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.456,
                                        "voteCount": 11,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/aSZiRMu05JqjvjUac8W3Y5TbtgG.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.456,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hF7jgEhP4ypR2oS5BQD1pxbg7QY.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.384,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/xLNtaLaHudIzOqdEZ7R3lcDLrQQ.jpg",
                                        "height": 2048,
                                        "iso6391": "en",
                                        "voteAverage": 5.346,
                                        "voteCount": 17,
                                        "width": 1382
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gjpM7NNfI5memp40mwqF1zxlLfz.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.326,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6n0hjR5Z1gqx9abEBzjSThpGCj0.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/cyLN1SPX5OhMEuUMGAIXrEqQRON.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.698,
                                        "filePath": "/tBA09E7q4iKGgWoFWh1QaD227kQ.jpg",
                                        "height": 1200,
                                        "iso6391": "en",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 837
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dUux8uVOTIRvnhHQPM37IQEZOso.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gZlZLxJMfnSeS60abFZMh1IvODQ.jpg",
                                        "height": 2250,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1500
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/vl9x5pX7szzsBn08tgn4yE5iz7s.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ijQafdZa2tINIzd9D4DDAEMjRaS.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.282,
                                        "voteCount": 14,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6aNRxxRjLF4xH2m2bLr4aC2rDea.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.27,
                                        "voteCount": 10,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/y5bUHUJsvni24e8Dz3J8FZUL0dW.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.258,
                                        "voteCount": 6,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/te1osuYNkmYOUj2olwFrfE3V6bz.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hNzmOOldBI90tuZHYGYfsgH6Z25.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/tOqTTslEfjyvoBoBM4B29aeqntt.jpg",
                                        "height": 948,
                                        "iso6391": "en",
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 640
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/eqYvTerwJBo5TfwH1EP5lZHEPsK.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/yNvhHdcRrRDaCPj1prehRxDv8Vc.jpg",
                                        "height": 1186,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 800
                                    },
                                    {
                                        "aspectRatio": 0.714,
                                        "filePath": "/xjUYbSdXbgXoA5KOrWlFps52DCD.jpg",
                                        "height": 1131,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 808
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/o67Kak3mE6lupmolB0lZiMkJT2i.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dLZoXkbwaKhsDv25LTkFa5nwHqV.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/YEXAgc82jnPMUfJ9xvEKOUs6Pg.jpg",
                                        "height": 750,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/Al3xmuW8xe5KPEGqy6wJejiLURv.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/zzCvzEyr5ewNZ2jyJV7zY9XR105.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/m1KcDQIznHIctIFsUiATW6bSsRi.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/daThrZDJeqRnhqAVl652QK7lM6e.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/A7pYQrXvvYcrjdykLY0mfKDISQ.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6ufUN4AEY6GVUogEygbdSP30FsU.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/pN0n2IBve1dtyzQg7gv5MyRYALh.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/iSHxJvp6DY0BzaBlF8z8GbIoQiN.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/7D2BSfUM9NKDDELVIfURikgHzEq.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/2Vx98vFryEr6TVBpHIctg8szIeq.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/AtbGhz8zHqof53atBp6Ze8UwylA.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5OHdnI7jjIn6jJXEBdgM0r0IbNe.jpg",
                                        "height": 900,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 600
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hxd5duFW23jEck2oXykqUdeeMQh.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/s5WJzgyhIxBnpkf1S9qteY2KovN.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dFKynRGqACG1nVXMCFq6zEGFTTV.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dnF1P5FmjUHJUHUduxAoXgpd3nM.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/xaJy6zESJMn2wXcRBYLqjLF4fZ0.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wQBoiOLeCxbLFjoAUzv0Phhr8Yc.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 4.502,
                                        "height": 956,
                                        "iso_639_1": "en",
                                        "file_path": "/miTr6IeG51il2U2HDykozKfu0Xn.png",
                                        "vote_average": 5.384,
                                        "vote_count": 2,
                                        "width": 4304
                                    },
                                    {
                                        "aspect_ratio": 4.503,
                                        "height": 173,
                                        "iso_639_1": "en",
                                        "file_path": "/yjxeUjfIWeyIZxhNXs8TAP8kij2.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 779
                                    },
                                    {
                                        "aspect_ratio": 4.539,
                                        "height": 362,
                                        "iso_639_1": "en",
                                        "file_path": "/oocKoWbxXnCImeGSkgNSHRg6s4N.png",
                                        "vote_average": 5.172,
                                        "vote_count": 1,
                                        "width": 1643
                                    },
                                    {
                                        "aspect_ratio": 4.562,
                                        "height": 384,
                                        "iso_639_1": "en",
                                        "file_path": "/sE6lFIwfgIYLGNhuJSWtN6nehKY.svg",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 1752
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "61b7a07ee263bb0041cb80de",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "5vXLo9GJTxk",
                                        "name": "Matrix Resurrections - Featurette - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Featurette",
                                        "official": true,
                                        "publishedAt": "2021-12-07T15:22:44.000Z"
                                    },
                                    {
                                        "id": "61af10a56dea3a0060a9afa5",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "TCVe1eY8cYs",
                                        "name": "Matrix Resurrections - Trailer #2 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-12-06T17:00:16.000Z"
                                    },
                                    {
                                        "id": "613a07b3c68b69002bc2eb57",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "IJ6zlh5enpc",
                                        "name": "Matrix Resurrections - Offizieller Trailer #1 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-09-09T13:00:17.000Z"
                                    },
                                    {
                                        "id": "6138bb70da9ef20090c5905f",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "XvsLA8WmAf0",
                                        "name": "Matrix Resurrections - Teaser Trailer #1 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Teaser",
                                        "official": true,
                                        "publishedAt": "2021-09-07T16:00:14.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": "保護級",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "M",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IE",
                                        "releaseDates": [
                                            {
                                                "certification": "15A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-01-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-06-30T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "16+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "14",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "14",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-26T00:00:00.000Z",
                                                "type": 4,
                                                "note": "HBO Max"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-18T00:00:00.000Z",
                                                "type": 1,
                                                "note": "San Francisco, CA"
                                            },
                                            {
                                                "certification": "R",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "R",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 4,
                                                "note": "HBO Max"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PT",
                                        "releaseDates": [
                                            {
                                                "certification": "M/14",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 190000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7104032,
                                        "item_count": 13,
                                        "iso_639_1": "tr",
                                        "list_type": "movie",
                                        "name": "Özel"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 30,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "description": "watched movies",
                                        "favorite_count": 0,
                                        "id": 44662,
                                        "item_count": 1863,
                                        "iso_639_1": "ar",
                                        "list_type": "movie",
                                        "name": "Watched"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3171,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5918,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143813,
                                        "item_count": 5336,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MNR"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173719,
                                        "item_count": 10,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "To watch"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168656,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDBNinjaList"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 80639,
                                        "item_count": 745,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Películas"
                                    },
                                    {
                                        "description": "Filmes que assisti em 2022, independente do ano de lançamento...",
                                        "favorite_count": 0,
                                        "id": 8173897,
                                        "item_count": 3,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Filmes 2022"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173900,
                                        "item_count": 8,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Want to Watch"
                                    },
                                    {
                                        "description": "Master List In Progress",
                                        "favorite_count": 0,
                                        "id": 51108,
                                        "item_count": 8926,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8174019,
                                        "item_count": 3,
                                        "iso_639_1": "de",
                                        "list_type": "movie",
                                        "name": "Muss man nicht sehen"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168662,
                                        "item_count": 55,
                                        "iso_639_1": "be",
                                        "list_type": "movie",
                                        "name": "lista"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 787,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7111843,
                                        "item_count": 11,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "awk-player.watch-later-list.#preset_0"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7050333,
                                        "item_count": 756,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Seen"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 142602,
                                        "item_count": 1092,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Accion"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7144,
                                        "item_count": 5278,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Latest Releases"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7107699,
                                        "item_count": 64,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "ESTREIAS CINEMA"
                                    }
                                ],
                                "total_pages": 15,
                                "total_results": 288
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 17:00:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/hf7jgehp4ypr2os5bqd1pxbg7qy.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:45",
                        "updateTimestamp": "2022-01-03 10:31:45",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    },
                    {
                        "statistics": null,
                        "requestId": "5544337",
                        "cmsId": "61ccb97697599fbeaafe091d",
                        "eventTypeId": "261487",
                        "userId": "2",
                        "username": "phil",
                        "userImageUrl": "admin/uploads/person-data/person-5c31cca1f15cc2-53946731.jpg",
                        "cinemaId": "1020",
                        "cinemaName": "Blue Boxx",
                        "description": "Thomas Anderson (Keanu Reeves) lebt ein normales Leben, arbeitet für eine große Firma und geht regelmäßig zur Therapie, wo sein Therapeut (Neil Patrick Harris) versucht, ihm seine Traumata auszutreiben. Denn Thomas hat Probleme damit, Realität und Fiktion auseinanderzuhalten. Er kann doch nicht wirklich einst als Auserwählter Neo in den Krieg gegen die Maschinen gezogen sein, oder? Mithilfe von Medikamenten (jeder Menge blauer Pillen) gelingt es Thomas Anderson jedoch, ein geregeltes Leben zu führen. Doch dann trifft er eines Tages in einem Café auf eine Frau namens Tiffany (Carrie-Anne Moss), die ihm irgendwie bekannt vorkommt, die ihn allerdings nicht zu erkennen scheint. Doch Thomas ist sich sicher: Hier ist irgendetwas faul – und er wird herausfinden, was…",
                        "expireDate": null,
                        "duration": "148",
                        "name": "Matrix Resurrections",
                        "slug": "matrix-resurrections",
                        "hospitality": null,
                        "category": "0",
                        "creationDate": "2022-01-03 10:31:45",
                        "acceptedCinema": true,
                        "acceptedUser": true,
                        "pending": true,
                        "private": false,
                        "uniqueId": null,
                        "assignedToCustomer": true,
                        "externalIdCount": null,
                        "copyrightId": null,
                        "commentCinema": null,
                        "commentCustomer": null,
                        "tmsUrl": "https://kinotickets.express/villingen-blueboxx/booking/172041",
                        "pricePerTicket": null,
                        "originalVoice": false,
                        "threeDimensional": false,
                        "viewCount": "0",
                        "likeCount": "0",
                        "confirmCount": "0",
                        "commentCount": "0",
                        "countVisitor": null,
                        "rating": null,
                        "regularEvent": true,
                        "createdByCinema": true,
                        "roomId": null,
                        "roomName": null,
                        "ageRating": null,
                        "facebookId": null,
                        "lastNotificationSent": null,
                        "externalId": "624860m",
                        "externalData": {
                            "lastUpdated": 1641296625,
                            "id": 624860,
                            "cineamoId": "624860m",
                            "series": false,
                            "selectedByCinema": false,
                            "adult": false,
                            "backdropPath": "/hv7o3VgfsairBoQFAawgaQ4cR1m.jpg",
                            "genres": [
                                {
                                    "id": 28,
                                    "name": "Action"
                                },
                                {
                                    "id": 878,
                                    "name": "Science Fiction"
                                }
                            ],
                            "homepage": "https://www.whatisthematrix.com",
                            "imdbId": "tt10838180",
                            "originalLanguage": "en",
                            "originalTitle": "The Matrix Resurrections",
                            "overview": "Thomas Anderson (Keanu Reeves) lebt ein normales Leben, arbeitet für eine große Firma und geht regelmäßig zur Therapie, wo sein Therapeut (Neil Patrick Harris) versucht, ihm seine Traumata auszutreiben. Denn Thomas hat Probleme damit, Realität und Fiktion auseinanderzuhalten. Er kann doch nicht wirklich einst als Auserwählter Neo in den Krieg gegen die Maschinen gezogen sein, oder? Mithilfe von Medikamenten (jeder Menge blauer Pillen) gelingt es Thomas Anderson jedoch, ein geregeltes Leben zu führen. Doch dann trifft er eines Tages in einem Café auf eine Frau namens Tiffany (Carrie-Anne Moss), die ihm irgendwie bekannt vorkommt, die ihn allerdings nicht zu erkennen scheint. Doch Thomas ist sich sicher: Hier ist irgendetwas faul – und er wird herausfinden, was…",
                            "popularity": 8479.71,
                            "posterPath": "/hF7jgEhP4ypR2oS5BQD1pxbg7QY.jpg",
                            "releaseDate": "2021-12-16",
                            "revenue": 69000000,
                            "runtime": 148,
                            "firstAirDate": null,
                            "status": "Released",
                            "tagline": "Zurück zum Ursprung",
                            "title": "Matrix Resurrections",
                            "video": false,
                            "voteAverage": 7.1,
                            "voteCount": 1596,
                            "images": {
                                "id": null,
                                "backdrops": [
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/hv7o3VgfsairBoQFAawgaQ4cR1m.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.392,
                                        "voteCount": 8,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/dB0CxRwLXJoOrRPUvsJjb2hnPtq.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.258,
                                        "voteCount": 6,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/hjAI67s5P3QO8Tl04nLV0rpqbID.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3bmJdYwXIEVa48l5iL5qxpkSDp4.jpg",
                                        "height": 2160,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/eNI7PtK6DEYgZmHWP9gQNuff8pv.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/3NiiRAKt2L5bUuAvSOkv6Yn7u6T.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.138,
                                        "voteCount": 8,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/9Rj9Z9jwzaKVqjF4Uv4WqrU0tqU.jpg",
                                        "height": 1080,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/pwZZjwx4PS00uwB37451S80CbA8.jpg",
                                        "height": 1080,
                                        "iso6391": "en",
                                        "voteAverage": 5.118,
                                        "voteCount": 4,
                                        "width": 1920
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/l3SrBvDk9XyqWF7jK0wXBsQ2jGb.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/4E1fP7IyU3Ky8iJZy7X5ZrD1N9V.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/lthbvAMIjuA44jSSdNCHNTx6jM3.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/k0Cuybu9ECuc47ODlHcIP8TJCZi.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.106,
                                        "voteCount": 2,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/gsRugpNWZoU4gEnvuUWVSkeKN9v.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.777,
                                        "filePath": "/gzPdp9IVQHqFRaQ9e6qcENG1ys.jpg",
                                        "height": 1555,
                                        "iso6391": null,
                                        "voteAverage": 5.044,
                                        "voteCount": 3,
                                        "width": 2764
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/4VGFbdUMLzVHiwTRWyn2H8ABhDg.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 4.996,
                                        "voteCount": 6,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/rWZC1dOapXyhNfJ7xUU1P6giTwc.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/uo2cEWr8Sl1qQ8xBTxEZg1rKTiy.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    },
                                    {
                                        "aspectRatio": 1.778,
                                        "filePath": "/lOfNGZFGonu8ZJ2SSE3wp2ZLAtX.jpg",
                                        "height": 2160,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 3840
                                    }
                                ],
                                "posters": [
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/8c4a8kE7PizaGQQnditMmI1xbRp.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.456,
                                        "voteCount": 11,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/aSZiRMu05JqjvjUac8W3Y5TbtgG.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.456,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hF7jgEhP4ypR2oS5BQD1pxbg7QY.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.384,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/xLNtaLaHudIzOqdEZ7R3lcDLrQQ.jpg",
                                        "height": 2048,
                                        "iso6391": "en",
                                        "voteAverage": 5.346,
                                        "voteCount": 17,
                                        "width": 1382
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gjpM7NNfI5memp40mwqF1zxlLfz.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.326,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6n0hjR5Z1gqx9abEBzjSThpGCj0.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/cyLN1SPX5OhMEuUMGAIXrEqQRON.jpg",
                                        "height": 3000,
                                        "iso6391": "de",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.698,
                                        "filePath": "/tBA09E7q4iKGgWoFWh1QaD227kQ.jpg",
                                        "height": 1200,
                                        "iso6391": "en",
                                        "voteAverage": 5.318,
                                        "voteCount": 3,
                                        "width": 837
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dUux8uVOTIRvnhHQPM37IQEZOso.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/gZlZLxJMfnSeS60abFZMh1IvODQ.jpg",
                                        "height": 2250,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1500
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/vl9x5pX7szzsBn08tgn4yE5iz7s.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 5.312,
                                        "voteCount": 1,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/ijQafdZa2tINIzd9D4DDAEMjRaS.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.282,
                                        "voteCount": 14,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6aNRxxRjLF4xH2m2bLr4aC2rDea.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.27,
                                        "voteCount": 10,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/y5bUHUJsvni24e8Dz3J8FZUL0dW.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.258,
                                        "voteCount": 6,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/te1osuYNkmYOUj2olwFrfE3V6bz.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hNzmOOldBI90tuZHYGYfsgH6Z25.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.246,
                                        "voteCount": 2,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/tOqTTslEfjyvoBoBM4B29aeqntt.jpg",
                                        "height": 948,
                                        "iso6391": "en",
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 640
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/eqYvTerwJBo5TfwH1EP5lZHEPsK.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.198,
                                        "voteCount": 7,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.675,
                                        "filePath": "/yNvhHdcRrRDaCPj1prehRxDv8Vc.jpg",
                                        "height": 1186,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 800
                                    },
                                    {
                                        "aspectRatio": 0.714,
                                        "filePath": "/xjUYbSdXbgXoA5KOrWlFps52DCD.jpg",
                                        "height": 1131,
                                        "iso6391": "en",
                                        "voteAverage": 5.18,
                                        "voteCount": 3,
                                        "width": 808
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/o67Kak3mE6lupmolB0lZiMkJT2i.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dLZoXkbwaKhsDv25LTkFa5nwHqV.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/YEXAgc82jnPMUfJ9xvEKOUs6Pg.jpg",
                                        "height": 750,
                                        "iso6391": "en",
                                        "voteAverage": 5.172,
                                        "voteCount": 1,
                                        "width": 500
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/Al3xmuW8xe5KPEGqy6wJejiLURv.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/zzCvzEyr5ewNZ2jyJV7zY9XR105.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/m1KcDQIznHIctIFsUiATW6bSsRi.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/daThrZDJeqRnhqAVl652QK7lM6e.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/A7pYQrXvvYcrjdykLY0mfKDISQ.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/6ufUN4AEY6GVUogEygbdSP30FsU.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/pN0n2IBve1dtyzQg7gv5MyRYALh.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/iSHxJvp6DY0BzaBlF8z8GbIoQiN.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/7D2BSfUM9NKDDELVIfURikgHzEq.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/2Vx98vFryEr6TVBpHIctg8szIeq.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.679,
                                        "filePath": "/AtbGhz8zHqof53atBp6Ze8UwylA.jpg",
                                        "height": 1236,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 839
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/5OHdnI7jjIn6jJXEBdgM0r0IbNe.jpg",
                                        "height": 900,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 600
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/hxd5duFW23jEck2oXykqUdeeMQh.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/s5WJzgyhIxBnpkf1S9qteY2KovN.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dFKynRGqACG1nVXMCFq6zEGFTTV.jpg",
                                        "height": 1500,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 1000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/dnF1P5FmjUHJUHUduxAoXgpd3nM.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/xaJy6zESJMn2wXcRBYLqjLF4fZ0.jpg",
                                        "height": 3000,
                                        "iso6391": "en",
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    },
                                    {
                                        "aspectRatio": 0.667,
                                        "filePath": "/wQBoiOLeCxbLFjoAUzv0Phhr8Yc.jpg",
                                        "height": 3000,
                                        "iso6391": null,
                                        "voteAverage": 0,
                                        "voteCount": 0,
                                        "width": 2000
                                    }
                                ],
                                "logos": [
                                    {
                                        "aspect_ratio": 4.502,
                                        "height": 956,
                                        "iso_639_1": "en",
                                        "file_path": "/miTr6IeG51il2U2HDykozKfu0Xn.png",
                                        "vote_average": 5.384,
                                        "vote_count": 2,
                                        "width": 4304
                                    },
                                    {
                                        "aspect_ratio": 4.503,
                                        "height": 173,
                                        "iso_639_1": "en",
                                        "file_path": "/yjxeUjfIWeyIZxhNXs8TAP8kij2.png",
                                        "vote_average": 5.312,
                                        "vote_count": 1,
                                        "width": 779
                                    },
                                    {
                                        "aspect_ratio": 4.539,
                                        "height": 362,
                                        "iso_639_1": "en",
                                        "file_path": "/oocKoWbxXnCImeGSkgNSHRg6s4N.png",
                                        "vote_average": 5.172,
                                        "vote_count": 1,
                                        "width": 1643
                                    },
                                    {
                                        "aspect_ratio": 4.562,
                                        "height": 384,
                                        "iso_639_1": "en",
                                        "file_path": "/sE6lFIwfgIYLGNhuJSWtN6nehKY.svg",
                                        "vote_average": 0,
                                        "vote_count": 0,
                                        "width": 1752
                                    }
                                ]
                            },
                            "videos": {
                                "id": null,
                                "results": [
                                    {
                                        "id": "61b7a07ee263bb0041cb80de",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "5vXLo9GJTxk",
                                        "name": "Matrix Resurrections - Featurette - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Featurette",
                                        "official": true,
                                        "publishedAt": "2021-12-07T15:22:44.000Z"
                                    },
                                    {
                                        "id": "61af10a56dea3a0060a9afa5",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "TCVe1eY8cYs",
                                        "name": "Matrix Resurrections - Trailer #2 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-12-06T17:00:16.000Z"
                                    },
                                    {
                                        "id": "613a07b3c68b69002bc2eb57",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "IJ6zlh5enpc",
                                        "name": "Matrix Resurrections - Offizieller Trailer #1 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Trailer",
                                        "official": true,
                                        "publishedAt": "2021-09-09T13:00:17.000Z"
                                    },
                                    {
                                        "id": "6138bb70da9ef20090c5905f",
                                        "iso6391": "de",
                                        "iso31661": "DE",
                                        "key": "XvsLA8WmAf0",
                                        "name": "Matrix Resurrections - Teaser Trailer #1 - Deutsch HD",
                                        "site": "YouTube",
                                        "size": 1080,
                                        "type": "Teaser",
                                        "official": true,
                                        "publishedAt": "2021-09-07T16:00:14.000Z"
                                    }
                                ]
                            },
                            "releaseDates": {
                                "id": null,
                                "results": [
                                    {
                                        "iso31661": "GB",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TW",
                                        "releaseDates": [
                                            {
                                                "certification": "保護級",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "KR",
                                        "releaseDates": [
                                            {
                                                "certification": "15",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AU",
                                        "releaseDates": [
                                            {
                                                "certification": "M",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-26T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IE",
                                        "releaseDates": [
                                            {
                                                "certification": "15A",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "ES",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "RU",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PL",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SG",
                                        "releaseDates": [
                                            {
                                                "certification": "PG13",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CZ",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "GR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "SE",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "IT",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-01-01T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "NL",
                                        "releaseDates": [
                                            {
                                                "certification": "12",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-16T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "AR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FR",
                                        "releaseDates": [
                                            {
                                                "certification": "U",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CA",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "DE",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2022-06-30T00:00:00.000Z",
                                                "type": 5,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "UA",
                                        "releaseDates": [
                                            {
                                                "certification": "16+",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "FI",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "BR",
                                        "releaseDates": [
                                            {
                                                "certification": "14",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "14",
                                                "iso6391": null,
                                                "releaseDate": "2022-01-26T00:00:00.000Z",
                                                "type": 4,
                                                "note": "HBO Max"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "US",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-18T00:00:00.000Z",
                                                "type": 1,
                                                "note": "San Francisco, CA"
                                            },
                                            {
                                                "certification": "R",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            },
                                            {
                                                "certification": "R",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 4,
                                                "note": "HBO Max"
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "CH",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "PT",
                                        "releaseDates": [
                                            {
                                                "certification": "M/14",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "TR",
                                        "releaseDates": [
                                            {
                                                "certification": null,
                                                "iso6391": null,
                                                "releaseDate": "2021-12-24T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    },
                                    {
                                        "iso31661": "HU",
                                        "releaseDates": [
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-22T00:00:00.000Z",
                                                "type": 1,
                                                "note": null
                                            },
                                            {
                                                "certification": "16",
                                                "iso6391": null,
                                                "releaseDate": "2021-12-23T00:00:00.000Z",
                                                "type": 3,
                                                "note": null
                                            }
                                        ]
                                    }
                                ]
                            },
                            "noRightsAvailable": false,
                            "noRightsTimestamp": null,
                            "budget": 190000000,
                            "lists": {
                                "page": 1,
                                "results": [
                                    {
                                        "favorite_count": 0,
                                        "id": 7104032,
                                        "item_count": 13,
                                        "iso_639_1": "tr",
                                        "list_type": "movie",
                                        "name": "Özel"
                                    },
                                    {
                                        "description": "A list of Movies running in Theaters",
                                        "favorite_count": 0,
                                        "id": 63802,
                                        "item_count": 30,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Now Running"
                                    },
                                    {
                                        "description": "watched movies",
                                        "favorite_count": 0,
                                        "id": 44662,
                                        "item_count": 1863,
                                        "iso_639_1": "ar",
                                        "list_type": "movie",
                                        "name": "Watched"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7053491,
                                        "item_count": 3171,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD (Popular)"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7109840,
                                        "item_count": 5918,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "New HD"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 143813,
                                        "item_count": 5336,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "MNR"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173719,
                                        "item_count": 10,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "To watch"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168656,
                                        "item_count": 51,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "TMDBNinjaList"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 80639,
                                        "item_count": 745,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Películas"
                                    },
                                    {
                                        "description": "Filmes que assisti em 2022, independente do ano de lançamento...",
                                        "favorite_count": 0,
                                        "id": 8173897,
                                        "item_count": 3,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "Filmes 2022"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8173900,
                                        "item_count": 8,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Want to Watch"
                                    },
                                    {
                                        "description": "Master List In Progress",
                                        "favorite_count": 0,
                                        "id": 51108,
                                        "item_count": 8926,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "My Movies"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8174019,
                                        "item_count": 3,
                                        "iso_639_1": "de",
                                        "list_type": "movie",
                                        "name": "Muss man nicht sehen"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 8168662,
                                        "item_count": 55,
                                        "iso_639_1": "be",
                                        "list_type": "movie",
                                        "name": "lista"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 89943,
                                        "item_count": 787,
                                        "iso_639_1": "fr",
                                        "list_type": "movie",
                                        "name": "Clem2k"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7111843,
                                        "item_count": 11,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "awk-player.watch-later-list.#preset_0"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7050333,
                                        "item_count": 756,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Seen"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 142602,
                                        "item_count": 1092,
                                        "iso_639_1": "es",
                                        "list_type": "movie",
                                        "name": "Accion"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7144,
                                        "item_count": 5278,
                                        "iso_639_1": "en",
                                        "list_type": "movie",
                                        "name": "Latest Releases"
                                    },
                                    {
                                        "favorite_count": 0,
                                        "id": 7107699,
                                        "item_count": 64,
                                        "iso_639_1": "pt",
                                        "list_type": "movie",
                                        "name": "ESTREIAS CINEMA"
                                    }
                                ],
                                "total_pages": 15,
                                "total_results": 288
                            }
                        },
                        "initComment": null,
                        "finalDate": "2022-01-04 19:50:00",
                        "ownContent": false,
                        "countPeople": "0",
                        "cinemaMarketing": false,
                        "corporation": false,
                        "eventImage": "https://image.tmdb.org/t/p/original/hf7jgehp4ypr2os5bqd1pxbg7qy.jpg",
                        "parentId": null,
                        "acceptedByCinemaTimestamp": null,
                        "finalAcceptedTimestamp": null,
                        "finalDeclinedTimestamp": null,
                        "state": "event_planned",
                        "date": [],
                        "declineReason": null,
                        "declineReasonLabel": null,
                        "businessRequest": null,
                        "insertTimestamp": "2022-01-03 10:31:45",
                        "updateTimestamp": "2022-01-03 10:31:45",
                        "shouldBePublished": true,
                        "historyDate": null,
                        "historyUserId": null,
                        "historyId": null
                    }
                ],
                "commentCount": "0"
            }
        ]

        });

    });
}

export default mockEventTypeRoutes
