import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconBill48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 2C6 1.44772 6.44772 1 7 1H10.0354C10.533 1 10.9549 1.36583 11.0254 1.8584C11.1985 3.069 12.2414 4 13.5 4C14.7586 4 15.8015 3.069 15.9746 1.8584C16.0451 1.36583 16.467 1 16.9646 1H20.0354C20.533 1 20.9549 1.36583 21.0254 1.8584C21.1985 3.069 22.2414 4 23.5 4C24.7586 4 25.8015 3.069 25.9746 1.8584C26.0451 1.36583 26.467 1 26.9646 1H30.0354C30.533 1 30.9549 1.36583 31.0254 1.8584C31.1985 3.069 32.2414 4 33.5 4C34.7586 4 35.8015 3.069 35.9746 1.8584C36.0451 1.36583 36.467 1 36.9646 1H41C41.5523 1 42 1.44772 42 2V46C42 46.5523 41.5523 47 41 47H36.9646C36.467 47 36.0451 46.6342 35.9746 46.1416C35.8015 44.931 34.7586 44 33.5 44C32.2414 44 31.1985 44.931 31.0254 46.1416C30.9549 46.6342 30.533 47 30.0354 47H26.9646C26.467 47 26.0451 46.6342 25.9746 46.1416C25.8015 44.931 24.7586 44 23.5 44C22.2414 44 21.1985 44.931 21.0254 46.1416C20.9549 46.6342 20.533 47 20.0354 47H16.9646C16.467 47 16.0451 46.6342 15.9746 46.1416C15.8015 44.931 14.7586 44 13.5 44C12.2414 44 11.1985 44.931 11.0254 46.1416C10.9549 46.6342 10.533 47 10.0354 47H7C6.44772 47 6 46.5523 6 46V2ZM8 3V45H9.25633C9.87425 43.252 11.5403 42 13.5 42C15.4597 42 17.1257 43.252 17.7437 45H19.2563C19.8743 43.252 21.5403 42 23.5 42C25.4597 42 27.1257 43.252 27.7437 45H29.2563C29.8743 43.252 31.5403 42 33.5 42C35.4597 42 37.1257 43.252 37.7437 45H40V3H37.7437C37.1257 4.74798 35.4597 6 33.5 6C31.5403 6 29.8743 4.74798 29.2563 3H27.7437C27.1257 4.74798 25.4597 6 23.5 6C21.5403 6 19.8743 4.74798 19.2563 3H17.7437C17.1257 4.74798 15.4597 6 13.5 6C11.5403 6 9.87425 4.74798 9.25633 3H8Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36 17H12V15H36V17Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36 25H12V23H36V25Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36 33H12V31H36V33Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconBill24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2 1C2 0.447715 2.44772 0 3 0H6.60061C6.87009 0 7.12816 0.108763 7.31636 0.301645C7.50455 0.494526 7.60693 0.755199 7.6003 1.0246C7.60009 1.03345 7.59998 1.04234 7.59998 1.05128C7.59998 1.6292 8.07058 2.10256 8.65712 2.10256C9.24365 2.10256 9.71426 1.6292 9.71426 1.05128C9.71426 1.04233 9.71415 1.03344 9.71393 1.02461C9.7073 0.755211 9.80968 0.494534 9.99788 0.30165C10.1861 0.108765 10.4441 0 10.7136 0H13.8006C14.0701 0 14.3282 0.108765 14.5164 0.30165C14.7046 0.494534 14.8069 0.755211 14.8003 1.02461C14.8001 1.03344 14.8 1.04233 14.8 1.05128C14.8 1.6292 15.2706 2.10256 15.8571 2.10256C16.4437 2.10256 16.9143 1.6292 16.9143 1.05128C16.9143 1.04233 16.9142 1.03344 16.9139 1.02461C16.9073 0.755211 17.0097 0.494534 17.1979 0.30165C17.3861 0.108765 17.6442 0 17.9136 0H21C21.5523 0 22 0.447715 22 1V23C22 23.5523 21.5523 24 21 24H18.1143C17.562 24 17.1143 23.5523 17.1143 23C17.1143 22.4741 16.6677 22 16.0571 22C15.4466 22 15 22.4741 15 23C15 23.5523 14.5523 24 14 24H11.1143C10.562 24 10.1143 23.5523 10.1143 23C10.1143 22.4741 9.66773 22 9.05714 22C8.44656 22 8 22.4741 8 23C8 23.5523 7.55228 24 7 24H3C2.44772 24 2 23.5523 2 23V1ZM4 2V22H6.17303C6.59579 20.8233 7.74325 20 9.05714 20C10.371 20 11.5185 20.8233 11.9413 22H13.173C13.5958 20.8233 14.7432 20 16.0571 20C17.371 20 18.5185 20.8233 18.9413 22H20V2H18.7637C18.3637 3.22194 17.2119 4.10256 15.8571 4.10256C14.5024 4.10256 13.3506 3.22193 12.9506 2H11.5637C11.1637 3.22193 10.0119 4.10256 8.65712 4.10256C7.30234 4.10256 6.15057 3.22194 5.75055 2H4Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18 9H6V7H18V9Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18 13H6V11H18V13Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18 17H6V15H18V17Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconBill16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 7H4V5H12V7Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12 11H4V9H12V11Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1 1C1 0.447715 1.44772 0 2 0H4.08535C4.50917 0 4.88696 0.267162 5.02819 0.666754C5.09731 0.862301 5.28386 1 5.5 1C5.71614 1 5.90269 0.862301 5.97181 0.666754C6.11304 0.267162 6.49083 0 6.91465 0H9.08535C9.50917 0 9.88696 0.267162 10.0282 0.666754C10.0973 0.862301 10.2839 1 10.5 1C10.7161 1 10.9027 0.862301 10.9718 0.666754C11.113 0.267162 11.4908 0 11.9146 0H14C14.5523 0 15 0.447715 15 1V15C15 15.5523 14.5523 16 14 16H11.9146C11.4908 16 11.113 15.7328 10.9718 15.3332C10.9027 15.1377 10.7161 15 10.5 15C10.2839 15 10.0973 15.1377 10.0282 15.3332C9.88696 15.7328 9.50917 16 9.08535 16H6.91465C6.49083 16 6.11304 15.7328 5.97181 15.3332C5.90269 15.1377 5.71614 15 5.5 15C5.28386 15 5.09731 15.1377 5.02819 15.3332C4.88696 15.7328 4.50917 16 4.08535 16H2C1.44772 16 1 15.5523 1 15V1ZM3 2V14H3.5001C3.95572 13.3933 4.68121 13 5.5 13C6.31879 13 7.04427 13.3933 7.4999 14H8.5001C8.95573 13.3933 9.68121 13 10.5 13C11.3188 13 12.0443 13.3933 12.4999 14H13V2H12.4999C12.0443 2.60669 11.3188 3 10.5 3C9.68121 3 8.95572 2.60669 8.5001 2H7.4999C7.04428 2.60669 6.31879 3 5.5 3C4.68121 3 3.95572 2.60669 3.5001 2H3Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
