import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconSeat48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48" 
             viewBox="0 0 48 48" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7861 2.53399L11.3327 8H4.66602L4.93601 2.60008C4.97149 1.89047 5.55718 1.33333 6.26768 1.33333H9.45937C10.1444 1.33333 10.7179 1.85239 10.7861 2.53399ZM3.33435 7.93342C3.32716 8.07713 3.34333 8.21952 3.38095 8.35551C3.00981 8.71216 2.74619 9.30696 2.68145 10H1.99935V6.66667C1.99935 6.29848 2.29783 6 2.66602 6H3.43102L3.33435 7.93342ZM2.66602 11.3333H1.33268C0.964492 11.3333 0.666016 11.0349 0.666016 10.6667V6.66667C0.666016 5.5621 1.56145 4.66667 2.66602 4.66667H3.49769L3.60434 2.5335C3.6753 1.11428 4.84668 0 6.26768 0H9.45937C10.8294 0 11.9765 1.03812 12.1128 2.40132L12.3393 4.66667H13.3327C14.4373 4.66667 15.3327 5.5621 15.3327 6.66667V10.6667C15.3327 11.0349 15.0342 11.3333 14.666 11.3333H13.3327V12.0833C13.3327 12.4055 13.1621 12.6667 12.9517 12.6667H11.3327V14.3333H11.9993V16.3333H3.99935V14.3333H4.66602V12.6667H3.04697C2.83657 12.6667 2.66602 12.4055 2.66602 12.0833V11.3333ZM10.0682 14.3333H5.99935V12.6667H9.99935L10.0682 14.3333ZM12.6594 7.86733L12.4727 6H13.3327C13.7009 6 13.9993 6.29848 13.9993 6.66667V10H13.3173C13.2525 9.30696 12.9889 8.71216 12.6177 8.35551C12.6612 8.19842 12.676 8.03303 12.6594 7.86733ZM4.28763 9.33333H4.66602H11.3327H11.7111C11.7262 9.35041 11.7461 9.37578 11.77 9.41247C11.8848 9.58826 11.9993 9.91006 11.9993 10.3333V11.3333H10.0845L10.0827 11.3333H5.91602L5.91418 11.3333H3.99935V10.3333C3.99935 9.91006 4.11389 9.58826 4.22869 9.41247C4.25265 9.37578 4.2725 9.35041 4.28763 9.33333ZM11.677 9.30214C11.6771 9.30168 11.6805 9.30354 11.687 9.30909C11.6802 9.30537 11.6769 9.30259 11.677 9.30214ZM4.31168 9.30909C4.31815 9.30354 4.32158 9.30168 4.32168 9.30214C4.32177 9.30259 4.31854 9.30537 4.31168 9.30909Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconSeat24(props: CustomSVGProps): JSX.Element {
    return (
        <svg  {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.1801 3.80099L17 12H7L7.405 3.90013C7.45822 2.83571 8.33675 2 9.4025 2H14.19C15.2175 2 16.0779 2.77859 16.1801 3.80099ZM5.0025 11.9001C4.99172 12.1157 5.01597 12.3293 5.0724 12.5333C4.51569 13.0682 4.12026 13.9604 4.02315 15H3V10C3 9.44772 3.44772 9 4 9H5.1475L5.0025 11.9001ZM4 17H2C1.44772 17 1 16.5523 1 16V10C1 8.34315 2.34315 7 4 7H5.2475L5.40749 3.80025C5.51393 1.67142 7.271 0 9.4025 0H14.19C16.245 0 17.9657 1.55718 18.1702 3.60198L18.51 7H20C21.6569 7 23 8.34315 23 10V16C23 16.5523 22.5523 17 22 17H20V18.125C20 18.6082 19.7442 19 19.4286 19H17V22H18V24H15.125H8.875H6V22H7V19H4.57143C4.25584 19 4 18.6082 4 18.125V17ZM15.1239 22H9V19H15L15.1239 22ZM18.9901 11.801L18.71 9H20C20.5523 9 21 9.44772 21 10V15H19.9769C19.8797 13.9604 19.4843 13.0682 18.9276 12.5333C18.9928 12.2976 19.0149 12.0496 18.9901 11.801ZM6.43242 14H7H17H17.5676C17.5903 14.0256 17.6201 14.0637 17.656 14.1187C17.8282 14.3824 18 14.8651 18 15.5V17H15.125H8.875H6V15.5C6 14.8651 6.17181 14.3824 6.34401 14.1187C6.37995 14.0637 6.40973 14.0256 6.43242 14ZM17.5165 13.9532C17.5167 13.9525 17.5218 13.9553 17.5315 13.9636C17.5212 13.9581 17.5164 13.9539 17.5165 13.9532ZM6.4685 13.9636C6.47821 13.9553 6.48335 13.9525 6.48349 13.9532C6.48363 13.9539 6.47878 13.9581 6.4685 13.9636Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

);
}

export function IconSeat16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" 
             height="16" 
             viewBox="0 0 16 16" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7861 2.53399L11.3327 8H4.66602L4.93601 2.60008C4.97149 1.89047 5.55718 1.33333 6.26768 1.33333H9.45937C10.1444 1.33333 10.7179 1.85239 10.7861 2.53399ZM3.33435 7.93342C3.32716 8.07713 3.34333 8.21952 3.38095 8.35551C3.00981 8.71216 2.74619 9.30696 2.68145 10H1.99935V6.66667C1.99935 6.29848 2.29783 6 2.66602 6H3.43102L3.33435 7.93342ZM2.66602 11.3333H1.33268C0.964492 11.3333 0.666016 11.0349 0.666016 10.6667V6.66667C0.666016 5.5621 1.56145 4.66667 2.66602 4.66667H3.49769L3.60434 2.5335C3.6753 1.11428 4.84668 0 6.26768 0H9.45937C10.8294 0 11.9765 1.03812 12.1128 2.40132L12.3393 4.66667H13.3327C14.4373 4.66667 15.3327 5.5621 15.3327 6.66667V10.6667C15.3327 11.0349 15.0342 11.3333 14.666 11.3333H13.3327V12.0833C13.3327 12.4055 13.1621 12.6667 12.9517 12.6667H11.3327V14.3333H11.9993V16.3333H3.99935V14.3333H4.66602V12.6667H3.04697C2.83657 12.6667 2.66602 12.4055 2.66602 12.0833V11.3333ZM10.0682 14.3333H5.99935V12.6667H9.99935L10.0682 14.3333ZM12.6594 7.86733L12.4727 6H13.3327C13.7009 6 13.9993 6.29848 13.9993 6.66667V10H13.3173C13.2525 9.30696 12.9889 8.71216 12.6177 8.35551C12.6612 8.19842 12.676 8.03303 12.6594 7.86733ZM4.28763 9.33333H4.66602H11.3327H11.7111C11.7262 9.35041 11.7461 9.37578 11.77 9.41247C11.8848 9.58826 11.9993 9.91006 11.9993 10.3333V11.3333H10.0845L10.0827 11.3333H5.91602L5.91418 11.3333H3.99935V10.3333C3.99935 9.91006 4.11389 9.58826 4.22869 9.41247C4.25265 9.37578 4.2725 9.35041 4.28763 9.33333ZM11.677 9.30214C11.6771 9.30168 11.6805 9.30354 11.687 9.30909C11.6802 9.30537 11.6769 9.30259 11.677 9.30214ZM4.31168 9.30909C4.31815 9.30354 4.32158 9.30168 4.32168 9.30214C4.32177 9.30259 4.31854 9.30537 4.31168 9.30909Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}