import { CustomSVGProps } from '../types/CustomSVGProps.types';

export function ImageCineamo48(props: CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5069 1.82324C16.7717 1.83801 15.3697 3.24921 15.3697 4.98799V13.0244C15.3697 10.7834 17.6345 9.25246 19.714 10.0875L23.0919 11.4444L46.6504 21.2194C47.4864 21.5637 48.285 20.6552 47.8363 19.8704L38.4346 3.41773C37.8759 2.43986 36.8401 1.83329 35.7155 1.82324H18.5069Z"
                fill="url(#paint0_linear_1685_21325)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0127 1.82324C12.888 1.83329 11.8522 2.43986 11.2934 3.41758L9.79311 6.04311L0.442559 22.4068C-0.113503 23.3797 -0.113503 24.5742 0.442559 25.5472L8.4175 39.4713C9.20751 40.854 10.902 41.4343 12.3739 40.826L17.4583 38.725L19.428 37.916C17.4353 38.5097 15.3695 37.022 15.3695 34.8809V30.3066V4.98799C15.3695 3.24921 16.7716 1.83801 18.5068 1.82324H14.0127Z"
                fill="url(#paint1_linear_1685_21325)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.8364 28.0837L38.4347 44.5363C37.8713 45.5223 36.8226 46.1309 35.6869 46.1309H14.0414C12.9056 46.1309 11.857 45.5225 11.2936 44.5363L9.79318 41.9109L8.41757 39.4715C9.20758 40.8542 10.9022 41.4345 12.374 40.8261L17.4584 38.7252L46.6505 26.7347C47.4865 26.3904 48.2849 27.2989 47.8364 28.0837Z"
                fill="#00FFDC"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1685_21325"
                    x1="58.9337"
                    y1="7.60418"
                    x2="38.064"
                    y2="-15.9079"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC" />
                    <stop
                        offset="0.490223"
                        stopColor="#00FFDC"
                    />
                    <stop
                        offset="1"
                        stopColor="#00AAA0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1685_21325"
                    x1="0.370614"
                    y1="1.82324"
                    x2="0.370614"
                    y2="39.6704"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC" />
                    <stop
                        offset="0.509799"
                        stopColor="#00FFDC"
                    />
                    <stop
                        offset="1"
                        stopColor="#00AAA0"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function ImageCineamo24(props: CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.53997 1C8.68821 1.00759 8 1.73235 8 2.62535V6.5C8 5.3491 9 5 9.53997 5C10.0799 5 11.7905 5.94122 11.7905 5.94122L23.3545 10.9615C23.7648 11.1383 24.1568 10.6717 23.9366 10.2686L19.3217 1.8189C19.0474 1.31668 18.539 1.00516 17.9869 1H9.53997Z"
                fill="url(#paint0_linear_1685_21349)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99358 1C6.43124 1.00499 5.91336 1.30617 5.63393 1.79163L4.8838 3.09528L0.208523 11.2203C-0.0695077 11.7034 -0.0695077 12.2965 0.208523 12.7796L4.19599 19.6933C4.591 20.3799 5.43823 20.668 6.17418 20.3659L8.71641 19.3228L10.0292 18.9211C9.03289 19.2159 8 18.4772 8 17.4141V15.1428V2.57139C8 1.70803 8.70102 1.00733 9.56864 1L6.99358 1Z"
                fill="url(#paint1_linear_1685_21349)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9054 14.0391L19.2046 22.2083C18.9229 22.6979 18.3986 23.0001 17.8307 23.0001H7.00796C6.44008 23.0001 5.91577 22.6979 5.63405 22.2083L4.88385 20.9047L4.19604 19.6935C4.59105 20.38 5.43836 20.6681 6.17424 20.3661L8.71646 19.3229L23.3125 13.3693C23.7305 13.1983 24.1297 13.6494 23.9054 14.0391Z"
                fill="#00FFDC"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1685_21349"
                    x1="29.3839"
                    y1="3.96898"
                    x2="18.6188"
                    y2="-7.62253"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC" />
                    <stop
                        offset="0.490223"
                        stopColor="#00FFDC"
                    />
                    <stop
                        offset="1"
                        stopColor="#00AAA0"
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1685_21349"
                    x1="0.17255"
                    y1="1"
                    x2="0.17255"
                    y2="19.7922"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFDC" />
                    <stop
                        offset="0.509799"
                        stopColor="#00FFDC"
                    />
                    <stop
                        offset="1"
                        stopColor="#00AAA0"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
}
