import { TFunction, useTranslation } from 'next-i18next';
import { Path, UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';

import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldPath } from 'react-hook-form/dist/types/path';

import { ApiErrorResponse } from '../../../api/ApiErrorResponse.types';
import { IconErrorSolid24 } from '../../../icons/symbols/ic-errorSolid';
import { InputErrorKey } from '../../../types/InputErrorKey.types';
import { showToastNew } from '../../toast-helper';

export type AffectedField<T> = {
    input: FieldPath<T>;
    errors: {
        message: string;
        messageKeyForTranslation: InputErrorKey;
    }[];
};

export function setAllFormErrors<T>(
    formErrorResponse: ApiErrorResponse<T>,
    useFormSetError: UseFormSetError<T>,
    toastProp: typeof toast,
    options?: {
        affectedFieldsCallback?: (affectedFields: AffectedField<T>[]) => void;
        t?: TFunction;
    }
): void {
    const formObject = formErrorResponse?.response?.data?.parameters || [];
    const formsWithError: FieldPath<T>[] = Object.keys(formObject) as FieldPath<T>[];

    const t = options?.t || useTranslation('error').t;

    const affectedFields: AffectedField<T>[] = formsWithError.map((formWithError) => {
        // E.g. "user" Form
        const fieldErrors: InputErrorKey[] = Object.keys(formObject[formWithError as string]) as InputErrorKey[];
        return {
            input: formWithError,
            errors: fieldErrors.map((fieldError) => {
                const errors = formObject[formWithError as string];
                const errorMessage: string = errors[fieldError];
                return {
                    messageKeyForTranslation: fieldError,
                    message: errorMessage
                };
            })
        };
    });

    affectedFields.forEach((form) => {
        form.errors.forEach((error) => {
            useFormSetError(form.input, {
                type: 'manual',
                message: options?.t ? t(`error:${error.messageKeyForTranslation}`) : error.message
            });
        });
    });

    if (affectedFields.length > 0 && t) {
        showToastNew({
            toastType: toastProp.info,
            messageTitle: t('error:something-went-wrong'),
            messageBody: t('error:please-check-your-inputs'),
            messageIcon: <IconErrorSolid24 className="text-red" />,
            closeButton: false
        });
    } else if (formErrorResponse?.response?.status >= 500) {
        showToastNew({
            toastType: toastProp.info,
            messageTitle: t('error:something-went-wrong'),
            messageIcon: <IconErrorSolid24 className="text-red" />,
            closeButton: false
        });
    } else if (formErrorResponse?.response?.status === 403) {
        showToastNew({
            toastType: toastProp.info,
            messageTitle: t('error:authorization-issue'),
            messageIcon: <IconErrorSolid24 className="text-red" />,
            closeButton: false
        });
    }

    if (typeof options?.affectedFieldsCallback === 'function') {
        options.affectedFieldsCallback(affectedFields);
    }
}

export function onInvalidSetErrorMessage<T extends FieldValues>(error, setError: UseFormSetError<T>, t?: TFunction) {
    Object.keys(error).forEach((key) =>
        setError(key as Path<T>, {
            message: error[key].type === 'required' ? t('error:isEmpty') : ''
        })
    );
}
