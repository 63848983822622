import create from 'zustand';

import { CinemaDto } from '../../models/cinema/CinemaDto.types';
import { IsomorphicStore, createIsomorphicStore } from '../index';

import { CinemaState, CinemaStore } from './cinemaStore.types';

/**
 * Here you can add more state related to a cinema
 * Note that you can consume cinemaId using the useCinemaStore
 * or by accessing it via props in page components.
 */
const initialState: CinemaState = {
    cinema: undefined,
    slug: undefined,
    cinemaId: undefined,
    cinemaConfiguration: undefined
};

const initializeStore: IsomorphicStore<CinemaStore, CinemaState> = (preloadedState) =>
    create<CinemaStore>((set) => ({
        ...initialState,
        ...preloadedState,
        setCinema(cinema?: CinemaDto | null) {
            set({
                cinema: cinema || undefined,
                cinemaConfiguration: cinema?._embedded?.cinemaConfiguration || undefined,
                cinemaId: cinema?.id || undefined,
                slug: cinema?.slug || undefined
            });
        },
        clearCinema() {
            set({
                cinema: undefined,
                cinemaConfiguration: undefined,
                slug: undefined,
                cinemaId: undefined
            });
        }
    }));

const {
    StoreProvider: CinemaStoreProvider,
    useStore: useCinemaStore,
    useCreateStore: useCreateCinemaStore
} = createIsomorphicStore<typeof initializeStore, CinemaStore, CinemaState>(initializeStore);

export { CinemaStoreProvider, useCinemaStore, useCreateCinemaStore };
