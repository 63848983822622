import { toast } from 'react-toastify';

import { showToastNew } from './toast-helper';

export function copyToClipboard(text: string, confirmationToastMessage?: string): void {
    if (typeof document !== 'undefined' && document.body) {
        const textAreaTmp = document.createElement('textarea');
        try {
            document.activeElement.appendChild(textAreaTmp);
        } catch {
            document.body.appendChild(textAreaTmp);
        }
        textAreaTmp.id = '__tmp__copy__to__clipboard__elem';
        textAreaTmp.style.position = 'fixed';
        textAreaTmp.style.left = '-9999';
        textAreaTmp.style.top = '-9999';
        textAreaTmp.style.opacity = '0';
        textAreaTmp.innerText = text;
        textAreaTmp.focus();
        textAreaTmp.select();
        // eslint-disable-next-line deprecation/deprecation
        document.execCommand('copy');

        document.getElementById('__tmp__copy__to__clipboard__elem').remove();

        if (confirmationToastMessage) {
            showToastNew({
                toastType: toast.info,
                messageTitle: confirmationToastMessage
            });
        }
    }
}
