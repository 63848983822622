import { Role } from '../constants/role-constants';
import { UserMeDto } from '../models/user/UserMeDto.types';

import { getUserTypeByRoles } from './user-helper';

export type Permission = 'admin_administration' | 'cinema_administration' | 'distributor_administration';

function useUserGetPermissions(user: UserMeDto): Permission[] {
    const userTypes = getUserTypeByRoles(user.roles);
    const permissions: Permission[] = [];

    userTypes.forEach((userType) => {
        if (userType === Role.ROLE_ADMIN) {
            permissions.push('admin_administration');
        }
        if (userType === Role.ROLE_ADMIN || userType === Role.ROLE_CINEMA) {
            permissions.push('cinema_administration');
        }
        if (userType === Role.ROLE_ADMIN || userType === Role.ROLE_DISTRIBUTOR) {
            permissions.push('distributor_administration');
        }
    });

    return permissions;
}

export default useUserGetPermissions;
