import { useRouter } from 'next/router';

export default function useRouterQueryKeyValue(queryIdName: string): {
    queryKey: string;
    queryValue: string | null;
    exists: boolean;
} {
    const router = useRouter();

    const queryId: string | null = getValueFromUrlQuery(router.query, queryIdName);

    return {
        queryKey: queryIdName,
        queryValue: queryId,
        exists: !!router.query
    };
}

// WDYT about this? This will not use 'queryId' as a key to access the query id we want, it will use the name of the
// query id as the key to access the query id.
// Example:
// OLD: const { queryId } = useRouterQueryId('distributorId');
// NEW: const { distributorId } = useRouterQueryId('distributorId');
//
// export default function useRouterQueryId<T extends string = string>(
//     queryIdName: T
// ): {
//     [x in T]: string;
// } & { queryIdName: string } {
//     const router = useRouter();
//     const queryId: string | null = getQueryIdFromQuery(router.query, queryIdName);
//     return { [queryIdName as keyof T]: queryId, queryIdName: queryIdName } as { [x in T]: string } & {
//         queryIdName: string;
//     };
// }

export function getValueFromUrlQuery(query: object, key: string): string {
    return typeof query?.[key] === 'string' ? query[key] : null;
}
