import {Server, Response} from 'miragejs'

const mockUserRoutes = (server: Server) => {
    server.get('https://api.cineamo.test/users/me', (_, request) => {

        return new Response(200, {}, {
            user: {
                userId: "2",
                username: "testuser",
                email: "info@cineamo.com",
                activated: true,
                firstName: "Peter",
                lastName: "Parker",
                phone: "01265846383",
                streetNumber: "1",
                zip: "12345",
                city: "New York",
                imageUrl: "",
                birthDate: "",
                userType: "cinema",
                usePersonName: false,
                plugins: null,
            }
        });

    });

    server.get('https://api.cineamo.test/users/1', (_, request) => {

        return new Response(200, {}, {
            user: {
                userId: "2",
                username: "testuser",
                email: "info@cineamo.com",
                activated: true,
                firstName: "Peter",
                lastName: "Parker",
                phone: "01265846383",
                streetNumber: "1",
                zip: "12345",
                city: "New York",
                imageUrl: "",
                birthDate: "",
                userType: "cinema",
                usePersonName: false,
                plugins: null,
            }
        });

    });

    server.get('https://api.cineamo.test/users', (_, request) => {

        return new Response(200, {}, {
            users: [
                {
                    userId: "1",
                    username: "spidy",
                    email: "info1@cineamo.com",
                    activated: true,
                    firstName: "Peter",
                    lastName: "Parker",
                    phone: "01265846383",
                    streetNumber: "1",
                    zip: "12345",
                    city: "New York",
                    imageUrl: "https://www.themoviedb.org/t/p/w1280/ncF4HivY2W6SQW5dEP3N3I4mfT0.jpg",
                    birthDate: "",
                    userType: "cinema",
                    usePersonName: false,
                    plugins: null,
                }, {
                    userId: "2",
                    username: "cobold",
                    email: "info2@cineamo.com",
                    activated: true,
                    firstName: "Norman",
                    lastName: "Osborn",
                    phone: "01265846383",
                    streetNumber: "1",
                    zip: "12345",
                    city: "New York",
                    imageUrl: "https://www.themoviedb.org/t/p/w1280/ui8e4sgZAwMPi3hzEO53jyBJF9B.jpg",
                    birthDate: "",
                    userType: "cinema",
                    usePersonName: false,
                    plugins: null,
                }, {
                    userId: "4",
                    username: "otto",
                    email: "info4@cineamo.com",
                    activated: true,
                    firstName: "Otto",
                    lastName: "Ocatvius",
                    phone: "01265846383",
                    streetNumber: "1",
                    zip: "12345",
                    city: "New York",
                    imageUrl: "https://www.themoviedb.org/t/p/w1280/nJo91Czesn6z0d0pkfbDoVZY3sg.jpg",
                    birthDate: "",
                    userType: "cinema",
                    usePersonName: false,
                    plugins: null,
                }
            ]
        });

    });
}

export default mockUserRoutes
