import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconBell48(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.075 5.33062C22.0158 5.11422 22.995 5 24 5C25.005 5 25.9842 5.11422 26.925 5.33062C26.6209 3.99599 25.4268 3 24 3C22.5732 3 21.3791 3.99599 21.075 5.33062ZM19 6C19 3.23858 21.2386 1 24 1C26.7614 1 29 3.23858 29 6C29 6.28147 28.9766 6.55825 28.9316 6.82829L28.7363 7.9977L27.6166 7.60806C26.4852 7.21437 25.2687 7 24 7C22.7313 7 21.5148 7.21437 20.3834 7.60806L19.2637 7.9977L19.0684 6.82829C19.0234 6.55825 19 6.28147 19 6Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14 25.2984V17C14 11.4772 18.4772 7 24 7C29.5228 7 34 11.4772 34 17V25.2984C34 26.2067 34.3091 27.088 34.8765 27.7972L41.7894 36.4383C41.9257 36.6087 42 36.8205 42 37.0387C42 37.5696 41.5696 38 41.0387 38H30H28H20H18H6.96125C6.43037 38 6 37.5696 6 37.0387C6 36.8205 6.07429 36.6087 6.21064 36.4383L13.1235 27.7972C13.6909 27.088 14 26.2067 14 25.2984ZM18 40H6.96125C5.3258 40 4 38.6742 4 37.0387C4 36.3663 4.22885 35.7139 4.6489 35.1889L11.5617 26.5478C11.8454 26.1932 12 25.7526 12 25.2984V17C12 10.3726 17.3726 5 24 5C30.6274 5 36 10.3726 36 17V25.2984C36 25.7526 36.1546 26.1932 36.4383 26.5478L43.3511 35.1889C43.7712 35.7139 44 36.3663 44 37.0387C44 38.6742 42.6742 40 41.0387 40H30C30 43.3137 27.3137 46 24 46C20.6863 46 18 43.3137 18 40ZM28 40H20C20 42.2091 21.7909 44 24 44C26.2091 44 28 42.2091 28 40Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

    );
}

export function IconBell24(props: CustomSVGProps) : JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.6606 14.0273L19.4388 17.5H15H9H4.56125L7.33945 14.0273C7.76704 13.4928 8 12.8287 8 12.1442V8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8V12.1442C16 12.8287 16.233 13.4928 16.6606 14.0273ZM3.48062 19.5C2.6629 19.5 2 18.8371 2 18.0194C2 17.6832 2.11442 17.357 2.32445 17.0944L5.77771 12.7779C5.92161 12.598 6 12.3745 6 12.1442V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V12.1442C18 12.3745 18.0784 12.598 18.2223 12.7779L21.6755 17.0944C21.8856 17.357 22 17.6832 22 18.0194C22 18.8371 21.3371 19.5 20.5194 19.5H3.48062Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8 19C8 21.2091 9.79086 23 12 23C14.2091 23 16 21.2091 16 19H14C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19H8Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10 2C10 0.895433 10.8954 0 12 0C13.1046 0 14 0.895433 14 2C14 3.10457 13.1046 4 12 4C10.8954 4 10 3.10457 10 2Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

    );
}
