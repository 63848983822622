import { useTranslation } from 'next-i18next';
import React from 'react';

import { NavigationDataGroup } from '@/components/navigation/data/NavigationDataItem.type';
import SideNavigationItem from '@/components/navigation/SidebarNavigationItem';

export type NavigationItemGroupProps = {
    navigation: NavigationDataGroup;
    modalOpeners: { [modalName: string]: (setIsOpen: boolean) => void };
};

function NavigationItemGroup(props: NavigationItemGroupProps) {
    const { navigation } = props;

    const { t } = useTranslation('navigation');

    return (
        <div>
            {navigation.label && (
                <label className="block pl-20 pr-16 mt-28 text-gray uppercase tracking-wider font-montserrat-semibold text-overline-10">
                    {t(navigation.label)}
                </label>
            )}
            {navigation.children?.map((item, index: number) =>
                React.isValidElement(item) ? (
                    <div
                        key={index}
                        className="pl-20 pr-16">
                        {item}
                    </div>
                ) : (
                    <SideNavigationItem
                        key={index}
                        link={item.href}
                        icon={item.icon}
                        text={t(item.title)}
                        neededPermission={item.necessaryPermission}
                        onClick={() => {
                            if (item.modal) {
                                props.modalOpeners[item.modal](true);
                            }
                        }}
                    />
                )
            )}
        </div>
    );
}

export default NavigationItemGroup;
